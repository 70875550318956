<template>
  <div>
    <!-- DE BOX WAARIN DE BASIS META INFO WORDT GETOOND -->
    <q-dialog v-model="showMetaInfo">
      <q-card style="width: 700px; max-width: 80vw">
        <q-bar :class="`bg-${getColor} text-white`"
          >Meta information for {{ type }}</q-bar
        >
        <q-card-section>
          <q-markup-table flat>
            <tr>
              <td>section</td>
              <td>{{ meta.sectionType }}</td>
            </tr>
            <tr :key="i" v-for="(field, i) in meta.fields">
              <td>{{ field.label }}</td>
              <td>{{ field.value }}</td>
            </tr>
          </q-markup-table>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn :color="getColor" label="Ok" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- THE SEARCH-AND-SELECT IMPORT -->
    <q-dialog full-height full-width v-model="showSASimporter">
      <q-card style="width: 700px; max-width: 80vw">
        <q-bar>
          Import Europeana object
          <q-space />
          <q-btn dense v-close-popup flat icon="fa fa-times" />
        </q-bar>

        <q-scroll-area>
          <q-card-section class="q-mb-xl">
            <h4>{{ title }}</h4>

            <q-spinner-radio size="xl" v-if="showImageSpinner" />
            <q-img
              :src="bestImage"
              @error="imageError"
              @load="startimageload"
            />

            <q-banner v-if="isImageError" rounded class="text-white bg-red">
              <h5 class="bg-red">Error</h5>
              <p>
                Cannot load the image, please try to open it directlty in your
                browser.
                <br />Click here to try
                <a :href="bestImage" target="_imagetab">open in new tab</a>
                and copy the image to your clipboard
              </p>
              <p>If that fails the image is not available at this moment.</p>
            </q-banner>

            <h4>Meta</h4>

            <q-markup-table flat>
              <tr>
                <td>type</td>
                <td>{{ meta.type }}</td>
              </tr>
              <tr :key="i" v-for="(field, i) in meta.fields">
                <td>{{ field.label }}</td>
                <td>{{ field.value }}</td>
              </tr>
            </q-markup-table>

            <q-card-actions align="center">
              <q-btn no-caps class="primary" label="Cancel" v-close-popup />
              <q-btn
                v-if="!isImageError"
                label="Add to My Historiana"
                @click="
                  addToMyHistoriana(bestImage, bestImageId, {
                    title: title,
                    description: description,
                  })
                "
              />
            </q-card-actions>

            <!--q-expansion-item icon="fa fa-expand" style="background-color: #ccc" label="Show raw data" class="q-col-2">
          <pre style="font-size:10px">{{ meta }}</pre>
            </q-expansion-item-->
          </q-card-section>
        </q-scroll-area>
      </q-card>
    </q-dialog>

    <!-- SHOW META INFO DIALOG -->
    <!-- to do: make pop-up meta info smaller e.g. medium -->
    <q-dialog
      v-if="meta"
      v-model="xxshowMetaInfo"
      :meta="meta"
      @open="openMeta(meta)"
    >
      <q-layout view="Lhh lpR fff" container class="bg-white">
        <div v-if="partner">
          <img
            width="40%"
            style="float: right"
            v-if="partner.logo"
            :src="partner.logo"
          />

          <h4>{{ partner.name }}</h4>
          <span v-if="partner.meta" v-html="partner.meta.text" />
        </div>

        <div
          class="meta-title-dialog bg-green text-h6 q-pa-md flex flex-center"
        >
          <q-avatar color="white" icon="fas fa-info" />
          <div class="text-h6 white meta-title q-ml-md">Meta information</div>
        </div>

        <table>
          <tr class="meta">
            <td class="meta-label">type</td>
            <td class="meta-value">{{ current.type }}</td>
          </tr>
          <tr class="meta" v-for="(mvalue, mkey) in current.meta" :key="mkey">
            <td clas="meta-label">{{ mkey }}</td>
            <td class="meta-value">{{ mvalue }}</td>
          </tr>
        </table>

        <div v-if="editMeta">
          <br />
          <h5>Edit Meta</h5>
          <HiPartnerSelect
            ref="partnerSelect"
            @update="partnerUpdate"
            @change="partnerChange"
            :current="partner"
            :target="item.uuid"
          />
        </div>

        <br />
        <q-btn
          v-if="bestImage"
          @click="showBestImage = true"
          label="View best Image"
        />

        <q-btn
          v-if="isOwner && !editMeta"
          @click="editMeta = true"
          label="Edit info"
        />

        <center>
          <q-btn
            @click="
              showMetaInfo = false;
              editMeta = false;
            "
            label="Ok"
          />
        </center>
      </q-layout>
    </q-dialog>

    <!--------------------->
    <!-- THE ACTUAL CARD -->
    <!--------------------->
    <q-card
      v-ripple
      class="card cursor-pointer q-hoverable"
      :key="uuid"
      :id="uuid"
    >
      <!-- section contains all of the card MINUS the action buttons -->
      <!-- a click "opens" the card; can't do it on the q-card element as actionbar clicks are then captured twice -->
      <section class="cardclick" @click="cardClick(target, url)">
        <q-tooltip
          anchor="center middle"
          self="center middle"
          v-if="cardTooltip"
          >{{ cardTooltip }}</q-tooltip
        >
        <q-card-section class="type" :class="csstype" :id="index">
          {{ type }}

          <!-- links to check:
          search result cards on http://localhost:8080/sas/search
          partners index: http://localhost:8080/partners
          partner collections overview page: http://localhost:8080/partners/webtic
          partners collection page: http://localhost:8080/partners/webtic/test-upload
          -->

          <!-- made for searchEuropeana.eu -->
          <q-avatar
            v-if="csstype === 'euresult' || isEuropeana"
            square
            size="28px"
            class="absolute-top-right"
            style="margin-right:8px"
          >
            <img
              style="
                width: 20px;
                height: 20px;
                margin-right: -10px;
                margin-top: 6px;
              "
              src="/europeana-icon.svg"
            />
          </q-avatar>

          <q-avatar
            v-if="
              type === 'Search partner' ||
              type === 'Europeana source' ||
              type === 'Source' ||
              type === 'Collection' ||
              type === 'Source collections'
            "
            square
            size="28px"
            class="absolute-top-right"
            style="margin-right: 4px; margin-top: 4px"
          >
            <img v-if="partner && partner.icon" :src="partner.icon" />
          </q-avatar>

          <!-- OR deal with the new Partners icon in the PartnerIndex view  -->
          <q-avatar
            square
            size="28px"
            v-else-if="partner && partner.icon && partner.icon.url"
            class="absolute-top-right"
            style="margin-right: 4px; margin-top: 4px"
          >
            <img
              v-if="partner.icon && partner.icon.url !== null"
              :src="partner.icon.url"
            />

            <img :src="partner.icon" />
          </q-avatar>
        </q-card-section>

        <!-- IMAGE -->
        <div
          v-if="item.content_type && !thumb && !imageUrl"
          style="
            background-color: #eee;
            max-width: 240px;
            height: 180px;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          "
        >
          <q-icon size="xl" :name="`fas fa-${item.content_type.icon}`"></q-icon>

          <!--
            audio element is too wide to use
            if needed we can use custom elements to control the audio as outlined in eg
            http://talkerscode.com/webtricks/create-custom-audio-player-using-html5-and-javascript.php
          -->

          <!--audio controls><source :src="item.url" /></audio-->
        </div>

        <q-img
          id="image_and_path"
          v-if="image && image.path"
          class="img hcard"
          :src="'/ua/' + image.path"
          >image met pad
        </q-img>

        <!-- imageUrl and thumb should migrate to thumb which is a real thumb not a dumb -->
        <!-- @paul: q-icon should change to different types -->
        <!-- xx: {{ content_type_icon }} yy: {{ csstype }} -->
        <q-img
          v-if="imageUrl && thumb !== null && !thumb"
          class="hcard"
          :class="type === 'Partner' ? 'logo' : 'img'"
          :src="imageUrl"
          id="imageUrl_no_thumb"
        >
          <q-icon
            v-if="(csstype === 'source' || csstype.indexOf('sas')>-1) && content_type_icon"
            color="text-white bg-hardroze"
            :name="content_type_icon"
            size="xs"
            class="
              text-white
              bg-hardroze
              absolute-bottom-right
              q-mr-none q-mb-none q-pa-sm
            "
          />
        </q-img>

        <q-img
          style="max-width: 240px; height: 180px"
          height="180"
          fit="cover"
          v-if="thumb"
          class="hcard img"
          :src="thumb"
        >
          <q-icon
            v-if="csstype === 'source' && content_type_icon"
            color="white bg-hardroze"
            :name="content_type_icon"
            size="xs"
            class="absolute-bottom-right q-mr-none q-mb-none q-pa-sm"
          />
          <!-- should we indicate VIDEO/IMAGE type in the Card for EU found sources? -->
          <!--q-btn
            v-if="eutype === 'VIDEO'"
            round
            icon="fas fa-play"
            class="bg-white-4 text-white"
            @click.stop="$emit('euvideo', item.eulink)"
          /-->
          <q-btn
            v-if="btnUseAsCover"
            class="absolute-bottom-right q-mx-xs q-my-xs"
            round
            dense
            color="primary bg-white"
            flat
            icon="fas fa-book-heart"
            size="sm"
            @click="$emit('useCover', item)"
          >
            <q-tooltip anchor="top middle" self="center middle"
              >Use as Cover</q-tooltip
            ></q-btn
          >
        </q-img>

        <section v-if="!toggleReference" id="startcard">
          <!-- the standard info -->
          <q-card-section>
            <div class="title">{{ title }}</div>
            <div class="description"><slot /></div>
          </q-card-section>
          <q-card-section>
            <ul v-if="hasTags" class="tags menu simple">
              <li v-for="tag in tags" :key="tag">{{ tag.name }}</li>
            </ul>
          </q-card-section>
        </section>
      </section>
      <section v-if="toggleReference" class="q-pa-md">
        <b>Referenced in:</b>
        <li style="list-style: '* '">
          <a
            target="_slug"
            href="`/historical-content/source-collections/${r.collection.slug}`"
            >{{ references.name }}</a
          >
        </li>
      </section>

      <!-- BUTTON BAR --->
      <q-card-actions class="justify-center">
        <!-- SHOW REFERENCES -->
        <q-btn
          v-if="btnReference"
          icon="fas fa-asterisk"
          round
          flat
          dense
          size="sm"
          :color="getColor"
          @click="toggleReference = !toggleReference"
        >
          <q-tooltip anchor="top middle" self="center middle"
            ><span v-if="toggleReference">Hide</span
            ><span v-else>Show</span> references</q-tooltip
          >
        </q-btn>

        <!-- MOVE ITEM LEFT -->
        <q-btn
          v-if="showOrdering"
          icon="fas fa-arrow-left"
          @click="$emit('left', index)"
          round
          flat
          dense
          size="sm"
          :disable="index === 0"
          :color="getColor"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Move left</q-tooltip
          >
        </q-btn>

        <!-- MOVE ITEM RIGHT -->
        <q-btn
          v-if="showOrdering"
          icon="fas fa-arrow-right"
          @click="$emit('right', index)"
          round
          flat
          dense
          size="sm"
          :color="getColor"
          :disable="index === itemCount - 1"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Move right</q-tooltip
          >
        </q-btn>

        <!-- OPTIONAL MORE BUTTON (shows text below card) -->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          icon="fa fa-chevron-down"
          v-if="showMore"
        >
          <q-menu auto-close v-model="isMoreInfoOpen">
            <div class="q-pa-md" style="max-width: 280px">
              <p>
                Extra info over een item onder T and L. Content zit nog niet in
                de database maar interface is wel al gemaakt.
              </p>
            </div>
          </q-menu>
          <q-tooltip
            v-if="!isMoreInfoOpen"
            anchor="top middle"
            self="center middle"
            >More information</q-tooltip
          >
        </q-btn>

        <!-- UNPUBLISH/PUBLISH ITEM -->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="btnUnPublish"
          @click="confirmUnpublish(item, title, type)"
          title="Unpublish"
          icon="fa fa-users"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Unpublish</q-tooltip
          >
        </q-btn>

        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="btnPublish"
          @click="confirmPublish(item, title, type)"
          title="Publish"
          icon="fa fa-users"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Publish</q-tooltip
          >
        </q-btn>

        <!-- SHOW META INFORMATION (i) OLDSTYLE, handled in CARD-->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="showMetaButton"
          @click="showMeMeta(uuid)"
          icon="fa fa-info"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Show meta information</q-tooltip
          >
        </q-btn>

        <!-- SHOW META INFORMATION (i) NEWSTYLE: via emit event-->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="btnMeta"
          @click="$emit('showMeta', item)"
          icon="fa fa-info"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Show meta information</q-tooltip
          >
        </q-btn>

        <!-- SHARE BUTTON -->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="btnShare"
          @click="showSharing"
          icon="fa fa-share"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle">Share</q-tooltip>
        </q-btn>

        <!-- DUPLICATE BUTTON -->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="btnDuplicate"
          @click="$emit('duplicateCard', item)"
          icon="fa fa-copy"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Duplicate</q-tooltip
          >
        </q-btn>

        <!-- EDIT BUTTON -->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="btnEditUrl"
          icon="fa fa-pencil"
          size="sm"
          :to="btnEditUrl"
        >
          <q-tooltip anchor="top middle" self="center middle">Edit</q-tooltip>
        </q-btn>

        <!-- EDIT IN MODAL BUTTON -->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="btnEditModal"
          @click="$emit('showEdit', item)"
          icon="fa fa-pencil"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Edit content</q-tooltip
          >
        </q-btn>

        <!-- ADD TO MY HISTORIANA BUTTON -->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="btnAdd"
          @click="confirmAddMH(item)"
          icon="fa fa-plus"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Add to MyHistoriana</q-tooltip
          >
        </q-btn>

        <!-- ADD TO MY HISTORIANA BUTTON -->

        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="showAddToMyHistoriana"
          @click="
            isAuthenticated
              ? $emit('addToMyHistoriana', item, type)
              : notLoggedIn()
          "
          icon="fa fa-plus"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Add to MyHistoriana</q-tooltip
          >
        </q-btn>

        <!-- DELETE BUTTON -->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="btnDelete"
          @click="confirmDelete(uuid, type, index)"
          icon="fa fa-trash"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Delete Card and content</q-tooltip
          >
        </q-btn>

        <!-- VIEW AS STUDENT BUTTON -->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="url && typecode === 'ela'"
          :href="btnStudentView"
          type="a"
          target="_new"
          icon="fa fa-external-link"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >View as student</q-tooltip
          >
        </q-btn>

        <!-- VIEW IN NEW TAB BUTTON -->
        <q-btn
          :color="getColor"
          round
          flat
          dense
          v-if="url && typecode === 'la'"
          :href="url"
          type="a"
          target="_new"
          icon="fa fa-external-link"
          size="sm"
        >
          <q-tooltip anchor="top middle" self="center middle"
            >View (in new tab)</q-tooltip
          >
        </q-btn>
      </q-card-actions>

      <!-- SELECT CARD; used wherever sources are viewed in the main site
        eg Historical Content, Partner Collections-->

      <q-card-actions v-if="btnSelect" vertical align="center">
        <q-checkbox
          keep-color
          :color="getColor"
          :key="item.uuid"
          :value="activeSelection.includes(item.uuid)"
          :val="item.uuid"
          @input="isAuthenticated ? toggleSelect(item.uuid) : notLoggedIn()"
          size="xs"
          dense
        >
          <q-tooltip anchor="top middle" self="center middle"
            >Select card</q-tooltip
          >
        </q-checkbox>
      </q-card-actions>
    </q-card>
  </div>
</template>
<script>
import { API } from "../tic";
import tic from "../tic";
import hiPartnerSelect from "../components/hiPartnerSelect";
import { mapGetters } from "vuex";
import getColor from "../mixins/getColor.mixin.js";

//imageUrl for backwards compatibilty; takes any url with an image
export default {
  name: "Card",
  mixins: [getColor],
  props: {
    isEuropeana: {
      type: Boolean,
      default: false
    },
    ctype: {
      type: String,
      default: "IMAGE",
    },

    eutype: {
      type: String,
      default: "",
    },
    showOrdering: {
      type: Boolean,
      default: false,
    },
    // show a cursor on hover
    hover: {
      type: Boolean,
      default: true,
    },

    filetype: {
      type: String,
      default: "xx",
    },

    thumb: {
      type: String,
      default: "",
    },

    activeSelection: {
      type: Array,
      default: () => [],
    },

    index: {
      type: Number,
      default: 0,
    },

    itemCount: {
      type: Number,
      default: 1,
    },

    // where does this card links to?
    url: {
      type: String,
      default: "",
    },

    // end user string stating type of card
    type: {
      type: String,
      default: "",
    },

    // code to indicate type of card (ela/la)
    typecode: {
      type: String,
      default: "",
    },

    // the a href target for when the image is linked and clicked
    target: {
      type: String,
      default: "",
    },

    // special prop received from vmodel
    value: {
      type: String,
      default: "",
    },

    checked: {
      type: Boolean,
      default: false,
    },

    partner: {
      type: Object,
      default: () => {
        return {};
      },
    },

    copyright: {
      type: String,
      default: "",
    },

    goto: {
      type: String,
      default: "",
    },

    title: {
      type: String,
      default: "",
    },

    image: {
      type: Object,
      default: () => {
        return {};
      },
    },

    imageUrl: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    cardTooltip: {
      type: String,
      default: "",
    },

    csstype: {
      type: String,
      default: "",
    },

    uuid: {
      type: String,
      default: "",
    },

    tags: {
      type: Array,
      default: () => {
        return [];
      },
    },

    hasTags: {
      type: String,
      default: "",
    },

    btnReference: {
      type: Boolean,
      default: false,
    },

    references: {
      type: Array,
      default: () => [],
    },

    btnPublish: {
      type: Boolean,
      default: false,
    },

    btnUnPublish: {
      type: Boolean,
      default: false,
    },

    btnUseAsCover: {
      type: Boolean,
      default: false,
    },

    more: {
      type: Boolean,
      default: false,
    },

    btnDuplicate: {
      type: Boolean,
      default: false,
    },

    btnShare: {
      type: Boolean,
      default: false,
    },

    btnUse: {
      type: String,
      default: "",
    },

    btnPreview: {
      type: Boolean,
      default: false,
    },

    btnEditUrl: {
      type: String,
      default: "",
    },

    btnStudentView: {
      type: String,
      default: "",
    },

    btnEditModal: {
      type: Boolean,
      default: false,
    },

    btnAdd: {
      type: Boolean,
      default: false,
    },

    btnDelete: {
      type: Boolean,
      default: false,
    },

    btnSelect: {
      type: Boolean,
      default: false,
    },

    btnAddToMyHistoriana: {
      type: String,
      default: "",
    },

    showAddToMyHistoriana: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object,
      default: function () {
        return {};
      },
    },

    // oldstyle meta
    showMetaButton: {
      type: Boolean,
      default: false,
    },

    btnMeta: {
      type: Boolean,
      default: false,
    },

    meta: {
      type: Object,
      default: () => {
        return {};
      },
    },

    icon: {
      type: String,
      default: "",
    },

    // only used in Sources atm
    isOwner: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    hiPartnerSelect,
  },

  computed: {
    ...mapGetters("user", ["loginUuid", "isAuthenticated"]),
    ts() {
      if (this.item && this.item.activity) {
        return tic.theDate(this.item.activity.created);
      }
      return null;
    },

    content_type_icon() {
      if (this.item.content_type?.icon) {
        return "fas fa-" + this.item.content_type.icon;
      }
      return false;
    },
  },
  methods: {
    toggleSelect(uuid) {
      const vm = this;
      vm.$emit("toggleSelect", uuid);
      // parent deals with selection for now
      // if (vm.activeSelection.includes(uuid)) {
      //   vm.activeSelection = vm.activeSelection.filter((e) => e.uuid !== uuid);
      // } else {
      //   vm.activeSelection.push(uuid);
      // }
    },

    // linking to the login/register functionality in a popup like this
    // is surprisingly hard
    notLoggedIn() {
      this.$q.dialog({
        title: '<i class="fa fa-exclamation-triangle"></i>&nbsp;Not Logged In!',
        message:
          "You are not logged in, either login or register to use this function.",
        html: true,
        ok: {
          color: "primary",
        },
      });
    },

    startimageload() {
      console.log("START");
      this.showImageSpinner = false;
    },

    imageError(err) {
      console.log("image error! : ", err);
      this.imageNotLoaded = true;
    },

    cardClick(target, url) {
      // a card is clicked, open in new window if target is set
      // otherwise push it on the router
      this.$emit("cardClicked");
      //console.log('cardclick: ', target, 'url: ', url);
      if (target) {
        window.open(url, target);
      } else if (url) {
        this.$router.push({ path: url });
      }
    },

    // user clicked on the info (meta) button
    // not used?
    showMeMeta() {
      //console.log('meta: ', this.meta);
      this.showMetaInfo = !this.showMetaInfo;
    },

    //==================
    oldshowMeta(uuid) {
      console.log("meta: ", this.meta);

      // either there is a local this.meta object (SASEuropeana creates those)
      // or there is none.
      this.imageNotLoaded = true;

      if (this.meta) {
        // dit is een europeana object
        console.log("There is local meta");
        this.showSASimporter = !this.showSASimporter;
        this.imageNotLoaded = true;
        if (this.meta.objectType === "IMAGE") {
          let imageId = this.meta.fields.find((e) => {
            return e.label === "id";
          }).value;
          console.log("imagE: ", imageId);
          this.fetchImage = true;
          API.post("/sas/get-best-image", { id: imageId }).then((resp) => {
            // add xxxxx to url to trigger image loading failures
            this.bestImage = resp.data.url; // 'xxxxx'
            this.bestImageId = imageId;
            this.fetchImage = false;
          });
        }
      } else {
        let vm = this;
        API.get(`/meta/${uuid}`)
          .then((r) => {
            this.showMetaInfo = !this.showMetaInfo;
            this.current = r.data.data;
          })
          .catch((error) => {
            //window.alert("error" + error.data.msg)
            vm.$q.dialog({
              //title: `Error: ${error.response.data.data.msg}`,
              title: `${error.response.data}`,
              message: "Cannot display meta-information for this object.",
            });
            // return Promise.reject(error.response);
          });
      }
    },

    // ======
    partnerChange() {
      console.log("partner change in card");
      //this.isChanged = true
      this.$emit("change");
    },
    partnerUpdate() {
      console.log("partner update in card");
      this.$emit("change");
    },

    addToMyHistoriana(url, x, item) {
      // this adds an image (search result from Search and Select) to MySources
      let vm = this;
      this.isFetching = true;
      this.isDone = false;

      API.post("/add-image-from-url", {
        user: this.$store.getters["user/uuid"],
        url: url,
        info: item,
        partner: "eu",
      }).then(() => {
        vm.$q.dialog({
          title: "Done",
          message: "Successfully added to your MyHistoriana Sources.",
        });

        vm.isFetching = false;
        vm.isDone = true;
        vm.showSASimporter = false;
      });
    },

    openMeta(meta) {
      // a bit hacky;
      // this is called when the meta box is opened
      this.objectType = meta.objectType;

      switch (meta.type) {
        case "europeana":
        case "searchpartner": {
          if (meta.objectType !== "IMAGE") {
            return;
          }
          let imageId = meta.fields.find((e) => {
            return e.label === "id";
          }).value;

          API.post("/sas/get-best-image", { id: imageId }).then((resp) => {
            this.bestImage = resp.data.url;
            this.bestImageId = imageId;
          });
          break;
        }
      }
    },
    getDate(timestamp) {
      return tic.theDate(timestamp);
    },
    getFilename(item) {
      // learning activities have a .x attribute
      if (item && item.x && item.x.filename) {
        return item.x.filename;
      }
      if (item && item.url) {
        return item.url.split("/").pop();
      }
    },

    partnerInfo(p) {
      // return partner meta info of this relation as html
      if (p.meta) {
        let meta = JSON.parse(p.meta.meta);
        return meta.html;
      }
    },

    duplicateCard(e) {
      this.$emit("xduplicateCard", e);
    },
    showSharing() {
      this.$emit("goShowSharing");
    },

    confirmDelete(uuid, type, index) {
      this.$q
        .dialog({
          title: '<i class="fa fa-trash"></i>&nbsp;Confirm Delete',
          message: `Are you sure you want to delete this ${type}?`,
          html: true,
          cancel: {
            noCaps: true,
            color: "grey-2",
            textColor: "black",
          },
          ok: { label: "Yes", color: "primary", noCaps: true },
        })
        .onOk(() => {
          this.$emit("deleteCard", index, uuid);
        });
    },

    confirmAddMH(item) {
      // USER CLICKED ON ADD TO MY HISTORIANA

      if (this.$store.getters["user/isAuthenticated"]) {
        //console.log("CU: ", item)

        if (this.type === "Source") {
          // Card in SourceCollections
          this.$emit("addSource", item);
        } else if (item.type === "e-Learning Activity") {
          this.$q
            .dialog({
              title: '<i class="fa fa-check-double"></i>&nbsp;Confirm',
              message: `Add <strong>${item.activity.title}</strong> to your MyHistoriana?`,
              html: true,
              cancel: {
                label: "No",
                noCaps: true,
                color: "grey-2",
                textColor: "black",
              },
              ok: { label: "Yes", color: "primary", noCaps: true },
            })
            .onOk(() => {
              // this sends an event to the parent where the adding is done
              this.$emit("addMyHistoriana", this.item, this.index);
              this.$store.dispatch("sendFeedback", {
                msg: "Activity added to your MyHistoriana",
                status: "ok",
              });
            });
        }

        // EUROPEANA SEARCH RESULT OF IMAGE TYPE
        else if (item.type === "IMAGE") {
          this.$q
            .dialog({
              title: '<i class="fa fa-check-double"></i>&nbsp;Confirm',
              message: `Add the Europeana source: <br/> <strong>${item.title.find(
                (x) => x !== undefined
              )}<br/></strong> to your MyHistoriana?<br/><br/><small>Note: triggers new dialog</small>`,
              html: true,
              cancel: {
                label: "No",
                noCaps: true,
                color: "grey-2",
                textColor: "black",
              },
              ok: { label: "Yes", color: "primary", noCaps: true },
            })
            .onOk(() => {
              console.log("Import Europeana image");
              this.showImageSpinner = true;
              this.showSASimporter = true;
              this.imageNotLoaded = true;
              let imageId = this.meta.fields.find((e) => {
                return e.label === "id";
              }).value;
              this.fetchImage = true;
              API.post("/sas/get-best-image", {
                id: imageId,
              }).then((resp) => {
                // add xxxxx to url to trigger image loading failures
                this.bestImage = resp.data.url; // 'xxxxx'
                this.bestImageId = imageId;
                this.fetchImage = false;
              });
            });
        } else {
          console.log("UNSUPPORTED ADD TO MY: ", item);
          this.$q.dialog({
            title: '<i class="fa fa-warning"></i> Warning',
            message: `Unsupported format: <b>${item.type}</b>`,
            html: true,
          });
        }
      } else {
        this.notLoggedIn();
      }
    },

    confirmUnpublish(item, title, type) {
      let vm = this;
      this.$q
        .dialog({
          title: '<i class="fas fa-users"></i>&nbsp;Confirm Unpublish',
          message: `Make ${type}<b>${title}</b> private?`,
          html: true,
          cancel: { noCaps: true, color: "grey-2", textColor: "black" },
          ok: { label: "Yes", color: "primary", noCaps: true },
        })
        .onOk(() => {
          vm.$emit("unpublish", item);
        });
    },

    confirmPublish(item, title, type) {
      let vm = this;
      this.$q
        .dialog({
          title: '<i class="fas fa-users"></i>&nbsp;Confirm Publish',
          message: `Make ${type} <b>${title}</b> publicly available?`,
          html: true,
          cancel: { noCaps: true, color: "grey-2", textColor: "black" },
          ok: { label: "Yes", color: "primary", noCaps: true },
        })
        .onOk(() => {
          vm.$emit("publish", item);
        });
    },

    closeConfirm() {
      this.showConfirmDelete = false;
    },
  },
  data() {
    return {
      toggleReference: false,
      testVal: [],
      isMoreInfoOpen: false,
      toggleIcon: "fa fa-chevron-up",
      showMore: false,
      showSASimporter: false,
      showConfirmAddMH: false,
      showMetaInfo: false,
      xxshowMetaInfo: false,
      showBestImage: false,
      objectType: null,
      imageNotLoaded: false,
      isImageError: false,
      editMeta: false,
      isFetching: false,
      isDone: false,
      current: {},
      fetchImage: false,
      bestImage: null,
      bestImageId: null,
      showImageSpinner: true,
    };
  },
};
</script>
<style lang="stylus">
@import '~quasar-variables';

/* Cards
-------------------------- */
/* select button in a card */
.q-card {
  -o-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.137255), 0px 1px 8px 0px rgba(0, 0, 0, 0.117647);
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.137255), 0px 1px 8px 0px rgba(0, 0, 0, 0.117647);
  margin-bottom: 1.4rem;
}

.q-card:hover {
  -o-box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.137255), 0px 5px 22px 4px rgba(0, 0, 0, 0.117647);
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.137255), 0px 5px 22px 4px rgba(0, 0, 0, 0.117647);
  cursor: hand;
}

.q-card label:before {
  bottom: 12px !important;
  width: 16px !important;
  height: 16px !important;
}

pre {
  font-size: 9px;
}

.inactive {
  opacity: 0.5;
}

.q-card:hover {
  // cursor: pointer;
  // filter: brightness(0.92);
  // -webkit-filter: brightness(0.92);
}

tr:nth-child(even) {
  background: lightgrey;
}

.q-card {
  width: 235px;

  .type {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: $generic-border-radius $generic-border-radius 0 0;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .q-img.hcard {
    width: 100%;
    height: 165px;
    min-width: 100%;
    object-fit: cover;
    object-position: top center;
  }

  .logo {
    height: auto;
    background-color: $light-gray;
    padding: 12px;
  }

  .title {
    padding: 0;
    font-weight: 600;
    max-height: calc(1.5em * 5);
    min-height: auto;
    letter-spacing: -0.03em;
  }

  .description {
    max-height: calc(1.5em * 3); /* body line-height x max-lines */
    min-height: auto;
  }

  .title, .description {
    overflow: hidden;
  }

  .q-avatar {
    height: 0.9em;
    width: 0.9em;
  }
}

#card-holder {
  background-color: $medium-gray;
  width: 100%;
  padding: 2em 0;

  .card-holder-title {
    color: $white;
    margin-bottom: 1em;
  }
}

/* define spacing of icons in action bar on Index Card */
.q-btn-item + .q-btn-item {
  margin-left: 0px;
}

tr:nth-child(even) {
  background: transparent;
}
</style>
