var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ea"},[_c('section',{attrs:{"id":"ea-identity"}},[_c('div',{staticClass:"row q-mx-auto"},[(_vm.mode=='teacher')?_c('q-btn',{staticClass:"absolute",staticStyle:{"margin-top":"-15px","z-index":"1000"},attrs:{"icon":"fas fa-th-large","label":"Edit in Builder","no-caps":""},on:{"click":_vm.goBack}}):_vm._e()],1),_c('div',{staticClass:"row q-mx-auto justify-end items-start relative-position"},[_c('h3',{staticClass:"row items-start"},[_vm._v(" "+_vm._s(_vm.activity.title || 'Untitled eActivity')+" ")]),_c('div',{attrs:{"id":"ea-logo"}},[_c('i',{staticClass:"fa fa-pencil",class:'ea-logo-'+_vm.activity.blockType}),_c('div',{staticClass:"text",class:'ea-logo-'+_vm.activity.blockType},[_vm._v(_vm._s(_vm.activity.blockType))])])])]),_c('section',{attrs:{"id":"ea-canvas"}},[_c('div',{staticClass:"row q-mx-auto relative-position"},[_c('block-top-navigation',{attrs:{"editor":"","mode":_vm.mode,"uuid":_vm.uuid,"block-id":_vm.block}},[_c('q-toggle',{attrs:{"id":"ea-student-action","dark":"","size":"sm","false-value":"teacher","true-value":"student"},model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}},[_c('div',{staticClass:"label"},[_vm._v("View as student")])])],1),_c('div',{staticClass:"col"},[_c('RouterView',{key:_vm.$route.path,ref:"bblock",attrs:{"editor":"","block-id":_vm.block,"mode":_vm.mode,"view":_vm.view,"show-instructions":_vm.showInstructions,"show-media":_vm.showMedia,"add-text":_vm.showText},on:{"addSources":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.setMenuOption.apply(void 0, [ 'addSources' ].concat( argsArray ))},"addInstructions":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.setMenuOption.apply(void 0, [ 'addInstructions' ].concat( argsArray ))},"addText":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.setMenuOption.apply(void 0, [ 'addText' ].concat( argsArray ))},"addMedia":function($event){_vm.showMedia=true},"closeInstructions":function($event){_vm.showInstructions=false},"closeAddText":function($event){_vm.showText=false},"hook:mounted":_vm.bblockMounted}})],1),_c('block-side-menu',{attrs:{"add-instructions":_vm.addInstructions,"add-sources":_vm.addSources,"add-text":_vm.addText},on:{"showInstructions":function($event){_vm.showInstructions=true},"showMedia":function($event){_vm.showMedia=true},"showText":function($event){_vm.showText=true}}})],1)]),(_vm.showMedia)?_c('my-sources-select',{attrs:{"block-id":_vm.block,"show":_vm.showMedia,"options":_vm.addSources},on:{"close":_vm.closeMedia}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }