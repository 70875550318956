<template>
  <Transition name="gallery">
    <div class="gallery-mask" @click="close">
      <div class="gallery-container" @click.stop style="overflow: scroll">
        <q-btn
          @click="close"
          flat
          round
          class="float-right"
          icon="far fa-times"
        />

        <div class="row justify-between presentation full-height">
          <!--- LEFT COLUMN ---->
          <div
            class="col-6"
            style="background-color: rgb(156, 156, 156); text-align: center"
          >
            <PDFViewer
              v-if="item && item.url && item.url.indexOf('.pdf') > -1"
              :url="item.url"
              class="fit"
              style="position: relative"
            />

            <div
              v-if="item.type === 'video/mp4'"
              class="row content-center fit"
            >
              <hi-video
                ref="video"
                :thumb="item.thumb"
                :url="item.playback"
                style="width: 100%"
              />
            </div>

            <div
              v-if="item.type === 'audio/mpeg'"
              class="row content-center fit"
              style="
                align-items: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
              "
            >
              <q-icon
                v-if="!item.thumb_url && item.content_type"
                size="xl"
                :name="`fas fa-${item.content_type.icon}`"
              ></q-icon>

              <q-img :src="item.thumb_url" v-if="item.thumb_url" />
              <br />
              <audio controls><source :src="item.url" /></audio>
            </div>

            <v-zoomer
              v-else
              controls
              ref="zoomer"
              class="fit"
              :zoomed.sync="zoomed"
            >
              <img :src="item.url" />
            </v-zoomer>
          </div>
          <div v-if="isView" class="col-6 scroll" style="padding: 3em">
            <Actionbar
              :mode="mode"
              :btn-edit-modal="true"
              :btn-delete="showDelete"
              @toggleEdit="toggleEdit"
              @prev="prev"
              @next="next"
              @deleteItem="deleteItem"
            >
              <li class="page-x-of-xx">
                <span class="text-hardroze"
                  >{{ current + 1 }} of {{ total }}&emsp;</span
                >
              </li>
            </Actionbar>

            <span style="color: black">
              <h3>{{ item.title }}</h3>
              <p>{{ item.description }}</p>
            </span>
          </div>
          <div v-else class="col-6 scroll" style="padding: 3em">
            <Actionbar
              @prev="prev"
              @next="next"
              :btn-delete="showDelete"
              @deleteItem="deleteItem"
            >
              <li class="page-x-of-xx">
                <span class="text-hardroze"
                  >{{ current + 1 }} of {{ total }}&emsp;</span
                >
              </li>
            </Actionbar>

            <q-input
              autogrow
              filled
              class="q-mb-md"
              placeholder="Title of Source"
              @input="isChanged = true"
              v-model="item.title"
              label="Title"
              type="text"
            />

            <q-input
              autogrow
              filled
              @input="isChanged = true"
              class="edit description"
              placeholder="Description of your Source"
              v-model="item.description"
              label="Description (used in tools)"
              type="textarea"
            />

            <br />

            <p><selectContentType @isDirty="dirty" :item="item" /></p>

            <!-- tags only to be shown in my-sources context, not in the eBuilder -->
            <div v-if="$route.name === 'my-sources'">
              <!-- select tags mag weg, komt in de pop-up van de i -->
              <p v-if="$store.getters['user/hasTags']">
                <!-- <p v-if="!$store.getters['user/hasTags']"> -->
                <q-btn
                  no-caps
                  label="Select tags"
                  @click="showTags = !showTags"
                  icon="fa fa-tags"
                />

                <q-dialog v-model="showTags">
                  <q-card style="width: 40vw">
                    <q-bar>
                      Select tags
                      <q-space />
                      <q-btn
                        dense
                        flat
                        round
                        icon="far fa-times"
                        v-close-popup
                      />
                    </q-bar>

                    <q-card-section>
                      <MemberTags
                        v-if="hasTags('MyClasses')"
                        :active="item.mytags"
                        name="Class"
                        nodelabel="MyClasses"
                        @input="toggleTag"
                      />
                      <MemberTags
                        v-if="hasTags('MyYears')"
                        :active="item.mytags"
                        name="Year"
                        nodelabel="MyYears"
                        @input="toggleTag"
                      />
                      <MemberTags
                        v-if="hasTags('MyKeywords')"
                        :active="item.mytags"
                        name="Keyword"
                        nodelabel="MyKeywords"
                        @input="toggleTag"
                      />
                      <MemberTags
                        v-if="hasTags('MyLanguages')"
                        :active="item.mytags"
                        name="Language"
                        nodelabel="MyLanguages"
                        @input="toggleTag"
                      />
                    </q-card-section>

                    <q-card-actions align="center" class="q-mb-md">
                      <q-btn
                        no-caps
                        label="Ok"
                        @click="isChanged = true"
                        v-close-popup
                      />
                    </q-card-actions>
                  </q-card>
                </q-dialog>
              </p>
              <p v-else>
                You can assign your own tags if you define them via
                <router-link :to="'/my/tags'">MyTags</router-link>
              </p>

              <div class="q-pa-md q-gutter-md">
                <div>
                  <q-chip
                    clickable
                    v-for="t in item.mytags"
                    :key="t"
                    @click="removeTag(t)"
                    icon="fas fa-times"
                    :label="myTag(t).name"
                  />
                </div>
              </div>
            </div>

            <q-btn
              v-if="showSave"
              no-caps
              :disabled="!isChanged"
              @click="saveButton"
              label="Save"
              icon="fa fa-save"
              color="primary"
              class="q-my-md"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
//import Modal from '../tags/Modal'
import Actionbar from "../tags/Actionbar";
import MemberTags from "../tags/MemberTags";
import PDFViewer from "../eabuilder/BB-highlighting/components/PDFViewer";
import hiVideo from "@/components/hiVideo";
import selectContentType from "@/components/SelectContentType";

export default {
  name: "Gallery",
  components: {
    Actionbar,
    MemberTags,
    PDFViewer,
    hiVideo,
    selectContentType,
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    item: {
      type: Object,
      default: () => {
        return {};
      },
    },

    partner: {
      type: String,
      default: "",
    },

    current: {
      type: Number,
      default: 0,
    },

    total: {
      type: Number,
      default: 0,
    },

    isView: {
      type: Boolean,
      default: false,
    },

    showDelete: {
      type: Boolean,
      default: false,
    },

    showSave: {
      type: Boolean,
      default: true,
    },

    mode: {
      type: String,
      default: "",
    },
  },

  computed: {
    myTags() {
      // these are the globla user-tags read from the Vuex environment
      return this.$store.state.user.tags;
    },
  },

  mounted() {
    console.log("## GALLERY MOUNTED");
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 37) {
        // left cursor
        // e.preventDefault()
        this.prev();
      }

      if (e.keyCode === 39) {
        // right cursor
        // e.preventDefault()
        this.next();
      }

      if (e.keyCode === 27) {
        // e.preventDefault()
        this.close();
      }
    });
  },
  methods: {
    dirty() {
      this.$emit("contenttypereload");
      console.log("signal reload from Gallery to parent");
    },

    myTag(uuid) {
      return this.$store.state.user.tags.find((e) => e.uuid === uuid);
    },

    toggleTag(tag) {
      this.isChanged = true;
      if (this.item.mytags.includes(tag)) {
        this.item.mytags = this.item.mytags.filter((e) => e !== tag);
      } else {
        this.item.mytags.push(tag);
      }
    },

    hasTags(setname) {
      return this.$store.state.user.tags.filter((e) => {
        return e.tag === setname;
      }).length;
    },

    saveButton() {
      if (this.isChanged) {
        this.$emit("saveChanges", this.item);
        this.isChanged = false;
      }
    },

    removeTag(tag) {
      this.isChanged = true;
      this.item.mytags = this.item.mytags.filter((e) => e !== tag);
    },

    deleteItem(item) {
      // cascade it back to caller
      this.$emit("delete", item);
    },

    toggleEdit() {
      this.$emit("toggleEdit");
    },

    next() {
      if (this.isChanged) {
        this.$emit("saveChanges", this.item);
      }
      this.localItem = this.item;
      this.$emit("next");
    },

    prev() {
      if (this.isChanged) {
        this.$emit("saveChanges", this.item);
      }
      this.localItem = this.item;
      this.$emit("prev");
    },

    close() {
      if (this.$refs.video) this.$refs.video.stop();
      this.$emit("close");
    },

    // active tags should be saved on the Source not the Activity!!
    saveChanges(item) {
      console.log("gallery save changes ", item);
      // tell my-sources to save changes
      if (this.isChanged) {
        this.$emit("saveChanges", item);
        // tell the partner component to save itself
        this.$refs.partnerSelect.saveChanges(this.item.uuid);
        // assume it went ok and reset SAVE button to grey
        this.isChanged = false;
      }
    },
  },

  data() {
    return {
      zoomed: true,
      isChanged: false, // true if something is edited
      localItem: this.item,
      showTags: false,
    };
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}

.gallery-mask {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 102, 204, 0.7);
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.gallery-container {
  width: 90%;
  height: 90%;
  padding: 0;
  margin: auto;
  max-width: 75rem;
  max-height: calc(100% - 100px);
  position: relative;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
}

.gallery-container .description {
  resize: vertical;
  line-height: 1.6em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: normal;
  -ms-word-break: normal;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-spacing: 0 -0.05em 1em;
  /* optimum, minimum, maximum respectively */
  letter-spacing: 0 -0.01em 0.02em;
}

.gallery-container .column.scroll {
  overflow-y: auto;
}

/* .gallery-container .row {
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 100%;
} */

.gallery-container img {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  /*  min-height:98%;*/
}

.gallery-container .menu.simple li.page-x-of-xx.page-x-of-xx {
  color: #0066cc;
  margin-right: 1em;
}
.gallery-container .page-x-of-xx .current {
  font-weight: bold;
}

.gallery-enter-active {
  animation: slide-in 0.5s;
  -webkit-animation: slide-in 0.5s;
}
.gallery-leave-active {
  animation: slide-in 0.5s reverse;
  -webkit-animation: slide-in 0.5s reverse;
}
@keyframes slide-in {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.form-label {
  display: block;
  margin-bottom: 1em;
}

.form-label > .form-control {
  margin-top: 0.5em;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  line-height: 1.5;
  border: 1px solid #ddd;
}

/* .edit {
  border: 1px dotted #ccc;
  padding: 0.4em;
  background-color: transparent;
  width: 100%;
} */
/*
.edit.description {
  height: 18em;
  line-height: 1.6em;
}
.edit.title {
  font-size: 1.25rem;
  line-height: 1.6em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: normal;
  word-break: normal;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
#edit-image img {
  vertical-align: top;
  margin-bottom: 24px;
} */
img.thumb {
  margin-top: 1em;
  width: 100px;
  height: 100px;
}

.inactive {
  opacity: 0.5;
}

.float-right {
  float: right;
}
</style>
