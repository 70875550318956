<template>
  <div class="source-info" :class="{ dark:dark, expanded:show }">
    <header class="row justify-between">
      <div class="row items-center">
        <i class="far fa-info-circle on-left q-icon" style="font-size:1.5rem"/>{{ header }}
      </div>
      <q-btn
        v-if="!fixed"
        flat
        round
        size="sm"
        icon="fas fa-chevron-down"
        @click.stop="$emit('update:show',false)"
      />
    </header>
    <q-scroll-area
      class="source-info-scroll"
      :dark="dark"
    >
      <div v-if="mode=='teacher'">
        <q-input
          v-model="source.title"
          label="Title"
          filled
          :dark="dark"
          @update="$emit('update:source')"
          @blur="$emit('store')"
        />
        <q-input
          v-model="source.description"
          type="textarea"
          label="Description"
          autogrow
          filled
          :dark="dark"
          @update="$emit('update:source')"
          @blur="$emit('store')"
        />
      </div>
      <div v-else>
        <div class="source-info-label">Title</div>
        {{ source.title || 'Untitled source' }}
        <div v-if="description" class="source-info-label">Description</div>
        <div v-if="description" v-html="description"/>
      </div>
    </q-scroll-area>

  </div>
</template>

<script>

export default {
  name: 'SourceInfo',

  props: {
    dark: {
      type:Boolean,
      default:false
    },
    show: {
      type:Boolean,
      default:false
    },
    fixed: {
      type:Boolean,
      default:false
    },
    mode: {
      type:String,
      default:'student'
    },
    source: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },

  computed: {
    header () {
      return this.mode=='teacher'? 'Edit source info':'About this source';
    },
    description () {
      return this.source.description? this.source.description.replace(/\n/g,'<br>'):false;
    }
  },

}
</script>

<style scoped lang="stylus">
@import '~quasar-variables'

.source-info
{
  position:absolute;
  left:0;
  bottom:0;
  height:50%;
  width:100%;

  transform:translateY(100%);
  transition:transform .2s ease 0s;

  color:$black;
  background-color:rgba(245,245,245,.85);
}
.source-info.dark
{
  color:$white;
  background-color:rgba(0,0,0,.75);
}
.source-info.expanded
{
  transform:none;
}
.source-info > header
{
  margin:8px 16px 8px 16px;
  font-size:1.25rem;
}
.source-info-label
{
  color:$medium-gray;
  margin:10px 0 0px 0;
}

.source-info >>> .q-field
{
  margin:10px 0 20px 0;
}
.source-info >>> .q-textarea .q-field__native
{
  resize:none;
}
.source-info-scroll
{
  padding:0px 16px 24px 16px;
  height:calc(100% - 46px)
}

</style>