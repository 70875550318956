<template>
  <span>
    <Login :show-dialog="showLogin" @hide="showLogin = false" />
    <q-btn
      flat
      dense
      size="13px"
      class="q-px-sm"
      icon="far fa-lock"
      label="Login"
      :color="inline? 'primary':dark? 'white':'dark-gray'"
      @click="showLogin = !showLogin"
    />
  </span>
</template>
<script>
import Login from "@/components/Login.vue";
export default {
  name: "LoginButton",
  components: { Login },
  props: {
    dark:{
      type:Boolean,
      default:false
    },
    inline:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showLogin: false
    };
  }
};
</script>
