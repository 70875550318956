<!-- Template for source-collections -->
<template>
  <div>
    <section>
      <div class="row q-mx-auto q-py-md justify-between items-start">
        <div class="col">
          <!-- oude entries hebben een Thumb in NewUpload; nieuwere
          gebruiken UserAsset-->
          <img
            v-if="thumb"
            style="width: 280px"
            class="float-left on-left"
            :src="thumb"
          />
          <h4>{{ col.name }}</h4>
          <h6>{{ col.subtitle }}</h6>
          <p v-html="newline(col.description)" />

          <p class="text-body2">{{ col.acknowledgements }}</p>
        </div>
      </div>
      <div class="row q-mx-auto justify-between items-start">
        <q-btn
          filled
          @click="addMultiple"
          v-show="multiSelect.length"
          class="primary q-my-md"
          >Add selection to MySources</q-btn
        >
      </div>
    </section>
    <section id="card-holder">
      <div class="row q-mx-auto justify-start items-start q-gutter-md">
        <div :key="i" v-for="(e, i) in all" class="column">
          <Card
            :item="e.entry"
            :title="e.entry.title"
            :image-url="getImageUrl(e)"
            :btn-delete="false"
            :uuid="e.entry.uuid"
            :partner="e.partner"
            :tags="e.entry.tags"
            :has_tags="e.entry.has_tags"
            :more="false"
            :btn-publish="false"
            :btn-share="false"
            :btn-preview="false"
            :btn-duplicate="false"
            :btn-add="true"
            :btn-edit-modal="false"
            :btn-select="isAuthenticated ? false : false"
            @cardClicked="showPreview(e.entry, e.col, i)"
            @addSource="addSource"
            @toggleSelect="toggleSelect"
            type="Source"
            csstype="source"
          />
        </div>
      </div>
    </section>
    <q-btn @click="addMultiple" v-show="multiSelect.length" class="button"
      >Add selection to MySources</q-btn
    >

    <!-- gallery is used to view images in popup -->

    <Gallery
      v-show="showPreviewModal"
      @close="showPreviewModal = false"
      :item="item"
      :total="all.length"
      :show-delete="false"
      :show-save="false"
      :current="currentFocus"
      @next="next"
      @prev="prev"
    />
  </div>
</template>
<script>
import Card from "../tags/Card";
import Gallery from "../tags/Gallery";
import { API } from "../tic";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "SasExploreSlug",
  components: { Card, Gallery },
  watch: {
    showZoomEntry: {
      handler: function (val, oldVal) {
        //this.saveChanges()
        console.log("showZoomEntry changed");
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("user", ["isAuthenticated"]),
    showAddSources() {
      return this.$store.getters["user/isAuthenticated"];
    },
  },
  methods: {
    newline(str) {
      return str.replace("\n", '<br class="newline"/>');
    },

    getImageUrl(e) {
      // wrapper to hide the fact that there are old and new Collections with different locations for the assets
      if (e.entry.url) {
        return e.entry.url;
      } else {
        return `//sas.historiana.eu/objects/${e.col.object}/images/${e.entry.filename}`;
      }
    },

    prev() {
      //console.log('prev: ', this.all[this.currentFocus-1])
      if (this.all[this.currentFocus - 1] !== undefined) {
        this.item = this.all[this.currentFocus - 1].entry;
        if (this.item.url) {
          this.image = this.item.url;
        } else {
          this.image = `//sas.historiana.eu/objects/${
            this.all[this.currentFocus - 1].col.object
          }/images/${this.item.filename}`;
        }
        this.currentFocus--;
      }
    },
    next() {
      if (this.all[this.currentFocus + 1] !== undefined) {
        console.log("right: ", this.currentFocus);
        this.item = this.all[this.currentFocus + 1].entry;
        if (this.item.url) {
          this.image = this.item.url;
        } else {
          this.image = `//sas.historiana.eu/objects/${
            this.all[this.currentFocus + 1].col.object
          }/images/${this.item.filename}`;
        }
        this.currentFocus++;
      }
    },
    addSource(e) {
      // add source to My Historiana -> My Sources
      //this.showZoomEntry[e] = false
      let user = this.$store.getters["user/uuid"];
      API.post("/addSource", {
        user: user,
        source: e.uuid,
      }).then((resp) => {
        this.$store.dispatch("sendFeedback", {
          msg: `Added ${e.title} to MySources`,
          status: "ok",
        });
      });
    },
    addMultiple() {
      let me = this;
      console.log("user: ", this.$store.getters["user/uuid"]);
      let user = this.$store.getters["user/uuid"];
      let sources = this.multiSelect;
      API.post("/addSources", {
        user: user,
        sources: sources,
      }).then((response) => {
        this.$store.dispatch("sendFeedback", {
          msg: `Added ${sources.length} items to MySources`,
          status: "ok",
        });
        // zap and reset UI state
        me.multiSelect = [];
        // XXX eslint does not know $
        //$("[type=checkbox]").prop('checked', false)
        // a more selective way; probably slower?
        // for (let e of me.multiSelect) {
        //   $('#img_'+e).prop('checked', false)
        // }
      });
    },
    toggleSelect(e, i) {
      console.log("toggleSelect: ", e);
      console.log("------ :", i);
      let me = this;
      if (_.includes(me.multiSelect, e)) {
        // before eslint XXX
        // me.multiSelect = me.multiSelect.filter( (v) => { return v===e ? false : true })
        console.log("already in selection, remove");
        me.multiSelect = me.multiSelect.filter((v) => {
          return v !== e;
        });
      } else {
        console.log("add to selection");
        me.multiSelect.push(e);
      }
    },
    closeZoom(e) {
      console.log("closezoom ", e);
      this.showPreviewModal = false;
    },

    showPreview: function (item, collection, offset) {
      console.log("SHOW SOURCE DETAILS");
      this.showPreviewModal = true;
      // item is het gewone record
      // sasobject is de uuid van het collectie-object dat nodig is om
      // het pad naar het plaatje te herleiden op de oude server
      // console.log("SHOW PREVIEW ", item, collection, offset)
      this.item = item;

      if (this.item.url) {
        this.image = this.item.url;
      } else {
        this.image = `//sas.historiana.eu/objects/${
          this.all[this.currentFocus + 1].col.object
        }/images/${this.item.filename}`;
      }

      this.currentFocus = offset;
      //this.currentFocus = offset
      this.showPreviewModal = true;
    },
  },
  mounted: function () {
    let me = this;
    console.log("mounted sas-explore-slug ", this.$route.params.slug);
    API.post("/sas/explore/" + this.$route.params.slug, {}).then(function (
      response
    ) {
      me.all = response.data.data;
      me.col = me.all[0].col;
      me.thumb = me.all[0].thumb;
      me.item = me.all[0];
      // for (let e of me.all) {
      //   me.showZoomEntry[e.entry.uuid] = false
      // }
    });
  },

  data() {
    return {
      multiSelect: [],
      all: [],
      col: {},
      thumb: {},
      showPreviewModal: false,
      item: {},
      currentFocus: 0,
      image: null,
    };
  },
};
</script>

<style scoped>
.tags {
  font-size: 80%;
}
li {
  margin-bottom: 2px;
  margin-right: 2px;
}
.menu > li > a {
  color: white;
  padding: 0.25rem 0.5rem;
  line-height: 1;
  background-color: gray;
  border-radius: 12px;
}
</style>
