<template>
  <div>
    <div
      v-show="showMenu"
      class="tooltip"
      ref="menu"
      :class="[ markColor, { below:isBelow, comment:hasComment, glossary:isGlossary }]"
      :style="{
        left: `${x}px`,
        top: `${y}px`
      }"
    >
      <div
        v-if="isGlossary"
        class="text-white"
      ><i class="far fa-book on-left"/>{{ glossary }}</div>

      <div
        v-if="!isGlossary && (isTeacherMark || isReview)"
      ><i
        class="mark on-left"
        :class="[markColor, { 'fas fa-chalkboard-teacher':isTeacherMark, 'fa fa-user':isReview && !isTeacherMark }]"
      /><span class="text-white">{{ comment }}</span></div>
      <q-input
        v-else
        v-show="hasComment"
        v-model="comment"
        ref="comment"
        type="textarea"
        class="textarea"
        placeholder="Add your highlight note"
        filled
        autogrow
        dark
        @blur="onBlur"
        @keyup.enter.stop
      />

      <div
        v-if="!isGlossary && !isTeacherMark && !isReview"
        class="row justify-center items-center"
      >
        <span
          v-for="key in colors"
            class="item mark"
            :key="key"
            :class="key"
            @mousedown.prevent="markText(key)"
        />
        <span
          class="item"
          @mousedown.prevent="markText('comment')"
          @mouseup.stop
        >
          <i class="far fa-comment"/>
        </span>

        <span
          v-show="onMark"
          class="item"
          @mousedown.prevent="deleteMark"
        >
          <i class="fas fa-trash"/>
        </span>
      </div>

    </div>

    <!-- text is added below -->
    <slot/>

  </div>
</template>

<script>

export default {

  name: 'HighlightAnnotate',

  props: {
    text: {
      type:String,
      default:''
    },
    mode:{
      type:String,
      default:'student'
    },
  },

  watch: {
    text: {
      immediate:true,
      handler () {
        this.$nextTick(this.parseComments);
      }
    }
  },

  data () {
    return {
      x: 0,
      y: 0,
      showMenu: false,
      isBelow: false,
      onMark: false,
      comment: '',
      hasComment: false,
      markColor: '',
      isGlossary: false,
      isTeacherMark: false,
      isReview: this.mode=='review',
      glossary: '',
      colors:['m1','m2','m3']
    }
  },

  computed: {
    highlightableEl () {
      return this.$slots.default[0].elm
    }
  },

  mounted () {
    window.addEventListener('mouseup', this.onMouseup)

    //watch scroll event (if in q-scrolable)
    let elm = this.highlightableEl.parentNode.parentNode.parentNode;
    if (elm && elm.classList.contains('scroll'))
    {
      this.scrollable = elm;
      elm.addEventListener('scroll', this.onScroll)
    }
  },

  beforeDestroy () {
    window.removeEventListener('mouseup', this.onMouseup)
    if (this.scrollable) this.scrollable.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    parseComments (clear) {
      //check for same line comments
      this.highlightableEl.querySelectorAll('mark.comment').forEach((v,i,list) => {
        if (clear) v.classList.remove('multi')
        else
        {
          let next = list[i+1];
          if (next && v.getBoundingClientRect().top==next.getBoundingClientRect().top)
          {
            next.classList.add('multi')
          }
        }
      })
    },

    onScroll () {
      this.hideMenu();
    },

    onBlur () {
      console.log('■■ Highlight: textarea blur, mark=',this.mark)

      if (!this.mark) return;

      let prev = this.mark.getAttribute('data-comment');

      if (!this.comment)
      {
        //empty comment: remove it from mark
        this.mark.removeAttribute('data-comment')
        this.mark.classList.remove('comment')
      }
      else if (prev!=this.comment)
      {
        //changed comment: send updated text to parent
        this.mark.setAttribute('data-comment',this.comment);
        this.storeText();
      }
    },

    onMouseup (e) {
      let elm = e.target;

      //keep text area in focus
      if (elm.tagName=='TEXTAREA') return elm.focus();

      //handle glossary elements
      if (elm.tagName=='DFN') return this.showGlossary(elm);

      //defaults
      this.isGlossary = false;
      this.hasComment = false;
      this.comment = '';

      //clicked on a <mark> ?
      if (elm.tagName=='MARK' || elm.parentNode.tagName=='MARK') //->add multiple parents here!, like jQuery elm.parents('mark')
      {
        this.mark = elm.tagName=='MARK'? elm:elm.parentNode;
        this.onMark = true;
        this.markColor = this.mark.className.replace('comment','').trim();

        //mark by teacher (in student mode)
        const isTeacher = this.mark.classList.contains('teacher');
        this.isTeacherMark = isTeacher;

        //comment set?
        if (this.mark.classList.contains('comment'))
        {
          this.comment = this.mark.getAttribute('data-comment');
          this.hasComment = true;
          if (!isTeacher && !this.isReview) this.$nextTick(() => { this.$refs['comment'].focus() });
        }
        else if (isTeacher)
        {
          //teacher annotation without comment, do not show popover
          this.showMenu = false;
          return;
        }

        //determine menu position and show it
        const { x, y, width } = this.mark.getBoundingClientRect();
        this.x = x + (width / 2);
        this.y = y + window.scrollY - 10;
        this.showMenu = true;
      }
      else
      {
        delete this.mark;
        this.onMark = false;
        this.isTeacherMark = false;
        this.markColor = '';

        const selection = window.getSelection();

        //validate selection
        if (!selection.rangeCount || this.isReview) return this.hideMenu();

        const selectionRange = selection.getRangeAt(0);
        const startNode = selectionRange.startContainer.parentNode;
        const endNode = selectionRange.endContainer.parentNode;

        //invalid range when for example multiple paragraphs are select
        if (!startNode.isSameNode(endNode)) return this.hideMenu();

        //validate selection width
        const { x, y, width } = selectionRange.getBoundingClientRect()
        if (width<1) return this.hideMenu();

        //store current selection
        this.selection = selection;

        //determine menu position and show it
        this.x = x + (width / 2);
        this.y = y + window.scrollY - 10;
        this.selectedText = selection.toString()
        this.showMenu = true
      }

      //make sure menu is inside view //-> not used at the moment, menu has fixed position
      if (this.showMenu) this.$nextTick(() => {
        let mx = this.$refs['menu'].clientWidth/2;
        if (this.x < mx) this.x = mx;
      });

    },

    showGlossary (elm) {
      //use tooltip menu to show glossary definition
      this.glossary = elm.getAttribute('title');
      this.isGlossary = true;
      this.hasComment = false;

       //determine menu position and show it
      const { x, y, width } = elm.getBoundingClientRect();
      this.x = x + (width / 2);
      this.y = y + window.scrollY - 10;
      this.showMenu = true;
    },

    hideMenu () {
      this.hasComment = false;
      this.showMenu = false;
    },

    markText (type) {

      console.log('■■ Highlight: markText, type=',type,', mark=',this.mark);

      //update existing mark
      if (this.mark)
      {
        let hasComment = this.mark.classList.contains('comment');

        console.log('■■ Highlight: markText on existing mark, hasComment=',hasComment);

        if (type=='comment')
        {
          if (hasComment && this.comment)
          {
            //comment has been entered, hide menu & trigger update
            this.hideMenu();
            this.$refs['comment'].blur();
          }
          else
          {
            //show comment input for existing mark without comment
            this.hasComment = true;
            this.mark.classList.add('comment');

             //focus textarea
            this.$nextTick(() => { this.$refs['comment'].focus() });
          }
        }
        else
        {
          //change color class on existing mark
          this.mark.className = hasComment? 'comment '+type:type;

          //trigger comment update
          if (hasComment && this.comment!=this.mark.getAttribute('data-comment')) return this.$refs['comment'].blur();

          //store color change
          this.storeText();
        }
      }
      else
      {
        if (this.selection.toString()==0) return this.hideMenu(); //selection was lost

        //wrap <mark> element around selection
        var r = this.selection.getRangeAt(0),
          s = document.createElement('mark');

        s.appendChild(r.extractContents());
        s.classList.add(type);

        r.insertNode(s)
        r.detach();

        if (type=='comment')
        {
          console.log('■■ Highlight: add new comment, focus textarea');

          //new comment
          let color = this.colors[0];
          s.classList.add(color);

          //show comment input
          this.comment = '';
          this.mark = s;
          this.hasComment = true;
          this.markColor = color;

          //focus textarea, don't store mark yet
          this.$nextTick(() => { this.$refs['comment'].focus() });
        }
        else
        {
          //store inserted mark
          this.storeText();
        }

        //reset selection
        this.selection.collapse(null);
      }
    },

    deleteMark () {
      //remove <mark> element from text (replace with its own contents)
      var elm,
        target = this.mark,
        tmp = document.createElement('div');

      tmp.innerHTML = target.innerHTML;

      var i = tmp.childNodes.length,
        last = target;

      while(i--){
        target.parentNode.insertBefore((elm = tmp.childNodes[i]), last);
        last = elm;
      }
      target.remove();

      //store updated text
      this.storeText();
    },

    storeText () {
      console.log('■■ Highlight: store text');

      //send DOM change to parent
      this.parseComments(true); //clear multi comment classes before storing
      this.$emit('change',this.highlightableEl.innerHTML);
    }

  }
}
</script>

<style scoped lang="stylus">
@import '~quasar-variables'

.tooltip.glossary,
.tooltip.teacher
{
  padding:8px 14px 12px 14px;
}

.tooltip i.mark
{
  background-color:transparent;
  opacity:.75;
}

.item {
  color:$white;
  cursor: pointer;
  font-size:18px;
}
.item:hover {
  color:$primary
}
.item + .item {
  margin-left: 12px;
}

.item.mark {
  display:inline-block;
  width:18px;
  height:18px;
  border-radius:50%;
  border:2px solid transparent;
  opacity:0.8;
}
.item.mark:hover {
  opacity:1;
}

/* active tooltip color */
.tooltip.m1 .item.mark.m1,
.tooltip.m2 .item.mark.m2,
.tooltip.m3 .item.mark.m3
{
  border-color:$white;
  opacity: 1;
  width: 20px;
  height: 20px;
}


</style>