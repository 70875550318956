<template>
  <div class="col-shrink" id="navbar">

    <ul class="menu vertical">

      <!-- add instructions -->
      <a v-if="addInstructions.show" href="/add-instructions" @click.prevent="$emit('showInstructions')">
        <li class="tool instructions">
          <span class="text" v-html="'instructions'"/>
        </li>
      </a>

      <!-- add sources -->
      <a v-if="addSources.show && addSources.max!==0" href="/add-media" @click.prevent="$emit('showMedia')">
        <li class="tool addimages">
          <span class="text" v-html="addSources.button || 'Add sources'"/>
        </li>
      </a>

      <!-- add text -->
      <a v-if="addText.show" href="/add-media" @click.prevent="$emit('showText')">
        <li class="tool addtext">
          <span class="text" v-html="addText.button || 'Add text'"/>
        </li>
      </a>

    </ul>

  </div>
</template>

<script>
export default {
  name: "BlockSideMenu",

  props: {
    addInstructions: {
      type: Object,
      default: () => { return {} }
    },
    addSources: {
      type: Object,
      default: () => { return {} }
    },
    addText: {
      type: Object,
      default: () => { return {} }
    },
  },
}
</script>
