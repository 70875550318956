<template>
  <q-dialog :value="show" @hide="$emit('hide')" full-width full-height>
    <q-card>
      <q-bar
        ><span class="text-h6"><b>Europeana Meta information</b></span
        ><q-space /> <q-btn v-close-popup flat dense round icon="far fa-times"
      /></q-bar>

      <q-card-section>
        <h4>Meta info</h4>
        <q-scroll-area style="height: 80vh">
          <q-markup-table
            separator="cell"
            bordered
            dense
            style="max-width: 100vw"
            wrap-cells
          >
            <tr>
              <th>label</th>
              <th>value</th>
            </tr>
            <tr>
              <td>eulink</td>
              <td>{{ record.eulink }}</td>
            </tr>
            <tr>
              <td>title</td>
              <td>{{ record.title }}</td>
            </tr>
            <tr>
              <td>description</td>
              <td
                style="max-width: 300px"
                v-html="format(record.description)"
              />
            </tr>
            <tr v-if="obj && obj.aggregations">
              <td>dataprovider</td>
              <td>{{ obj.aggregations[0].edmDataProvider.def }}</td>
            </tr>

            <tr :key="i" v-for="(x, i) in myentries(obj.proxies)">
              <td>{{ x.label }}</td>
              <td>{{ x.value }}</td>
            </tr>
          </q-markup-table>
        </q-scroll-area>
      </q-card-section>

      <q-card-actions align="center"
        ><q-btn label="ok" @click="$emit('hide')"
      /></q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { API } from "../tic";

export default {
  name: "eumeta",
  props: ["show", "record", "obj"],
  methods: {
    myentries(obj) {
      // get key/values from the obj.proxies entries
      const kvs = [];
      for (let e in obj) {
        // console.log("e: ", typeof e, e, obj[e]);
        // console.log("keys: ", Object.keys(obj[e]));
        for (let key of Object.keys(obj[e])) {
          kvs.push({ label: key, value: obj[e][key] });
        }
      }
      return kvs;
    },

    format(text) {
      if (text) {
        return text.replace("\n", "<br><br>");
      }
    },
  },
};
</script>
<style scoped>
th {
  text-align: left;
  background-color: lightgrey !important;
  color: black;
}

td {
  vertical-align: top;
}

tr:nth-child(odd) {
  background-color: #eee;
}

pre {
  font-size: 9pt;
}
</style>
