// blocktypes as used in eLA
// see also components/ABPanel.vue ; HTML for each block is defined in there

let BLOCK_TYPES = {

  text: {
    title: '',
    html: '',
    text: '',
    delta: ''
  },

  question: {
    title: '',
    html: '',
    text: '',
    delta: ''
  },


  embed: {
    title: '',
    description: '',
    embedUrl: '',
    embedCode: ''
  },

  sorting: {
    images: []
  },

  prioritizing: {
    images: []
  },

  analysing: {
    images: [],
    annotations: [],
    selections: [],
    answer:{
      annotations:[],
      selections:[]
    }
  },

  highlighting: {
    images: []
  },

  comparing: {
    images: [],
    annotations: [],
    selections: [],
    answer:{
      annotations:[],
      selections:[]
    }
  },

  discovering: {
    images: [],
    nodes: [],
    connections: [],
  }

}

export {BLOCK_TYPES}
