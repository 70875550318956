<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <h4><i class="fas fa-users"></i>&nbsp;Confirm Publish?</h4>
      </q-card-section>
      <!--
        ...content
        ... use q-card-section for it?
      -->
      <q-card-section>
        Make <b>{{ title }}</b> publicly available?
      </q-card-section>

      <q-card-section v-if="isAdmin">
        Publish this item as:
        <hi-content-type v-model="contentType" />
      </q-card-section>

      <!-- buttons example -->
      <q-card-actions align="right">
        <q-btn
          no-caps
          class="bg-grey-3 text-black"
          label="Cancel"
          @click="onCancelClick"
        />
        <q-btn
          :disable="contentType === null"
          no-caps
          color="primary"
          label="Yes"
          @click="onOKClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<!--

    confirmPublish(item, title, type) {
      let vm = this;
      this.$q
        .dialog({
          title: '<i class="fas fa-users"></i>&nbsp;Confirm Publish',
          message: `Make ${type} <b>${title}</b> publicly available?`,
          html: true,
          cancel: { noCaps: true, color: "grey-2", textColor: "black" },
          ok: { label: "Yes", color: "primary", noCaps: true },
        })
        .onOk(() => {
          vm.$emit("publish", item);
        });
    },

    -->

<script>
import hiContentType from "./hiContentType.vue";
export default {
  components: { hiContentType },
  props: {
    // ...your custom props
    title: "",
    isAdmin: false,
  },

  data() {
    return {
      contentType: null,
    };
  },

  methods: {
    // following method is REQUIRED
    // (don't change its name --> "show")
    show() {
      this.$refs.dialog.show();
    },

    // following method is REQUIRED
    // (don't change its name --> "hide")
    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      // required to be emitted
      // when QDialog emits "hide" event
      this.$emit("hide");
    },

    onOKClick() {
      // let vm = this;
      // on OK, it is REQUIRED to
      // emit "ok" event (with optional payload)
      // before hiding the QDialog
      this.$emit("ok", this.contentType.value);
      // or with payload: this.$emit('ok', { ... })

      // then hiding dialog
      this.hide();
    },

    onCancelClick() {
      // we just need to hide dialog
      this.hide();
    },
  },
};
</script>