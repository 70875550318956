// NOTE: Vue3 recommends to not use mixins anymore
// https://vuejs.org/api/options-composition.html#mixins
// refactor when we can
// used in narratives/NarrativeRelated.vue, narratives/NarrativeBuilderSearch.vue

export default {
  methods: {
    cardCSS (type,owner) {
      //return class based on card type //TODO modify card component for new types
      let c = type;
      switch (type)
      {
        case 'narrative':
        case 'Collection':
          c = 'historicalcontent';
          break;

        case 'source':
        case 'Source':
          c = 'sas';
          break;

        case 'learning-activity':
        case 'LearningActivity':
          c = 'learningactivities'
          break;

        case 'download':
          c = 'source';
          break;
      }
      if (owner) c+= ' private';
      return c;
    },
  }
};