<template>
  <q-dialog :value="show" @hide="$emit('hide')" full-width full-height>
    <q-card>
      <q-bar>
        <span class="text-h6"><b>Zoom video</b></span>
        <q-space />
        <q-btn v-close-popup flat dense round icon="far fa-times" />
      </q-bar>

      <q-scroll-area style="max-height: 86vh">
        <q-card-section>
          <div class="row">
            <p>
              Playing video from URL<br />
              <strong>{{ url }}</strong>
            </p>
          </div>
          <video style="width: 93vw" :src="url" controls autoplay />
        </q-card-section>
      </q-scroll-area>

      <q-card-actions align="center">
        <q-btn label="ok" @click="$emit('hide')" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { API } from "../tic";

export default {
  name: "zoomVideo",
  props: ["show", "url"],
};
</script>