/* eslint-disable */
import axios from "axios"
import { indexOf, without } from "lodash"
import { format } from 'date-fns'
import { nl } from 'date-fns/locale'
// import(/* webpackExclude: /_lib/ */`date-fns/locale/${navigator.language}/index.js`)
// console.log(`loading ${navigator.language}`)
// let locale = require(`date-fns/locale/${navigator.language}/index.js`);
let locale = nl

// https://medium.com/vuejs-tips/tiny-debounce-for-vue-js-cea7a1513728
function debounce(fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export const slugify = string => {
  if (string) {
    const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;"
    const b = "aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------"
    const p = new RegExp(a.split("").join("|"), "g")

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w-]+/g, "") // Remove all non-word characters
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "") // Trim - from end of text
  }
}

function parseUrl(url) {
  var elem = document.createElement("a")
  elem.href = url
  return {
    protocol: elem.protocol,
    host: elem.host,
    hostname: elem.hostname,
    port: elem.port,
    pathname: elem.pathname,
    hash: elem.hash
  }
}

function getCurrent(url) {
  // get the current BB-page index from the URL
  var path = parseUrl(url).pathname.split("/")
  // return parseInt(path.pop())
  return path.pop()
}

let sortBy = function (field, reverse, primer) {
  var key = primer
    ? function (x) {
      return primer(x[field])
    }
    : function (x) {
      return x[field]
    }

  reverse = !reverse ? 1 : -1

  return function (a, b) {
    a = key(a)
    b = key(b)
    return a === b ? 0 : reverse * ((a > b) - (b > a))
  }
}

function toggleItemInArray(collection, item) {
  console.log("###### toggle ", collection)
  console.log("itme: ", item)
  const index = indexOf(collection, item)
  if (index !== -1) {
    return without(collection, item)
  }
  return [...collection, item]
}

//deep copy an object
export const clone = (obj) => JSON.parse(JSON.stringify(obj))

export default {
  parseUrl,
  getCurrent,
  sortBy,
  toggleItemInArray,
  debounce,

  // used in older modules as tic.humanDate
  // replace with explicit export as done in Members.vue and export below
  humanDate(timestamp) {
    //return new Date(timestamp)
    // moment.locale("en-gb")
    // return moment(timestamp).format("d LLL YY- HH:mm")
    let d = new Date(timestamp)
    //console.log("locale: ", locale)
    return format(d, "PP - HH:mm", locale)
    // return 'fix:humanDate'
  },

  theDate(timestamp, relative = false) {
    // return 'fix:theDate'
    // centrale date format
    let d = new Date(timestamp)
    return format(d, "PP - HH:mm", locale)
    //return d.toUTCString()
    // return relative ? moment(d).fromNow() : moment(d)
    // return moment(d).format('MMMM Do YYYY, HH:mm:ss')
    // moment().startOf('day').fromNow();        // 12 hours ago
  }
}

// the Historiana API
export const API = axios.create({
  baseURL: `/api/`,
  headers: {
    Authorization: "Bearer {token}"
  }
})

export const humanDate = (timestamp) => {
  let d = new Date(timestamp ? timestamp : null)
  return format(d, "PP", locale)
}

export const humanDateTime = (timestamp) => {
  let d = new Date(timestamp ? timestamp : null)
  return format(d, "PP HH:mm", locale)
}

/*	usemedia.com . joes koppers . 2009
  ease-out animation object, usage:

  var ease = new UseEase(update [, callback [, speed [, refresh [, margin ]]]]);
      //update 	function, called during ease, with current values
      //callback 	function, called at end of ease
      //speed		number, ease speed <1, default .35
      //refresh	number, update rate (milliseconds) default 40
      //margin	number, snap margin at end of ease, default 1

    ease.start(values);
      //values	object, { a:[start value,end value], b:[start,end], ... } */


export const UseEase = function (e, d, c, a, b) { this.a = false; this.s = c || 0.35; this.m = b || 1; this.r = a || 40; this.uCb = e; this.eCb = d }

UseEase.prototype.start = function (a) { this.oV = a; this.v = []; this.t = []; var b = 0; for (var c in a) { this.v[b] = a[c][0]; this.t[b] = a[c][1]; b++ } this.sld(); return this }; UseEase.prototype.abort = function (b) { if (this.a) { window.clearTimeout(this.a) } if (b) { var a = {}; for (var c in this.oV) { a[c] = this.oV[c][0] } this.uCb(a) } }; UseEase.prototype.upd = function () { var a = {}, b = 0; for (var c in this.oV) { a[c] = this.v[b]; b++ } this.uCb(a) }; UseEase.prototype.sld = function () { var f = false; for (var a = 0; a < this.v.length; a++) { var e = this.t[a] - this.v[a]; this.v[a] += this.s * (e); var b = (e > 0 && this.v[a] < this.t[a] - this.m) || (e < 0 && this.v[a] > this.t[a] + this.m); if (b) { f = true } } if (f) { this.upd(); var c = this; this.a = window.setTimeout(function () { c.sld() }, this.r) } else { for (var a = 0; a < this.v.length; a++) { this.v[a] = this.t[a] } this.upd(); if (this.eCb) { this.eCb() } this.a = false } };
