<template>
  <div>
    <!-- selectcontent type forx {{ uuid }}-->
    <!-- <p>item: {{ item }}</p>
    <p>ctype: {{ ctype }}</p> -->

    <q-select
      filled
      :options="content_types"
      option-label="name"
      option-value="uuid"
      label="Select content type"
      v-model="item.content_type"
      @input="update"
    >
      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
          <q-item-section>
            <q-item-label v-html="scope.opt.name"></q-item-label>
            <q-item-label caption>{{ scope.opt.description }}</q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-icon color="primary" :name="`fa fa-${scope.opt.icon}`"></q-icon>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script>
import { API } from "../tic";
import { mapGetters } from "vuex";

export default {
  name: "selectcontenttype",
  props: ["item"],
  computed: {
    ...mapGetters("user", { isAdmin: "isAdmin", user: "uuid" }),
  },
  methods: {
    update(v) {
      console.log("update!");
      this.$emit("isDirty");
      this.ctype = v;
      API.post("/set-contenttype", {
        user: this.user,
        item: this.item.uuid,
        ctype: v.uuid,
      });
    },
  },

  data() {
    return {
      //xxctype: this.content_types.find((e) => (e.uuid = this.uuid)),
      ctype: this.current_type,
    };
  },
};
</script>
