<template>
  <div class="ea" style="height:100%">

    <!-- enter email to start prompt -->
    <q-dialog
      ref="emailInput"
      :value="emailPrompt"
      persistent
    >
      <q-card
        class="q-pa-md q-gutter-sm"
        style="width: 700px; max-width: 80vw;"
      >
        <q-form
          ref="emailForm"
          @submit="start"
        >
          <q-card-section>
            <h4><i class="far fa-pencil on-left"/>eActivity</h4>
            Please enter your email address to start this eActivity
          </q-card-section>

          <q-card-section class="q-pt-none">
            <q-input
              v-model="email"
              dense
              autofocus
              :rules="[val => !!val || 'Please enter your email', isValidEmail]"
              lazy-rules
            />
          </q-card-section>

          <q-card-actions align="right">
            <q-btn
              color="primary"
              no-caps
              label="Start"
              @click="start"
            />
          </q-card-actions>

        </q-form>
      </q-card>
    </q-dialog>

    <!-- eActivity title and icon -->
    <section id="ea-identity">
      <div class="row q-mx-auto justify-end items-start relative-position">
        <h3 class="row items-start">{{ title }}</h3>
        <div id="ea-logo">
          <img v-if="activity.thumb" :src="activity.image.thumb">
          <i v-else class="fa fa-th-large" :class="'ea-logo-'+(activity.blockType || 'default')"/>
        </div>
      </div>
    </section>

    <section v-if="block" id="ea-canvas">
      <div class="row q-mx-auto relative-position">

        <!-- numbered tabs navigation -->
        <block-top-navigation
          v-if="block"
          :mode="mode"
          :uuid="uuid"
          :block-id="block"
          :answered="answered"
        >
          <div id="ea-student-action" class="row items-center">
            <div v-if="mode=='student'">

              <!-- submit answers -->
              <q-btn
                v-if="canSubmit"
                flat
                no-caps
                icon="fa fa-paper-plane"
                label="Send to teacher"
                :disabled="!done"
                @click="submitAnswers"
              >
                <q-tooltip content-class="ea-tooltip" anchor="bottom middle" self="top middle" :offset="[0,10]">Submit eActivity to your teacher{{ done? '':', answer all blocks first!' }}</q-tooltip>
              </q-btn>
              <em v-else>
                Sent to teacher on {{ submitDate }}
              </em>

            </div>
            <div v-if="mode=='view'">

              <!-- not authenticated: login and register modals -->
              <login
                v-if="!this.isAuthenticated"
                :show-dialog="showLogin"
                @hide="showLogin = false"
              />

              <register
                v-if="!this.isAuthenticated"
                no-redirect
                :show-dialog="showRegister"
                @hide="showRegister = false"
              />

              <!-- add to my historiana -->
              <q-btn
                v-if="!added && !isOwner"
                flat
                no-caps
                icon="fa fa-plus"
                label="Add to my Historiana"
                @click="add"
              />

              <!-- edit button for owner -->
              <q-btn
                v-if="isOwner"
                flat
                no-caps
                icon="fa fa-pencil"
                label="Edit Activity"
                @click="edit(false)"
              />

            </div>

            <!-- review students select -->
            <div v-if="mode=='review'" class="row items-center">
              <q-select
                filled
                dense
                dark
                xclearable
                class="on-right"
                style="min-width:250px"
                color="grey-1"
                v-model="review"
                label="Reviewing answers from"
                :options="students"
              >
                <template v-slot:prepend>
                  <i class="fa fa-user on-left"/>
                </template>
              </q-select>

              <div class="on-right text-body2 text-weight-medium" v-if="review">
                <span v-if="review.submitted">Submitted on {{ review.submitted }}</span>
                <span v-else>Not yet submitted!</span>
              </div>

            </div>

            <!-- <span class="q-mx-md text-weight-light"><i class="fa fa-user on-left"/>{{ email }}</span> -->

          </div>
        </block-top-navigation>

        <!-- bblocks view -->
        <div class="col">
          <router-view
            ref="bblock"
            :view="view"
            :key="$route.path"
            :show-instructions="showInstructions"
            @addInstructions="setAddInstructions"
            @closeInstructions="showInstructions=false"
            @answer="storeAnswer"
            @hook:mounted="bblockMounted"
          />
          <div id="ea-loading" v-if="loading" class="row items-center">
            <q-spinner class="q-mx-auto" size="3em" :class="'ea-logo-'+(activity.blockType || 'default')"/>
          </div>
        </div>

         <!-- right side navigation -->
        <block-side-menu
          :add-instructions="addInstructions"
          @showInstructions="showInstructions=true"
        />

      </div>
    </section>

  </div>
</template>

<script>
import BlockTopNavigation from '../eabuilder/BlockTopNavigation'
import BlockSideMenu from '../eabuilder/BlockSideMenu'
import Login from "@/components/Login.vue";
import Register from "@/components/Register.vue";
import { mapGetters } from 'vuex'
import { date } from 'quasar'
import { API } from '../tic'

export default {
  name: "EaViewer",

  components: {
    BlockTopNavigation,
    BlockSideMenu,
    Login,
    Register,
  },

  props: {
    mode: {
      type: String, //student or view
      default: null
    },

    uuid: {
      type: String,
      default: null
    },

    type: {
      type: String,
      default: null
    },

    block: {
      type: String,
      default: null
    },

  },

  data () {
    return {
      email:'',
      addInstructions:{ show:false },
      showInstructions:false,
      showLogin:false,
      showRegister:false,
      debug: false,
      session: null,
      answered:[],
      canSubmit:true,
      submitted:0,
      added:null,
      students:[],
      studentAnswers:{},
      review:null,
      reviewAnswer:{},
      loading:false
    }
  },

  computed: {
    ...mapGetters('activity', ['activity','blockOrder','answers','isChanged']),
    ...mapGetters('user', ['isAuthenticated','loginUuid','displayname']),

    view () {
      return this.mode=='teacher'? 'edit':'view';
    },

    title () {
      return (this.started || this.mode=='view' || this.mode=='review')? this.activity.title || 'Untitled eActivity':'Start eActivity';
    },

    isOwner () {
      return this.loginUud? this.activity.owner == this.loginUuid:false;
    },

    started () {
      return this.session? true:false
    },

    emailPrompt () {
      return this.mode=='student' && !this.started
    },

    done () {
      return this.answered.length>0 && this.answered.length == this.blockOrder.length
    },

    submitDate () {
      return date.formatDate(this.submitted, 'DD-MM-YYYY, HH:mm')
    }
  },

  watch: {
    $route() {
      //save answers when navigating between blocks
      if (this.isChanged) this.$store.dispatch('activity/saveAnswers')

      //clear record between blocks
      this.$store.commit('activity/setCurrentRecord', null)

      //default hide instructions button
      this.setAddInstructions(false);
    },

    review() {
      //view answers for selected student
      this.reviewStudent()
    },

    addInstructions: {
      deep:true,
      handler() {
        let hasAnswered = this.answers[this.block]? Object.keys(this.answers[this.block]).length:false;
        if (this.mode!='review' && this.addInstructions.show && !hasAnswered) this.showInstructions = true;
      }
    }
  },

  mounted () {
    console.log('✎ EaViewer mounted')

    if (this.mode=='view' || this.mode=='review')
    {
      //(re)view: load the activity
      this.$store.dispatch('activity/view', {
        mode: this.mode,
        uuid: this.uuid,
        block: this.block
      }).then(response => {

        //list available answers for review
        this.students = response.map(a => {
          return {
            label: a.member.email,
            uuid: a.answer,
            submitted: a.submitted? date.formatDate(a.submitdate, 'DD-MM-YYYY, HH:mm'):false
          }
        })

      })
    }
    else
    {
      //check if we're in session
      this.session = sessionStorage.getItem('studentEmail');

      //activity has been started before?
      if (this.started)
      {
        //get email from session and start activity
        this.email = this.session;
        this.startActivity();
      }
      else if (this.block)
      {
        //always redirect to start screen
        this.$router.push({ name: 'eaViewer', params: { mode:this.mode, uuid:this.uuid }})
      }
    }
  },

  methods: {
    bblockMounted() {
      console.log('✎ EaViewer: bblock mounted',this.block)

      //check if we're in session (as computed value it did not catch the update, so we do it here manually)
      this.session = sessionStorage.getItem('studentEmail');

      //update state
      this.$store.commit('activity/setCurrentBlock', this.block)
    },

    setAddInstructions(show) {
      this.addInstructions.show = show;
    },

    isValidEmail (val) {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || 'Invalid email';
    },

    start() {
      this.$refs.emailForm.validate().then(success => {
        if (success) {
          this.startActivity()
        }
      });
    },

    startActivity () {
      this.$store.dispatch("activity/start", {
        uuid: this.uuid,
        email: this.email,
        block: this.block
      }).then((submitted) => {

        this.canSubmit = submitted? false:true;
        this.submitted = submitted

        this.getAnsweredBlocks()
      })
    },

    storeAnswer (answer) {
      console.log(Date.now(),'storeAnswer called')
      //store answer for current block
      if (this.mode=='student' && this.canSubmit)
      {
        this.$store.commit('activity/setAnswer',answer)
        this.getAnsweredBlocks()
      }
    },

    getAnsweredBlocks() {
      //gather all blocks that have an answer set
      this.answered = Object.keys(this.answers).filter(id => Object.keys(this.answers[id]).length)
    },

    submitAnswers () {
      //submit eActivity after confirm
      this.$q.dialog({
        title: '<i class="fa fa-paper-plane"></i>&nbsp;Confirm Send',
        message: '<p>Sending eActivity to your teacher, once sent your answers cannot be modified. </p><p>Are you sure?</p>',
        html: true,
        cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
        ok: { label: 'Yes', color: 'primary', noCaps: true, }
      }).onOk(() => {

        this.$store.dispatch('activity/submitAnswers').then((submitted) => {

          this.canSubmit = false; //block re-submit
          this.submitted = submitted

          //feedback
          this.$q.notify({
            type: 'positive',
            position: 'top',
            message: 'eActivity was submitted successfully!'
          })

        })

      })
    },

    getAnswers () {
      //fetch answers for student
      this.loading = true;

      API.post('/ea/get-answer', {
        answer: this.review.uuid
      }).then(response => {

        console.log('◆ API /ea/get-answer', response);

        //cache result and view answers
        this.studentAnswers[this.review.uuid] = JSON.parse(response.data.data.answer.answers)
        this.reviewStudent()
      })

    },

    reviewStudent () {
      //show answers for selected student
      const answers = this.studentAnswers[this.review.uuid]

      //fetch first if not cached
      if (!answers) return this.getAnswers()

       //set answers in store
      this.$store.commit('activity/setAnswers',answers)
      this.getAnsweredBlocks()

      //reset record to trigger bblock update
      this.$store.commit('activity/setCurrentRecord', null)
      this.$nextTick(() => {
        this.$store.commit('activity/setCurrentBlock', this.block)
        this.loading = false;
      })
    },

    add() {
      //add activity to my historiana

      if (!this.isAuthenticated) return this.$q.notify({
        type: 'negative',
        position: 'top',
        html: true,
        message: 'You need to be signed in',
        actions: [
          { label: 'Login', noCaps:true, color: 'white', handler: () => { this.showLogin = true } },
          { label: 'Register', noCaps:true, color: 'white', handler: () => { this.showRegister = true } }
        ]
      })

      API.post('/ea/add-to-my-historiana', {
        activity: this.activity.uuid,
        member:this.loginUuid
      }).then(response => {

        console.log('◆ API /ea/add-to-my-historiana', response);

        this.added = response.data.activity;

        //notify with link to editor
        this.$q.notify({
          type: 'positive',
          position: 'top',
          html: true,
          timeout:0,
          message: '<strong>e-Learning Activity added to your Historiana!</strong><br>You can now edit your own version and share it',
          actions: [
            { label: 'Start editing', noCaps:true, color: 'white', handler: () => { this.edit(this.added) } }
          ]
        })

      })
    },

    edit (uuid) {
      //redirect to BlockEditor
      let path = this.$route.path.split('/')
      const block = path.pop()
      const type = path.pop()

      //update the uuid in store
      if (uuid) this.$store.commit('activity/setUuid', uuid);

      this.$router.push('/builder/'+(uuid || this.activity.uuid)+'/edit/'+type+'/'+block);
    },
  }
}
</script>