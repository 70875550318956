<template>
    <center>
      <div classs="row">
        <h4 style="color: #a9a9a9">Loading...</h4>
      </div>
      <div classs="row">
        <q-circular-progress
          indeterminate
          size="8em"
          color="cyan"
          class="q-ma-md"
        />
      </div>
    </center>
</template>
<script>
	export default {
		name: 'HiLoading'
		
	}
</script>