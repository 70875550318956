<template>
  <div>

    <!-- Search + Results view in the Builder right sidebar -->

    <div class="builder-search">
      <q-input
        flat
        bottom-slots
        type="search"
        clearable
        clear-icon="far fa-times"
        debounce=250
        :placeholder="placeholder"
        :loading="isLoading"
        v-model="query"
        @clear="clear"
        @focus="$emit('setSidePanelExpanded',true)"
      >
        <template v-slot:prepend>
          <i class="far fa-search" />
        </template>
      </q-input>
    </div>

    <div class="result">
      <card
        draggable="true"
        v-for="(card, i) in result"
        style="margin-right:16px; cursor:pointer"
        :class="{ selected:selected.includes(card.uuid) }"
        ref="cards"
        :key="'card_'+i"
        :item="card"
        :title="card.title"
        :image-url="card.thumb.url"
        :type="card.type"
        :csstype="cardCSS(card.type,card.owner.uuid==user)"
        :hover="false"
        :btn-select="selectable(card)"
        :active-selection="selected"
        @cardClicked="toggleSelect(card.uuid)"
        @toggleSelect="toggleSelect"
        @dragstart.native="e=>dragStart(e,card)"
        @dragend.native="dragEnd"
      >{{card.text}}</card>
    </div>

  </div>
</template>

<script>
import { API } from '../tic';
import Card from '../tags/Card';
import cardCSS from '../mixins/cardCSS.mixin.js';

export default {
  name: 'NarrativeBuilderSearch',

  props: {

    placeholder: {
      type: String,
      default: ''
    },

  },

  setup (props, context) {
  },

  components: {
    Card
  },

  mixins: [cardCSS],

  data() {
    return {
      query:'',
      isLoading:false,
      willCollapse:false,
      result:[],
      selected:[]
    };
  },

  computed: {
    user() {
      return this.$store.getters['user/uuid']
    },
  },

  watch: {
    query () {
      if (this.query && this.query.length>2) this.search()
      else this.clear()
    }
  },

  mounted () {
    //->DEV
    //this.query = 'syner';
  },

  methods: {

    search () {
      //execute search, update results
      this.isLoading = true;
      this.selected = [];

      API.get('/search/?searchFor='+encodeURIComponent(this.query)+'&member='+this.$store.getters['user/uuid'])
      .then(rsp => {
        console.log('search result count=',rsp.data.count)

        //filter out 'my' results and insert them at front
        const
          result = rsp.data.data,//.map((e,i) => { e.uuid = 'card_'+i; return e }),
          my = result.filter(v => v.owner?.uuid==this.user);

        this.result = my.concat(result.filter(v => v.owner?.uuid!=this.user)).slice(0,48); //show top 50 results //TODO add limit parameter to API?

      })
      .catch(err => {
        console.log('error!',err.response?.data,err);
      })
      .then(() => {
        this.isLoading = false;
      })

    },

    clear () {
      this.result = [];
      this.selected = [];
      this.isLoading = false;
    },

    selectable (card) {
      return card.type.toLowerCase()=='source' && card.content_type.name!='Video';
    },

    toggleSelect (uuid) {
      const index = this.selected.findIndex((s) => s == uuid);
      if (index == -1) {
        //add
        this.selected.push(uuid)
      } else {
        //remove
        this.selected.splice(index, 1);
      }
    },

    cloneCard (elm)
    {
      //return a cloned card elm without the action bar
      const
        node = elm.cloneNode(true),
        actions = node.getElementsByClassName('q-card__actions');

      //remove action bar
      while (actions.length > 0) actions[0].remove();

      return node;
    },

    dragStart (e,card) {

      console.log('📚 Narrative-builder-search, drag card',card)

      //create offscreen clone element as dragimage, to work around buggy webkit ghostimage rendering
      //TODO add custom dragimages for different content types
      const
        { left, top } = e.target.getBoundingClientRect(),
        dragElm = document.createElement('div');

      //add offscreen
      dragElm.style.position = 'absolute';
      dragElm.style.left = '-1000px';
      dragElm.style.top = '-1000px';
      dragElm.appendChild(this.cloneCard(e.target));
      document.body.appendChild(dragElm);

      let type, data;


      //add more cards?
      if (this.selected.length>1)
      {
        //append additional cards to dragImage
        this.selected
          .filter(uuid => uuid!=card.uuid)
          .forEach((uuid,i) => {
            let index = this.result.findIndex(c => c.uuid==uuid);

            const card = this.cloneCard(this.$refs.cards[index].$el);
            card.style.position = 'absolute';
            card.style.left = (i+1)*10 +'px';
            card.style.top = (i+1)*10 +'px';
            card.style.zIndex = -(i+1);

            dragElm.appendChild(card)
          })

        //create sources array for transfer data
        data = {
          type:'NarrativeSourceCollection',
          attrs:{
            sources:this.result.filter(s => this.selected.includes(s.uuid))
          },
          content:[{
            "type": "paragraph",
            "content":[],
            "attrs": { "blockData": { "isCaption":'Collection' }}
          }]
        }

        e.dataTransfer.effectAllowed = 'copy';
        type = 'copy';

      }
      else
      {
        //setup transfer data for single node
        data = {
          attrs: {
            uuid: card.uuid,
            title: card.title,
            description: card.description,
          }
        }

        //TODO support more element types
        switch (card.type)
        {
          case 'source':
          case 'Source':
          case 'CollectionItem':
            data.type = 'NarrativeSource';
            data.attrs.src = card.url;
            data.attrs.type = card.content_type.name;
            data.content = [{
              "type": "paragraph",
              "attrs": { blockData:{ isCaption:'Source' } },
              "content": card.text? [{
                "type":"text",
                "text":card.text
              }]:[]
            }];
            e.dataTransfer.effectAllowed = 'copy';
            type = 'copy';
            break;

          case 'Collection':
            data.type = 'NarrativeSourceCollection';
            data.attrs.sources = card.sources;
            data.content = [{
              "type": "paragraph",
              "attrs": { blockData:{ isCaption:'Collection' } },
              "content": card.text? [{
                "type":"text",
                "text":card.text
              }]:[]
            }];
            e.dataTransfer.effectAllowed = 'copy';
            type = 'copy';
            break;

          default:
            data = {
              type:card.type,
              uuid:card.uuid,
              title:card.title,
              description:card.text,
              url:card.url,
              image:card.thumb?.url
            }
            e.dataTransfer.effectAllowed = 'link';
            type = 'link';
            break;
        }
      }


      //set drag image and remove the helper element
      e.dataTransfer.setDragImage(dragElm,e.clientX - left,e.clientY - top);
      setTimeout(e=>document.body.removeChild(dragElm),10);

      //??? below does not seem to work, why?
      e.dataTransfer.allow = type;

      //set placeholder element that will be dropped on editor (and ignored)
      //we add the dropeffect type in it, as native drag drop seems to be inconsistent across browsers
      e.dataTransfer.clearData();
      e.dataTransfer.setData("text/html", '<draggable-'+type+'/>');

      //set tranfer JSONdata for editor
      e.dataTransfer.setData("text/plain",JSON.stringify(data))

      //collapse side-panel
      this.willCollapse = setTimeout(e=>this.$emit('setSidePanelExpanded',false),500)
    },

    dragEnd (e) {
      if (this.willCollapse) clearTimeout(this.willCollapse);

      console.log('dragEnd',e.dataTransfer.dropEffect)
      //clear card selection
      if (e.dataTransfer.dropEffect!='none') this.selected = []
      //e.dataTransfer.clearData();
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~quasar-variables'

.builder-search
  position:sticky;
  top:0;
  padding:0 25px;
  z-index:1;
  background-color:alpha($appelgroen,.95);
  backdrop-filter:blur(2px);

.builder-search >>>
  .q-field__marginal
  .q-placeholder::placeholder
    color: $plasticgroen

.result
  display:flex;
  flex-wrap: wrap;
  overflow:visible;
  padding:25px;
  padding-right:calc(25px - 16px)

.selected >>> .q-card
  box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.137), 0px 5px 22px 4px rgba(0,0,0,0.118);
  cursor: grab;

</style>