<!--
Blockeditor is the main interface for editing the Blocks in the e-activity builder
It shows as a Modal with a topbar navigating back to the main site and the various BB-blocks in a
tabbed interface with additional tools in right-handed navigation via <NavBuildingBlock/>
-->

<template>
  <div class="ea">

<!--
    addSources={{ addSources }}
    addInstructions={{ addInstructions }}
    addText={{ addText }}
 -->

    <!-- eActivity title and icon -->
    <section id="ea-identity">
      <div class="row q-mx-auto">
          <q-btn
            class="absolute"
            style="margin-top:-15px; z-index:1000"
            v-if="mode=='teacher'"
            icon="fas fa-th-large"
            label="Edit in Builder"
            no-caps
            @click="goBack"
          />
      </div>
      <div class="row q-mx-auto justify-end items-start relative-position">
        <h3 class="row items-start">
          {{ activity.title || 'Untitled eActivity' }}
        </h3>
        <div id="ea-logo">
          <i class="fa fa-pencil" :class="'ea-logo-'+activity.blockType"/>
          <div class="text" :class="'ea-logo-'+activity.blockType">{{ activity.blockType }}</div>
        </div>
      </div>
    </section>

    <section id="ea-canvas">
      <div class="row q-mx-auto relative-position">

        <!-- numbered tabs navigation -->
        <block-top-navigation editor :mode="mode" :uuid="uuid" :block-id="block">

          <!-- student-mode toggle -->
          <q-toggle
            id="ea-student-action"
            dark
            size="sm"
            v-model="mode"
            false-value="teacher"
            true-value="student"
          >
            <div class="label">View as student</div>
          </q-toggle>
        </block-top-navigation>

        <!-- bblocks view -->
        <div class="col">
          <RouterView
            editor
            ref="bblock"
            :key="$route.path"
            :block-id="block"
            :mode="mode"
            :view="view"
            :show-instructions="showInstructions"
            :show-media="showMedia"
            :add-text="showText"

            @addSources="setMenuOption('addSources',...arguments)"
            @addInstructions="setMenuOption('addInstructions',...arguments)"
            @addText="setMenuOption('addText',...arguments)"

            @addMedia="showMedia=true"
            @closeInstructions="showInstructions=false"
            @closeAddText="showText=false"

            @hook:mounted="bblockMounted"
          />
        </div>

        <!-- right side navigation -->
        <block-side-menu
          :add-instructions="addInstructions"
          :add-sources="addSources"
          :add-text="addText"
          @showInstructions="showInstructions=true"
          @showMedia="showMedia=true"
          @showText="showText=true"
        />

      </div>
    </section>

    <!-- media lib -->
    <my-sources-select
      v-if="showMedia"
      :block-id="block"
      :show="showMedia"
      :options="addSources"
      @close="closeMedia"
    />

  </div>
</template>

<script>
import BlockTopNavigation from './BlockTopNavigation'
import BlockSideMenu from '../eabuilder/BlockSideMenu'
import MySourcesSelect from '../eabuilder/MySourcesSelect'
import { mapGetters } from 'vuex'

export default {
  name: 'BlockEditor',

  components: {
    BlockTopNavigation,
    BlockSideMenu,
    MySourcesSelect
  },

  props: {
    //current eactivity
    uuid: {
      type: String,
      default: ''
    },

    //current block id
    block: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      mode: 'teacher',

      //menu visibility and options
      addInstructions:{ show:false },
      addSources:{ show:false },
      addText:{ show:false },

      //menu actions
      showInstructions:false,
      showMedia:false,
      showText:false,

      debug: false,
    }
  },

  computed: {
    ...mapGetters('user', ['displayname', 'isAuthenticated']),
    ...mapGetters('activity', ['activity','isChanged']),

    view () {
      return this.mode=='teacher'? 'edit':'view';
    }
  },

  watch: {
    isAuthenticated() {
      console.log('□ BlockEditor: Auth change ('+this.isAuthenticated+')-> load activity')

      //login changed: load activity
      this.$store.dispatch('activity/load', {
        route: '',
        uuid: this.uuid,
        mode: 'teacher',
        block: this.block
      })
    },

    $route(val, oldVal) {
      console.log('□ BlockEditor: Route change -> changed=',this.isChanged,this.isChanged ? '-->> SAVE activity' : '(do not save)')

      //save changes to API server when navigating between blocks
      if (this.isChanged) {
        this.$store.dispatch('activity/save')
      }

      //update navigation when routing between same block types, as mounted hook will not be called
      if (val.name == oldVal.name) {
        console.log('□ BlockEditor: Route changed between same blocktypes')
      }

      //clear record between blocks
      this.$store.commit('activity/setCurrentRecord', null)

      //default hide BlockSideMenu options
      //this.addInstructions = false;
      //this.useMediaLib(false);
      this.setMenuOption(['addInstructions','addSources','addText'],false);
    },

    addInstructions: {
      deep:true,
      handler() {
        if (this.mode!='teacher' && this.addInstructions.show) this.showInstructions = true;
      }
    }

  },

  mounted() {},

  methods: {
    bblockMounted() {
      console.log('□ BlockEditor: bblock mounted (' + this.block + ')')
      this.$store.commit('activity/setCurrentBlock', this.block)
    },

//     setAddSources(options) {
//       this.addSources = typeof(options)=='object'? options:{}
//       this.addSources.show = options && options.show!==false? true:false;
//     },

    setMenuOption(option,options) {
      //menu option visibility and options

      if (typeof(option)=='object')
      {
        //set multiple false/true
        option.forEach(o => {
          this[o] = { show:options }
        });
      }
      else
      {
        this[option] = typeof(options)=='object'? options:{}
        this[option].show = options && options.show!==false? true:false;
      }
    },

    closeMedia() {
      this.showMedia = false;
    },

    goBack() {
      this.$router.replace(`/builder/${this.uuid}/`)
    }
  }
}
</script>
