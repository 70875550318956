<!-- going to replace this page for a modal. See 'Adding partner' on the partner pages. -->
<!-- Structure: pop-up modal, bar, row with buttons, content -->

<template>
  <div>
    <div v-if="!$q.loading.isActive">
      <!-- needed in future to limit the amount of sources based on the specs of a tool -->
      <!--  <p>maxitems: {{ maxItems }}</p> -->

      <q-bar>
        Media Library
        <q-space />
        <q-btn flat round icon="fa fa-close" @click.native="$router.go(-1)" />
      </q-bar>

      <hi-search placeholder="Search MySources" v-model="searchFor" />

      <section
        v-show="selectedImages.length === 0"
        id="actionbar-imagelibrary-a"
      >
        <div class="row justify-start items-center q-mx-auto q-gutter-md">
          <q-select
            v-model="sortKey"
            :options="sortOptions"
            label="Sort on"
            filled
          />

          <q-btn
            no-caps
            label="Upload sources"
            icon="fa fa-upload"
            @click="$store.commit('user/showUpload')"
          />
        </div>
      </section>

      <!-- OPTION B: AFTER SELECTING SOURCES -->
      <section v-show="selectedImages.length" id="actionbar-imagelibrary-b">
        <q-page-sticky position="top" :offset="[0, 18]">
          <div
            class="row q-pa-md q-gutter-md items-center"
            style="background-color: white"
          >
            <p>
              <strong>{{ selectedImages.length }}</strong
              >&nbsp;items selected
            </p>
            <q-btn
              no-caps
              color="accent"
              label="Add to tool"
              icon="fa fa-plus"
              @click="addToTool"
            />

            <q-btn
              no-caps
              color="accent"
              label="Deselect all"
              icon="fa fa-minus"
              @click="deselectAll"
            />
          </div>
        </q-page-sticky>
      </section>
      <!--END OPTION B-->

      <!--<button class="button" @click="images.unshift(images[0])">CXX</button>-->

      <!-- ===== OVERVIEW IMAGES ===== -->

      <section id="card-holder" class="full-height">
        <div class="row q-mx-auto justify-start items-start q-gutter-md">
          <div v-for="(card, i) in sources" :key="i">
            <transition
              enter-active-class="animated fadeIn"
              leave-active-class="animated fadeOut"
            >
              <Card
                :index="i"
                :item="card.source"
                :title="card.source.title"
                :thumb="card.asset.thumb"
                :filetype="card.asset.type"
                :image-url="card.source.url"
                :btn-delete="false"
                :uuid="card.source.uuid"
                :tags="card.tags"
                :has_tags="card.has_tags"
                :btn-share="false"
                :btn-edit-modal="(showZoom = false)"
                :btn-select="true"
                :more="false"
                @toggleSelect="changeit"
                @deleteCard="deleteCard"
                :active-selection="selectedImages"
                type="Source"
                csstype="source"
              >
                <!-- {card.source.description}}-->
              </Card>
            </transition>
          </div>
        </div>
      </section>

      <UploadSource
        @close="$store.commit('user/closeUpload')"
        v-show="$store.getters['user/showUpload']"
      />
    </div>
  </div>
</template>

<script>
import Card from "../tags/Card";
// import Modal from '../tags/Modal'
import tic from "../tic";
import UploadSource from "../components/UploadSource";
import $ from "jquery";
import _ from "lodash";
import { API } from "../tic";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "MySources",
  //components: { Card, Modal, Upload },
  components: { Card, UploadSource },
  props: {
    maxItems: {
      type: Number,
      default: 0,
    },

    filter: {
      type: String,
      default: "*",
    },
  },
  watch: {
    searchFor: _.debounce(function (e) {
      this.$store.dispatch("user/getSources", { searchFor: e });
    }, 500),

    sortKey(key) {
      switch (key.value) {
        case "az":
          return this.sources.sort(
            tic.sortBy("source", false, (a) => {
              return a.title ? a.title.toUpperCase() : "";
            })
          );

        case "za":
          return this.sources.sort(
            tic.sortBy("source", true, (a) => {
              return a.title ? a.title.toUpperCase() : "";
            })
          );

        case "newest":
          return this.sources.sort(
            tic.sortBy("source", true, (a) => {
              return a.created;
            })
          );

        case "oldest":
          return this.sources.sort(
            tic.sortBy("source", false, (a) => {
              return a.created;
            })
          );
      }
    },
  },

  methods: {
    // code from https://stackoverflow.com/questions/6268508/restart-animation-in-css3-any-better-way-than-removing-the-element
    // added @click="setFilter(t), reset_animation()" to the corresponding buttons

    isSelected(uuid) {
      return uuid in this.selectedImages;
    },

    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        // tagKeywords.filter(v.name => v.name.toLowerCase().indexOf(needle) > -1)
        this.filteredTagKeywords = this.tagKeywords.filter(
          (v) => v.name.toLowerCase().indexOf(needle) > -1
        );
      });
    },

    deselectAll() {
      this.selectedImages = [];
      // force a redraw; doing it more subtle than this would be nice
      //this.sources = []
      // deselect the cards in the interface with jQuery ;-(((
      $(".card-select").prop("checked", false);
      return this.loadData();
    },
    loadData() {
      API.post("/my-sources", {
        uuid: this.$store.state.user.uuid,
      }).then((response) => {
        // this.sources = response.data.sources
      });
    },
    addToTool() {
      // add the selected images to the current block of the current tool
      // we have UUIDs so we need to get the approtiate entries from sources
      // by filtering them

      // does not show as there is no feedback div at this moment
      // this.$store.dispatch('sendFeedback', { msg: 'Sources added to E-Activity', status: 'ok' })

      for (let img of this.selectedImages) {
        this.$store.commit({
          type: "activity/addSource",
          current: this.$store.getters["activity/currentBlock"],
          source: this.sources.filter((e) => {
            return e.source.uuid === img;
          })[0].source,
        });
      }

      history.go(-1);
      // save now to avoid loss of images
      return this.$store.dispatch("activity/save");
    },
    deleteCard(index) {
      this.sources.splice(index, 1);
    },
    closeZoom(e) {
      console.log("closezoom ", e);
      this.showModal = false;
    },
    showEdit(item, offset) {
      console.log("SHOW EDIT ", item, offset);
      this.item = item.source;
      this.currentFocus = offset;
      this.showModal = true;
    },
    changeit(e) {
      let i = this.selectedImages.indexOf(e);
      if (i !== -1) {
        // remove
        this.selectedImages.splice(i, 1);
      } else {
        // add
        this.selectedImages.push(e);
      }
    },
  },

  computed: {
    ...mapGetters("user", ["sources", "tagLanguages", "tagKeywords"]),

    // sources () {
    //   return this.$store.getters['user/sources']
    // }
  },

  mounted() {
    this.$q.loading.show({
      delay: 400, // ms
    });

    this.$store.dispatch("user/getSources").then(() => {
      this.$q.loading.hide();
    });
    //this.sources = this.$store.getters['user/sources']

    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 37) {
        // left cursor
        if (this.sources[this.currentFocus - 1].source) {
          this.item = this.sources[this.currentFocus - 1].source;
          this.currentFocus--;
        }
      }

      if (e.keyCode === 39) {
        // right cursor
        if (this.sources[this.currentFocus + 1].source) {
          this.item = this.sources[this.currentFocus + 1].source;
          this.currentFocus++;
        }
      }
    });
  },
  data() {
    return {
      filteredTagKeywords: this.tagKeywords,
      value2: [],
      sortOptions: [
        {
          label: "Oldest",
          value: "oldest",
        },
        {
          label: "Newest",
          value: "newest",
        },
        {
          label: `A${"\u2192"}Z`,
          value: "az",
        },
        {
          label: `Z${"\u2192"}A`,
          value: "za",
        },
      ],
      otagLanguages: [
        {
          id: "goog",
          desc: "Google",
        },
        {
          id: "fb",
          desc: "Facebook",
        },
        {
          id: "twt",
          desc: "Twitter",
        },
        {
          id: "app",
          desc: "Apple",
        },
        {
          id: "ora",
          desc: "Oracle",
          inactive: true,
        },
      ],
      options: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      selectedKeywords: [],
      selectedLanguages: [],
      value3: [],
      typing: false,
      currentFocus: 0,
      showModal: false,
      selectedImages: [],
      item: {},
      sortKey: "newest",
      activeFilters: [],
      searchFor: null,
      // sources: []
    };
  },
};
</script>

<style lang="scss">
$red: "#fcfcfc";

.inactive {
  opacity: 0.5;
  background-color: $red;
}

.float-right {
  float: right;
}
#filteron .menu {
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
}
</style>
