<template>
  <div id="workbench" class="ea-canvas">

    <!-- teacher view -->
    <div class="bblock" v-if="mode=='teacher'" :class="{ 'is-loading':isLoading }">
      <h3 class="ea-block-title" :class="{ editor:mode=='teacher' }">
        <i class="far fa-file-alt on-left" />Text block
      </h3>

      <q-input
        v-model="localRecord.title"
        style="margin-bottom:20px;"
        label="Title for this block"
        filled
        @blur="storeRecord"
      />

      <quill-editor
        ref="editor"
        v-model="localRecord.html"
        :toolbar-options="[
          [{ header: [1, 2, 3, 4, 5, false] }],
          ['bold', 'italic', 'underline'],
          ['blockquote', { list: 'ordered' }, { list: 'bullet' }]
        ]"
        @update="setText"
        @blur="storeRecord"
      />
    </div>

    <!-- student view -->
    <div v-if="view=='view'">
      <h3 class="ea-block-title">
        <i class="far fa-file-alt on-left" />{{ localRecord.title }}
      </h3>
      <p v-html="localRecord.html" />
    </div>

    <!-- This is the HELP message -->
    <q-dialog :value="showHelp" @input="$emit('toggleHelp')">
      <q-card class="ea-help">
        <q-card-section class="row items-center justify-between">
          <div class="text-h6"><i class="fa fa-question on-left"/>Text tool explanation</div>
          <q-btn icon="fas fa-times" flat round dense v-close-popup />
        </q-card-section>

        <span v-if="view=='view'">
          <q-card-section>
            <p>This is a help message for students</p>
          </q-card-section>
        </span>

        <span v-if="view=='edit'">
          <q-card-section>
            <p>This is a help message for the creator</p>
          </q-card-section>
        </span>

        <q-card-actions class="flex-center">
          <q-btn color="primary" no-caps label="OK" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>
<script>
import { clone } from "../tic"
import QuillEditor from "../components/QuillEditor"
import { mapGetters } from 'vuex'

export default {
  name: "BbText",

  components: {
    QuillEditor
  },

  props: {
    blockId: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'student'
    },
    view: {
      type: String,
      default: 'view'
    },
    editor: {
      type: Boolean,
      default: false
    },
    showHelp: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoading: true,
      localRecord: {},
    }
  },

  computed: {
    ...mapGetters('activity',['record','answer']),
  },

  watch: {
    record: {
      //monitor global record update (e.g. login changes after block is mounted)
      immediate: true,
      handler() {
        console.log('BB-text: record changed')
        if (this.record) this.setLocalRecord()
      }
    }
  },

  mounted() {},

  methods: {
    setLocalRecord() {
      //clone global state record to localRecord
      this.localRecord = clone(this.record)

      //mark block 'done' by setting answer value
      if (this.mode=='student' && this.answer && !Object.keys(this.answer).length)
      {
         this.$emit('answer',{ viewed:true })
      }

      requestAnimationFrame(() => { this.isLoading = false });
    },

    setText(text) {
      this.localRecord.text = text
    },

    storeRecord() {
      console.log("BB-text: push localRecord to Store")
      this.$store.commit("activity/setRecord", {
        block: this.blockId,
        record: this.localRecord
      })
    }
  }
}
</script>
