<template>
  <section id="hiSearch" class="light-gray">
    <div class="row q-mx-auto justify-between">
      <div class="col">
        <q-input
          flat
          bottom-slots
          :color="getColor"
          type="search"
          clearable
          clear-icon="fas fa-times"
          input-class="search"
          style="max-width: 960px"
          :placeholder="placeholder"
          :hint="hint"
          :loading="isLoading"
          @input="handleInput"
          debounce="500"
        >
          <template v-slot:prepend><i class="fas fa-search" /> </template>
        </q-input>
      </div>
    </div>
  </section>
</template>
<script>
import getColor from "../mixins/getColor.mixin.js";
export default {
  name: "HiSearch",
  mixins: [getColor],

  props: {
    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    hint: {
      type: String,
      default: "",
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: "",
    };
  },
  methods: {
    handleInput(event) {
      console.log("handleinput ", event);
      this.$emit("input", event);
    },
  },
};
</script>


