<template>
  <div id="workbench" class="ea-canvas">

    <!-- teacher view -->
    <div class="bblock" v-if="mode==='teacher'" :class="{ 'is-loading':isLoading }">
      <h3 class="ea-block-title">
        <i class="fa fa-link on-left"/>Embed block
      </h3>

      <q-input
        v-model="localRecord.title"
        style="margin-bottom:20px;"
        label="Title for this block"
        filled
        @blur="storeRecord"
      />

      <quill-editor
        ref="editor"
        v-model="localRecord.description"
        placeholder="Optional description"
        :toolbar-options="[
          [{ 'header': [1, 2, 3, 4, 5, false] }],
          ['bold', 'italic', 'underline'],
          ['blockquote',{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ]"
        @update="setText"
        @blur="storeRecord"
      />

      <q-separator class="q-mt-xl"/>
      <h5>Embed code</h5>

      <div class="row">
        <div class="col">
          <q-input
            v-model="localRecord.embedCode"
            type="textarea"
            filled
            rows="6"
            label="Paste embed code here"
            @blur="storeRecord"
          />
        </div>
        <div class="col q-ml-lg text-body2">
          How to embed a video from YouTube:
          <ol>
            <li>On a computer, go to the YouTube video you want to embed.</li>
            <li>Under the video, click <strong>Share</strong></li>
            <li>Click <strong>Embed</strong>.</li>
            <li>From the box that appears, copy the HTML code.</li>
            <li>Paste the code into the textfield on the left.</li>
          </ol>
        </div>
      </div>

      <q-separator class="q-mt-xl"/>
      <h5>External link</h5>

      <q-input
        v-model="localRecord.embedUrl"
        filled
        label="Paste external link here"
        :rules="[ val => val.length==0 || val.startsWith('http') || 'Please enter a full url, starting with http(s)://' ]"
        lazy-rules
        @blur="storeRecord"
      />

    </div>

    <!-- student view -->
    <div v-if="view=='view'">
      <h3 class="ea-block-title">
        <i class="fa fa-link on-left"/>{{ localRecord.title }}
      </h3>
      <p v-html="localRecord.description" />

      <!-- embed -->
      <div v-if="localRecord.embedCode" v-html="localRecord.embedCode"/>

      <!-- or iframe with url -->
      <iframe
        v-else-if="localRecord.embedUrl!=''"
        :src="localRecord.embedUrl"
        frameborder="0"
        allowfullscreen
      />
    </div>

    <!-- This is the HELP message -->
    <q-dialog :value="showHelp" @input="$emit('toggleHelp')">
      <q-card class="ea-help">
        <q-card-section class="row items-center justify-between">
          <div class="text-h6"><i class="fa fa-question on-left"/>Embed tool explanation</div>
          <q-btn icon="fas fa-times" flat round dense v-close-popup />
        </q-card-section>

        <span v-if="view=='view'">
          <q-card-section>
            <p>This is a help message for students</p>
          </q-card-section>
        </span>

        <span v-if="view=='edit'">
          <q-card-section>
            <p>This is a help message for the creator</p>
          </q-card-section>
        </span>

        <q-card-actions class="flex-center">
          <q-btn color="primary" no-caps label="OK" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>

</template>

<script>
import { clone } from '../tic';
import QuillEditor from '../components/QuillEditor'
import { mapGetters } from 'vuex'

export default {
  name: 'BbEmbed',

  components: {
    QuillEditor
  },

  props: {
    blockId: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'student'
    },
    view: {
      type: String,
      default: 'view'
    },
    editor: {
      type: Boolean,
      default: false
    },
    showHelp: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoading:true,
      localRecord: {}
    }
  },

  computed: {
    ...mapGetters('activity',['record','answer']),
  },

  watch: {
    record: {
      //monitor global record update (e.g. login changes after block is mounted)
      immediate: true,
      handler() {
        console.log('BB-embed: record changed')
        if (this.record) this.setLocalRecord()
      }
    }
  },

  mounted() {
  },

  methods: {
    setLocalRecord() {
      //clone global state record to localRecord
      this.localRecord = clone(this.record);

      //mark block 'done' by setting answer value
      if (this.mode=='student' && this.answer && !Object.keys(this.answer).length)
      {
         this.$emit('answer',{ viewed:true })
      }

      requestAnimationFrame(() => { this.isLoading = false });
    },

    setText(text) {
      this.localRecord.descrition = text
    },

    storeRecord () {
      console.log('BB-question: push localRecord to Store');
      this.$store.commit('activity/setRecord', { block: this.blockId, record: this.localRecord });
    },
  }

}

</script>