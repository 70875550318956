<template>
  <section id="my-tags">
    <div class="row q-mx-auto justify-between item-start">
      <div class="col q-pa-md">
        <p>Manage your tags by adding or deleting. You use these custom tags to add them to the e-Learning activities you create.</p>
      </div>
    </div>

    <div class="row q-mx-auto justify-between item-start add-tags-container q-gutter-md">

      <!-- LANGUAGE TAGS --->
      <div class="col">
        <h4 class="q-mb-md">Language</h4>
        <q-input
          filled
          dense
          class="input-group-field q-mb-lg"
          v-model="inLang"
          @keyup.enter="doAddTag(inLang, 'MyLanguages'); inLang=''"
          type="text"
          label="Add a new language"
        />

        <q-chip
          v-for="tag in tagLanguages"
          removable
          color="grey-6"
          text-color="white"
          @remove="removeTag(tag.uuid, 'MyLanguage')"
          :key="tag.uuid"
          :label="tag.name"/>
      </div>

      <!-- YEAR TAGS --->
      <div class="col">
        <h4 class="q-mb-md">Year</h4>
        <q-input
          filled
          dense
          class="input-group-field q-mb-lg"
          v-model="inYear"
          @keyup.enter="doAddTag(inYear, 'MyYears'); inYear=''"
          type="text"
          label="Add a new year"
        />

        <q-chip
          v-for="tag in tagYears"
          removable
          color="grey-6"
          text-color="white"
          @remove="removeTag(tag.uuid, 'MyLanguage')"
          :key="tag.uuid"
          :label="tag.name"
        />
      </div>

      <!-- CLASS TAGS --->
      <div class="col">
        <h4 class="q-mb-md">Class/group</h4>
        <q-input
          filled
          dense
          class="input-group-field q-mb-lg"
          v-model="inClass"
          @keyup.enter="doAddTag(inClass, 'MyClasses'); inClass=''"
          type="text"
          label="Add a new class or group"
        />

        <q-chip
          v-for="tag in tagClasses"
          removable
          color="grey-6"
          text-color="white"
          @remove="removeTag(tag.uuid, 'MyClasses')"
          :key="tag.uuid"
          :label="tag.name"
        />
      </div>

      <!-- KEYWORD TAGS --->
      <div class="col">
        <h4 class="q-mb-md">Keyword</h4>
        <q-input
          filled
          dense
          class="input-group-field q-mb-lg"
          v-model="inKeyword"
          @keyup.enter="doAddTag(inKeyword, 'MyKeywords'); inKeyword=''"
          type="text"
          label="Add a new keyword"
        />

        <q-chip
          v-for="tag in tagKeywords"
          removable
          color="grey-6"
          text-color="white"
          @remove="removeTag(tag.uuid, 'MyKeywords')"
          :key="tag.uuid"
          :label="tag.name"
        />
      </div>

    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
export default {
  computed: {
    ...mapGetters('user', ['tagLanguages', 'tagYears', 'tagClasses', 'tagKeywords', 'hasTag']),
  },
  methods: {
    ...mapActions('user', ['addTag']),

    // TODO: move verify to action instead of here? 
    doAddTag (tagname, tagset) {
      if (this.hasTag(tagname, tagset)) {
        this.$q.dialog({
          title: 'Warning',
          message: `Tag <b>${tagname}</b> already exists`,
          html: true,
          ok: {
            color: 'primary',
          }
        })
      } else {
        this.addTag({
          name: tagname,
          set: tagset
        })
      }
    },

    removeTag (tagUuid) {
      this.$q.dialog({
        title: 'Warning',
        message: 'This will permanently delete the tag.<br/>Continue?',
        persistent: true,
        html: true,
        ok: {
          push: false,
          noCaps: true,
          color: 'primary'

        },
        cancel: {
          push: false,
          noCaps: true,
          color: 'grey'

        }
      }).onOk(() => {
        this.$store.dispatch('user/removeTag', tagUuid)
        this.$q.notify({
          message: 'Tag deleted',
          position: 'center',
          color: 'green'
        })
      }).onCancel(() => {
        this.$q.notify({
          message: 'Not deleted',
          position: 'center',
          color: 'green'
        })
      })
    }
  },

  // ixxxx for user input of new elements
  data () {
    return {
      inLang: null,
      inYear: null,
      inClass: null,
      inKeyword: null
    }
  }
}
</script>
<style scoped>

  </style>