<template>
  <node-view-wrapper
    class="key-question-node"
    ref="nodeview"
    :class="{ 'has-focus':focus }"
    @dragover.native="dragOver"
    @dragleave.native="dragLeave"
    @drop.native="e=>e.preventDefault()"
  >
    <div
      class="drag-handle"
      contenteditable="false"
      draggable="true"
      data-drag-handle
      @click="selectNode"
    />
    <node-view-content class="question-text"/>

  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2'
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
    EditorContent,
    BubbleMenu,
  },
  props: nodeViewProps,

  data () {
    return {
      focus:false
    }
  },

  methods: {

    selectNode () {
      this.editor.commands.setNodeSelection(this.getPos());
    },

    dragOver (e) {
      e.preventDefault();
      this.editor.options.onToggleDropcursor(false);
      //this.editor.commands.hideDropcursor();
    },

    dragLeave (e) {
      this.editor.options.onToggleDropcursor(true);
      //this.editor.commands.showDropcursor();
    },

  },

  mounted () {

    this.$nextTick(e => {

      //autogrow on textarea after it is rendered
      //this.$refs.description.$el.setAttribute('autogrow',true)

      //Firefox input interaction fix: remove draggable attribute from top element //TODO find better solution?
      this.$refs.nodeview.$el.setAttribute('draggable',false)

    });
  }
}
</script>


<style lang="stylus" scoped>
@import '~quasar-variables'

.key-question-node
  /* background-color:alpha($korenblauw,.2) */
  /* background: #FAF594; */
  position: relative;
  margin:16px 0;
  clear:left;
  /* box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.137), 0px 5px 22px 4px rgba(0,0,0,0.118); */

.key-question-node.has-focus
/*
  border:2px solid $korenblauw;
  margin:-2px;
  margin-bottom:14px;
 */
  //box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);
  box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.137), 0px 5px 22px 4px rgba(0,0,0,0.118);

.drag-handle
  float:left;
  width:50px;
  height:50px;
  cursor: grab;
  background-color:$eigeel;


.question-text
  padding:10px 0 12px 0;
  font-size: 14px;
  line-height: 21.5px;
  color:$eigeel;
  font-weight:bold;
  white-space: pre-wrap;

</style>
