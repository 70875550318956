<!--
  description: Display a text in a modal screen and wait for the user to give either a Cancel or OK response. Also sets-up keyboard to listen for Escape (Cancel) and Enter (OK)

  image: 
    url: /static/media/Confirm.png
    title: Sample Confirm dialog

  inputs: 
    title: the text to display inside a h3 element.
    action: name of action as displayed in the feedback message.

  emits:
    closeConfirm: user clicked cancel.
    confirmed: user clicked confirm.
  
  example: |
    <confirm action="Delete Block" 
    v-show="showConfirmDeleteBlock"
    title="Confirm delete"
    @closeConfirm="showConfirmDeleteBlock=!showConfirmDeleteBlock" 
    @confirmed="deleteBlock">Are you sure you want to delete this block?
    </confirm>

  note: The v-show variable must be in the local context as a data propery

  todo: Make the icon dynamic; currently it is hardcoded to `fa-trash-o`

-->
<template>
  <Transition name="modal">
    <div
      class="modal-mask"
      @click="close"
    >
      <div
        class="modal-container"
        @click.stop
      >
        <div class="modal-header">
          <h3>
            <i
              class="fa fa-trash"
              aria-hidden="true"
            /> {{ title }}
          </h3>
        </div>

        <div class="modal-body">
          <slot />
        </div>

        <div class="modal-footer align-right button-group small">
          <a
            class="modal-cancel-button button"
            @click="close"
          >
            <i
              class="fa fa-ban"
              aria-hidden="true"
            />
            Cancel
          </a>
          <a
            class="modal-default-button button call-to-action"
            @click="confirm"
          >
            <i
              class="fa fa-check"
              aria-hidden="true"
            />
            Yes
          </a>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>

export default {
  name: 'ConfirmModal',

  props: {
    title: {
      type: String,
      default: '-no title-'
    },
    action: {
      type: String,
      default: ''
    }
  },


  methods: {
    close () {
      console.log('** confirm.vue close')
      if (this.action) {
        this.$store.dispatch('sendFeedback', {msg: `${this.action} canceled`, status: "ok"})
      } else {
        this.$store.dispatch('sendFeedback', {msg: "Canceled", status: "ok"})
      }
      this.$emit('closeConfirm')
    },
    confirm () {
      this.$emit('confirmed')
    }
  }
}
</script>
<style>

.modal-header h3 {
    margin-top: 0;
    color: #000;
}

.modal-body {
    margin: 20px 0;
    color:black;
}

.text-right {
    text-align: right;
}

.form-label {
    display: block;
    margin-bottom: 1em;
}

.form-label > .form-control {
    margin-top: 0.5em;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.5em 1em;
    line-height: 1.5;
    border: 1px solid #ddd;
}

</style>
