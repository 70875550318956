<template>
  <div>
    <q-uploader
      v-show="false"
      :url="avatarUploadURL"
      ref="uploader"
      :multiple="false"
      auto-upload
      @uploaded="getUpInfo"
    />

    <section id="my-profile">
      <q-banner
        style="z-index:1; position: fixed; width: 100%"
        v-show="isSaved"
        class="bg-positive text-white"
      >
        <h3 class="flex flex-center">Changes saved</h3>
      </q-banner>

      <div class="row q-mx-auto justify-between q-pt-md vertical-middle">
        <div class="col q-gutter-md">
          <q-form autocomplete="off" class="q-gutter-md">
            <div class="note" v-if="record.login">
              NOTE: Username cannot be changed, contact support@historiana.eu if
              you really need to.
            </div>

            <q-input
              type="text"
              v-if="record.login"
              :value="record.login"
              readonly
              label="Username"
            />

            <q-input
              xautocomplete="name"
              type="text"
              v-model="record.name"
              label="Your name"
            />

            <q-input
              xautocomplete="email"
              type="text"
              v-model="record.email"
              placeholder="name@school.com"
              label="E-mail"
            />

            <CountrySelect
              :value="record.countrycode"
              @update="updateCountry"
              :selected="record.countrycode"
            />
            <br />

            <q-toggle label="Show Beta features" v-model="record.showBeta">
              <q-tooltip
                >Hide beta features unless<br />directed otherwise</q-tooltip
              >
            </q-toggle>

            <div v-if="$store.state.showDebug">
              <h5>My Roles</h5>
              <ul>
                <li>uuid {{ uuid }}</li>
                <li>site-admin: {{ isAdmin }}</li>
                <li>partner: {{ isPartner }}</li>
              </ul>
            </div>
          </q-form>
        </div>

        <div
          class="col-shrink q-ml-md q-pa-md text-center"
          style="border: 1px solid grey"
        >
          <h4 class="q-mt-sm">Avatar</h4>
          <q-avatar label="Avatar" size="86px">
            <img :src="imageUrl" />
          </q-avatar>
          <q-separator dark class="q-py-md" />
          <q-btn-group>
            <q-btn
              @click="pickFile"
              unelevated
              size="sm"
              color="grey-6"
              icon="fa fa-upload"
              label="Upload"
            />
            <q-btn
              @click="removeAvatar"
              unelevated
              size="sm"
              color="grey-6"
              icon="fa fa-trash"
              label="Delete"
            />
          </q-btn-group>
        </div>
      </div>

      <div class="row q-mx-auto justify-between items-start">
        <div class="col q-py-md">
          <q-btn
            no-caps
            unelevated
            color="primary"
            class="q-mr-md"
            @click="save"
            label="Save My Profile"
          />
          <!-- test email button not needed for now -->
          <!-- q-btn no-caps left @click="sendmail" label="Send me a test mail"/-->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CountrySelect from '../components/CountrySelect';
//import app from '../main.js'
import { API } from '../tic';
import store from '../store';
import { mapGetters } from 'vuex';
export default {
  name: 'MyProfile',
  components: { CountrySelect },
  watch: {
    'record.countrycode': function() {
      this.error = false;
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin', 'isPartner', 'uuid']),
    avatarUploadURL() {
      return `/api/avatar/${this.$store.getters['user/uuid']}`;
    },

    avaButton() {
      return this.imageUrl ? 'Change' : 'Upload';
    }
  },

  methods: {
    getUpInfo(info) {
      //let resp = JSON.parse(info.xhr.response)
      this.record.avatar = `/ua/avatars/${this.$store.getters['user/uuid']}.jpg`;
      // force update in UI by adding a timestamp
      this.imageUrl = `${this.record.avatar}?{Date.now()}`;
      this.$refs.uploader.reset();
      //this.save()
    },

    pickFile() {
      this.$refs.uploader.pickFiles();
      // upload is triggered with auto-upload! thus it needs to be set on the instance
    },

    removeAvatar() {
      console.log('remove ava');
      this.record.avatar = '';
      console.log(this.record);
      this.imageUrl = null;
    },

    checkUsername(e) {
      API.post('/check-username', {
        username: e.target.value,
        uuid: this.$store.getters['user/uuid']
      }).then(r => {
        this.loginExists = r.data.data.exists;
        if (!this.loginExists) {
          this.record.login = e.target.value;
        }
      });
    },

    sendmail() {
      API.post('/send-a-mail', {
        user: this.$store.getters['user/uuid'],
        subject: 'een oonderwerp'
      }).then(e => {
        alert('message sent');
      });
    },

    // zou dit eigenlijk niet in het country-select component kunnen
    // zodat we hier een v-model kunnen doen ipv een event handler?
    updateCountry(e) {
      console.log('update country! ', e);
      this.record.countrycode = e;
    },

    save(e) {
      if (!this.record.countrycode) {
        this.error = true;
        window.eventBus.$emit('alert', 'Please select a country.');
        return;
      }
      this.$store.dispatch('user/updateProfile', this);
    },

    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.record.avatar = `/ua/avatars/${this.$store.getters['user/uuid']}.jpg`;
      this.save();
    }
  },

  data() {
    let vm = this;
    return {
      error: false,
      loginExists: false,
      record: {
        name: this.$store.getters['user/name'],
        login: this.$store.getters['user/login'],
        email: this.$store.getters['user/email'],
        countrycode: this.$store.getters['user/countrycode'] || '',
        avatar: this.$store.getters['user/avatar'],
        showBeta: this.$store.getters['user/showBeta']
      },
      noChange: true,
      hasChanged: false,
      isSaved: false,
      imageUrl: this.$store.getters['user/avatar'] || '',
      headers: [
        // these are needed in the API to identify the user
        { name: 'X-Session', value: vm.$store.getters['user/session'] },
        { name: 'X-Process', value: 'createSource' }
      ]
    };
  }
};
</script>
<style>
.note {
  background-color: yellow;
  padding: 1em;
}
.noChange {
  background-color: grey;
}

.hasChanged {
  background-color: red;
}

.isSaved {
  background-color: #0f0;
  color: black;
  text-align: center;
  font-weight: bold;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 278px;
  height: 278px;
  display: block;
}

.red {
  color: red;
  background-color: red;
  border: 2px solid red;
}
</style>
