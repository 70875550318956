<template>
  <div id="workbench" class="ea-canvas">

    <!-- teacher view -->
    <div class="bblock" v-if="mode=='teacher'" :class="{ 'is-loading':isLoading }">
      <h3 class="ea-block-title" :class="{ editor:mode=='teacher' }">
        <i class="fa fa-question on-left" />Question block
      </h3>

      <q-input
        v-model="localRecord.title"
        style="margin-bottom:20px;"
        label="Title for this block"
        filled
        @blur="storeRecord"
      />

      <quill-editor
        ref="editor"
        v-model="localRecord.html"
        :toolbar-options="[
          [{ header: [1, 2, 3, 4, 5, false] }],
          ['bold', 'italic', 'underline'],
          ['blockquote', { list: 'ordered' }, { list: 'bullet' }]
        ]"
        @update="setText"
        @blur="storeRecord"
      />
    </div>

    <!-- student view -->
    <div v-if="view=='view'">
      <h3 class="ea-block-title">
        <i class="fa fa-question on-left" />
        {{ localRecord.title }}
      </h3>
      <p v-html="localRecord.html" />

      <!-- answer input -->
      <q-input
        :disable="this.mode=='review'"
        type="textarea"
        filled
        rows="10"
        cols="72"
        v-model="studentAnswer"
        :label="this.mode=='review'? 'Answer':'Write your answer here'"
        @blur="storeAnswer"
      />
    </div>

    <!-- This is the HELP message -->
    <q-dialog :value="showHelp" @input="$emit('toggleHelp')">
      <q-card class="ea-help">
        <q-card-section class="row items-center justify-between">
          <div class="text-h6"><i class="fa fa-question on-left"/>Question tool explanation</div>
          <q-btn icon="fas fa-times" flat round dense v-close-popup />
        </q-card-section>

        <span v-if="view=='view'">
          <q-card-section>
            <p>This is a help message for students</p>
          </q-card-section>
        </span>

        <span v-if="view=='edit'">
          <q-card-section>
            <p>This is a help message for the creator</p>
          </q-card-section>
        </span>

        <q-card-actions class="flex-center">
          <q-btn color="primary" no-caps label="OK" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import { clone } from '../tic'
import QuillEditor from '../components/QuillEditor'
import { mapGetters } from 'vuex'

export default {
  name: 'BbQuestion',

  components: {
    QuillEditor
  },

  props: {
    blockId: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'student'
    },
    view: {
      type: String,
      default: 'view'
    },
    editor: {
      type: Boolean,
      default: false
    },
    showHelp: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoading: true,
      localRecord: {},
      studentAnswer: ''
    }
  },

  computed: {
    ...mapGetters('activity',['record','answer']),
  },

  watch: {
    record: {
      //monitor global record update (e.g. login changes after block is mounted)
      immediate: true,
      handler() {
        console.log('BB-question: record changed')
        if (this.record) this.setLocalRecord()
      }
    }
  },

  mounted() {},

  methods: {
    setLocalRecord() {
      //clone global state record to localRecord
      this.localRecord = clone(this.record)

      //add student answer
      if (this.answer) this.studentAnswer = this.answer.text || ''

      requestAnimationFrame(() => { this.isLoading = false });
    },

    setText(text) {
      this.localRecord.text = text
    },

    storeRecord() {
      console.log('BB-question: push localRecord to Store')
      this.$store.commit('activity/setRecord', {
        block: this.blockId,
        record: this.localRecord
      })
    },

    storeAnswer() {
      this.$emit('answer',this.studentAnswer? { text:this.studentAnswer }:false )
    }
  }
}
</script>
