<!--
description: "this is the headless global view used for site and popups"
-->
<template>
  <q-layout view="hHh lpr fff" class="items-center" @scroll="onScroll" :class="{ 'page-scrolled':pageScrolled }">
    <router-view />
  </q-layout>
</template>

<script>
export default {
  data() {
    return {
      pageScrolled:false
    }
  },
  created() {
    this.$store
      .dispatch("startApp", {
        session: window.localStorage.session,
        autologin: window.localStorage.autologin
      })
      .then(() => {
        console.log(
          "App created with session: ",
          window.localStorage.session,
          " and ",
          window.localStorage.autologin
        )
      })
  },
  methods: {
    onScroll (e) {
      this.pageScrolled = e.position>0;
    }
  }
}
</script>
<style></style>
