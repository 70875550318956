<template>
  <!-- Compare & Contrast wrapper of BB-annotating component -->
  <bb-annotating
    type="comparing"
    intro="Start creating your Compare & Contrast block by adding at least two "
    v-on="$listeners"
    v-bind="$props"
  />
</template>

<script>
import BbAnnotating from './BB-annotating';

export default {
  name: "BBComparing",

  components:{
    BbAnnotating
  },

  props:{
    blockId: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'student'
    },
    view: {
      type: String,
      default: 'view'
    },
    editor: {
      type: Boolean,
      default:false
    },
    showInstructions: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    console.log('mount BB-comparing');
  }
}
</script>