import Vue from "vue"
import Vuex from "vuex"

import user from "./modules/user"
// import admin from './modules/admin'
import activity from "./modules/eactivity"
import feedback from "./modules/feedback"
//import ea from './modules/ea'

import { API } from "../tic"
import { router, $q } from "../main.js"

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

export default new Vuex.Store({
  state: {
    showLogin: false,
    showRegister: false,
    showWelcome: false,
    showBBHelp: false,
    showDebug: debug,
    isLoading: false,
    isAPIOnline: false,
    version: "Historiana Client v.1.44"
  },

  getters: {
    // login dialog
    showLogin: state => {
      return state.showLogin
    },

    // welcome screen
    showWelcome: state => {
      return state.showWelcome
    },

    showRegister: state => {
      console.log("getter Showregister")
      return state.showRegister
    },

    isLoading: state => {
      return state.isLoading
    },
    showBBHelp: state => {
      return state.showBBHelp
    },
    showDebug: state => {
      return state.showDebug
    }
  },

  mutations: {
    toggleShowDebug(state) {
      state.showDebug = !state.showDebug
    },

    clearFeedback(state) {
      state.feedback = false
    },

    showRegister(state, value) {
      state.showRegister = value
      return state.showRegister
    },

    showLogin(state, value) {
      state.showLogin = value
    },

    showWelcome(state, value) {
      state.showWelcome = value
    },

    setFeedback(state, msg) {
      state.feedback = msg
    },

    showBBHelp(state, value) {
      state.showBBHelp = value
    },

    setIsLoading(state, value) {
      state.isLoading = value
    },

    ApiOnline(state, value) {
      console.log("API online: ", value)
      state.isAPIOnline = value
    }
  },

  actions: {
    Welcome(ctx) {
      ctx.commit("showWelcome", true)
      setTimeout(() => {
        ctx.commit("showWelcome", false)
      }, 4000)
    },

    xsendFeedback(ctx, msg) {
      ctx.commit("setFeedback", msg)
      setTimeout(function() {
        ctx.commit("clearFeedback")
      }, 1500)
    },

    sendFeedback(ctx, msg) {
      $q.notify({
        message: msg.msg,
        position: "center",
        //actions: [{label: 'OK', dense:true, textColor:'white'}],
        color: "green",
        textColor: "white",
        timeout: 1800,
        classes: ["hi-notify"]
      })
    },

    startApp(ctx, data) {
      let { session, autologin } = { ...data }

      console.log("** START-APP **")
      console.log("** session: ", session)
      console.log("** autologin: ", autologin)

      if (session && autologin === "true") {
        console.log("--> use existing session", session)
        API.post("/startsession", {
          session: session
        })
          .then(
            response => {
              console.log("AUTOLOGIN STARTED WITH: ", response.data)
              ctx.commit("user/setUser", response.data)
              ctx.commit("ApiOnline", true)
              // resolve(response);
              // Let the calling function know that http is done.
              // You may send some data back
            },
            error => {
              console.log("API startsession error ", error)
              ctx.commit("ApiOnline", false)
              router.push({ name: "error" })

              reject(new Error("init App startsesssion api error"))
            }
          )
          .catch(e => {
            console.log("autologin start-catch")
          })
      } else {
        // create new anonymous session
        API.post("/create-anonymous-session", {}).then(
          response => {
            console.log("--> create new anonymous session")
            console.log("data: ", response.data.session_uuid)
            window.localStorage.setItem("session", response.data.session_uuid)
            ctx.commit("ApiOnline", true)

            //ctx.commit('user/setUser', response.data)
            //resolve() //response);
            // Let the calling function know that http is done. You may send some data back
          },
          error => {
            console.log("API startsession error ", error)
            ctx.commit("ApiOnline", false)
            //reject(new Error("init App startsesssion api error"))
          }
        )
      }
    },

    initApp(ctx, iets) {
      return new Promise((resolve, reject) => {
        console.log("** INIT APP **")
        // we can't use the this.$localStorage instance here.
        // use native but we loose the nice string > bool translation
        let session = window.localStorage.session
        let autologin = window.localStorage.autologin
        //setTimeout( () => { console.log('**WAIT') }, 2000 )
        console.log("## initApp session: ", session, " autologin:", autologin)

        // axios.get("/status").then((resp) => {
        //   console.log("status resp: ", resp)
        // }).catch((e) => {
        //   console.log("status catch: ", e)
        // })

        // console.log("continue initApp")

        if (session && autologin === "true") {
          console.log("--> use existing session", session)
          API.post("/startsession", {
            session: session
          })
            .then(
              response => {
                console.log("AUTOLOGIN STARTED WITH: ", response.data)
                ctx.commit("user/setUser", response.data)
                ctx.commit("ApiOnline", true)
                resolve(response)
                // Let the calling function know that http is done.
                // You may send some data back
              },
              error => {
                console.log("API startsession error ", error)
                ctx.commit("ApiOnline", false)
                reject(new Error("init App startsesssion api error"))
              }
            )
            .catch(e => {
              console.log("autologin start-catch")
            })
        } else {
          // create new anonymous session
          API.post("/create-anonymous-session", {}).then(
            response => {
              console.log("--> create new anonymous session")
              console.log("data: ", response.data.session_uuid)
              window.localStorage.setItem("session", response.data.session_uuid)
              ctx.commit("ApiOnline", true)

              //ctx.commit('user/setUser', response.data)
              resolve() //response);
              // Let the calling function know that http is done. You may send some data back
            },
            error => {
              console.log("API startsession error ", error)
              ctx.commit("ApiOnline", false)
              reject(new Error("init App startsesssion api error"))
            }
          )
        }
      })
    }
  },

  modules: {
    user,
    //admin,
    activity,
    feedback
  },

  strict: debug
  //plugins: [createPersistedState()]
})
