<template>
  <q-dialog
    maximized
    :value="show"
    @hide="$emit('hide')"
    ref="sourceEditor"
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card>
      <q-bar class="bg-hardroze text-white">
        Edit Source
        <q-space />
        <q-btn
          flat
          round
          :disable="current === 0"
          color="white"
          @click="$emit('prev')"
          icon="fa fa-chevron-left"
        />

        {{ current + 1 }}/{{ total }}

        <q-btn
          flat
          round
          :disable="current === total"
          color="white"
          icon="fa fa-chevron-right"
          @click="$emit('next')"
        />
        <q-btn
          flat
          round
          class="float-right text-white"
          icon="far fa-times"
          v-close-popup
        />
      </q-bar>
      <!-- <q-card-section class="q-pa-none full-height"> -->
      <div class="row justify-between full-height">
        <div class="col-6 bg-grey" style="text-align: center">
          <v-zoomer
            controls
            ref="zoomer"
            style="object-fit; contain; width: 50vw; height: 100vh;"
            class="fit"
            :zoomed.sync="zoomed"
          >
            <img :src="image" />
          </v-zoomer>
        </div>
        <div class="col-6 q-pa-xl scroll">
          <q-scroll-area style="max-height: 90vh">
            <q-input
              filled
              fill-input
              name="title"
              label="title"
              v-model="record.title"
            />
            <p class="q-pt-md q-mb-sm">Introduction</p>
            <q-editor
              type="text"
              name="introduction"
              label="introduction"
              v-model="record.introduction"
              :toolbar="toolbar"
              placeholder="introduction"
              paragraph-tag="p"
              flat
              content-class="bg-grey-2"
              toolbar-text-color="white"
              toolbar-toggle-color="grey-14"
              toolbar-bg="grey-13"
            />

            <p class="q-pt-md q-mb-sm">Description</p>
            <q-editor
              type="text"
              name="description"
              label="description"
              v-model="record.description"
              :toolbar="toolbar"
              paragraph-tag="p"
              flat
              content-class="bg-grey-2"
              toolbar-text-color="white"
              toolbar-toggle-color="grey-14"
              toolbar-bg="grey-13"
            />

            <hi-license
              class="q-pt-md"
              v-model="record.license"
              :value="record.license"
              :items="licenses"
            />

            <hi-language v-model="record.language" :value="record.license" />

            <!-- tags -->
            <q-select
              class="q-pt-md"
              filled
              v-model="record.tags"
              multiple
              :options="tagsAvailable"
              use-chips
              stack-label
              label="Historiana Tags"
            />
            <br />
            <q-select
              filled
              v-model="record.myTags"
              multiple
              :options="myTagsAvailable"
              use-chips
              stack-label
              label="MyTags"
            />

            <q-card-actions>
              <q-btn color="grey" no-caps v-close-popup label="Cancel" />
              <q-btn
                color="primary"
                @click="save(record)"
                no-caps
                label="Save"
              />
            </q-card-actions>
          </q-scroll-area>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import HiLicense from "@/components/hiLicense";
import HiLanguage from "@/components/hiLanguage";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "SourceEditor",
  components: { HiLicense, HiLanguage },
  props: {
    show: {
      type: Boolean,
      default: true,
    },

    record: {
      type: Object,
      default() {
        return { title: "", uuid: null };
      },
    },

    licenses: {
      type: Array,
      default: () => [],
    },

    current: {
      type: Number,
      default: 0,
    },

    total: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters("user", [
      "displayname",
      "isAuthenticated",
      "uuid",
      "email",
      "session",
    ]),

    permalink() {
      return `${window.location.origin}${this.record.url}`;
    },

    image() {
      if (this.record && this.record.ua) {
        return `/ua/${this.record.ua.path}`;
      }
    },
  },

  mounted() {
    const vm = this;
    // get system and personal tags
    vm.$API.get("/tags").then((r) => {
      vm.tagsAvailable = [...r.data.data];
    });

    vm.$API.post("/mytags", { uuid: this.uuid }).then((r) => {
      vm.myTagsAvailable = [...r.data.data];
    });

    document.addEventListener("keydown", (e) => {
      //console.log("key: ", e.code);
      if (e.code === "ArrowLeft") {
        // left cursor
        // e.preventDefault()
        this.$emit("prev");
      }

      if (e.code === "ArrowRight") {
        // right cursor
        // e.preventDefault()
        this.$emit("next");
      }
    });
  },

  data() {
    return {
      zoomed: true,
      tagsAvailable: [],
      myTagsAvailable: [],

      tab: "overview",
      toolbar: [
        ["bold", "italic", "strike", "underline", "subscript", "superscript"],
        ["undo", "redo"],
        ["viewsource"],
      ],
      meta: [
        { label: "title", value: "title" },
        { label: "uuid", value: "uuid" },
        { label: "filename", value: "filename" },
        { label: "license", value: "license" },
      ],
    };
  },

  methods: {
    save(item) {
      const vm = this;
      vm.$API
        .post("/saveSource", { member: this.uuid, record: item })
        .then((r) => {
          this.$q.notify({
            type: "positive",
            position: "center",
            message: "Saved changes",
          });
        });

      console.log("save item: ", item);
    },
  },
};
</script>
<style >
th {
  text-align: left;
  background-color: lightgrey !important;
  color: black;
}

td {
  vertical-align: top;
}

tr:nth-child(odd) {
  background-color: #eee;
}
</style>