<template>
  <q-dialog :value="show" @hide="$emit('hide')" full-width full-height>
    <q-card>
      <q-bar>
        <span class="text-h6"><b>Zoom image</b></span>
        <q-space />
        <q-btn v-close-popup flat dense round icon="far fa-times" />
      </q-bar>

      <q-scroll-area style="max-height: 86vh">
        <q-card-section>
          <q-img fit="cover" :src="url" spinner-size="82px" />
        </q-card-section>
      </q-scroll-area>

      <q-card-actions align="center">
        <q-btn label="ok" @click="$emit('hide')" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { API } from "../tic";

export default {
  name: "zoomImage",
  props: ["show", "url"],
};
</script>