<template>

  <q-scroll-area
    ref="scrollarea"
    v-scroll.immediate="updateScrollBounds"
  >
    <div class="scroll-padding">
    
      <ScrollingPage
        v-for="page in pages"
        :key="page.pageNumber"
        v-bind="{page, clientHeight, scrollTop, focusedPage, enablePageJump}"
        v-slot="{isPageFocused, isElementFocused}"
        @page-jump="onPageJump"
        >
        <div class="scrolling-page">
          <slot v-bind="{page, isPageFocused, isElementFocused}"/>
        </div>
      </ScrollingPage>

      <div v-visible="fetchPages" class="observer"><!-- add loader here --></div>

    </div>
    
  </q-scroll-area>

</template>

<script>
import scroll from './directives/scroll';
import visible from './directives/visible';
import { event } from 'quasar'

import ScrollingPage from './ScrollingPage';


export default {
  components: {
    ScrollingPage,
  },

  directives: {
    visible,
    scroll,
  },

  props: {
    pages: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
    enablePageJump: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    isParentVisible: {
      type:Boolean,
      default: true,
    },
  },

  data() {
    return {
      focusedPage: undefined,
      scrollTop: 0,
      clientHeight: 0,
    };
  },

  computed: {
    scrollingElm() {
      return this.$refs.scrollarea.$el.firstChild;
    },
    pagesLength() {
      return this.pages.length;
    },
  },

  watch: {
    isParentVisible: 'updateScrollBounds',

    pagesLength(count, oldCount) {
      if (oldCount === 0) this.$emit('pages-reset');

      // Set focusedPage after new pages are mounted
      this.$nextTick(() => {
        this.focusedPage = this.currentPage;
      });
    },

    currentPage(currentPage) {
      if (currentPage > this.pages.length) {
        this.fetchPages(currentPage);
      } else {
        this.focusedPage = currentPage;
      }
    },
  },
  
  mounted () {
    //we need to implement horizontal scroll, as q-scroll-area only supports a single direction and prevents the default event
    this.scrollingElm.addEventListener('wheel',this.horizontalScroll);
    
    //->to do: add scroller thumb element for users without trackpad/mousewheel
  },

  methods: {
    fetchPages(currentPage) {
      this.$emit('pages-fetch', currentPage);
    },

    onPageJump(scrollTop) {
      this.$refs.scrollarea.setScrollPosition(scrollTop,120);
      //this.$emit('page-jump', scrollTop);
    },
    
    updateScrollBounds() {
//    const {scrollTop, clientHeight} = this.$el;
//    this.scrollTop = scrollTop;
      this.scrollTop = this.$refs.scrollarea.getScrollPosition();
      this.clientHeight = this.$el.clientHeight;
    },
    
    horizontalScroll(e) {
      //manually apply horizontal wheel scroll to DOM
      const d = event.getMouseWheelDistance(e)
      this.scrollingElm.scrollLeft += d.x;
    },
  },

}
</script>

<style scoped>
.scroll-padding
{
  width:100%;
  padding:20px 0 20px 0;
}
</style>