<template>

  <ScrollingDocument
    class="pdf-document"
    v-bind="{pages, pageCount, currentPage}"
    v-slot="{page, isPageFocused, isElementFocused}"
    :enable-page-jump="true"
    @page-jump="onPageJump"
    @pages-fetch="onPagesFetch"
    @pages-reset="fitWidth"
    >
    <PDFPage
      v-bind="{scale, optimalScale, page, isPageFocused, isElementFocused, pixelRatio}"
      @page-rendered="onPageRendered"
      @page-errored="onPageErrored"
      @page-focus="onPageFocused"
    />
  </ScrollingDocument>
</template>

<script>
// PDFDocument renders an entire PDF inline using
// PDF.js and <canvas>. Currently does not support,
// rendering of selected pages (but could be easily
// updated to do so).
//import {PIXEL_RATIO, VIEWPORT_RATIO} from './utils/constants';

import ScrollingDocument from './ScrollingDocument';
import PDFPage from './PDFPage';

const VIEWPORT_RATIO = 0.8; //->check how this works!

export default {
  name: 'PDFDocument',

  components: {
    ScrollingDocument,
    PDFPage,
  },

  props: {
    pages: {
      type: Array,
      required: true
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    scale: {
      type: Number,
      default: 1.0,
    },
    optimalScale: {
      type: Number,
      default:2
    },
    fit: {
      type: String,
      default:'auto'
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pixelRatio: {
      type: Number,
      default: 1
    },
  },

  computed: {
    defaultViewport() {
      if (!this.pages.length) return {width: 0, height:0};
      const [page] = this.pages;

      //get max width and height from current pages
      var w = 0, h = 0, s = { scale:1.0 };
      this.pages.forEach((page,i) => {
        let v = page.getViewport(s);
        if (v.width>w) w = v.width;
        if (v.height>h) h = v.height;
      })

      return {
        width:w + 30,
        height:h + 30
      }
      //return page.getViewport(s);
    },

    isPortrait() {
      const {width, height} = this.defaultViewport;
      return width <= height;
    },
  },

  methods: {
    pageWidthScale() {
      const {defaultViewport, $el} = this;
      if (!defaultViewport.width) return 0;

      return ($el.clientWidth * this.pixelRatio) * VIEWPORT_RATIO / defaultViewport.width;
    },

    pageHeightScale() {
      const {defaultViewport, $el} = this;
      if (!defaultViewport.height) return 0;

      return ($el.clientHeight * this.pixelRatio) * VIEWPORT_RATIO / defaultViewport.height;
    },
    // Determine an ideal scale using viewport of document's first page, the pixel ratio from the browser
    // and a subjective scale factor based on the screen size.
    fitWidth() {
      const scale = this.pageWidthScale();
      this.updateScale(scale, {isOptimal: !this.optimalScale});
    },

    fitHeight() {
      const scale = this.isPortrait ? this.pageHeightScale() : this.pageWidthScale();
      this.updateScale(scale);
    },

    fitAuto() {
      const scale = Math.min(this.pageWidthScale(), this.pageHeightScale());
      this.updateScale(scale);
    },

    updateScale(scale, {isOptimal = false} = {}) {
      if (!scale) return;
      this.$emit('scale-change', {scale, isOptimal});
    },

    onPageJump(scrollTop) {
      //this.$el.scrollTop = scrollTop; // triggers 'scroll' event //-> may be removed if q-scroll-area works well (needs testing)
    },

    onPagesFetch(currentPage) {
      this.$parent.$emit('pages-fetch', currentPage);
    },

    onPageFocused(pageNumber,page) {
      this.$parent.$emit('page-focus', pageNumber, page);
    },

    onPageRendered(payload) {
      this.$parent.$emit('page-rendered', payload);
    },

    onPageErrored(payload) {
      this.$parent.$emit('page-errored', payload);
    },
  },

  watch: {
    fit(fit) {
      switch (fit) {
        case 'width':
          this.fitWidth();
          break;

        case 'auto':
          this.fitAuto();
          break;

        default:
          break;
      }
    },
    pageCount: 'fitWidth',
    isPreviewEnabled: 'fitWidth',
  },
};
</script>

<style>
.pdf-document {
  position: absolute !important;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
}

.scrolling-page {
  margin-bottom: 1em;
}

@media print {
  .pdf-document {
    position: static;
  }
}
</style>
