<template>
  <!--
    select ContentType
    only for site-admins to determine location of an item
  -->
  <section>
    <q-select
      style="min-width: 12vw"
      v-model="contentType"
      fill-input
      :options="items"
      label="Content type"
      option-label="label"
      option-value="value"
      hint=""
      @input="$emit('input', contentType)"
    >
      <template v-slot:selected v-if="contentType">
        {{ contentType.label }}
      </template>
      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
          <q-item-section>
            <q-item-label>{{ scope.opt.label }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </section>
</template>

<script>
export default {
  name: "HiContentType",
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      items: [
        { label: "Case Study", value: "casestudy" },
        { label: "Key Moment", value: "keymoment" },
        { label: "Learning Activity", value: "la" },
        { label: "Unit", value: "unit" },
        { label: "Viewpoint", value: "viewpoint" },
      ],
      contentType: null,
    };
  },

  watch: {
    value: {
      handler() {
        this.contentType = this.value;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
