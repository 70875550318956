<template>
  <q-dialog
    full-width
    full-height
    :value="show"
    @hide="$emit('hide')"
    ref="metaTable"
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card>
      <q-bar>
        <q-icon flat round name="fa fa-info" /><b>Meta</b>
        <span class="text-h6"></span>
        <q-space />
        <q-btn v-close-popup flat dense round icon="fas fa-times"
      /></q-bar>

      <q-card-section>
        <q-markup-table
          separator="cell"
          bordered
          dense
          style="max-width: 100vw"
          wrap-cells
        >
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>

          <tr :key="i" v-for="(row, i) in meta">
            <td>label: {{ row.label }}</td>
            <td>value: {{ row.value }}</td>
          </tr>

          <!-- <tr v-if="record.ua">
            <td>mimetype</td>
            <td>{{ record.ua.type }}</td>
          </tr> -->

          <tr>
            <td>permalink</td>
            <td>permalink</td>
          </tr>
        </q-markup-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script>
export default {
  name: "metaTable",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      meta: [
        { label: "label", value: "value" },
        { label: "label", value: "value" },
        { label: "label", value: "value" },
        { label: "label", value: "value" },
      ],
      record: { ua: "ua" },
    };
  },

  computed: {
    permalink() {
      return `${window.location.origin}${this.record.url}`;
    },
  },
};
</script>
