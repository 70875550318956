<template>
  <div class="split-view">

    <!-- LEFT: source -->

    <div class="source">

      <!-- pdf -->
      <PDFViewer
        v-if="type=='pdf' && render"
        :url="source.url"
        :jump-to-page="currentPage"
        @document-errored="onDocumentErrored"
        @page-focus="updateCurrentPage"
        @page-count="updatePageCount"
        />

      <!-- image -->
      <div
        v-if="type=='image' && render"
        style="position:absolute; left:0; top:0; right:0; bottom:0;"
      >
        <v-zoomer
          controls
          ref="zoomer"
          style="position:relative; width:100%; height:100%"
          :zoomed.sync="zoomed"
          @hook:mounted="zoomerMounted"
          >
          <img
            :src="source.url"
            style="object-fit: contain; width: 100%; height: 100%;"
          >
        </v-zoomer>
      </div>

      <!-- remove source -->
      <q-btn
        v-if="mode=='teacher'"
        round
        size="sm"
        icon="fas fa-trash"
        class="remove-source"
        @click="$emit('remove')"
      />

      <!-- source info -->
      <q-btn
        round
        size="sm"
        icon="fas fa-info"
        class="source-info"
        @click="showInfo=!showInfo"
      />

      <!-- source info panel -->
      <source-info
        :mode="mode"
        :show.sync="showInfo"
        :source.sync="localSource"
        @store="storeRecord(false)"
      />

    </div>

    <!-- RIGHT: text -->

    <div class="text bblock">

      <div class="header">
        <div class="header-bg" :style="{ 'background-color': headerColor }"/>
        <div class="header-content">

          <!-- pagination -->
          <q-pagination
            v-if="pageCount>1"
            color="dark"
            v-model="currentPage"
            :max="pageCount"
            :max-pages="4"
            :input="true"
            :direction-links="true"
          />

          <div class="spacer" style="width:100%"/>

          <!-- mode toggle -->
          <q-toggle
            v-if="mode=='teacher'"
            dense
            v-model="editMode"
            false-value="annotate"
            true-value="edit"
            icon="fa fa-pencil"
          />

        </div>
      </div>

     <!--  answer={{ answer }} -->

      <div class="body" v-if="render">

        <!-- edit text -->
        <div class="edit-text" v-if="editMode=='edit'">

          <q-input
            v-model="localSource.title"
            style="margin-bottom:20px;"
            label="Title of source"
            filled
            @change="storeRecord"
          />

          <highlight-edit
            :text="text"
            @change="updateText"
          />

        </div>

        <!-- annotate text -->
        <q-scroll-area
          v-if="editMode=='annotate'"
          class="annotate-container"
        >
          <highlight-annotate
            :text="text"
            :mode="mode"
            @change="updateText"
          >
            <div class="annotate-text" v-html="text"/>
          </highlight-annotate>
        </q-scroll-area>

      </div>

    </div>

  </div>
</template>

<script>

import PDFViewer from './PDFViewer';
import HighlightEdit from './Highlight-edit'
import HighlightAnnotate from './Highlight-annotate'
import SourceInfo from '../../../components/SourceInfo';

export default {
  name: 'HighlightSource',

  components: {
    PDFViewer,
    HighlightEdit,
    HighlightAnnotate,
    SourceInfo
  },

  props: {
    render: Boolean,
    mode:{
      type:String,
      default:'student'
    },
    color:{
      type:String,
      default:'white'
    },
    source: {
      type: Object,
      default: function () {
        return {
          url: '',
          text: []
        }
      }
    },
    answer: {
      type: Array,
      default: function() {
        return []
      }
    }
  },

  watch: {
    source: {
      immediate:true,
      handler () {
        this.setLocalSource();
      }
    },
    mode () {
      //teacher/student view toggle in BlockEditor
      if (this.mode=='student') this.editMode = 'annotate';
      //refresh text
      this.updateCurrentPage(this.currentPage)
      //if (!this.fetchText) this.getText(this.currentPage);
    },
    editMode (m) {
      //store and save record when toggling back to annotate mode
      if (m=='annotate') this.storeRecord(true);
    }
  },

  data () {
    return {
      localSource:{
        title:'Untitled Source',
        pages:[]
      },
      editMode:this.mode=='teacher'? 'edit':'annotate',
      text:'',
      currentPage:1,
      pageCount:0,
      fetchText:true,
      fetching:false,
      zoomed: false,
      headerColor:'#0066CC',
      showInfo:false,
    };
  },

  computed: {
    type () {
      //quick hack to determine source type, would be better if provided by MediaLib
      return this.source.url.indexOf('.pdf')>-1? 'pdf':'image';
    },
  },

  mounted () {
  },

  methods: {

    setLocalSource () {
      //clone source to local object
      this.localSource = {...this.source};

      //text is stored as array, with an entry for each page in the pdf
      if (!this.localSource.text) this.localSource.text = [];

      //set text if already in record
      const text = this.localSource.text[this.currentPage];
      this.text = text || '';

      //force refresh in review mode (as PDF might be rendered and won't trigger updateCurrentPage)
      if ((this.mode=='review' || this.mode=='student') && text) this.getText(this.currentPage)
    },

    zoomerMounted () {
    },

    updateCurrentPage (p,page) {
      //set current page and fetch text if needed
      this.currentPage = p;

      this.fetchText = this.localSource.text[p] && this.localSource.text[p]!='<br>'? false:true;

      console.log('□■ Highlight-source, set page to '+p+', fetchText=',this.fetchText);

      if (page && this.fetchText)
      {
        console.log('■■ Highlight-source, fetching PDF text contents...');

        this.fetching = true;

        //clear editor while fetching
        this.text = '<p class="placeholder">Fetching text contents&hellip;</p>';

        //fetch text contents from pdf page object
        var elm = document.createElement('page');
        page.getTextContent().then(text => {

          elm.innerHTML = '';

          const p = document.createElement('p');
          elm.appendChild(p);

          text.items.forEach(text => {

            //auto-detect paragraphs based on y offset ->for reference, might use this later in some way
//             let ty = text.transform[5];
//             if (y != ty)
//             {
//               p = document.createElement('p');
//               elm.appendChild(p);
//               y = ty;
//             }

            p.textContent += text.str;
          })

          this.text = elm.innerHTML;
          this.fetching = false;
        });
      }
      else
      {
        //update editor with record text
        this.getText(p);//this.localSource.text[p];
      }
    },

    updatePageCount (p) {
      this.pageCount = p;
    },

    getText (p) {
      //return teacher or student text for current page
      if (this.mode=='teacher')
      {
        this.text = this.localSource.text[p];
      }
      else
      {
        let text = this.answer[p];
        if (!text)
        {
          //return record text, add teacher class to marks first
          text = this.localSource.text[p];

          let elm = document.createElement('div');
          elm.innerHTML = text;

          const marks = elm.getElementsByTagName('mark');
          for (let mark of marks) mark.classList.add('teacher');

          text = elm.innerHTML;
        }

        this.text = text;
      }
    },

    updateText (text) {
      //set (highlighted) text and store it
      this.text = text;
      this.storeRecord();
    },

    storeRecord (save) {
      if (this.fetching) return

      if (this.mode=='student')
      {
        this.$emit('storeAnswer',this.currentPage,this.text)
      }
      else
      {
        //update text in local source first
        this.localSource.text[this.currentPage] = this.text;

        //call parent to store it
        this.$emit('store',this.localSource,save);
      }
    },

    onDocumentErrored (e) {
      //this.documentError = e.text;
      console.log(e.text)
    },

  },
}
</script>

<style scoped lang="stylus">
@import '~quasar-variables'

.split-view
{
  position:relative;
  width:100%;
  height:100%;

  display:flex;
}
.source
{
  position:relative;
  width:50%;
  background-color:$light-gray;
}
.text
{
  position:relative;
  width:50%;
  height:100%;

  display:flex;
  flex-direction:column;

  background-color:$white;
  box-shadow:-2px 0 5px rgba(0,0,0,.1);

}
.pdf-viewer >>> .pdf-document {
  top: 42px;
}
.remove-source
{
  position:absolute;
  right:50%;
  transform:translateX(50%);
  bottom:15px;
  background-color:rgba(0,102,204,.5);
}

.header
{
  position:sticky;
  top:0;
  left:0;
  width:100%;
  height:42px;
  z-index:10;
}
.header > div
{
  position:absolute;
  width:100%;
  height:100%;
  z-index:1;
}
.header-bg
{
  opacity:.2;
  z-index:0;
}
.header-content
{
  display:flex;
  padding:0 20px 0 32px;
  justify-content:space-between;
  align-items:center;

  color:$dark-gray;
}

.toggle
{
  /* color:white; */
  margin-right:16px;
}


.body
{
  position:absolute;
  top:42px;
  width:100%;
  height:calc(100% - 42px);
  overflow:hidden; /* need this to fit body to parent height */
}

.source-title
{
  margin:8px 0 16px 0;
  min-height:2rem;
}
.navigation
{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:8px;
  min-height:40px;
}

.annotate-text,
.edit-text
{
  position:relative;
  padding:20px 20px 10px 40px;
}
.edit-text
{
  height:100%;
}

input.title
{
  margin-bottom:20px;
}

.annotate-container
{
  position:relative;
  height:100%;
  user-select: text;
}

.annotate-text >>> mark.comment::before
{
  content:'\f075';
  position:absolute;
  left:15px;

  font-family: 'Font Awesome 5 Pro';
  font-size:1rem;
  font-weight:normal;

  color:$medium-gray;
}
.annotate-text >>> mark.comment.teacher::before
{
  opacity:.5;
}

.annotate-text >>> p > mark.comment.multi::before
{
  content:'\f086';
  z-index:1;
  background-color:$white;
}

.annotate-text >>> dfn,
.edit-text >>> dfn
{
  font-style:normal;
  color:var(--q-color-primary);
  /* border-bottom:1px solid var(--q-color-primary); */
}


</style>