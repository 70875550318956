<template>
  <section>
    <!-- popup media Library -->
    <UploadSource
      :show="showUpload"
      @close="showUpload = false"
      @reload="getMedia"
    />

    <q-dialog
      :value="show"
      @hide="$emit('close')"
      ref="mediaLib"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card
        style="
          width: 90vw;
          max-width: 90vw;
          height: 90vh;
          background: transparent;
        "
      >
        <!-- header -->
        <q-bar class="row justify-between bg-primary text-white">
          <h5><i class="far fa-image on-left" />{{ title }}</h5>
          <q-btn dense round flat icon="fa fa-times" v-close-popup />
        </q-bar>

        <!-- scrollable body -->
        <q-scroll-area class="bg-medium-gray">
          <hi-search
            placeholder="Search MySources"
            v-model="searchFor"
            :is-loading="searching"
          />

          <!-- sort options, needs redesign! -->
          <section>
            <div class="row justify-start items-center q-mx-auto q-gutter-md">
              <q-select
                v-model="sortKey"
                :options="sortOptions"
                label="Sort on"
                filled
              />

              <q-btn
                no-caps
                flat
                color="white"
                label="Upload sources"
                icon="fa fa-upload"
                @click="showUpload = !showUpload"
              />
            </div>
          </section>

          <!-- sources -->
          <section
            id="card-holder"
            class="fit"
            :style="{
              paddingBottom: selectedSources.length ? '100px' : '32px',
            }"
          >
            <transition-group
              appear
              name="fade"
              tag="div"
              class="row q-mx-auto justify-start items-start q-gutter-md"
            >
              <card
                v-for="(card, i) in sources"
                :key="card.source.uuid"
                :index="i"
                :item="card.source"
                :title="card.source.title"
                :thumb="card.asset.thumb"
                :filetype="card.asset.type"
                :image-url="card.source.url"
                :btn-delete="false"
                :uuid="card.source.uuid"
                :tags="card.tags"
                :has_tags="card.has_tags"
                :btn-share="false"
                :btn-edit-modal="(showZoom = false)"
                :btn-select="selectable(card)"
                :more="false"
                @toggleSelect="toggleSelect"
                :active-selection="activeSelection"
                type="Source"
                csstype="source"
              />
            </transition-group>
          </section>

          <UploadSource
            @close="$store.commit('user/closeUpload')"
            v-show="$store.getters['user/showUpload']"
          />
        </q-scroll-area>

        <!-- selected items action -->
        <transition name="slide-in">
          <section id="selected-items" v-if="selectedSources.length">
            <div class="row bg-white q-pa-md q-gutter-x-md items-center">
              <strong>{{ selectedSources.length }}</strong
              >&nbsp;item{{ selectedSources.length != 1 ? "s" : "" }} selected
              <q-btn
                no-caps
                color="grey-2"
                textColor="black"
                label="Cancel"
                v-close-popup
              />
              <q-btn
                no-caps
                color="primary"
                :label="buttonLabel"
                icon="fa fa-plus"
                :disabled="limitExceeded"
                @click="addToTool"
              />
            </div>
          </section>
        </transition>
      </q-card>
    </q-dialog>
  </section>
</template>

<script>
import Card from "../tags/Card";
import tic from "../tic";
import { clone } from "../tic";
import UploadSource from "../components/UploadSource";
import _ from "lodash";
import { API } from "../tic";
import { mapGetters } from "vuex";

export default {
  name: "MySourcesSelect",

  components: {
    Card,
    UploadSource,
  },

  props: {
    blockId: {
      type: String,
      default: "",
    },

    show: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Object,
      default: () => {},
    },

    filter: {
      type: String,
      default: "*",
    },
  },

  data() {
    return {
      searching: false,
      showUpload: false,
      filteredTagKeywords: this.tagKeywords,
      value2: [],
      sortOptions: [
        {
          label: "Oldest",
          value: "oldest",
        },
        {
          label: "Newest",
          value: "newest",
        },
        {
          label: `A${"\u2192"}Z`,
          value: "az",
        },
        {
          label: `Z${"\u2192"}A`,
          value: "za",
        },
      ],
      otagLanguages: [
        {
          id: "goog",
          desc: "Google",
        },
        {
          id: "fb",
          desc: "Facebook",
        },
        {
          id: "twt",
          desc: "Twitter",
        },
        {
          id: "app",
          desc: "Apple",
        },
        {
          id: "ora",
          desc: "Oracle",
          inactive: true,
        },
      ],
      selectedKeywords: [],
      selectedLanguages: [],
      value3: [],
      typing: false,
      currentFocus: 0,
      showModal: false,
      selectedSources: [],
      item: {},
      sortKey: "newest",
      activeFilters: [],
      searchFor: null,
      // sources: []
    };
  },

  computed: {
    ...mapGetters("user", ["sources", "tagLanguages", "tagKeywords"]),

    title() {
      return this.options.title || "Select Sources";
    },

    maxItems() {
      return this.options.max;
    },

    buttonLabel() {
      return this.options.label || "Add to block";
    },

    limitExceeded() {
      return this.maxItems
        ? this.selectedSources.length > this.maxItems
        : false;
    },

    activeSelection() {
      return this.selectedSources.map((s) => s.uuid);
    },
  },

  watch: {
    selectedSources() {
      if (this.limitExceeded)
        this.$q.notify({
          type: "negative",
          position: "top",
          message:
            "You cannot add more than " +
            this.maxItems +
            " source" +
            (this.maxItems == 1 ? "" : "s"),
        });
    },

    searchFor: _.debounce(function (e) {
      this.searching = true;
      this.$store.dispatch("user/getSources", { searchFor: e }).then(() => {
        this.searching = false;
      });
    }, 500),

    sortKey(key) {
      switch (key.value) {
        case "az":
          return this.sources.sort(
            tic.sortBy("source", false, (a) => {
              return a.title ? a.title.toUpperCase() : "";
            })
          );

        case "za":
          return this.sources.sort(
            tic.sortBy("source", true, (a) => {
              return a.title ? a.title.toUpperCase() : "";
            })
          );

        case "newest":
          return this.sources.sort(
            tic.sortBy("source", true, (a) => {
              return a.created;
            })
          );

        case "oldest":
          return this.sources.sort(
            tic.sortBy("source", false, (a) => {
              return a.created;
            })
          );
      }
    },
  },

  mounted() {
    //get sources
    this.getMedia();
  },

  methods: {
    getMedia() {
      this.$store
        .dispatch("user/getSources")
        .then(() => console.log(this.sources));
    },

    selectable(source) {
      //do not allow selecting sources on skip list or with excluded type
      if (this.options.skip || this.options.excludeTypes) {
        let skip = this.options.skip
          ? this.options.skip.includes(source.source.uuid)
          : false;
        let skipType = this.options.excludeTypes
          ? this.options.excludeTypes.includes(source.asset.type)
          : false;
        return !(skip || skipType);
      } else return true;
    },

    toggleSelect(uuid) {
      let index = this.selectedSources.findIndex((s) => s.uuid == uuid);
      if (index == -1) {
        //add, clone the source object to local selection
        this.selectedSources.push(
          clone(this.sources)
            .filter((s) => s.source.uuid == uuid)
            .map((s) => s.source)[0]
        );
      } else {
        //remove
        this.selectedSources.splice(index, 1);
      }
    },

    deselectAll() {
      this.selectedSources = [];
    },

    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        // tagKeywords.filter(v.name => v.name.toLowerCase().indexOf(needle) > -1)
        this.filteredTagKeywords = this.tagKeywords.filter(
          (v) => v.name.toLowerCase().indexOf(needle) > -1
        );
      });
    },

    addToTool() {
      let sources = this.selectedSources;

      //add custom properties, set by tools
      if (this.options.props) {
        sources = sources.map((s) => Object.assign(s, this.options.props));
      }

      if (this.options.action) this.options.action(sources);
      else {
        this.$store.commit("activity/addSources", {
          block: this.blockId,
          sources: sources,
        });
      }

      //close modal
      this.$refs.mediaLib.hide();

      //auto-save?
      //..
    },
  },
};
</script>

<style lang="stylus" scoped>
#card-holder {
  min-height: 100%;
}

#selected-items {
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 100%;
  text-align: center;
  pointer-events: none;
}

#selected-items > div {
  display: inline-block;
  border-radius: 4px;
  pointer-events: auto;
}

.slide-in-enter-active, .slide-in-leave-active {
  transition: transform 0.2s ease 0s;
}

.slide-in-enter, .slide-in-leave-to {
  transform: translateY(calc(100% + 10px));
}
</style>