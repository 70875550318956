<template>
  <!--ALWAYS VISIBLE: ORDERING -> FILTER AND SORT-->
  <!-- <div> -->

  <!--     <section id="search">
    <div class="row q-mx-auto q-py-lg justify-start items-start">
      <div class="col-12 col-md q-pr-xl">

              <q-input
            v-model="search"
            autogrow
            type="search"
            placeholder=""
            label="Search shares"
            clearable
          >
            <template v-slot:prepend>
              <q-icon
                name="fa fa-search"
              />
            </template>
          </q-input>
        </div>
      </div>
    </section> -->

  <section id="browse">
    <div class="row q-mx-auto justify-between items-center">
      <div class="col-shrink q-pr-md">
        <span class="text-h5">Filter on</span>
      </div>

      <div v-if="!hideYear" class="col-12 col-md q-pr-xl">
        <q-select
          v-model="selectedYears"
          multiple
          :options="$store.getters['user/tagYears']"
          option-value="uuid"
          option-label="name"
          use-chips
          label="Year's"
          wrap
          @input="updateFilter"
        />
      </div>

      <div v-if="!hideClass" class="col-12 col-md q-pr-xl">
        <q-select
          v-model="selectedClasses"
          multiple
          :options="$store.getters['user/tagClasses']"
          option-value="uuid"
          option-label="name"
          use-chips
          label="Class"
          wrap
          @input="updateFilter"
        />
      </div>

      <div v-if="!hideKeyword" class="col-12 col-md q-pr-xl">
        <q-select
          v-model="selectedKeywords"
          multiple
          :options="$store.getters['user/tagKeywords']"
          option-value="uuid"
          option-label="name"
          use-chips
          label="Keyword"
          wrap
          @input="updateFilter"
        />
      </div>

      <div v-if="!hideLanguage" class="col-12 col-md q-pr-xl">
        <q-select
          v-model="selectedLanguages"
          multiple
          :options="$store.getters['user/tagLanguages']"
          option-value="uuid"
          option-label="name"
          use-chips
          label="Language"
          wrap
          @input="updateFilter"
        />
      </div>

      <div class="col-12 col-md q-pr-xl">
        <q-select
          options-cover
          @input="sortChanged"
          v-model="sortKey"
          :options="sortOptions"
          label="Sort on"
          filled
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MyFilter',
  props: {
    showOnlySort: {
      default: false,
      type: Boolean
    },
    hideKeyword: {
      default: false,
      type: Boolean
    },
    hideLanguage: {
      default: false,
      type: Boolean
    },
    hideClass: {
      default: false,
      type: Boolean
    },
    hideYear: {
      default: false,
      type: Boolean
    }
  },

  methods: {
    updateFilter() {
      console.log('update filter');
      this.activeFilters = [
        ...this.selectedYears,
        ...this.selectedClasses,
        ...this.selectedKeywords,
        ...this.selectedLanguages
      ];
      this.$emit('filterChanged', this.activeFilters);
    },
    sortChanged() {
      // sent new sortKey to parent
      this.$emit('sortOn', this.sortKey.value);
    }
  },
  data() {
    return {
      sortKey: { label: 'Newest', value: 'newest' },
      sortOptions: [
        {
          label: 'Oldest',
          value: 'oldest'
        },
        {
          label: 'Newest',
          value: 'newest'
        },
        {
          label: `A${'\u2192'}Z`,
          value: 'az'
        },
        {
          label: `Z${'\u2192'}A`,
          value: 'za'
        }
      ],
      userHasTags: false, // XXX change this to the getter
      activeTags: [],
      activeFilters: [],
      searchFor: null,
      selectedYears: [],
      selectedClasses: [],
      selectedKeywords: [],
      selectedLanguages: []
    };
  }
};
</script>
