<template>
  <div>
    <label>
      Partner:<br>
      <ElSelect
        @change="saveChanges"
        v-model="selected_value"
        placeholder="Select"
      >
        <ElOption
          v-for="p in partners"
          :key="p.uuid"
          :label="p.name"
          :value="p.uuid"
        />
      </ElSelect>
    </label>

    <label>
      Text on this item -> partner relation:
                                   <div id="quill-wrapper">
                                     <div id="quill-editor" />
                                   </div>
                                   <div class="clearfix">
                                     <br>
                                   </div>
    </label>
    <!-- TODO leave this in for now otherwise this component does not have data 
      		when clicked first in MySources due to some unknown issue -->
    <div style="display:none">
      {{ current }}
    </div>
  </div>
</template>
<script>
import {API} from '../tic'
import Quill from 'quill'
import _ from 'lodash'
export default {
  props: {
    current: {
      type: String,
      default: ''
    },
    meta: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    }, 
    item: {
      type: String,
      default: ''
    },         
  },


	methods: {
		// change (e,bla) {
		// 	let vm = this
		// 	// let parent know (to enable Save button)
		// 	console.log("changed ", this.selected_value)
		// 	this.$emit('change')
		// },

		saveChanges () {
			let vm = this
			console.log("### PARTNER SAVE CHANGES: ", vm.target)
			API.post("/store-meta", {
				target: vm.target,
				partner: vm.selected_value || null,
				meta: JSON.stringify(vm.tmpMeta),
				text: vm.tmpMeta.text || '' 
			})
			.then((resp) => {
				console.log("saved meta")
				// forces reload of MySources in Gallery.vue
				vm.$emit('update', vm.target)
				// vm.$store.dispatch('user/getSources')
			})
		}
	},
	updated () {
		// let vm = this
		// console.log('hipartnerselect updated----')
		// // console.log("selected_value: ", this.selected_value)
		// // console.log("selValue: ", this.selValue)
		// // console.log("selValue: ", this.current.uuid)
		// if (vm.selected_value === undefined) {
		// 	vm.selected_value = vm.current.uuid
		// }

  //   	if (vm.current.meta) {
  //   		// set the text
  //   		let x = JSON.parse(vm.current.meta.meta)
  //     		vm.editor.setContents(x.delta)
  //   	}	    		
	},

	mounted () {
		let vm = this
		// set initial value
		vm.selected_value = vm.current.uuid

		API.get('/get-partner-list')
		.then((resp) => {
			this.partners = resp.data.data
		})

	    vm.editor = new Quill('#quill-editor', {
	      theme: 'snow',
	      placeholder: 'Enter your text...',
	      modules: {
	        toolbar: [
	          [{'header': [1, 2, 3, 4, false]}],
	          ['bold', 'italic', 'underline', 'strike'],
	          [{'list': 'ordered'}, {'list': 'bullet'}],
	          [{'color': []}, {'background': []}],
	          ['image','link']
	        ]
	      }
	    });

    	if (vm.current.meta) {
    		// set the text
    		let x = JSON.parse(vm.current.meta.meta)
      		vm.editor.setContents(x.delta)
    	}	 	    

    	// if (vm.current.meta) {
    	// 	// set the text
    	// 	let x = JSON.parse(vm.current.meta.meta)
     //  		vm.editor.setContents(x.delta)
    	// }	    

	    vm.editor.on('text-change', _.debounce(function (delta, oldDelta, source) {
	    	vm.tmpMeta = {
	        	delta: 	vm.editor.getContents(),
	        	html: 	vm.editor.root.innerHTML,
	        	text: 	vm.editor.getText()
	    	}
	    	// vm.$emit('change')
	    	vm.saveChanges()
	    }, 600))

	},


	data () {
    	return {
    		tmpMeta: {},
    		selected_value: '',
    		partners: []
    	}
    }
}
</script>
