<template>
  <div>
    <hi-search placeholder="Find your shares" v-model="searchFor" />

    <my-filter
      @filterChanged="filterChanged"
      @sortOn="sortOn"
      :hide-language="true"
      :hide-keyword="true"
    />

    <!-- TO DO improve delete UI below -->
    <section>
      <div
        v-if="selected.length"
        class="row q-mx-auto q-py-lg justify-between items-start"
      >
        <q-btn @click="deleteShares" color="warning" no-caps
          >Delete {{ selected.length }} selected
          {{ selected.length == 1 ? 'share' : 'shares' }}</q-btn
        >
      </div>
    </section>

    <section class="result">
      <div
        v-if="shares && filteredShares.length > 0"
        class="row q-mx-auto q-py-lg justify-between items-start"
      >
        <q-markup-table style="width:100%" xseparator="cell">
          <thead>
            <tr class="text-left">
              <th style="padding-right:0">
                <q-checkbox v-model="allSelected" size="xs" dark />
              </th>
              <th style="padding-left:8px">e-Activity</th>
              <th>Year</th>
              <th>Class</th>
              <th>Short link</th>
              <th>
                Date shared
                <!--i class="fa fa-chevron-down" aria-hidden="true"></i-->
              </th>
              <th>
                Shared with
                <!--i class="fa fa-chevron-down" aria-hidden="true"></i-->
              </th>
              <th class="text-center">Answers</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="s in filteredShares" tag="label">
              <td style="padding-right:0">
                <q-checkbox :val="s.share.uuid" v-model="selected" size="xs" />
              </td>
              <td style="padding-left:8px">
                <div class="row items-center">
                  <img
                    class="thumb on-left"
                    v-if="s.has_image"
                    :src="s.image.url"
                  />
                  {{ s.title }}
                </div>
              </td>
              <td>
                <ul class="menu tags simple">
                  <li v-for="tag in s.tags" v-if="tag.label === 'MyYears'">
                    {{ tag.name }}
                  </li>
                </ul>
              </td>
              <td>
                <ul class="menu tags simple">
                  <li v-for="tag in s.tags" v-if="tag.label === 'MyClasses'">
                    {{ tag.name }}
                  </li>
                </ul>
              </td>
              <td>
                <div class="row justify-between items-center">
                  {{ s.short }}
                  <q-btn
                    class="on-right"
                    round
                    flat
                    dense
                    size="sm"
                    icon="fa fa-clipboard"
                    @click="copyToClipboard(s.short)"
                  >
                    <q-tooltip>Copy to clipboard</q-tooltip>
                  </q-btn>
                </div>
              </td>
              <td>{{ tic.humanDate(s.share.created) }}</td>
              <td>{{ s.share.mode }}</td>
              <td class="text-right">
                <div v-if="s.answers" class="row items-center justify-end">
                  {{ s.submitted }} / {{ s.answers }}
                  <q-btn
                    class="on-right"
                    round
                    flat
                    dense
                    size="sm"
                    icon="fa fa-info"
                    @click="showStatus($event, s.share.uuid)"
                  >
                    <q-tooltip>View who's answered</q-tooltip>
                  </q-btn>

                  <!-- </router-link> -->
                </div>
                <div v-else>&ndash;</div>
              </td>
              <td class="text-right">
                <a
                  v-if="s.answers"
                  :href="'/ea/review/' + s.share.uuid"
                  target="_blank"
                >
                  <q-btn
                    no-caps
                    no-wrap
                    color="primary"
                    icon="far fa-tasks"
                    label="Review"
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </q-markup-table>

        <!-- answer state menu -->
        <q-menu
          ref="studentMenu"
          dark
          :target="statusElm"
          :offset="[0, 10]"
          anchor="top middle"
          self="bottom middle"
        >
          <q-list>
            <q-item
              v-for="a in students"
              :key="a.uuid"
              v-close-popup
              clickable
              @click="copyToClipboard(a.member.email)"
            >
              <q-item-section class="text-body2">
                <div class="row no-wrap items-center justify-between">
                  {{ a.member.email }}
                  <i
                    class="fa fa-check on-right"
                    :xclass="{ invisible: !a.submitted }"
                    v-if="a.submitted"
                  />
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </div>

      <!-- no result  -->
      <div v-else class="row q-mx-auto q-py-lg items-center text-white">
        <i class="fa fa-exclamation-circle on-left" />
        <span v-if="shares.length===0">You did not share any e-Learning Activities yet.</span>
        <span v-else>No shares found for current filters.</span>
      </div>

    </section>
  </div>
</template>

<script>
import tic from '../tic';
import { API } from '../tic';
import MyFilter from '../components/MyFilter';
import clipboard from '../mixins/clipboard.mixin.js';
import { mapGetters } from 'vuex';
import { date } from 'quasar';

export default {
  name: 'MyShares',

  components: {
    MyFilter
  },

  mixins: [clipboard],

  data() {
    return {
      showDebug: false,
      answers: [],
      currentRecord: {},
      tic: tic,
      date: date,
      shares: [],
      selected: [],
      allSelected: false,
      activeFilters: [],
      languages: [],
      years: [],
      classes: [],
      keywords: [],
      statusElm: false,
      students: [],
      searchFor: null
    };
  },

  computed: {
    ...mapGetters('user', ['loginUuid']),

    filteredShares() {
      let allShares = [...this.shares];

      // user free text search in title
      if (this.searchFor) {
        allShares = allShares.filter(e => {
          let title = e.title.toLowerCase();
          return title.includes(this.searchFor.toLowerCase());
        });
      }

      // user selected one or more filters
      if (this.activeFilters.length) {
        let show = this.activeFilters.map(e => e.uuid);
        allShares = allShares.filter(e => {
          return (
            e.tags.filter(a => {
              return show.includes(a.uuid);
            }).length > 0
          );
        });
      }
      return allShares;
    }
  },

  watch: {
    allSelected(v) {
      this.selected = v ? this.shares.map(s => s.share.uuid) : [];
    }
  },

  mounted() {
    this.getShares();

    // needed for the tags
    let me = this;
    API.post('/getProfile', { uuid: this.loginUuid }).then(response => {
      for (let t of response.data.user.tags) {
        let tagLookup = {
          MyLanguages: me.languages,
          MyYears: me.years,
          MyClasses: me.classes,
          MyKeywords: me.keywords
        };

        try {
          tagLookup[t.tag].push(t);
        } catch (e) {
          console.log('Ignored unsupported TAG cloud: ', t.tag);
        }
      }
    });
  },

  methods: {
    getShares() {
      API.post('/my/shares', {
        member: this.loginUuid
      }).then(response => {
        console.log('◆ API /my/shares', response);

        this.shares = response.data.shares;
      });
    },

    showStatus(e, uuid) {
      //show answer state for selected share

      this.statusElm = e.currentTarget; //to position the menu

      API.post('/my/shares/status', {
        owner: this.loginUuid,
        share: uuid
      }).then(response => {
        console.log('◆ API /my/shares/status', response);

        this.students = response.data.data;
        this.$refs.studentMenu.show();
      });
    },

    deleteShares() {
      //delete selected shares, confirm first

      let n = this.selected.length;
      this.$q
        .dialog({
          title: '<i class="fa fa-trash"></i>&nbsp;Confirm Delete',
          message:
            '<p>Deleting ' +
            n +
            ' share' +
            (n != 1 ? 's' : '') +
            ', submitted student answers will be lost.</p><p>Are you sure?</p>',
          html: true,
          cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
          ok: { label: 'Yes', color: 'primary', noCaps: true }
        })
        .onOk(() => {
          API.post('/my/shares/delete', {
            member: this.loginUuid,
            uuids: this.selected
          }).then(() => {
            this.getShares();
            this.selected = [];
          });
        });
    },

    //->TO DO: fix sorting and filtering below
    sortOn(key) {
      switch (key) {
        case 'oldest':
          this.shares.sort((a, b) => {
            // console.log("a: ", a)
            return parseInt(a.share.created) - parseInt(b.share.created);
          });
          break;

        case 'newest':
          this.shares.sort((b, a) => {
            // console.log("a: ", a)
            return parseInt(a.share.created) - parseInt(b.share.created);
          });
          break;

        case 'az':
          this.shares.sort((a, b) => {
            //console.log(a)
            let nameA = a.title ? a.title.toUpperCase() : '';
            let nameB = b.title ? b.title.toUpperCase() : '';
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
          break;

        case 'za':
          this.shares.sort((b, a) => {
            let nameA = a.title ? a.title.toUpperCase() : '';
            let nameB = b.title ? b.title.toUpperCase() : '';
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
          break;

        default:
          console.log('MyShares sortKey not handled: ', key);
          break;
      }
    },

    filterChanged(filter) {
      // set active filter
      this.activeFilters = filter;
    }
  }
};
</script>

<style scoped lang="stylus">
@import '~quasar-variables';

.result {
  background-color: $medium-gray;
}

thead {
  background-color: $primary;
  color: $white;
}

.thumb {
  width: 50px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
