b<template>
  <div>
    <q-layout view="hHh lpR fFf">
      <q-header elevated class="bg-primary text-white q-pa-md">
        <q-toolbar>
          <hi-logo color="white" size="xl" />
          <q-toolbar-title>
            <span class="text-h2">TestPage</span>
          </q-toolbar-title>
          <span style="float: right">
            build: <b>{{ buildDate }}</b></span
          >
        </q-toolbar>
      </q-header>

      <q-page-container>
        <div class="row entry-header q-pa-md">
          <h4>hiPerson</h4>
          <q-space />
          <span>value: {{ showPerson }}</span>
        </div>
        <div class="row q-pa-md entry">
          <q-btn label="Associate Person" @click="showPerson = !showPerson" />
          <hi-person
            :show="showPerson"
            uuid="42"
            @close="showPerson = false"
            title="Associate Person"
          />
        </div>

        <q-separator />

        <div class="row entry-header q-pa-md">
          <h4>hiContentType</h4>
          <q-space />
          <span>value: {{ valueContentType }}</span>
        </div>

        <div class="row q-pa-md entry">
          <hi-content-type v-model="valueContentType" />
        </div>

        <q-separator />

        <div class="row entry-header q-pa-md">
          <h4>hiPublishDialog</h4>
          <q-space />
          <span>value: {{ valuehiPublishDialog }}</span>
        </div>

        <div class="row q-pa-md entry">
          <q-btn label="trigger" @click="triggerPublishDialog" />
          <q-checkbox v-model="isAdmin" label="admin" />
        </div>

        <q-separator />
        <div class="row entry-header q-pa-md">
          <h4>OCR API</h4>
          <q-space />
          <span>{{ ocrStatus }}</span>
        </div>

        <div class="row q-pa-md entry">
          <q-input
            class="col-11"
            filled
            label="url to image"
            v-model="ocrUrl"
          />
          <q-btn class="q-ml-md" label="OCR" @click="doOCR" />
        </div>

        <div
          class="row q-pa-md entry justify-center"
          v-if="ocrDone && !ocrError"
        >
          <div class="col-4" style="border-right: 1px solid black">
            <h5>Image</h5>
            <q-img cover="fit" :src="ocrUrl" />
          </div>
          <div class="col-8 q-pl-md">
            <h5>OCR text</h5>
            <q-input filled type="textarea" :value="ocrText" />
          </div>
        </div>
        <div v-else-if="ocrError" class="row q-pa-md entry justify-center">
          <div class="col-4" style="border-right: 1px solid black">
            API reports error: <b>{{ ocrError }}</b>
          </div>
          <div class="col-8 q-pl-md" style="border-right: 1px solid black">
            Response received from url:
            <q-input filled type="textarea" :value="ocrContent" />
          </div>
        </div>

        <q-separator />
        <div class="row entry-header q-pa-md">
          <h4>AVCard</h4>
          <q-space />
          <span></span>
        </div>

        <div class="row q-pa-md entry">
          <AVCard
            class="q-pa-md"
            type="Source"
            title="Ein Wort Hindenburgs über die Kriegsanleihe (1916)"
            :btnPublish="true"
            imageUrl="https://image.mux.com/zGg7bZ01o46GkUBYwMUaCbt02df02P4kUZEbQjety02AI6Q/thumbnail.png"
            :avUrl="[
              {
                type: 'video/mp4',
                src: 'https://stream.mux.com/zGg7bZ01o46GkUBYwMUaCbt02df02P4kUZEbQjety02AI6Q/medium.mp4',
              },
            ]"
          >
            [qm/mp4/mux.com] Werbefilm, der zur Zeichnung der 5. Kriegsanleihe
            aufruft. Bild: Handschriftlicher Brief von Hindenburg: "Das deutsche
            Volk wird seine Feinde nicht nur mit dem Schwerte sondern auch mit
            dem Gelde schlagen. Das wird die Kriegsanleihe beweisen. Gr. H. Qu.
            11.9.1916 von Hindenburg General-Feldmarschall". Zwischentitel: Wer
            läßt Hindenburg im Stich? Bild: Porträtfoto Paul von Hindenburgs;
            Zwischentitel: Eine gute Kapitalsanlage. Bild: Sachtrick zeigt
            jährlich mögliche Zinsen von 50 Mark; Münzrolle öffnet sich, Münzen
            legen sich geordnet auf den Tisch; Zwischentitel: Jeder kluge Sparer
            zeichne daher Kriegsanleihe! Quelle: Bundesarchiv
          </AVCard>

          <AVCard
            class="q-pa-md"
            type="Source"
            title="Herdenking slag om Arnhem: Zuid Nederland bevrijd"
            :btnPublish="true"
            imageUrl="https://api.europeana.eu/thumbnail/v2/url.json?uri=http%3A%2F%2Fopenbeelden.nl%2Fimages%2F738071%2FHerdenking_slag_om_Arnhem_Zuid_Nederland_bevrijd_%25282_14%2529.png"
            :avUrl="[
              {
                type: 'video/mp4',
                src: 'http://openbeelden.nl/files/07/38/738059.738047.WEEKNUMMER693-HRE0001C94A.mp4',
              },
            ]"
          >
            [qm/mp4/europeana] Bioscoopjournaals waarin Nederlandse onderwerpen
            van een bepaalde week worden gepresenteerd.
          </AVCard>

          <AVCard
            class="q-pa-md"
            type="Source"
            title="Herdenking slag om Arnhem: Zuid Nederland bevrijd"
            :btnPublish="true"
            imageUrl="https://api.europeana.eu/thumbnail/v2/url.json?uri=http%3A%2F%2Fopenbeelden.nl%2Fimages%2F738071%2FHerdenking_slag_om_Arnhem_Zuid_Nederland_bevrijd_%25282_14%2529.png"
            :avUrl="[
              {
                type: 'video/video',
                src: 'http://openbeelden.nl/files/07/38/738059.738047.WEEKNUMMER693-HRE0001C94A.mp4',
              },
            ]"
          >
            [v/mp4/europeana] mp4 bron op Europeana met &lt;video&gt; tag
          </AVCard>

          <AVCard
            type="Source"
            title="Herdenking slag om Arnhem: Zuid Nederland bevrijd"
            :btnPublish="true"
            imageUrl="https://api.europeana.eu/thumbnail/v2/url.json?uri=http%3A%2F%2Fopenbeelden.nl%2Fimages%2F738071%2FHerdenking_slag_om_Arnhem_Zuid_Nederland_bevrijd_%25282_14%2529.png"
            :avUrl="[
              {
                type: 'video/qvideo',
                src: 'http://openbeelden.nl/files/07/38/738059.738047.WEEKNUMMER693-HRE0001C94A.mp4',
              },
            ]"
          >
            [qv/mp4/europeana] mp4 bron op Europeana met &lt;video&gt; tag
          </AVCard>

          <AVCard
            class="q-pa-md"
            type="Source"
            title="The African Zouaves in the Flanders (1915)"
            :btnPublish="true"
            imageUrl="https://image.mux.com/MewPXpRk7eddqK2dkDH3Ar5zsw1fLzZtPmO025jOf7ms/thumbnail.png"
            :avUrl="[
              {
                type: 'video/mux',
                src: 'https://stream.mux.com/MewPXpRk7eddqK2dkDH3Ar5zsw1fLzZtPmO025jOf7ms.m3u8',
              },
            ]"
          >
            [m3u8/mux] Der von der französischen Filmkammer produzierten Film
            zeigt Zuaven, eine Infanterieeinheit nordafrikanischer
            Kolonialsoldaten, im belgischen Flandern. Neben einer Parade sind
            verschiedene Freizeitaktivitäten der Soldaten zu sehen, die
            angeblich einen Kilometer hinter der Front aufgenommen wurden:
            Fronttheater, ausgelassene Spiele, Boxkämpfe sowie ein Ringen, das
            als symbolischer Kampf zwischen einem weißen Eroberer und einem
            afrikanischen Kolonisierten inszeniert wird.
          </AVCard>

          <AVCard
            class="q-pa-md"
            type="Source"
            title="The African Zouaves in the Flanders (1915)"
            :btnPublish="true"
            imageUrl="https://image.mux.com/MewPXpRk7eddqK2dkDH3Ar5zsw1fLzZtPmO025jOf7ms/thumbnail.png"
            :avUrl="[
              {
                type: 'video/m3qv',
                src: 'https://stream.mux.com/MewPXpRk7eddqK2dkDH3Ar5zsw1fLzZtPmO025jOf7ms.m3u8',
              },
            ]"
          >
            [qv/m3u8/mux] Der von der französischen Filmkammer produzierten Film
            zeigt Zuaven, eine Infanterieeinheit nordafrikanischer
            Kolonialsoldaten, im belgischen Flandern. Neben einer Parade sind
            verschiedene Freizeitaktivitäten der Soldaten zu sehen, die
            angeblich einen Kilometer hinter der Front aufgenommen wurden:
            Fronttheater, ausgelassene Spiele, Boxkämpfe sowie ein Ringen, das
            als symbolischer Kampf zwischen einem weißen Eroberer und einem
            afrikanischen Kolonisierten inszeniert wird.
          </AVCard>

          <AVCard
            class="q-pa-md"
            type="Source"
            title="The African Zouaves in the Flanders (1915)"
            :btnPublish="true"
            imageUrl="https://image.mux.com/MewPXpRk7eddqK2dkDH3Ar5zsw1fLzZtPmO025jOf7ms/thumbnail.png"
            :avUrl="[
              {
                type: 'video/m3qmp',
                src: 'https://stream.mux.com/MewPXpRk7eddqK2dkDH3Ar5zsw1fLzZtPmO025jOf7ms.m3u8',
              },
            ]"
          >
            [qmp/m3u8/mux] Der von der französischen Filmkammer produzierten
            Film zeigt Zuaven, eine Infanterieeinheit nordafrikanischer
            Kolonialsoldaten, im belgischen Flandern. Neben einer Parade sind
            verschiedene Freizeitaktivitäten der Soldaten zu sehen, die
            angeblich einen Kilometer hinter der Front aufgenommen wurden:
            Fronttheater, ausgelassene Spiele, Boxkämpfe sowie ein Ringen, das
            als symbolischer Kampf zwischen einem weißen Eroberer und einem
            afrikanischen Kolonisierten inszeniert wird.
          </AVCard>

          <AVCard
            class="q-pa-md"
            type="Source"
            title="[1375] 'Not a Lock vs. 10/10 Rated Master Lock"
            :btnPublish="true"
            imageUrl="https://i.ytimg.com/vi/bbYxr7-HZ4E/hq720.jpg"
            :avUrl="[
              {
                type: 'video/youtube',
                src: 'https://youtu.be/bbYxr7-HZ4E',
              },
            ]"
          >
            [youtube] A sample video from youtube
          </AVCard>
        </div>
      </q-page-container>
    </q-layout>
  </div>
</template>
<script>
import hiPerson from "@/components/hiPerson";
import hiContentType from "../components/hiContentType";
import hiPublishDialog from "../components/hiPublishDialog";
import { API } from "@/tic";
import AVCard from "@/tags/AVCard.vue";

export default {
  components: { hiPerson, hiContentType, hiPublishDialog, AVCard },
  mounted() {
    console.log("mounted TestPage");
    API.get("/ui-build").then((r) => {
      console.log(r);
      this.buildDate = r.data.data.build;
    });
  },

  methods: {
    doOCR(e) {
      const vm = this;
      this.ocrDone = false;
      this.ocrText = "";
      API.post("/ocr", { url: this.ocrUrl })
        .then((r) => {
          vm.ocrStatus = r.errorCode;
          vm.ocrText = r.data.text;
          vm.ocrDone = true;
          vm.ocrError = false;
        })
        .catch((err) => {
          this.ocrContent = err.response.data.content;
          vm.ocrError = err.response.data.errorCode;
          vm.ocrStatus = err.response.data.errorCode;
          vm.ocrDone = true;
        });
    },

    triggerPublishDialog(e) {
      // see https://v1.quasar.dev/quasar-plugins/dialog
      console.log("trigger hiPublishDialog");

      this.$q
        .dialog({
          component: hiPublishDialog,
          parent: this, // becomes child of this Vue node
          title: "Title of the Item",
          isAdmin: this.isAdmin,
        })
        .onOk((e) => {
          console.log("OK: ", e);
          this.valuehiPublishDialog = e;
        })
        .onCancel(() => {
          console.log("Cancel");
        })
        .onDismiss(() => {
          console.log("Called on OK or Cancel");
        });
    },
  },
  data() {
    return {
      avSources: [
        {
          xsrc: "https://stream.mux.com/MewPXpRk7eddqK2dkDH3Ar5zsw1fLzZtPmO025jOf7ms.m3u8",
          src: "https://stream.mux.com/MewPXpRk7eddqK2dkDH3Ar5zsw1fLzZtPmO025jOf7ms/medium",
          type: "video/mp4",
        },
      ],
      buildDate: "",
      valuehiPublishDialog: null,
      valueContentType: {},
      showPerson: false,
      isAdmin: false,
      ocrText: "",
      ocrDone: false,
      ocrUrl: "",
      ocrError: false,
      ocrContent: "",
      ocrStatus: "",
    };
  },
};
</script>

<style>
.entry-header {
  background-color: #eee;
}

div.entry {
  margin: 1em;
  border: 1px solid black;
  width: 80vw;
}
</style>
