<template><p/></template>
<script>
import {API} from '../tic'
export default {
  mounted () {
    if (!this.$route.params.shortCode) {
      alert('No short code found')
      this.$router.push('/')
      return
    }
    API.get('hist/get/' + this.$route.params.shortCode, {
    }).then(resp => {
      if (resp.data.isError) {
        alert("Redirect Error")
        this.$router.push('/')
      } else {
        window.location.replace(resp.data.url)
      }
    })    
  }
}
</script>
