<template>
  <div>
    <!-- upload source via Dialog -->
    <UploadSource
      :show="showUpload"
      @close="showUpload = false"
      @reload="reload"
    />

    <q-dialog v-model="showImport">
      <q-card style="width: 80vw">
        <q-bar>
          Import from URL
          <q-space />
          <q-btn v-close-popup flat dense round icon="fas fa-times" />
        </q-bar>

        <q-card-section>
          <q-input v-model="importUrl" hint="direct url to image or video" />
        </q-card-section>

        <q-card-section v-if="msgImport.length">
          <span class="text-red text-bold">ERROR: {{ msgImport }}</span>
        </q-card-section>

        <q-card-section align="center" v-if="importBusy">
          <q-spinner color="primary" size="3em" />
        </q-card-section>

        <q-card-section align="center" v-if="importDone">
          <q-icon name="fas fa-check" size="xl" color="green" />
        </q-card-section>

        <q-card-actions align="center">
          <q-btn color="secondary" v-close-popup label="Cancel" />
          <q-btn
            :disable="importUrl.length < 3"
            color="primary"
            label="Import"
            @click="importAsset(importUrl)"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <section id="buttonbar">
      <div class="row q-mx-auto">
        <div class="col q-py-md">
          <a class="subnavlink" href="#" @click="showSearchEuropeana = true">
            Import From Europeana
            <q-avatar square size="28px">
              <img
                src="/europeana-icon.svg"
                class="icon_europeana"
              /> </q-avatar
          ></a>

          <a class="subnavlink" href="#" @click="showUpload = !showUpload">
            Upload sources
          </a>

          <a class="subnavlink" @click="showImport = !showImport" href="#"
            >Import from URL</a
          >
        </div>
      </div>

      <search-europeana
        :show="showSearchEuropeana"
        @hide="
          showSearchEuropeana = false;
          reload();
        "
        @dirty="reload"
      />
    </section>
    <!-- FIND FILES TOGGLE A OR B -->
    <!-- OPTION A: SEARCH AND UPLOAD -->

    <hi-search placeholder="Find your sources" v-model="searchFor" />

    <my-filter
      :show-only-sort="true"
      @filterChanged="filterChanged"
      @sortOn="sortSources"
    />

    <section id="filter" class="q-pt-md bg-grey text-white">
      <div class="row q-mx-auto text-body1">
        <div class="col">
          Show:
          <q-checkbox
            v-model="filterType"
            :val="e.uuid"
            :label="e.name.toUpperCase()"
            :key="i"
            v-for="(e, i) in filterTypes"
          />
        </div>
      </div>
    </section>

    <section id="card-holder">
      <!-- CARD HOLDER WITH OVERVIEW ALL CARDS -->
      <div
        v-if="filteredSources.length <= 0"
        class="row q-mx-auto justify-between items-center nosources"
      >
        <div class="col">
          <h3>
            <i class="fa fa-exclamation-circle" aria-hidden="true" /> No sources
            yet
          </h3>
          <p>
            <a
              @click="$store.commit('user/showUpload')"
              class="button medium call-to-action"
            >
              <i class="fa fa-upload" aria-hidden="true" /> Upload sources
            </a>
          </p>
        </div>
      </div>
      <div
        v-else
        class="row q-mx-auto justify-between items-start"
        id="sources"
      >
        <div class="row q-gutter-md">
          <div :key="i" v-for="(card, i) in filteredSources" class="column">
            <Card
              :is-owner="true"
              :is-europeana="card.asset.via==='EU_IMPORT'"
              :index="i"
              :item="card.source"
              :partner="card.partner ? card.partner : null"
              :copyright="card.copyright ? card.copyright.uuid : null"
              :title="card.source.title"
              :image-url="card.source.url"
              :thumb="card.asset.thumb"
              :filetype="card.asset.type"
              :uuid="card.source.uuid"
              :tags="card.tags"
              :has_tags="card.has_tags"
              :more="false"
              :btn-publish="false"
              :btn-share="false"
              :btn-preview="false"
              :btn-duplicate="true"
              :btn-add="false"
              :btn-delete="true"
              :btn-edit-modal="(showZoom = true)"
              @toggleSelect="changeit"
              @showPreview="showPreview(card, i)"
              @showEdit="showEdit(card, i)"
              @cardClicked="showEdit(card, i)"
              @deleteCard="deleteCard(card, i)"
              @duplicateCard="duplicateCard(card, i)"
              @change="reload()"
              type="Source"
              csstype="source"
              :meta="meta(card)"
              :hover="false"
            >
            </Card>
            <!-- no description for sources -->
          </div>
        </div>
      </div>

      <Gallery
        v-show="showGallery"
        :item="item"
        :total="sources.length"
        :current="currentFocus"
        :is-view="isView"
        @prev="prev"
        @next="next"
        @close="
          showGallery = false;
          isView = true;
          reload();
        "
        @toggleEdit="isView = !isView"
        @saveChanges="saveCurrentCard"
        @reload="reload"
        @contenttypereload="cReload"
      />
    </section>
  </div>
</template>

<script>
import { API } from "../tic";
import Card from "../tags/Card";
import Gallery from "../tags/Gallery";
import tic from "../tic";
import MyFilter from "../components/MyFilter";
import UploadSource from "../components/UploadSource";
import SearchEuropeana from "../components/searchEuropeana.vue";
import { mapGetters } from "vuex";

import _ from "lodash";

// TODO CLEANUP old code related to sort and search via the API
// see https://gitlab.com/webtic/Historiana/issues/286

export default {
  name: "MySources",
  components: { Card, Gallery, MyFilter, UploadSource, SearchEuropeana },
  watch: {
    // searchFor (e) {
    //   this.$store.dispatch('user/getSources',{ searchFor: e })
    // },
    // activeFilters (e) {
    //   this.$store.dispatch('user/getSources',{ filters: this.activeFilters })
    //   console.log("filters: ", this.activeFilters)
    // }
  },
  computed: {
    // sources() {
    //   // pass the sorting to the store
    //   return this.$store.getters["user/asources"](this.sortKey);
    // },

    ...mapGetters("user", { isAdmin: "isAdmin", user: "uuid" }),

    filteredSources(filter) {
      console.log("** filteredSources called");
      if (this.searchFor) {
        return this.sources.filter((e) => {
          // search in all property values; this needs more work
          // as deeper property-names are part of the string now
          let item = JSON.stringify(Object.values(e)).toLowerCase();
          return item.includes(this.searchFor.toLowerCase());
        });
      } else {
        return this.sources;
      }
      this.$forceUpdate();
    },
  },
  methods: {
    cReload() {
      console.log("RELOAD FOR CONTENT TYPE CHANGE");
      this.getSources();
      const vm = this;
      API.post("/my-sources", {
        uuid: vm.user,
      })
        .then((r) => {
          this.sources = [...r.data.sources];
          console.log(this.sources);
          this.$q.loading.hide();
        })
        .catch((e) => {
          window.alert("ERROR " + e);
        });
    },

    importAsset(url) {
      let vm = this;
      this.msgImport = "";

      // import an url
      // eg
      // https://embed.europeana.eu/2051906/data_euscreenXL_http___openbeelden_nl_media_31902

      vm.importBusy = true;
      vm.importDone = false;

      API.post("/import", {
        user: this.$store.getters["user/uuid"],
        url: url,
      })
        .then((r) => {
          console.log("result import : ", r);
          this.$store.dispatch("sendFeedback", {
            msg: "Import done",
            status: "ok",
          });
          vm.importBusy = false;
          vm.importDone = true;
          vm.showImport = false;
          vm.getSources();
        })
        .catch((e) => {
          // display error in UI
          this.msgImport = e.response.data.msg
            ? e.response.data.msg
            : e.response.data.errorCode;
          vm.importBusy = false;
          vm.importDone = false;
        });
    },

    reload() {
      console.log("---------reload after meta edit -----");
      //this.$store.dispatch("user/getSources");
      this.getSources();
    },

    meta(item) {
      let fields = [
        { label: "title", value: item.source.title ? item.source.title : null },
        {
          label: "description",
          value: item.source.description ? item.source.description : null,
        },
        {
          label: "created",
          value: item.source.created
            ? tic.humanDate(item.source.created)
            : null,
        },
        { label: "id", value: item.source.uuid ? item.source.uuid : null },
        {
          label: "filename",
          value: item.asset.filename ? item.asset.filename : null,
        },
        { label: "filetype", value: item.asset.type ? item.asset.type : null },
        {
          label: "copyright",
          value: item.copyright.name ? item.copyright.name : null,
        },
        {
          label: "imported from",
          value: item.source.imported_from ? item.source.imported_from : null,
        },
      ].filter((e) => {
        return e.value;
      });

      return {
        type: "source",
        objectType: "source",
        fields: fields,
      };
    },

    sortSources(key) {
      // this triggers a re-render as the computed `sources` key is changed
      this.sortKey = key;
    },

    updateItem(item) {
      console.log("item changed; update ", item);
    },
    filterChanged(filter) {
      this.$store.dispatch("user/getSources", { filters: filter });
    },

    saveCurrentCard() {
      console.log("SAVE CARD ****");
      // XXX TODO
      // move feedback and update into saveSource; not sure how though ATM
      this.$store.dispatch("user/saveSource", this.item);
      this.$store.dispatch("sendFeedback", {
        msg: "Changes saved",
        status: "ok",
      });
      this.$store.commit("user/updateSource", {
        item: this.item,
        index: this.currentFocus,
      });
    },

    duplicateCard(card, index) {
      console.log("dupcard");
      let me = this;
      // make a real copy; not a 2nd instance
      var a = JSON.parse(JSON.stringify(this.sources[index]));
      a.source.title = a.source.title + " (Copy)";
      API.post("/duplicate-source", {
        user: this.$store.getters["user/uuid"],
        source: this.sources[index].source.uuid,
        title: a.source.title,
      }).then(
        (resp) => {
          //console.log(JSON.stringify(resp))
          if (resp.data.status === "ok") {
            this.sources.splice(index + 1, 0, a);
            me.$store.dispatch("sendFeedback", {
              msg: "Card duplicated",
              status: "ok",
            });
            me.$store.dispatch("user/getSources");
          } else {
            console.log(resp.data.msg);
            //me.$store.dispatch('sendFeedback', { msg: resp.data.msg, status:resp.data.status})
            me.$store.dispatch("sendFeedback", {
              msg: "Error duplicating card",
              status: "nok",
            });
          }
          // console.log("source updated")
          // update UI
          // this.userInput = false
        },
        (err) => {
          console.log("ERROR", err);
        }
      );
    },

    deleteCard(card, index) {
      let me = this;
      console.log("-- delete card");
      API.post("/delete-source", {
        user: this.$store.getters["user/uuid"],
        source: this.sources[index].source.uuid,
      }).then(
        (resp) => {
          //console.log(JSON.stringify(resp))
          if (resp.data.status === "ok") {
            this.sources.splice(index, 1);
            me.$store.dispatch("sendFeedback", {
              msg: "Source deleted",
              status: "ok",
            });
          } else {
            console.log(resp.data.msg);
            //me.$store.dispatch('sendFeedback', { msg: resp.data.msg, status:resp.data.status})
            me.$store.dispatch("sendFeedback", {
              msg: "Error deleting source",
              status: "nok",
            });
          }
        },
        (err) => {
          console.log("ERROR", err);
        }
      );
    },

    prev() {
      if (this.sources[this.currentFocus - 1]) {
        this.item = _.clone(this.sources[this.currentFocus - 1].source);
        this.currentFocus--;
      }
    },
    next() {
      if (this.sources[this.currentFocus + 1]) {
        this.item = _.clone(this.sources[this.currentFocus + 1].source);
        this.currentFocus++;
      }
    },

    showEdit(item, offset) {
      // TOOD this causes some timing issues
      // and we need a display:none to sync data into hiPartnerSelect
      //
      this.item = _.clone(item.source);
      //this.$set(this.partner, '')
      this.partner = _.clone(item.partner);
      //this.$set(this, 'partner', item.partner)
      //console.log("******** ",item)
      //console.log("SHOW EDIT ", item,offset, this.item.title)
      //console.log("this.item now: ", this.item)
      this.currentFocus = offset;
      this.isView = false;
      this.showGallery = true;
    },

    showPreview(item, offset) {
      console.log("aSHOW PREVIEW ", item, offset);
      this.item = item.source;
      this.partner = _.clone(item.partner);
      this.currentFocus = offset;
      this.showGallery = true;
      this.isView = true;
    },

    changeit(e) {
      let i = this.selectedImages.indexOf(e);
      if (i !== -1) {
        // remove
        this.selectedImages.splice(i, 1);
      } else {
        // add
        this.selectedImages.push(e);
      }
    },

    getSources() {
      console.log("getSources() in MySources!");
      let vm = this;
      // this.$q.loading.show({
      //   delay: 400, // ms
      // });

      API.post("/my-sources", {
        uuid: vm.user,
      })
        .then((r) => {
          vm.sources = [...r.data.sources];
          vm.$q.loading.hide();
          console.log("sources laoded: ", vm.sources);
          vm.$forceUpdate();
        })
        .catch((e) => {
          window.alert("ERROR " + e);
        });
    },
  },

  mounted() {
    console.log("mount MySources");
    this.getSources();
    console.log("done mount MySources");
  },

  data() {
    return {
      // active
      filterType: this.content_types.map((e) => e.uuid),
      // available
      filterTypes: this.content_types,
      sources: [],
      msgImport: "",
      importBusy: false,
      importDone: false,
      activeSelection: [],
      showSearchEuropeana: false,
      importUrl: "",
      showImport: false,
      showGallery: false,
      showUpload: false,
      isView: false,
      currentFocus: 0,
      // sources: [],
      selectedImages: [],
      item: { uuid: null },
      partner: {},
      uuid: null,
      userHasTags: false, // XXX change this to the getter
      activeTags: [],
      sortKey: "newest",
      activeFilters: [],
      popYear: false,
      popLanguage: false,
      popKeywords: false,
      popover1: true,
      popoverx: true,
      popclass: true,
      activeFilter: "All",
      searchFor: "",
    };
  },
};
</script>

<style scoped>
.edit {
  border: 1px dotted #ccc;
  padding: 0.4em;
  background-color: transparent;
  width: 100%;
}

.edit.description {
  height: 18em;
  line-height: 1.6em;
}
.edit.title {
  font-size: 1.25rem;
  line-height: 1.6em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: normal;
  word-break: normal;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
#edit-image img {
  vertical-align: top;
  margin-bottom: 24px;
}
img.thumb {
  margin-top: 1em;
  width: 100px;
  height: 100px;
}

.inactive {
  opacity: 0.5;
}

.float-right {
  float: right;
}

.icon_europeana {
  filter: invert(0.6);
  --webkit-filter: invert(0.6);
}
.icon_europeana:hover {
  filter: invert(1);
  --webkit-filter: invert(1);
}
</style>
