<template>
  <div class="q-pt-sm q-pb-lg">
    <a :href="card.action">
      <q-card
        v-ripple
        align="center"
        class="
          cursor-pointer
          q-hoverable
          bg-hardroze
          text-white
          q-ma-none q-pt-md q-pb-sm
        "
      >
        <h1 class="q-pa-none q-ma-none">{{ stats.total }}</h1>
        <q-icon class="fas" :class="card.icon" />
        <div class="text-weight-bold q-pb-md">
          <a href="#" class="text-white">
            {{ card.title }}
            <q-tooltip>{{ card.about }}</q-tooltip>
          </a>
        </div>

        <q-space />
        <q-btn
          v-if="more"
          color="bg-hardroze text-white"
          round
          flat
          icon="fas fa-ellipsis-v"
        >
          <q-tooltip> More options </q-tooltip>
          <q-menu>
            <q-list>
              <q-item>aaa</q-item>
              <q-item>bbb</q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </q-card>
    </a>

    <!-- <q-card-section class="bg-grey-4 text-body2">{{ card.about }}</q-card-section> -->

    <!-- <q-separator /> -->
    <!-- <q-card-actions v-if="card.add">
      <q-btn flat icon="fas fa-plus" label="Add another" />
      <q-separator />
    </q-card-actions> -->
    <!-- <q-card-actions>
      <q-btn
        flat
        icon="fas fa-arrow-right"
        class="q-pr-md"
        :label="`Go to ${card.title}`"
      />
    </q-card-actions> -->
  </div>
</template>
<script>
export default {
  name: "DashCard",
  props: {
    card: {
      // the card properties (as defined in MyDashboard.vue:dash[] )
      type: Object,
      default: () => {},
    },
    // the stats collected from the API partners.py:dashboard
    stats: {
      type: Object,
      default: () => {},
    },

    // show more in right top corner (not used yet)
    more: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    tonedown(e) {
      console.log("element: ", e);
      //alert("foo");
    },
  },
};
</script>
<style>
.q-card {
  -o-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.137255),
    0px 1px 8px 0px rgba(0, 0, 0, 0.117647);
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.137255),
    0px 1px 8px 0px rgba(0, 0, 0, 0.117647);
  margin-bottom: 1.4rem;
}

.q-card:hover {
  -o-box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.137255),
    0px 5px 22px 4px rgba(0, 0, 0, 0.117647);
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.137255),
    0px 5px 22px 4px rgba(0, 0, 0, 0.117647);
  cursor: hand;
}
</style>