<template>
  <div id="search-sources">
    <hi-subnav :childs="childs" />
    <router-view />
  </div>
</template>
<script>
  export default {
    name: 'SelectSources',
    data () {
      return {
        childs: [
        {
          url: '/sas/partners',
          label: 'Search Partners'
        },
        {
          url: '/sas/search',
          label: 'Search Europeana'
        },        
        ]
      }
    }
  }
</script>