<template>
  <q-footer>
    <section>
      <div class="row q-mx-auto justify-between items-center" id="branding">
        <div class="col vertical-middle">
          <p>
            <a href="/">
              <img
                class="logo-footer q-pr-sm"
                id
                src="/static/footer/Logo_Historiana_diap.png"
              />
            </a>
            <span class="expl">is developed by</span>
            <a href="https://euroclio.eu" target="_blank">
              <img
                class="logo-footer q-px-sm"
                id
                src="/static/footer/EuroClio_logo_lighter.png"
              />
            </a>
            <span class="expl">,</span>
            <a href="https://webtic.eu" target="_blank">
              <img
                class="logo-footer q-px-sm"
                id
                src="/static/footer/logo_webtic_diap.png"
              />
            </a>
            <span class="expl">and</span>
            <a href="https://use.media/20years/" target="_blank">
              <img
                class="logo-footer q-px-sm"
                id
                src="/static/footer/USE_logo_footer.png"
                style="height: 20px;"
              />
            </a>
          </p>
        </div>
      </div>
    </section>
    <q-separator light inset class="q-my-lg" />
    <section>
      <div class="row q-mx-auto justify-between items-start">
        <!--<div class="column">
            <h5>Action</h5>
            <ul class="menu vertical" id="">
                <li><a href="#">First time visitor</a></li>
                <li><a href="#">Why register?</a></li>
                <li><a href="#">Support us</a></li>
                <li><a href="#">Give feedback</a></li>
                <li><a href="#">Get involved</a></li>
            </ul>
        </div>-->

        <div class="col-2 q-pr-sm" id="about-historiana">
          <h5 class="q-mb-sm">About</h5>
          <q-list>
            <q-item clickable v-ripple to="/partners">Partners</q-item>
            <q-item clickable v-ripple to="/about">About & Contact us</q-item>
          </q-list>
        </div>
        <div class="col-2 q-pr-sm">
          <h5 class="q-mb-sm">Follow us</h5>
          <q-list>
            <q-item clickable v-ripple>
              <a
                href="https://www.facebook.com/groups/historiana/"
                target="_blank"
                title="Opens in a new window"
                >Facebook</a
              >
            </q-item>
            <q-item clickable v-ripple>
              <a
                href="https://twitter.com/Historiana_EU/"
                target="_Blank"
                title="Opens in a new window."
                >Twitter</a
              >
            </q-item>

            <q-item clickable v-ripple>
              <a
                href="http://euroclio.eu/newsletter"
                target="_Blank"
                title="Opens in a new window."
                >EuroClio newsletter</a
              >
            </q-item>
          </q-list>
        </div>
        <div class="col-3 q-pr-sm">
          <h5 class="q-mb-sm">Copyright</h5>
          <q-list>
            <q-item>
              <a
                href="https://creativecommons.org/licenses/by/4.0/legalcode"
                target="_blank"
                >CC-BY-4.0 EuroClio - European Association of History
                Educators</a
              >
            </q-item>
          </q-list>
        </div>
        <div class="col q-pr-sm">
          <h5 class="q-mb-sm">Acknowledgements</h5>
          <q-list>
            <q-item>
              Historiana would not be possible without the efforts and generous
              contributions of historians and educators from Europe and beyond.
              <!--
         <ul class="menu vertical">
          <li>Historiana would not be possible without the efforts and generous contributions of historians and educators from Europe and beyond.</li>

          <li><a href="https://europa.eu/european-union/index_en" target="_Blank" title="Opens in a new window."><img class="logo-footer" id="" src="/static/interface-img/Logo/flag_black_white_low.jpg" /> Co-funded by the European Union</a></li>
          <li><a href="http://www.europeana.eu/portal/en" target="_Blank" title="Opens in a new window."><img class="logo-footer" id="" src="/static/interface-img/Logo/EU_split_logo_A_white.png" /> Powered by Europeana</a></li>
        </ul>
      </ul>
              -->
            </q-item>
          </q-list>
        </div>
      </div>
    </section>
    <q-separator light inset class="q-my-xl" />
    <section>
      <div class="row q-mx-auto q-mt-lg justify-between items-center">
        <p>
          <a
            href="https://europa.eu/european-union/index_en"
            target="_Blank"
            title="Opens in a new window."
          >
            <img
              style="max-width:60px;max-height:30px;"
              class="logo-footer"
              id
              src="/static/footer/flag_black_white_low.jpg"
            />&nbsp;&nbsp;Co-funded by the European Union&nbsp;&nbsp;&nbsp;
          </a>
          <a
            href="http://www.europeana.eu/portal/en"
            target="_Blank"
            title="Opens in a new window."
          >
            <img
              style="max-width:60px;max-height:30px;"
              class="logo-footer"
              id
              src="/static/footer/EU_made_with_europeana.jpg"
            />&nbsp;&nbsp;Made with Europeana
          </a>
        </p>
      </div>
    </section>
    <q-separator light inset class="q-my-lg" />
  </q-footer>
</template>

<script>
export default {
  name: 'HiFooter',
  data() {
    return {
      msg: ''
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
#branding {
  margin-top: 40px;
}

.logo-footer {
  max-width: 200px;
  max-height: 42px;
}

.q-footer {
  background-color: #595959;
}

footer, footer li a, footer a {
  color: #9c9c9c;
}

footer li a:hover, footer a:hover {
  color: white;
  background-color: transparent;
}

footer h5 {
  color: #bfbfbf;
  font-size: 1.25rem;
}

img {
  opacity: 0.9;
}

img:hover {
  opacity: 1;
}

footer .menu > li > a {
  padding: 0.25rem 0rem;
}

footer .q-item {
  min-height: 24px;
  padding-left: 0;
  padding-bottom: 0;
}
</style>
