<template>
  <div class="builder">

    <!-- Builder UI in right sidebar in editor mode -->

    <q-resize-observer @resize="setTabPanelsHeight" />

    <div
      class="nav-block action float-right"
      :class="{ disabled: viewDisable }"
      @click="nextStep"
    >
      <i :class="lastStep? 'fa fa-save':'fa fa-arrow-right'"/>
      <label>{{ lastStep? 'save':'next' }}</label>
    </div>

    <q-tabs
      class="builder-steps"
      no-caps
      v-model="localView"
      indicator-color="plasticgroen"
      align="justify"
      narrow-indicator
      style="z-index:2"
    >
      <q-tab
        v-for="(v,i) in views"
        :key="v"
        :name="v"
        :label="viewLabels[i]"
        :disable="viewDisable"
      />
    </q-tabs>

    <q-separator color="white" size="2px" style="margin-top:-6px;"/>

    <q-tab-panels
      ref="tabPanels"
      class="bg-transparent builder-step"
      v-model="localView"
    >

      <!-- step 1: intro -->

      <q-tab-panel :name="views[0]" style="padding:25px">
        <div class="info">
          <i class="fa fa-info-circle"/>
          <p>Set title, cover image and teaser text so your Narrative can be found and shared on Historiana. Optionally add an introduction for your story.</p>
        </div>

        <!-- index card preview -->
        <card
          style="margin-top:25px"
          :thumb="narrative.icon? narrative.icon.url:null"
          :title="narrative.title"
          type="narrative"
          csstype="historicalcontent"
          :hover="false"
        >
          <q-input
            class="meta-text"
            textarea
            autogrow
            borderless
            dense
            v-model="narrative.teaser"
            placeholder="Write a teaser text …"
            @input="$emit('unsaved')"
          />

        </card>

      </q-tab-panel>


      <!-- step 2: summary -->

      <q-tab-panel :name="views[1]"  style="padding:25px">
        <div class="info">
          <i class="fa fa-info-circle"/>
          <p>Add <u class="cursor-pointer" @click="$emit('addChapter')">chapters</u> to set the main outlines of your Narrative, or <u class="cursor-pointer" @click="nextStep">start writing</u> right away.</p>
        </div>
      </q-tab-panel>


      <!-- step 3: write -->

      <q-tab-panel :name="views[2]">

        <q-scroll-area
          :thumb-style="{ opacity: .8 }"
          :style="{ height:tabPanelsHeight }"
          @mousewheel.stop
        >
          <!-- info msg -->

          <div class="info" style="margin:25px">
            <i class="fa fa-info-circle"/>
            <p>Find sources and other elements for your Narrative. Drag onto the text area to link or embed them.</p>
          </div>

          <!-- cards search -->

          <narrative-builder-search
            placeholder="Find elements for your Narrative"
            @setSidePanelExpanded="e=>$emit('setSidePanelExpanded',e)"
          />

        </q-scroll-area>
      </q-tab-panel>

    </q-tab-panels>
  </div>
</template>

<script>
import { UseEase, clone } from '../tic';
import { ref, computed, watch } from '@vue/composition-api'
import Card from '../tags/Card';
import NarrativeBuilderSearch from './NarrativeBuilderSearch';

import { dom } from 'quasar'
const { offset } = dom

export default {
  name: "NarrativeBuilder",

  props: {

    view:{
      type:String,
      value:''
    },

    views:{
      type:Array,
      default:() => []
    },

    viewLabels:{
      type:Array,
      default:() => []
    },

    narrative:{
      type:Object,
      default:() => {}
    },

  },

  setup (props, context) {
  },

  components: {
    Card,
    NarrativeBuilderSearch
  },

  data () {
    return {
      localView:'',
      debug:false,
      search:null,
      searching:false,
      tabPanelsHeight:undefined,
    }
  },

  computed: {
    viewDisable () {
      return !this.narrative.title;
      //return false;// (!this.narrative.title || !this.narrative.icon); //require title and cover for new narratives
    },
    lastStep () {
      return this.view=='read';
    },
  },

  watch: {
    view:{
      handler() {
        this.localView = this.view;
      },
      immediate:true
    },

    localView () {
      if (this.localView!=this.view) this.$emit('changeView',this.localView)
    },
  },

  mounted () {
    console.log('📚 Narrative-builder mounted')
  },

  methods: {
    nextStep () {
      if (this.lastStep)
      {
        this.$emit('store')
      }
      else
      {
        this.localView = this.views[this.views.findIndex(v => v==this.view) + 1];
      }
    },

    setTabPanelsHeight() {
      this.tabPanelsHeight = this.$refs.tabPanels? 'calc(100vh - 100px - '+this.$refs.tabPanels.$el.offsetTop+'px)':undefined;
    },

  }
}
</script>

<style lang="stylus" scoped>
@import '~quasar-variables'

.builder
  min-width:285px;
  min-height:calc(100vh - 100px);
  color:$plasticgroen;
  background-color:$appelgroen;

.builder .head
  height:50px;

.builder h2
  margin:0;
  font-size: 1.25rem;
  /* font-weight: 500; */

.builder .nav-block.action
  margin-bottom:10px;
  z-index:10;

.builder-steps
  clear:both;

.builder-steps >>> .q-tab__indicator
  /* margin-bottom:-4px; */
  height:6px !important;

.builder-steps >>> .q-focus-helper,
.builder-steps >>> .q-ripple
  height:calc(100% - 6px)

.builder-step >>> .q-panel.scroll
  overflow:visible;

.builder-step .q-tab-panel
  padding:0;

.builder-step >>> .q-scrollarea__thumb
  background:$plasticgroen;
  z-index:10;

.builder-step .info
  font-weight:600;
  font-style:italic;
  font-size:.875rem;

.builder >>> .q-textarea.q-field--dense .q-field__native /* textarea inside card (teaser text) */
  padding:0;
  line-height:1.5;


@media (max-width:1400px)
  .builder .nav-block.action
    width:50px;
    height:50px;
    label
      display:none;


</style>