<template>
  <div>
    <section>
      <div class="row q-mx-auto q-py-lg justify-between items-start">
        <div class="col q-pr-md">
          <h3>{{ partner.name }}</h3>
          <p v-html="partner.text" class="text-body2" />
        </div>
        <div class="col-shrink col-2">
          <img style="float:right" :src="partner.logo" />
        </div>
      </div>

      <hi-search placeholder="Find sources from this partner" v-model.lazy="searchFor" />

      <!-- <div class="row q-mx-auto justify-between items-start">
        <div class="col q-py-md">
          <q-input
            type="text"
            v-model.lazy="searchFor"
            label="Search partner"
          />
        </div>
      </div>-->
    </section>

    <section id="card-holder">
      <!-- <div class="row q-mx-auto justify-between items-between">
        <div class="col q-py-md">
          <div v-if="hasResults">
            <h4>{{ result.totalResults }} items match <b>{{ searchFor }}</b></h4>
          </div>
          <q-btn-group
            flat
            spread
          >
            <q-btn
              @click="prevnext('prev')"
              icon="fa fa-chevron-left"
              label="Previous"
              align="left"
            />
            <q-btn
              @click="prevnext('next')"
              label="Next"
              icon-right="fa fa-chevron-right"
              align="right"
            />
          </q-btn-group>
        </div>
      </div>-->
      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md">
          <div :key="i" v-for="(card, i) in items" class="column">
            <Card
              :index="i"
              :item="card"
              type="Partner source"
              csstype="searchpartner"
              :title="card.title[0]"
              :image-url="card.edmPreview ? card.edmPreview[0] : ''"
              uuid
              url
              :meta="meta(card)"
              :is-owner="false"
              :btn-add="true"
              :show-meta-button="true"
            >
              <div
                class="card-description"
                v-if="card.dcDescription"
              >{{ card.dcDescription.join(" ") }}</div>
            </Card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { API } from "../tic";
import Card from "../tags/Card";

export default {
  name: "ExplorePartner",
  components: { Card },
  props: {
    slug: {
      type: String,
      default: ""
    },
    uuid: {
      type: String,
      default: ""
    }
  },

  watch: {
    searchFor: function() {
      this.isLoading = true;
      API.post("/sas/browse-partner", {
        searchFor: this.searchFor,
        showMax: this.showMax,
        partner: this.partner.code
      }).then(resp => {
        console.log("data: ", resp.data.data);
        this.result = resp.data.data;
        this.isLoading = false;
        this.hasResults = Boolean(resp.data.data.totalResults);
      });
    }
  },
  computed: {
    items() {
      //return this.result.items.filter((e) => { return e.edmPreview })
      return this.result.items;
    }
  },
  methods: {
    meta(item) {
      let fields = [
        { label: "title", value: item.title.join(",") },
        { label: "provider", value: item.provider.join(",") || null },
        {
          label: "dataprovider",
          value: item.dataProvider ? item.dataProvider.join(",") : null
        },
        {
          label: "year",
          value: item.year ? item.year.join(",") : null
        },
        {
          label: "Eu collection",
          value: item.europeanaCollectionName
            ? item.europeanaCollectionName.join(",")
            : null
        },
        {
          label: "country",
          value: item.country ? item.country.join(",") : null
        },
        {
          label: "created",
          value: item.timestamp_created ? item.timestamp_created : null
        },
        { label: "id", value: item.id ? item.id : null },
        { label: "type", value: item.type ? item.type : null }
      ].filter(e => {
        return e.value;
      });

      return {
        type: "europeana",
        sectionType: "Europeana Search Partner",
        objectType: item.type,
        fields: fields
      };
    },

    prevnext(what) {
      this.isLoading = true;
      switch (what) {
        case "next":
          this.prevCursor.push(this.result.nextCursor);
          break;

        case "prev":
          this.prevCursor.pop();
          break;
      }

      API.post("/sas/browse-partner", {
        partner: this.partner.code,
        searchFor: this.searchFor,
        showMax: this.showMax,
        cursor: what === "next" ? this.result.nextCursor : this.prevCursor.pop()
      }).then(resp => {
        this.result = resp.data.data;
        this.isLoading = false;
        this.hasResults = Boolean(resp.data.data.totalResults);
      });
    }
  },
  mounted() {
    console.log("mounted partner");
    API.post("/sas/get-partner", {
      slug: this.slug,
      uuid: this.uuid
    }).then(resp => {
      this.partner = resp.data.data;
      API.post("sas/browse-partner", {
        partner: this.partner.code
      }).then(resp => {
        this.result = resp.data.data;
      });
    });
  },

  data() {
    return {
      partner: {},
      searchFor: "",
      showMax: 25,
      hasResults: false,
      result: [],
      prevCursor: [],
      isLoading: false
    };
  }
};
</script>
