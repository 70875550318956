<template>
  <div>
    <!-- QMediaPlayer type="video" :sources="[{ src: url }]" /-->
    <video ref="vp" class="full-width" controls></video>

    <!-- <q-img :src="thumb">
      <q-btn label="replace" />

      <q-slider v-model="thumbtime" :min="0" :max="50" />
    </q-img> -->
  </div>
</template>
<script>
// import "@quasar/quasar-ui-qmediaplayer/dist/index.css";
// import { QMediaPlayer } from "@quasar/quasar-ui-qmediaplayer";
import Hls from "hls.js";
export default {
  name: "hiVideo",
  // components: { QMediaPlayer },

  props: {
    url: {
      type: String,
      default: null,
    },

    thumb: {
      type: String,
      default: null,
    },

    autoplay: {
      type:Boolean,
      default:false
    }
  },

  data() {
    return {
      thumbtime: "",
    };
  },

  watch: {
    url () {
      this.load();
    }
  },

  methods: {
    load() {
      const
        hls = new Hls(),
        vp = this.$refs.vp;

      hls.loadSource(this.url);
      hls.attachMedia(vp);
      if (this.autoplay) hls.on(Hls.Events.MANIFEST_PARSED, () => {
        vp.play();
      });
    },

    stop() {
      let video = this.$refs.vp;
      video.pause();
    },
  },

  mounted() {
    this.load();
  },

  beforeUnmount() {
    this.stop();
  }
};
</script>
