<template>
  <section id="uploadcollection">
    <q-dialog
      :value="show"
      @hide="$emit('close')"
      persistent
      transition-show="slide-up"
      transition-hide="slide-down"
    >

      <q-card style="width:100%; max-width:720px;">
        <q-bar class="bg-hardroze text-white title-bar">
          <q-icon name="fad fa-file-upload" class="q-mr-md"/>
          &nbsp; Upload Collection &mdash; {{ action.label }}
          <q-space />
          <q-btn dense flat round icon="fal fa-times" v-close-popup>
            <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <!-- p>type: {{action}}</p>
        <p>r: {{record}}</p> -->
        <q-card-section>
          <div class="row q-pa-md">
            <div class="col q-pr-md">
              <q-uploader
                :auto-upload="action.autoUpload"
                flat
                square
                bordered
                ref="uploader"
                url="/api/upload"
                :label="`${action.uploadText} to upload`"
                :accept="accept"
                :multiple="action.multiple"
                batch
                :headers="getHeaders"
                hide-upload-btn
                @uploaded="uploadDone"
              />

              <p class="note" style="margin-top: 1em" v-show="type === 'excel'">
                Important: The Excel needs to be formatted as outlined in the
                example otherwise it will not be processed.
              </p>
              <p class="note" style="margin-top: 1em" v-show="type === 'excel'">
                Note: We only process .XLSX, convert your old .XLS as needed.
              </p>
            </div>
            <div class="col">
              <h4>About this collection</h4>
              <q-form>
                <q-input v-model="record.name" label="Name" />
                <q-input
                  v-model="record.introduction"
                  type="textarea"
                  autogrow
                  label="Introduction on card"
                />
                <q-input
                  v-model="record.description"
                  type="textarea"
                  autogrow
                  label="Description"
                />
                <q-input
                  v-model="record.acknowledgements"
                  type="textarea"
                  autogrow
                  label="Acknowledgements"
                />
                <hi-language v-model="record.language" />
              </q-form>
            </div>
          </div>

          <q-card-actions align="center" class="q-pt-xl">
              <q-btn no-caps label="Cancel" @click="$emit('close')" />&nbsp;
              <q-btn
                no-caps
                color="green"
                :disable="collectionFormValid()"
                @click="saveCollection"
                label="Save"
              />
          </q-card-actions>

        </q-card-section>
      </q-card>
    </q-dialog>
  </section>
</template>

<script>
import { collection } from '../datamodel'
import _ from 'lodash'
import { API } from '../tic'
import HiLanguage from '../components/hiLanguage'
import { mapState, mapActions, mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'UploadCollection',
  components: { HiLanguage },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: '.jpg, .png, image/*'
    },
    type: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      defaul: ''
    }
  },

  watch: {
    show() {
      this.record = _.clone(collection);
      this.record.user = this.$store.getters['user/uuid'];
    }
  },

  data() {
    return {
      record: {}
    };
  },

  computed: {
    ...mapGetters('user', [
      'displayname',
      'isAuthenticated',
      'uuid',
      'email',
      'session'
    ]),

    action() {

      const types = [
        {
          type: 'collection-excel',
          label: 'via Excel processor',
          uploadText: 'Excel',
          multiple: false,
          autoUpload: false
        },
        {
          type: 'collection-batch',
          label: 'batch upload images',
          uploadText: 'Images',
          multiple: true,
          autoUpload: false
        },
        {
          type: 'collection-powerpoint',
          label: 'via Powerpoint processor',
          uploadText: 'Powerpoint',
          multiple: false,
          autoUpload: true
        }
      ]

      try {
        return types.filter(e => e.type===this.type).pop()
      }

      catch {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `Unknown type: ${this.type}`
        })
      }
    }
  },

  methods: {
    // doInit() {
    //   console.log('UPLOAD INIT: ', this.$route)
    //   this.record = _.clone(collection)
    //   this.record.user = this.$store.getters['user/uuid']
    //   this.uploadState = 'collection'
    // },

    uploadDone(req) {
      const vm = this
      console.log('====== upload done ============== type: ', vm.type)

      // PPTX returns a data object
      if (vm.type==='collection-powerpoint' || vm.type==='collection-excel') {
        let data = JSON.parse(req.xhr.response).data
        vm.record.uuid = data.uuid
        vm.record.name = data.title
        vm.record.introduction = data.subtitle
        vm.record.description = data.description
        vm.record.acknowledgements = data.acknowledgements
      }

      // others just reply with status.OK
      vm.$forceUpdate()
      vm.$emit('isDirty')
      vm.$emit('close');

      this.$q.notify({
        type: 'positive',
        position: 'top',
        message: `${this.action.uploadText} uploaded`
      })
    },

    getHeaders(e) {
      // console.log("===== GET HEADERS ======")
      // console.log("user: ", this.$store.getters['user/session'])
      // console.log("type: ", this.action.type)
      // console.log("coll: ", this.record && this.record.uuid ? this.record.uuid : null )
      // console.log("rec : ", this.record)
      // console.log("===== DONE HEADERS ======")

      return [
        // these are needed in the API to identify the user
        { name: 'X-Session', value: this.$store.getters['user/session'] },
        { name: 'X-Process', value: this.action.type },
        { name: 'X-Collection', value: this.record && this.record.uuid ? this.record.uuid : null }
      ]
    },

    collectionFormValid() {
      return (
        _.isEmpty(this.record.name) ||
        _.isEmpty(this.record.introduction) ||
        _.isEmpty(this.record.description) ||
        _.isEmpty(this.record.acknowledgements)
      );
    },

    saveCollection() {
      let vm = this;

      if (this.action.type==='collection-powerpoint') {
        console.log("UPDATE INSTEAD")
        console.log("r: ", this.record)
        API.post('/collection/update', this.record)
        .then(r => {
          console.log("update collection resp?: ", r)
          vm.$emit('isDirty')
          vm.$emit('close');
        })
        return
      }

      // make sure we pass the current user.uuid
      this.record.user = this.$store.getters['user/uuid'];
      console.log('POST TO ADD COLLECTION: ', this.record);
      API.post('/collection/add', this.record)
        .then(r => {
          // GET the uuid for the Collection just created
          this.record = r.data.data;
          console.log("COLLECTION CREATED: ", this.record)

          // upload the files now so we can connect them to collection.uuid
          // dialog closed via @finish on q-uploader
          this.$refs.uploader.upload();

          // custom feedback on excel upload
          if (this.type === 'collection-excel') {
            this.$q.dialog({
              title: '<i class="fa fa-info"></i>&nbsp;Batch received',
              message: `Processing started, progress will be reported via ${vm.email}.`,
              html: true,
              ok: {
                color: 'primary'
              }
            })
          }

          //vm.$emit('close')
          //vm.$emit('isDirty')


        })
        .catch(e => {
          console.log('catch error: ', e);
          this.$q.dialog({
            title: '<i class="fa fa-bomb"></i>&nbsp;Database Error',
            message: e.response.data.data,
            html: true,
            ok: {
              color: 'primary'
            }
          })
        })
    }
  }
};
</script>
