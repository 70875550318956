<template>
  <div id="bblock-compare" class="ea-canvas" ref="canvas" @mousemove="onMouseMove">
    <q-resize-observer @resize="onResize" />

    <!-- intro -->
    <h5
      v-if="!sources.length"
      class="guide"
    >
      <span v-if="mode!='teacher'">Please add sources in teacher mode first</span>
      <span v-else>{{ intro }}<a href="/add-media" class="text-hardroze" @click.prevent="$emit('addMedia')">{{ mySourcesLink }}</a></span>
    </h5>

    <!-- scrollable sources -->
    <q-scroll-area
      horizontal
      ref="scrollarea"
      :thumb-style="{ background: '#dd0077', opacity: .8 }"
      @scroll="onScroll"
    >
      <div
        id="sources"
        ref="sources"
        :class="{ removing:isRemoving }"
        :style="{ paddingRight:sourcesMargin +'px' }"
      >

        <!-- sources -->
        <compare-source
          ref="source"
          v-for="(source,index) in sources"
          :singleSelection="isAnalysing"
          :style="{ 'width':sourceWidth+'px' }"
          :index="index"
          :key="index"
          :source="source"
          :mode="mode"
          :disabled="isRemoving"
          :annotations="annotationsList"
          :selections="selectionsList[index]"
          :no-zoom="isScrolling"
          :add-selection="addSelection"
          :active-annotation.sync="activeAnnotation"
          :annotation-colors="annotationColors"
          @remove="removeSource(index)"
          @updateSource="storeRecord(false)"
          @addSelection="addSelectionMode"
          @activeSelection="selectionPosition"
          @editSelection="editSelection"
          @storeSelections="updateSelections(index,...arguments)"
        />

        <!-- append placeholder(s) if less than 2 sources -->
        <a
          v-if="isComparing"
          v-for="(p,index) in placeholders"
          class="placeholder"
          :class="{ active:mode=='teacher' }"
          href="/add-media"
          :key="'p_'+index"
          :style="{ 'width':sourceWidth+'px' }"
          @click.prevent="mode=='teacher'? $emit('addMedia'):false"
        />

        <!-- annotation text tooltip -->
        <div
          v-show="showAnnotationText"
          v-html="addLineBreaks(annotationText)"
          class="tooltip"
          :style="{
            left: `${sx}px`,
            top: `${sy}px`,
            width: annotationTextWidth,
            fontSize:`${labelSize}px`
          }"
        />

      </div>
    </q-scroll-area>

    <!-- debugging -->
<!--
    <div style="position:absolute; color:magenta; background-color:rgba(0,0,0,.8); width:100%">
      annotation = {{ annotation }}
    </div>
 -->

    <!-- annotations panel -->
    <div id="annotations"
      ref="sidebar"
      v-if="showSidebar"
      :class="{ collapsed:!isExpanded }"
      @click="isExpanded? false:isExpanded=true"
    >

      <!-- add annotation -->
      <div id="annotations-header" class="row justify-between items-center">
        <q-btn
          no-wrap
          no-caps
          flat
          round
          style="color:#e0e0e0"
          :size="isExpanded? '10px':'14px'"
          :icon="isExpanded? 'fas fa-chevron-right':'far fa-comments'"
          @click.stop="isExpanded=!isExpanded"
        />
        <q-btn
          v-if="isExpanded && this.mode!='review'"
          no-wrap
          no-caps
          flat
          class="text-right"
          color="primary"
          align="right"
          icon="far fa-comment-plus"
          label="Add Annotation"
          :disable="addSelection"
          @click="addAnnotation"
        />
      </div>

      <!-- annotations list -->
      <q-scroll-area
        id="annotations-list"
        ref="list"
        @click.native="resetActiveAnnotation"
      >
        <q-list dark separator ref="annotations">
          <q-item
            v-for="(a,index) in annotationsList"
            class="annotation"
            ref="annotation"
            active-class="active"
            v-ripple="{ color:annotationColors[a.color] }"
            clickable
            :key="a.id"
            :active="activeAnnotation==a.id"
            :style="{ color:activeAnnotation==a.id? annotationColors[a.color]:'' }"
            @click.stop="addSelection? null:setActiveAnnotation(index,a)"
          >
            <q-item-section>
              <q-item-label class="row justify-between head">
                <!-- colored annotation icon & edit toggle -->
                <i
                  class="fa-comment"
                  :class="{ far:activeAnnotation!=a.id, fas:activeAnnotation==a.id}"
                  :style="{ color:annotationColors[a.color], opacity:!isExpanded && !a.editable? .5:1 }"
                />
                <!-- edit toggle -->
                <q-toggle
                  v-if="a.editable"
                  v-show="!a.empty"
                  dense
                  dark
                  v-model="a.edit"
                  class="text-white"
                  icon="fa fa-pencil"
                  :disable="addSelection"
                  @input="editAnnotation(index,...arguments)"
                />
                <i
                  v-else
                  class="fas"
                  :class="{ 'fa-chalkboard-teacher':mode=='student' || a.id.indexOf('t')===0, 'fa-user-graduate':mode=='teacher' }"
                />
              </q-item-label>
              <section v-if="isExpanded">
                <q-item-label class="text">
                  <section v-if="a.empty">
                    <!-- add selection hint -->
                    <em><i class="far fa-long-arrow-left"></i> Drag on the image to create a selection</em>
                  </section>
                  <section v-else>
                    <!-- textarea or text display -->
                    <q-input
                      v-if="a.edit"
                      v-model="a.text"
                      type="textarea"
                      placeholder="Add your annotation"
                      filled
                      autogrow
                      dark
                      @blur="addSelection? null:store"
                    />
                    <span v-else v-html="addLineBreaks(a.text)"/>
                  </section>
                </q-item-label>
                <q-item-label v-if="a.edit" class="row no-wrap justify-between items-center controls">
                  <!-- color picker -->
                  <div class="row">
                  <span
                    v-for="(c,cIndex) in annotationColors"
                    class="color"
                    :class="{ active:a.color==cIndex }"
                    :key="c"
                    :style="{ backgroundColor:c }"
                    @click="a.color=cIndex"
                  />
                  </div>
                  <!-- add selection -->
                  <q-btn
                    v-if="isComparing"
                    flat
                    round
                    size="10px"
                    class="add-selection"
                    icon="fas fa-plus"
                    style="margin-bottom:-8px"
                    @click="addSelectionMode(true,a.id)"
                  ><i class="far fa-vector-square"/></q-btn>
                  <!-- delete -->
                  <q-btn
                    v-if="!a.empty"
                    flat
                    round
                    size="10px"
                    icon="fas fa-trash"
                    class="text-white"
                    style="margin-bottom:-8px"
                    @click.stop="removeAnnotation(index,a.id)"
                  />
                </q-item-label>
              </section>
            </q-item-section>
          </q-item>
        </q-list>

      </q-scroll-area>
    </div>

    <!-- add-selection cursor -->
    <i
      v-if="addSelection"
      class="fal fa-vector-square cursor"
      :style="{
        color:annotationColors[annotationsList.find(a => a.id==activeAnnotation).color],
        left:mx + 'px',
        top:my + 'px',
      }"
    ><i class="fas fa-plus"/></i>

    <!-- instructions -->
    <block-instructions
      v-model="instructions"
      :mode="mode"
      :show="showInstructions"
      @close="closeInstructions"
    />

  </div>
</template>

<script>
import { clone } from '../tic';
import CompareSource from './AnnotatableSource';
import { mapGetters } from 'vuex';
import BlockInstructions from './BlockInstructions'

export default {
  name: "BBAnnotating",

  components: {
    CompareSource,
    BlockInstructions
  },

  props:{
    blockId: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'student'
    },
    view: {
      type: String,
      default: 'view'
    },
    editor: {
      type: Boolean,
      default:false
    },
    showInstructions: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default:''
    },
    intro: {
      type: String,
      default:''
    }
  },

  data () {
    return {
      toolWidth:1140,
      isScrolling:false,
      isExpanded:true,
      isRemoving:false,
      addSelection:false,
      activeAnnotation:'',
      annotationColors:[
        '#EEBB00',
        '#CC0000',
        '#00AAEE',
        '#55BB00',
        '#AA0099'
      ],

      mx:0,
      my:0,
      sx:0,
      sy:0,
      zoom:1,

      instructions:null,
      sources: [],
      annotations: [],
      selections: [],
      studentAnnotations: [],
      studentSelections: [],
    };
  },

  computed: {
    ...mapGetters('activity',['record','answer']),

    isAnalysing() {
      return this.type=='analysing';
    },

    isComparing() {
      return this.type=='comparing';
    },

    defaultAnnotationColor() {
      return this.isAnalysing? 3:2;
    },

    maxSources() {
      return this.isAnalysing? 1:5;
    },

    mySourcesLink() {
      return this.type=='analysing'? 'source':'sources';
    },

    scrollingElm() {
      return this.$refs.scrollarea.$el.firstChild;
    },

    sourcesMargin() {
      if (this.isComparing && this.sources.length<2) return 0
      else return this.isExpanded? 240:50;
    },

    sourceWidth () {
      return this.isAnalysing? '100%':(this.toolWidth - (this.sources.length<2? 0:this.sourcesMargin)) / 2;
    },

    placeholders () {
      //0, 1 or 2 placeholders based on sources length
      return [true,true].filter((v,i) => { if (!this.sources[i]) return v });
    },

    showSidebar () {
      return this.isAnalysing? this.sources.length==1:this.sources.length>1;
    },

    annotationsList () {
      //combine teacher and student annotations
      return this.annotations.concat(this.studentAnnotations);
    },

    selectionsList () {
      //combine teacher and student selections (per source)
      return this.sources.map((s,index) => {
        return this.studentSelections[index].concat(this.selections[index]);
      });
    },

    showAnnotationText () {
      return this.isAnalysing && this.activeAnnotation && this.annotationText!='' && !this.isExpanded;
    },

    annotation () {
      return this.annotationsList.find(a => a.id==this.activeAnnotation);
    },

    annotationText () {
      let a = this.annotation;
      if (a && a.text)
      {
        let fromTeacher = !a.editable? '<i class="fas fa-chalkboard-teacher float-right"></i>':'';
        return fromTeacher+'<i class="far fa-comment on-left" style="color:'+this.annotationColors[a.color]+'"></i>'+a.text;
      }
      else return '';
    },

    annotationTextWidth () {
      return this.annotation && this.annotation.text.length>50? '500px':'auto';
    },

    labelSize() {
      return Math.min(this.zoom,16/12) * 12
    },
  },

  watch: {
    record: {
      immediate:true,
      deep:true,
      handler () {
        console.log('BB-annotating ('+this.type+') record change');

        if (this.record && this.record.images)
        {
          this.sources = clone(this.record.images);
          this.instructions = this.record.instructions;
          this.resetActiveAnnotation();
          this.setAnnotations();
          this.setSelections();
          this.setActions();
        }
      }
    },

    mode (m) {
      //handle teacher/student view toggle in BlockEditor
      if (m=='student')
      {
        //make sure state.record is up to date, as we rebuild all annotations and selections
        this.$store.commit('activity/syncCurrentRecord',this.blockId)
      }
      else
      {
        //clear student data
        this.studentAnnotations = [];
        this.studentSelections = [];
      }

      //rebuild display
      this.resetActiveAnnotation();
      this.setAnnotations();
      this.setSelections();

      //toggle actions
      this.setActions()
    },

    activeAnnotation () {
      const annotations = this.mode=='student'? this.studentAnnotations:this.annotations;

      //exit edit-mode on others when active annotation changes
      if (!this.isRemoving) annotations.forEach(a => {
        if (a.id!=this.activeAnnotation)
        {
          if (a.edit==true)
          {
            this.addSelection = false;
            this.store(); //always store changes when leaving edit-mode
          }
          a.edit = false;
        }
      });

      //scroll active list element into view
      const index = this.annotationsList.findIndex(a => a.id==this.activeAnnotation);
      this.$nextTick(() => {
        const a = this.$refs.annotation[index];
        if (a) {
          setTimeout(() => {
            this.$refs.list.setScrollPosition(a.$el.offsetTop, 200)
          },300);
        }
      });
    },

    isExpanded () {
      //make sure remove class is not set (it blocks width transition while removing a source)
      this.isRemoving = false;
    }
  },

  mounted () {
    //listen for esc key
    window.addEventListener('keydown', this.exitAddSelection);
  },

  destroyed() {
    window.removeEventListener('keydown', this.exitAddSelection);
  },

  methods: {

    addLineBreaks (string) {
      return string.replace(/\n/g,'<br>');
    },

    setActions () {
      let addSources = { max:this.maxSources - this.sources.length, excludeTypes:['application/pdf'] }
      if (this.isAnalysing) {
        addSources.title = 'Select source';
        addSources.button = 'Add Source';
      }

      this.$emit('addSources',this.mode=='teacher'? addSources:false)
      this.$emit('addInstructions',this.mode=='teacher'? true:this.instructions && this.instructions!='');
    },

    setAnnotations () {
      //deep copy annotations from store record & add properties for list view
      this.annotations = clone(this.record.annotations || []).map(a => {
        a.editable = this.mode=='teacher';
        a.edit = false;
        return a;
      });

      //add student data
      if (this.answer) this.studentAnnotations = clone(this.answer.annotations || []).map(a => {
        a.editable = this.mode=='student';
        a.edit = false;
        return a;
      });
    },

    setSelections () {
      //deep copy selections from store record & add properties for display
      this.selections = clone(this.record.selections || []).map(source => {
        return source.map(s => {
          s.active = false;
          s.editable = this.mode=='teacher';
          s.edit = false;
          return s;
        })
      });

      //add student data
      if (this.answer) this.studentSelections = clone(this.answer.selections || []).map(source => {
        return source.map(s => {
          s.active = false;
          s.editable = this.mode=='student';
          s.edit = false;
          return s;
        })
      });

      //make sure there's a selections array for each source
      this.sources.forEach((s,i) => {
        if (!this.selections[i]) this.selections.push([]);
        if (!this.studentSelections[i]) this.studentSelections.push([]);
      });
    },

    updateSelections (index,selections) {
      //split teacher and student selections for source at index (teacher ids start with 't', student with 's'),
      //using $set to make array change reactive
      this.$set(this.selections,index,selections.filter(s => s.a.indexOf('t')>-1));
      this.$set(this.studentSelections,index,selections.filter(s => s.a.indexOf('s')>-1));
      this.store();

      if (this.isAnalysing) {
        //remove active annotation if selection was deleted
        if (!this.removeEmptyAnnotation(selections))
        {
          //active annotation has selection: show and focus textarea
          this.annotation.empty = false;
          this.$nextTick(()=>{
            this.$refs.annotations.$el.getElementsByTagName('TEXTAREA').item(0).focus();
          });
        }

        //exit add mode
        this.addSelection = false;
      }
    },

    selectionPosition (p) {
      this.sx = p.x;
      this.sy = p.y;
      this.zoom = p.zoom;
    },

    removeSource: function (index) {

      let clearData = () => {

          //remove source from data
          this.sources.splice(index,1);

          if (this.isComparing)
          {
            //remove machting selections
            this.selections.splice(index,1);
          }
          else
          {
            //clear all annotations and selections
            this.annotations = [];
            this.selections = [];
          }

          //push to global store and save immediately
          this.store(true);

          //sync record, this will trigger UI refresh
          this.$store.commit('activity/syncCurrentRecord',this.blockId)
      }

      //remove a source from the record, confirm first
      this.$q.dialog({
        title: '<i class="fa fa-trash"></i>&nbsp;Confirm Remove',
        message: '<p>Removing source from this block, all annotations on this source will be lost.</p><p>Are you sure?</p>',
        html: true,
        cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
        ok: { label: 'Yes', color: 'primary', noCaps: true, }
      }).onOk(() => {

        if (this.isAnalysing) clearData()
        else
        {
          //remove with transition  //->TBD: investigate why Vue list transitions won't work here
          let remove;
          const elm = this.$refs.sources.childNodes[index];
          this.isRemoving = true;

          elm.addEventListener('transitionend',remove = (e) => {
            if (e.propertyName=='width') //element is first faded, then resized
            {
              elm.removeEventListener('transitionend',remove);
              elm.classList.remove('remove'); //elements are re-used by Vue, so clear transition class
              this.isRemoving = false;

              clearData();
            }
          });

          //start transition
          elm.classList.add('remove');
        }

      });
    },

    addAnnotation () {
      //get store
      let store = this.mode=='teacher'? this.annotations:this.studentAnnotations;

      //get insert index for UI list (student annotations are preceded by teacher annotations)
      let index = this.mode=='teacher'? store.length:this.annotationsList.length;

      //generate new id: current highest id + 1
      let id = store.length? store.map(a => parseInt(a.id.substring(1))).reduce((a, b) => Math.max(a, b)) + 1:0;
      id = this.mode.substring(0,1)+id;

      //auto select next color
      let color = index? this.annotationsList[index-1].color+1:this.defaultAnnotationColor;
      if (color>=this.annotationColors.length) color = 0;

      //insert new annotation
      store.push({
        id:id,
        color:color,
        text:'',
        editable:true,
        edit:true,
        empty:this.isAnalysing? true:false
      })

      //set active state
      this.activeAnnotation = id;

      //switch to add-selection mode on next update
      this.$nextTick(() => {
        this.addSelection = true;

        //focus text area in Compare tool
        if (this.isComparing) this.$refs.annotations.$el.getElementsByTagName('TEXTAREA').item(0).focus(); //there's only one as the other edits are auto-closed
      });

      //push new state
      if (!this.isAnalysing) this.store()
    },

    setActiveAnnotation (index,a) {
      this.activeAnnotation = a.id;
      if (a.editable) this.editAnnotation(index,true)
    },

    editSelection (id) {
      //switch to annotation edit when selection is edited
      const index = this.annotationsList.findIndex(a => a.id==id);
      this.editAnnotation(index,true);
    },

    editAnnotation (index,value) {
      //set edit state for annotation at index, all others default to false
      this.annotationsList.forEach((a,i) => {
        if (a.edit && !a.empty && i!=index) this.store() //always store when leaving edit-mode //->review this
        a.edit = i==index? value:false;
      })

      if (value)
      {
        this.activeAnnotation = this.annotationsList[index].id;

        //auto-focus textarea?
        //..
      }
      else
      {
        this.addSelection = false;
        this.store();
      }
    },

    removeEmptyAnnotation(selections) {
      //remove active annotation if it doesn't have any selections
      let hasSelection = selections.filter(s => s.a==this.activeAnnotation);
      if (!hasSelection.length)
      {
        const index = this.annotationsList.findIndex(a => a.id==this.activeAnnotation);
        this.removeAnnotation(index,this.activeAnnotation,true);
        return true;
      }
      return false;
    },

    removeAnnotation (index,id,force) {

      this.addSelection = false;

      //remove annotation list element with transition
      const remove = () => {

        this.isRemoving = true;

        const elm = this.$refs.annotation[index].$el;

        //set explicit height so we can animate it
        elm.style.height = elm.offsetHeight + 'px';

        //first move offscreen
        let remove;
        elm.addEventListener('transitionend',remove = (e) => {
          if (e.target==elm)
          {
            //then set zero height so remaining list elms animate in place, triggers another transitionend
            elm.classList.add('hide');

            //transitions done, remove from store
            if (e.propertyName=='height')
            {
              //reset DOM element, might still be use by Vue
              elm.removeEventListener('transitionend',remove)
              elm.classList.remove('remove','hide');
              elm.style.height = '';

              //remove related selections
              this.removeSelections(this.annotationsList[index].id);

              //remove annotation
              if (this.mode=='student') index -= this.annotations.length; //student annotations are preceded by teacher annotations
              const annotations = this.mode=='student'? 'studentAnnotations':'annotations';
              this[annotations].splice(index,1);

              //reset active annotation
              this.activeAnnotation = '';

              //store changes
              this.store(true);

              this.isRemoving = false;
            }
          }
        })

        //start transition
        elm.classList.add('remove');

      }

      if (force) remove()
      else
      {
        //confirm first
        this.$q.dialog({
          title: '<i class="fa fa-trash"></i>&nbsp;Confirm Remove',
          message: '<p>Removing annotation</p><p>Are you sure?</p>',
          html: true,
          cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
          ok: { label: 'Yes', color: 'primary', noCaps: true, }
        }).onOk(remove);
      }
    },

    removeSelections (id) {
      //remove all selections related to an annotation
      const selections = this.mode=='student'? 'studentSelections':'selections';

      this[selections] = this[selections].map(selections => {
        return selections.filter(s => s.a!=id)
      });
    },

    addSelectionMode (active,id) {
      this.addSelection = active;
      if (active) this.activeAnnotation = id;
      else if (this.activeAnnotation && this.isAnalysing) this.removeEmptyAnnotation(this.selections[0]);
    },

    resetActiveAnnotation () {
      if (this.addSelection) this.exitAddSelection(true)
      else this.activeAnnotation = '';
    },

    stopEditing () {
      this.$refs.source.forEach(function(source) {
        source.resetSelection()
      })
    },

    exitAddSelection (e) {
      if (e==true || e.key=='Escape')
      {
        if (this.isComparing) this.addSelection = false
        else
        {
          //exit add mode in Analysing: remove newly created annotation
          const index = this.annotationsList.findIndex(a => a.id==this.activeAnnotation);
          this.removeAnnotation(index,this.activeAnnotation,true);
        }
      }
    },

    closeInstructions () {
      this.$emit('closeInstructions');
      if (this.mode=='teacher') this.store(true);
    },


    //vuex

    store (save) {
      //update activity or submit answer data
      if (this.mode=='teacher')
      {
        this.storeRecord(save)
      }
      else
      {
        if (!this.editor) this.storeAnswer();
      }
    },

    storeRecord (save) {
      //send record to store, we clone the local arrays otherwise the cleanup mapping is applied on them as well
      this.$store.commit('activity/setRecord', {
        block: this.blockId,
        record: {
          images:this.sources,
          instructions: this.instructions,
          annotations:clone(this.annotations).map(this.cleanUp),
          selections:clone(this.selections).map(s => s.map(this.cleanUp))
        }
      });

      if (save) this.$store.dispatch("activity/save");
    },

    storeAnswer () {
      this.$emit('answer',{
        annotations:clone(this.studentAnnotations).map(this.cleanUp),
        selections:clone(this.studentSelections).map(s => s.map(this.cleanUp))
      });
    },

    cleanUp (v) {
      //remove dislay properties before pushing to data store
      delete v.editable;
      delete v.edit;
      delete v.empty;
      delete v.active;
      delete v.teacher;
      return v;
    },


    //DOM event handling

    onResize (s) {
      this.toolWidth = s.width; // we need to set explicit scrolling container width as q-scroll-area does not display the scroller when using widht=% (Vue bug?)

      //get container position for mousemove
      const { x,y } = this.$el.getBoundingClientRect();
      this.canvasLeft = x;
      this.canvasTop = y;
    },

    onScroll () {
      if (this.scrolling) clearTimeout(this.scrolling)
      this.isScrolling = true;
      this.scrolling = setTimeout(function() {
        this.isScrolling = false;
      }.bind(this),300)
    },

    onMouseMove (e) {
      this.mx = e.clientX - this.canvasLeft + 5;
      this.my = e.clientY - this.canvasTop + 15;
    },

  },

};
</script>

<style scoped lang="stylus">
@import '~quasar-variables'

/* canvas layout*/

#bblock-compare {
  position: relative;
  background-color: $white;
  color:$black;
  cursor: default;
  overflow:hidden;
}
#sources {
  position:relative;
  display:flex;
  min-width:100%;
  height:100%;
  transition:padding .3s ease 0s;
}
#sources:not(.removing) .source
{
  transition:width .3s ease 0s;
}
.q-scrollarea
{
  position: absolute;
  width:100%;
  height:100%;
}

/* source select placeholder */

.guide {
  position:absolute;
  left:20px;
  right:20px;
  top:100px;
  text-align:center;
  z-index:1;
}
.placeholder
{
  position:relative;
}
.placeholder:first-child
{
  background-color:#f2f2f2;
}
.placeholder.active::before
{
  position:absolute;
  left:18px;
  top:15px;
  color:$medium-gray ;
  content:'\F067';
  font-family:'Font Awesome 5 Pro';
  font-weight: bold;
  font-size:30px;
}
.placeholder:hover
{
  border-color:$primary;
  /* opacity:.8; */
}
.placeholder:hover::before
{
  color:$primary;
}

/* annotations panel */

#annotations
{
  position:absolute;
  right:0;
  width:240px;
  height:100%;
  background-color:rgba(0,0,0,.825);
  box-shadow: -2px 0 5px rgba(0,0,0,.2);
  transition:transform .3s ease 0s;
  z-index:1500;
}
#annotations.collapsed
{
  transform:translateX(190px);
}
#annotations-header
{
  padding:6px 15px 6px 13px;
  height:50px;
}
#annotations.collapsed #annotations-header
{
  padding-left:4px;
}
#annotations-list
{
  height:calc(100% - 50px);
}
.annotation
{
  padding-bottom:12px;
}
.annotation.remove
{
  /* move offscreen transtion */
  transition:transform .4s ease 0s;
  transform:translateX(100%);
}
.annotation.remove .head
{
  /* explicit height on annotation breaks vertical layout, this rule prevents that */
  height:1.2em;
}
.annotation.remove.hide
{
  /* collapse list transition */
  transition:all .2s ease 0s;
  height:0 !important;
  min-height:0;
  padding:0;
  overflow:hidden;
}

.annotation:last-child
{
  margin-bottom:50px;
}
.annotation:not(.active)
{
  opacity:.7;
}
.annotation.active > >>> .q-focus-helper
{
  background:currentColor;
  opacity:.15;
}

.annotation .fa-comment
{
  font-size:22px;
}
.annotation .text
{
  margin-top:10px;
  color:$white;
}
.annotation .text >>> textarea
{
  padding-top:4px;
  font-size:16px;
  line-height:1.2em;
  letter-spacing: normal;
}
.cursor .fa-plus
{
  position:absolute;
  left:7px;
  top:6px;
  font-size:12px !important;
}
.add-selection >>> .fa-plus
{
  font-size:.6rem;
}
.add-selection .fa-vector-square
{
  position:absolute;
  font-size:1.25rem;
}

.color
{
  margin:10px 10px 0 0;
  display:block;
  width:18px;
  height:18px;
  border-radius:50%;
  border:2px solid transparent;
  opacity:.8;
}
.color.active
{
  margin-top:9px;
  border-color:$white;
  opacity:1;
  width:20px;
  height:20px;
}
.color:hover
{
  opacity:1;
}


/* add-annotation cursor */

.cursor
{
  position:absolute;
  font-size:24px;
  text-shadow: 0 1px 1px rgba(0,0,0,.3);
  z-index:2001;
}

/* annotation text tooltip */

.tooltip
{
  position:absolute;
  padding:6px 14px 14px 14px;
  max-width:500px;
  color:$white;
  transition:none;
}

.tooltip >>> .fa-chalkboard-teacher
{
  margin:0 -8px 0 6px;
  opacity:.7;
}
</style>