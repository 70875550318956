<template>
  <!-- wrapper for BlockEditor and EaViewer | NOT the start page of the Builder -->
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
    name: "EActivity"
}
</script>

<style lang="stylus">
@import '../styles/eActivity';

/* dedicated style for the full screen BlockEditor and EaViewer */

/* default block size */
.ea-canvas
  width: 100%;
  min-height: calc(100vh - 140px);

@media (min-height:769px)
  .ea-canvas
    min-height: calc(100vh - 160px)

@media (min-height:1000px)
  .ea-canvas
    min-height: calc(100vh - 180px)

/* logo & top navigation */

#ea-identity {
  padding-top:30px;
  color:$white
}
#ea-identity h3 {
  margin:12px 15px;
  font-weight: 300;
}
#ea-logo
{
  position:relative;
  width:100px;
  height:100px;
  margin-bottom:1px;
  background-color:#f2f2f2;
}
#ea-logo > i
{
  position:absolute;
  left:20px;
  top:30px;
  transform: translate(-50%, -50%);
  font-size:24px;
}
#ea-logo > img
{
  width:100%;
  height:100%;
  object-fit:cover;
  object-position:50% 30%;
}

#ea-student-action
{
  color:$white;
  font-size:15px;
  margin:0 0 2px 20px
}
#ea-student-action .label
{
  margin-top:-2px;
}

#ea-navigation
{
  position:absolute;
  left:0;
  top:0;
  transform:translateY(-100%);
  /* margin-top:100px; */
}

.q-tooltip.ea-tooltip
  font-size: 14px
  background-color: rgba(0,0,0,.75)

.ea-block-title
  margin: 0 0 25px 0

.ea-block-title.editor
  margin-left 12px

.is-ea-viewer {
  background-color: $hardroze;
}

.ea-logo-default
{
  color:$hardroze;
}

#ea-loading
  position:absolute;
  left:0;
  top:0;
  right:100px;
  bottom:0;
  background-color:rgba(254,254,254,.75)


/* prevent label animation when tool data is loading */
.bblock.is-loading .q-field--float .q-field__label {
  transition:none !important;
}


/* increase icon size in buttons */
.ea .q-btn .fa,
.ea .q-btn .fas,
.ea .q-btn .far,
.ea .q-btn .fal
  font-size: 1.5em;

.q-card
  color $black;

.q-card.ea-help
  width 700px
  max-width 80vw

/* pagination with numbers on top left of canvas */
#sequence-navigator li
  width 36px
  min-height 36px
  margin-right 1px
  background-color $dark-gray
  color $white
  -o-transition 0.5s ease-out
  -ms-transition 0.5s ease-out
  -moz-transition 0.5s ease-out
  -webkit-transition 0.5s ease-out
  transition 0.5s ease-out
  &:hover
    background-color $white
    color $medium-gray
  &.bb-active
    background-color $white
    a
      color $medium-gray
  a
    font-weight 700
    font-size 1.2em
    color $white
    padding 0.7rem 0.7rem
    &:hover
      color $dark-gray
  .fa-check
    font-size 10px
    margin 3px 0 0 1px


/* white canvas */
#workbench
  position:relative
  background-color $white
  color $black
  padding 3%

.is-bb-analysing #workbench
  padding 0

#workbench #drag-drop-area {
  height: 240px;
}

// .buildingblock-intro
.bblock
	width 100%

.bblock
.title
.description
    // padding 0 4px
    // border 1px dashed #9c9c9c
    background-color transparent
    outline none
    -webkit-appearance none
    &:focus
      // border 1px dashed #dd0077

.title
  width 100%
  height auto
  min-height 3em
  margin-bottom 0.5rem
  padding 0 12px

.description
  height auto
  // min-height 12.8em
  font-size inherit

.menu.horizontal
  -webkit-flex-wrap nowrap
  -ms-flex-wrap nowrap
  flex-wrap nowrap


/* navbar = suare buttons on the right side of the canvas */
// #navbar ul
//   position relative
//   width 100px
//   list-style none
//   margin-left 0
//   li
//     position relative
//     width 100px
//     height 100px
//     margin-bottom 1px
//     background-color #9c9c9c
//     color #f5f5f5
//     transition 0.5s ease-out
//     &:before
//       font-family 'Font Awesome 5 Pro'
//       font-size 24px
//       font-weight 900
//       position absolute
//       left 20px
//       top 30px
//       -webkit-transform translate(-50%, -50%)
//       -ms-transform translate(-50%, -50%)
//       transform translate(-50%, -50%)

// #navbar ul li.manual
//     &:before
//       content '\f128'
//     a
//       color #fefefe

// #navbar ul li.settings
//   &:before
//     content '\f013'
//   a
//     color #fefefe

// #navbar ul li.delete
//   &:before
//     content '\f014'
//   a
//     color #fefefe

// #navbar ul li.share
//   &:before
//     content '\f1e0'
//   a
//     color #fefefe

// #navbar ul li.close
//   &:before
//     content '\f00d'

// #navbar ul li.next
//   background-color #595959
//   color #fefefe
//   &:before
//     content '\f061'
//   &:hover
//     background-color #fefefe
//     color #595959

// #navbar ul li.previous
//   background-color #595959
//   color: #fefefe
//   &:before
//     content '\f060'
//   &:hover
//     background-color #fefefe
//     color #595959

// #navbar ul li.duplicate:before {
//   content: '\f24d';
// }

// #navbar ul li.saveBlock {
//   background-color: #595959;
//   color: #fefefe;
// }

// // fa-check-cirle
// #navbar ul li.saveBlock:before {
//   font-weight: normal;
//   content: '\f058';
// }

// #navbar ul li.submit_eLA:hover {
//   background-color: #fefefe;
//   color: #595959;
// }

// #navbar ul li.submit_eLA:before {
//   content: '\f1d8';
// }

// #navbar ul li.submit_eLA:hover {
//   background-color: #fefefe;
//   color: #595959;
// }

// #navbar ul li.tool {
//   background-color: #ff6600;
//   color: #fefefe;
// }

// #navbar ul li.tool::before {
//   content: '\F067';
// }

// #navbar ul .text {
//   font-weight: bold;
//   font-size: 11px;
//   text-transform: uppercase;
//   position: absolute;
//   left: 10px;
//   bottom: 10px;
//   right: 10px;
// }

// #navbar li:hover {
//   webkit-filter: grayscale(100%);
//   -moz-filter: grayscale(100%);
//   -ms-filter: grayscale(100%);
//   -o-filter: grayscale(100%);
//   -webkit-filter: grayscale(100%);
//   filter: grayscale(100%);
// }

// #navbar ul li:hover {
//   background-color: #fefefe;
//   color: #595959;
// }
</style>
