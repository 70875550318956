<template>
  <div
    class="related"
    :class="{ 'active':active }"
  >

    <!-- intro view -->

    <q-scroll-area
      v-if="view=='start'"
      visible
      :thumb-style="{ opacity: 1 }"
    >
      <q-btn
        v-if="related.length"
        class="count"
        dense
        round
        unelevated
        color="plasticgroen"
        text-color="appelgroen"
        :label="related.length"
        @click="toggleActive()"
      />
      <div class="row" style="margin-left:50px">
        <card
          v-for="(card, i) in related"
          :key="'related_'+i"
          :index="i"
          :title="card.type"
          type="Source"
          csstype="source"
          style="margin-right:16px"
        />
      </div>
    </q-scroll-area>


    <!-- summary view -->

    <div
      v-if="view=='summary'"
      class="related-items"
      v-for="(r,i) in relatedByChapter"
      :style="{ 'min-height':summaryOffsets[i] + 56 + 'px' }"
    >
      <q-btn
        v-if="r.length"
        class="count"
        dense
        round
        unelevated
        color="plasticgroen"
        :outline="i!=activeSummary"
        :text-color="i==activeSummary? 'appelgroen':'plasticgroen'"
        :label="r.length"
        @click="toggleActive(i)"
      />
      (chapter={{i+1}} rel={{r.length}})
    </div>


    <!-- read view -->

    <!-- additional header in edit view -->

    <div
      v-if="edit"
      class="side-panel-header"
    >
      <div
        class="nav-block small button toggle"
        @click.stop="showBuilder"
      ><i class="far fa-project-diagram"/>
      </div>
      <h2 class="ellipsis">Linked items</h2>
      <q-btn
        class="close"
        flat
        round
        dense
        style="position:absolute; right:10px"
        color="appelgroen"
        size="12px"
        icon="fa fa-times"
        @click.stop="showBuilder"
      />
    </div>

    <!-- related items by chapter -->

    <div
      v-if="view=='read'"
      class="related-items-chapter"
      v-for="(r,chapter) in relatedByChapter"
      :style="{ 'min-height':chapterHeight(chapter) +'px' }"
    >
      <!-- related directly on chapter -->

      <div
        v-if="relatedOnChapter(chapter).length"
        class="related-items-node"
        :class="{ active:isActive(chapter,-1) }"
      >
        <q-btn
          class="count"
          dense
          round
          unelevated
          color="appelgroen"
          :outline="!isActive(chapter,-1)"
          :text-color="isActive(chapter,-1)? 'plasticgroen':'appelgroen'"
          :icon="relatedIcon(relatedOnChapter(chapter))"
          :label="relatedLabel(relatedOnChapter(chapter))"
          @click="toggleActive(chapter,-1)"
        />
        <transition name="slide-related">
          <div class="cards" v-if="isActive(chapter,-1) && !cardsHidden">
            <div
              class="card-wrapper"
              v-for="(card, i) in relatedOnChapter(chapter)"
              :key="'card_'+i"
            >
              <card
                :title="card.title"
                :description="card.description"
                :image-url="card.image"
                :type="card.type"
                :csstype="cardCSS(card.type)"
                :url="edit? null:card.url"
                :target="card.type=='download' && !edit? '_blank':null"
                style="margin-right:16px;"
              />
              <div
                v-if="edit"
                class="nav-block small action unlink"
                @click.stop="removeRelated(chapter,-1,i)"
              ><i class="fa fa-unlink"/></div>
            </div>
          </div>
        </transition>
      </div>

      <!-- related on subschapter / node in chapter -->

      <div
        v-for="group,nodeIndex in relatedByNode[chapter]"
        class="related-items-node"
        :class="{ active:isActive(chapter,group[0].nodeIndex) }"
        :style="{ top:relatedNodePosition(chapter,group[0]) }"
      >
        <q-btn
          class="count"
          dense
          round
          unelevated
          color="appelgroen"
          :outline="!isActive(chapter,group[0].nodeIndex)"
          :text-color="isActive(chapter,group[0].nodeIndex)? 'plasticgroen':'appelgroen'"
          :icon="relatedIcon(group)"
          :label="relatedLabel(group)"
          @click="toggleActive(chapter,group[0].nodeIndex)"
        />
        <transition name="slide-related">
          <div class="cards" v-if="isActive(chapter,group[0].nodeIndex) && !cardsHidden">
            <div
              class="card-wrapper"
              v-for="(card, i) in group"
              :key="'card_'+i"
            >
              <card
                :title="card.title"
                :description="card.description"
                :image-url="card.image"
                :type="card.type"
                :csstype="cardCSS(card.type)"
                :url="edit? null:card.url"
                :target="card.type=='download && !edit'? '_blank':null"
                style="margin-right:16px;"
              >{{card.description}}</card>
              <div
                v-if="edit"
                class="nav-block small action unlink"
                @click.stop="removeRelated(chapter,group[0].nodeIndex,i,group[0].sub,group[0].subNode)"
              ><i class="fa fa-unlink"/></div>
            </div>
          </div>
        </transition>
      </div>
    </div>

  </div>
</template>

<script>
import Card from '../tags/Card';
import cardCSS from '../mixins/cardCSS.mixin.js';
//import { ref, computed, watch } from '@vue/composition-api';

export default {
  name: "NarrativeIndex",

  props: {

    view: {
      type: String,
      default: null
    },

    edit: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    },

    narrative:{
      type:Object,
      default:() => {}
    },

    relatedByChapter:{
      type:Array,
      default:() => []
    },

    summaryOffsets:{
      type:Array,
      default:() => []
    },

    chapterOffsets:{
      type:Array,
      default:() => []
    },

    chapterNodeOffsets:{
      type:Array,
      default:() => []
    },

    activeChapter:{
      type:Number,
      default:0
    },

    activeChapterNode:{
      type:Number,
      default:0
    },

    activeSubChapter:{
      type:Number,
      default:0
    },

    lastScroll:{
      type:Number,
      default:0
    }

  },

  setup (props, context) {
  },

  components: {
    Card
  },

  mixins: [cardCSS],

  data () {
    return {
      activeSummary:0,
      selectedChapter:0,
      selectedNode:this.edit? null:-1,
      cardsHidden:false,
    }
  },

  computed: {
    related () {
      //flat list of all related items (not by chapter)
      return this.relatedByChapter.flat()
    },

    relatedByNode () {
      //list of related items by chapter, grouped by related node
      let related = [];

      this.relatedByChapter.forEach(c => {

        let group = [], nodeIndex, groupedByNode = []

        c.forEach(item => {

          if (item.nodeIndex!=nodeIndex)
          {
            //insert new group
            group = [];
            groupedByNode.push(group);
            nodeIndex = item.nodeIndex;
          }
          group.push(item);
        })

        related.push(groupedByNode)

      })

      return related;
    },

  },

  watch: {
    activeChapterNode () {

      if (this.edit && !this.active) return;
      //if (this.edit) return; //skip auto select in edit view

      const
        chapter = this.activeChapter,
        node = this.activeChapterNode-1;

      //console.log(this.lastScroll>0,'scroll node change:','active=',node,'selected=',this.selectedNode,'active=',chapter,'selectedChapter=',this.selectedChapter)

      let update = true;

      //do not update when: //TODO add more sophisticated rules below
      //-> selected node is below new node and scroll direction is forward
      //->
      if (this.lastScroll>0 && (node<this.selectedNode && chapter==this.selectedChapter)) update = false;
      if (this.lastScroll>0 && chapter<this.selectedChapter) update = false;
      //console.log('->update=',update)

      if (update)
      {
        this.selectedChapter = this.activeChapter;

        if (node<=-1)
        {
          //show direct chapter relations
          if (this.relatedOnChapter(this.selectedChapter).length) this.selectedNode = -1;
        }
        else
        {
          //only update selected when there's a match at new index
          let match = this.relatedByNode[this.selectedChapter].filter(group => group[0].nodeIndex==node);
          if (match.length) this.selectedNode = node;
        }
      }
    }
  },

  mounted () {
    console.log('📙 Narrative-related mounted')
  },

  methods: {

    toggleActive (index,node) {
      this.$emit('setActive')
      if (this.edit) this.$emit('setSidePanelHidden',true)

      //summary view
      if (index!=undefined) this.activeSummary = index

      //read view: set active chapter / node
      this.selectedChapter = index;
      this.selectedNode = node;
    },

    showBuilder () {
      //return to builder in edit view, reset selected items
      this.$emit('setSidePanelHidden',false);
      this.selectedNode = null;
    },

    isActive (chapter,node) {
      return this.selectedChapter==chapter && this.selectedNode==node;
    },

    chapterHeight (index) {
      //calculate chapter heights based on their y offsets
      if (index<this.chapterOffsets.length-1) return this.chapterOffsets[index+1] - this.chapterOffsets[index]
      else return 100;
    },

    relatedOnChapter (index) {
      return index==-1? []:this.relatedByChapter[index].filter(r => r.nodeIndex==undefined);
      //console.log('relatedOn',index)
      //return items related directly on chapter[index]

    },

    relatedIcon (items) {
      return items.length==1? 'far fa-project-diagram':undefined;
    },

    relatedLabel (items) {
      return items.length>1? items.length:undefined;
    },

    removeRelated (chapter,node,index,sub,subNode)
    {
      //remove related from chapter / node in (sub)chapter
      this.$emit('removeRelated',chapter,node,index,sub,subNode)
    },

    relatedNodePosition (chapter,item) {
      let offset = -25; //top padding of narrative body

      if (item.nodeType=='paragraph') offset += 3;
      if (item.nodeType=='subchapter') offset += 10;

      return this.chapterNodeOffsets[chapter]? this.chapterNodeOffsets[chapter][item.nodeIndex] + offset + 'px':0;
    },



  }
}
</script>

<style lang="stylus" scoped>
@import '~quasar-variables'

.related
  padding:150px 0 50px 50px;
  min-width:300px;

.related >>> .q-scrollarea
  margin-left:-50px;
  height:calc(100vh - 150px);

.related >>> .q-scrollarea .count
  margin-left:12px;

.related >>> .q-scrollarea__thumb
  background:$plasticgroen;

.related-items-chapter
  position: relative;
  padding-top:0px;
  min-height:250px;
  transition:min-height .5s ease 0s;

.related-items-node
  position:absolute;
  top:0;
  left:0;
  width:100%;

.related-items-node.active
  z-index:1;

.related:not(.active) >>> .q-card
  box-shadow:none;

.count
  float:left;
  font-size:13.5px;
  margin:0 10px 0 -38px;
  font-weight:700;

.count >>> .q-btn__wrapper
  min-width:26px !important;
  min-height:26px !important;

.count >>> .q-icon
  font-size:14px;

.cards
  display:flex;
  flex-wrap:wrap;

.slide-related-enter-active
  transition: transform var(--related-slide-speed) var(--fast-ease) 0s;

.slide-related-leave-active
  transition: opacity .25s ease 0s;

.slide-related-enter
  transform: translateX(100%);

.slide-related-leave-to
  opacity:0;


/* edit view */

.related-edit
  position:absolute;
  top:0;
  padding-left:0;
  min-width:0;
  width:calc(100vw - 910px - 150px);
  right:calc((100vw - 910px - 150px) * -1);

.related-edit .side-panel-header
  width:calc(100vw - 910px - 150px);

.related-edit .side-panel-header .toggle
  padding-left:0;

.related-edit .cards
  max-width:100%;
  overflow:hidden;
  padding-left:16px;
  padding-top:16px;
  margin-top:-16px;

.related-edit
  .slide-related-enter,
  .slide-related-leave-to
    transform:none;

.card-wrapper
  position:relative;

.card-wrapper  .action.unlink
  position:absolute;
  left:0;
  bottom:1.4rem;
  z-index:1;

.card-wrapper:not(:hover) .action.unlink
  visibility:hidden;

</style>