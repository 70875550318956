<template>
  <!-- Index for /teaching-learning -->
  <div class="teaching-learning" v-if="!$q.loading.isActive">
    <!-- ===== START NAVIGATION TEACHING LEARNING | LAYOUT: DEFAULT  ===== -->
    <!-- ===== SEARCH FIELD ===== -->

    <hi-search
      v-model="searchFor"
      placeholder="Find ideas and ready-to-use resources for your classroom practice"
    />

    <!-- hi-theme-filter component -->
    <!-- <hi-themefilter context="TL"/> -->

    <!-- hi-filter component -->
    <hi-filter :types="types" @setFilter="setFilter" />

    <!-- <section id="filter">
      <div class="row q-mx-auto justify-between items-center q-gutter-sm">
        <div class="row items-center q-gutter-md">
          <div class="col">
            <h4>Filter on</h4>
          </div>

          <q-btn
            no-caps
            :class="{active: activeFilter==='All'}"
            @click="doFilter('all')"
            label="All"
          />

          <q-btn
            no-caps
            :class="{active: activeFilter==='la'}"
            @click="doFilter('la')"
            label="Learning Activities"
          />

          <q-btn
            no-caps
            :class="{active: activeFilter==='ela'}"
            @click="doFilter('ela')"
            label="e-Learning Activities"
          />

        </div>
      </div>
    </section> -->

    <!-- ===== END NAVIGATION T&L | START BODY CONTENT ===== -->

    <section id="card-holder">
      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md">
          <div
            :key="i"
            v-for="(card, i) in filteredSources(activeFilter)"
            :id="card.activity.uuid"
          >
            <!-- current card is owned by current user -->
            <Card
              v-if="
                card.owner.uuid === $store.getters['user/uuid'] &&
                  $store.getters['user/isAuthenticated']
              "
              :index="i"
              :item="card"
              :title="card.activity.title"
              :image-url="card.icon !== '/ua/null' ? card.icon : card.image_url"
              :uuid="card.activity.uuid"
              :tags="card.tags"
              :has_tags="card.has_tags"
              :btn-share="false"
              :btn-edit-url="'/ela/' + card.activity.uuid"
              :btn-student-view="
                card.typecode === 'ela'
                  ? `/ea/view/${card.activity.uuid}/`
                  : card.url
              "
              :btn-delete="true"
              :btn-publish="true"
              :goto="
                card.typecode === 'ela'
                  ? `/ea/view/${card.activity.uuid}/`
                  : card.url
              "
              :type="card.type"
              :typecode="card.typecode"
              url=""
              csstype="ela"
              @goShowSharing="showShareModal(card.activity.uuid)"
              @deleteCard="deleteCard"
              @publishCard="publishCard"
              @unpublishCard="unpublishCard"
              :is-owner="true"
              :show-meta-button="true"
              :meta="meta(card)"
              class="animated slideInUp fast"
            >
              <span v-if="card.typecode === 'la'">
                {{ card.activity.summary }}
              </span>
              <span v-if="card.typecode === 'ela'">
                {{ card.activity.description }}
              </span>
            </Card>
            <Card
              v-else
              :index="i"
              :target="'_blank'"
              :is-owner="false"
              :item="card"
              :title="card.activity.title"
              :image-url="card.icon !== '/ua/null' ? card.icon : card.image_url"
              :uuid="card.activity.uuid"
              :tags="card.tags"
              :has_tags="card.has_tags"
              :btn-add="
                (card.typecode === 'ela' ? true : false) &&
                  $store.getters['user/isAuthenticated']
              "
              :btn-student-view="
                card.typecode === 'ela'
                  ? `/ea/view/${card.activity.uuid}/`
                  : card.url
              "
              :type="card.type"
              :typecode="card.typecode"
              :url="
                card.typecode === 'ela'
                  ? `/ea/view/${card.activity.uuid}/`
                  : card.url
              "
              :show-meta-button="true"
              :meta="meta(card)"
              csstype="teachingstrategies"
              @addMyHistoriana="addMyHistoriana"
              class="animated slideInUp fast"
            >
              <span v-if="card.typecode === 'la'">
                {{ card.activity.summary }}
              </span>
              <span v-if="card.typecode === 'ela'">
                {{ card.activity.description }}
              </span>
            </Card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import tlLearningActivitiees from '../components/LearningActivities'
import { API } from '../tic';
import Card from '../tags/Card';
// import Modal from '../tags/Modal'
import $ from 'jquery';
export default {
  name: 'TeachingAndLearning',
  components: { Card },

  mounted() {
    this.$q.loading.show({
      delay: 100 // ms
    });

    API.post('/get-teaching-learning', {
      user: this.$store.getters['user/uuid']
    }).then(r => {
      this.sources = r.data.data;
      this.$q.loading.hide();
    });
  },

  methods: {
    meta(item) {
      let fields = [
        { label: 'title', value: item.activity.title },
        { label: 'type', value: item.type },
        { label: 'slug', value: item.activity.slug },
        { label: 'uuid', value: item.activity.uuid }
      ];

      return {
        sectionTitle: 'Teaching and Learning',
        sectionType: 'teaching-learning',
        objectType: item.type,
        fields: fields
      };
    },

    filteredSources(filter) {
      let items = this.sources;
      if (this.searchFor) {
        items = items.filter(e => {
          // search in all property values; this needs more work
          // as deeper property-names are part of the string now
          let item = JSON.stringify(Object.values(e)).toLowerCase();
          return item.includes(this.searchFor.toLowerCase());
        });
      }

      switch (filter) {
        case 'All':
          return items;
          break;

        case 'e-Learning Activities':
          return items.filter(e => {
            return e.typecode === 'ela';
          });
          break;

        case 'Learning Activities':
          return items.filter(e => {
            return e.typecode === 'la';
          });
          break;
      }
    },

    setFilter(what) {
      this.activeFilter = what;
      this.searchFor = null;
    },

    deleteCard(index) {
      let me = this;
      API.post('/delete-activity', {
        user: this.$store.getters['user/uuid'],
        activity: this.sources[index].activity.uuid
      }).then(
        resp => {
          if (resp.data.status === 'ok') {
            me.$store.dispatch('user/getActivities');
            API.post('/get-teaching-learning', {
              user: this.$store.getters['user/uuid']
            }).then(r => {
              me.sources = r.data.data;
            });
            me.$store.dispatch('sendFeedback', {
              msg: 'Activity deleted',
              status: 'ok'
            });
          } else {
            me.$store.dispatch('sendFeedback', {
              msg: 'Error deleting activity',
              status: 'nok'
            });
          }
        },
        err => {
          console.log('ERROR', err);
        }
      );
    },
    publishCard(item, idx) {
      let me = this;
      API.post('/publish-activity', {
        user: this.$store.getters['user/uuid'],
        uuid: item.activity.uuid
      }).then(resp => {
        me.$store.commit('user/setActivityPublished', idx);
      });
    },
    unpublishCard(item, idx) {
      let me = this;
      API.post('/unpublish-activity', {
        user: this.$store.getters['user/uuid'],
        uuid: item.activity.uuid
      }).then(resp => {
        me.$store.commit('user/setActivityUnPublished', idx);
      });
    },
    addMyHistoriana(item, index) {
      API.post('/ea/add-to-my-historiana', {
        member: this.$store.getters['user/uuid'],
        activity: item.activity.uuid
      }).then(r => {
        if (r.data.isError) {
          this.$alert(r.data, r.data.msg);
        }
      });
    }
  },

  data() {
    return {
      sources: [],
      activeFilter: 'All',
      searchFor: null,
      types: ['Learning Activities', 'e-Learning Activities']
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.selected {
  text-decoration: underline;
}
</style>
