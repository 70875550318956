<template>
  <section id="hiSubnav">
    <div class="row q-mx-auto justify-between items-center">
      <div class="col q-pb-md q-pt-lg">
        <router-link
          class="subnavlink left"
          :key="i"
          v-for="(c, i) in childs"
          :to="c.url"
          >{{ c.label }}</router-link
        >
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "HiSubnav",
  props: {
    childs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style>
.subnavlink {
  color: #9c9c9c;
}
.subnavlink:hover {
  color: #dd0077;
}
#hiSubnav a.router-link-active {
  color: #dd0077;
}
.is-select-sources .subnavlink:hover,
.is-select-sources #hiSubnav .router-link-exact-active {
  border-color: #0066cc;
}
.subnavlink {
  padding: 1em 0;
  margin-left: 0;
}
.subnavlink {
  color: #9c9c9c;
}
.subnavlink {
  padding: 1em;
  margin: 0em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 80%;
  color: #595959;
}
</style>