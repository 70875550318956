<template>
  <q-dialog
    :value="show"
    @hide="
      searchFor = '';
      selection = [];
      result = [];
      importer = [];
      $emit('hide');
    "
    full-width
    full-height
  >
    <q-card>
      <q-bar>
        <span class="text-h6">
          <b v-if="activeFilter">Search in {{ activeFilter }} on Europeana</b>
          <b v-else> Search on Europeana</b>
        </span>
        <q-avatar square size="28px">
          <img src="/europeana-icon.svg" class="icon_europeana" />
        </q-avatar>
        <q-space />
        <q-btn v-close-popup flat dense round icon="fas fa-times"
      /></q-bar>

      <zoom-image
        v-if="record._type === 'IMAGE' && record"
        :obj="obj"
        :record="record"
        :url="url"
        @hide="showZoom = false"
        :show="showZoom"
      />

      <zoom-video
        v-if="record._type === 'VIDEO' && record"
        :obj="obj"
        :record="record"
        :url="url"
        @hide="showZoom = false"
        :show="showZoom"
      />

      <eumeta
        v-if="record"
        :show="showMeta"
        :record="record"
        :obj="obj"
        @hide="showMeta = false"
      />

      <section id="hiSearch" class="light-gray row q-pa-md">
        <q-input
          class="col-8"
          flat
          bottom-slots
          color="pink"
          type="search"
          clearable
          clear-icon="fas fa-times"
          input-class="search"
          style="max-width: 960px"
          placeholder="Search on Europeana for..."
          v-model="searchFor"
          @keydown="searchActive = true"
          @keyup.enter="go"
        >
          <template v-slot:prepend>
            <i class="fas fa-search" />
          </template>
        </q-input>

        <div id="actions" v-if="result" class="q-pa-md col-4">
          <q-btn
            :disable="!selection.length"
            color="primary"
            label="Add selection to MyHistoriana"
            no-caps
            style="float: right"
            @click="addSelection"
          />
        </div>
      </section>

      <section id="filter" v-if="result.length" class="q-pa-md">
        Show:
        <q-checkbox
          v-model="filterType"
          :val="e"
          :label="e"
          :key="i"
          v-for="(e, i) in types"
        />
      </section>

      <section id="card-holder" v-if="result.length">
        <q-scroll-area style="height: 80vh">
          <div
            class="row q-mx-auto justify-start items-start q-gutter-md"
            id="collections"
          >
            <div :key="i" v-for="(card, i) in filteredResults" class="column">
              <Card
                :item="card"
                :title="card.title"
                :thumb="card.url"
                :uuid="card.uuid"
                type="Europeana source"
                :eutype="card._type"
                csstype="euresult"
                :showAddToMyHistoriana="false"
                :btnSelect="true"
                @toggleSelect="select(card.uuid)"
                :activeSelection="selection"
                @cardClicked="click(card)"
                :btnMeta="true"
                @showMeta="clickMeta"
              >
                {{ card.description }}
              </Card>
            </div>
          </div>
        </q-scroll-area>
      </section>
      <section v-else-if="!searchActive && searchFor.length > 0">
        <p class="row q-mx-auto justify-center text-h3">No results</p>
      </section>
    </q-card>
  </q-dialog>
</template>
<script>
import axios from "axios";
import { mapState, mapActions, mapGetters } from "vuex";
import { API } from "../tic";
import Card from "../tags/Card";
import zoomImage from "../components/zoomImage";
import zoomVideo from "../components/zoomVideo";
import eumeta from "../components/EUmeta";

export default {
  name: "SearchEuropeana",
  props: ["show"],
  components: { Card, zoomImage, zoomVideo, eumeta },

  mounted() {
    API.post("/partners/start-eusearch", {
      session: this.session,
    })
      .then((r) => {
        // API call returns Partner object or nothing, only setup if there is a valid reply
        if (r.data.data) {
          this.partner = r.data.data;
          // string to show in search dialog containing current QF data|provider
          this.activeFilter = [
            r.data.data.providers ? r.data.data.providers.join(" ,") : null,
            r.data.data.dataproviders
              ? r.data.data.dataproviders.join(" ,")
              : null,
          ]
            .filter((e) => e !== null)
            .join(", ");
        }
      })
      .catch((e) => {
        window.alert("API REPORTS: " + e.response.data.msg);
      });
  },

  data() {
    return {
      searchFor: "",
      result: [],
      importer: [],
      selection: [],
      url: "",
      showZoom: false,
      showMeta: false,
      obj: {},
      record: {},
      collectionName: "",
      activeFilter: "",
      partner: {},
      searchActive: false,
      collection: "",
      types: [],
      filterType: [],
    };
  },
  computed: {
    ...mapGetters("user", ["session"]),
    filteredResults() {
      // filter on the Europeana content-type
      return this.result.filter((e) => {
        return this.filterType.includes(e._type);
      });
    },
  },

  methods: {
    addSelection() {
      const vm = this;
      console.log("add selection: ", this.selection);
      console.log("route: ", this.$route);
      // this.collection =
      //  this.$route.name === "my-sources" ? "sources" : "collection";

      for (let k of this.selection) {
        let e = this.result.find((e) => e.uuid === k);
        this.importer.push(e);
      }

      if (this.$route.name === "my-sources") {
        vm.$q.loading.show({
          message: "Retrieving sources from Europeana...",
        });

        API.post("/partners/import", {
          dataset: this.importer,
          partner: this.partner.uuid,
          session: this.session,
          type: "sources",
        }).then((r) => {
          // import is done, reset
          this.result = [];
          this.dataset = [];
          this.importer = [];
          this.type = "sources";
          this.name = "";
          this.$q.loading.hide();
          vm.$q.notify({
            type: "positive",
            message: "Import done",
            position: "center",
          });
          vm.$emit("dirty");
          vm.$emit("hide");
        });
      }

      if (this.$route.name === "my-collections") {
        this.$q
          .dialog({
            title: "Prompt",
            message: "Name this collection",
            prompt: {
              model: "",
              type: "text", // optional
            },
            cancel: true,
            persistent: true,
          })
          .onOk((collectionName) => {
            // request collection-name from user, then call API
            // duplicate API call in else otherwise import is triggered
            // while still waiting for the user to input the collection-name

            vm.$q.loading.show({
              message: "Retrieving sources from Europeana...",
            });

            API.post("/partners/import", {
              dataset: this.importer,
              partner: this.partner.uuid,
              session: this.session,
              type: "collection",
              name: collectionName,
            }).then((r) => {
              // import is done, reset
              this.result = [];
              this.dataset = [];
              this.importer = [];
              this.type = "collection";
              this.name = "";
              vm.$q.loading.hide();
              vm.$q.notify({
                type: "positive",
                message: "Import done",
                position: "center",
              });
              vm.$emit("dirty");
              vm.$emit("hide");
            });
          });
      }

      /* this.$q
        .dialog({
          title: "Add selection to MyHistoriana",
          options: {
            type: "radio",
            // default to collection
            model: this.selection.length > 1 ? "collection" : "sources",
            inline: false,
            items: [
              {
                label: "Import as collection",
                value: "collection",
                color: "primary",
              },
              {
                label: "Import as individual sources",
                value: "sources",
                color: "primary",
                //disable: true,
              },
            ],
          },
          cancel: true,
          persistent: true,
        })
        .onOk((importType) => {
          // create seperate array with selected items
          for (let k of this.selection) {
            let e = this.result.find((e) => e.uuid === k);
            this.importer.push(e);
          }

          if (importType === "collection") {
            this.$q
              .dialog({
                title: "Prompt",
                message: "Name this collection",
                prompt: {
                  model: "",
                  type: "text", // optional
                },
                cancel: true,
                persistent: true,
              })
              .onOk((collectionName) => {
                // request collection-name from user, then call API
                // duplicate API call in else otherwise import is triggered
                // while still waiting for the user to input the collection-name

                vm.$q.loading.show({
                  message: "Retrieving sources from Europeana...",
                });

                API.post("/partners/import", {
                  dataset: this.importer,
                  partner: this.partner.uuid,
                  session: this.session,
                  type: importType,
                  name: collectionName,
                }).then((r) => {
                  // import is done, reset
                  this.result = [];
                  this.dataset = [];
                  this.importer = [];
                  this.type = "collection";
                  this.name = "";
                  vm.$q.loading.hide();
                  vm.$q.notify({
                    type: "positive",
                    message: "Import done",
                    position: "center",
                  });
                  vm.$emit("dirty");
                  vm.$emit("hide");
                });
              });
          } else {
            // if not a collection we can proceed without input of user
            API.post("/partners/import", {
              dataset: this.importer,
              partner: this.partner.uuid,
              session: this.session,
              type: importType,
            }).then((r) => {
              // import is done, reset
              this.result = [];
              this.dataset = [];
              this.importer = [];
              this.type = "sources";
              this.name = "";

              console.log("API RESP: ", r);

              vm.$q.notify({
                type: "positive",
                message: "Import done",
                position: "center",
              });
              vm.$emit("hide");
              vm.$emit("dirty");
            });
          }
        })
        .onCancel(() => {
          // console.log('>>>> Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
        */
    },

    clickMeta(card) {
      const vm = this;
      axios.get(card.eulink).then((r) => {
        vm.url = r.data.object.aggregations[0].edmIsShownBy;
        vm.obj = r.data.object;
        vm.record = { ...card };
        vm.showMeta = true;
      });
    },

    click(card) {
      // click on card to zoom image/view meta
      const vm = this;
      console.log("carD: ", card);
      console.log("GET: ", card.eulink);
      console.log("TYPE: ", card.eutype);
      //if (card._type === "IMAGE") {
      axios.get(card.eulink).then((r) => {
        vm.url = r.data.object.aggregations[0].edmIsShownBy;
        console.log("shown at: ", r.data.object.aggregations[0].edmIsShownBy);
        vm.obj = r.data.object;
        vm.record = { ...card };
        vm.showZoom = true;
      });
      //}
    },

    select(val) {
      console.log("select: ", val);
      if (this.selection.includes(val)) {
        // remove
        this.selection = this.selection.filter((e) => e !== val);
      } else {
        this.selection.push(val);
      }
    },

    go(val) {
      const vm = this;
      vm.searchActive = true;
      this.result = [];
      vm.$q.loading.show({ message: "Querying Europeana..." });
      API.post("/partners/eusearch", {
        search: this.searchFor,
        session: this.session,
      }).then((r) => {
        console.log("RES: ", r.data.data);
        this.result = r.data.data.results || [];
        this.types = r.data.data.types || [];
        this.filterType = [...this.types];
        vm.$q.loading.hide();
        vm.searchActive = false;
        //this.activeFilter = r.data.types
      });
    },
  },
};
</script>
<style>
.icon_europeana {
  filter: invert(1);
  --webkit-filter: invert(1);
}
</style>