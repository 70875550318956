<template>
  <div
    class="index"
    :class="[{
      active:indexActive,
      transition:indexTransition,
      edit:edit,
      'index-menu':indexMenu,
      'hide-menu':hideMenu
    },view]"
    @mouseleave="showIndex(false)"
  >
    <pre v-if="debug" class="debug">content={{localContent}}<br>chapters={{localChapters}}</pre>

    <div class="index-scroll hide-scrollbar" ref="scroll">
      <div v-if="view=='summary' && !indexTransition && !localChapters.length" class="hint">
        Add <span class="text-primary cursor-pointer" @click="$emit('addChapter')">chapter</span> or <span class="text-primary cursor-pointer" @click="$emit('read')">write</span> something
      </div>
      <div
        v-for="(c,i) in localChapters"
        class="chapter"
        :class="{
          collapsed:collapsed(i),
          focus:focusChapters[i],
          'cursor-pointer':!(view=='summary' && edit)
        }"
        ref="chapters"
        :key="'chapter'+i"
        @click="edit && view=='summary'? null:read(i)"
      >
        <div
          class="nav-block"
          :class="{ active:i==activeChapter && view=='read', 'no-icon':!c.icon }"
          @mouseover="showIndex(true)"
        >

          <!-- icon and index number -->

          <q-img
            v-if="c.icon"
            :src="c.icon.url"
            :ratio="1"
            spinner-color="plasticgroen"
          />
          <div class="number">
            <h2 class="nav-block small row justify-around items-center">{{i+1}}</h2>

            <!-- edit info / icon buttons -->

            <edit-button
              v-if="edit"
              class="absolute-bottom-left edit-summary"
              @click="summary(i)"
            >Edit chapter info</edit-button>

            <edit-button
              v-if="edit"
              class="absolute-bottom-left edit-icon"
              icon="fa fa-image"
              @click="addChapterIcon(i)"
            >Edit chapter image</edit-button>

          </div>
          <div v-if="view=='read'" class="progress absolute-top-right" :style="{ height:chapterProgress[i] * 100 +'%' }"/>
        </div>
        <div
          class="index-text"
          :class="{ 'no-transition':hideImmediate }"
          :style="{ minHeight:view=='summary'? summaryHeight[i] +'px':'unset' }"
        >

          <!-- title -->

          <q-input
            v-if="summaryEdit"
            class="summary-title"
            borderless
            dense
            v-model="c.title"
            @focus="focus=true"
            @blur="focus=false"
            @input="updateNarrative"
            placeholder="Chapter title"
          />

          <template v-else>
            <h2 :class="{ placeholder:!c.title.length }">{{c.title? c.title:'Chapter title'}}</h2>

            <!-- subschapters -->

            <div
              class="nav-block small sub justify-around items-center"
              v-if="indexActive && subChapters(c).length"
              @click.stop="toggleSub(i)"
            ><i class="fa" :class="['fa-chevron-'+(subIndexActive[i]? 'down':'right')]"/></div>
            <ul
              class="sub-chapters"
              :style="{ height:subChaptersHeight(c,i) }"
            >
              <li
                v-for="s,si in subChapters(c)"
                class="row items-center no-wrap"
                @click.stop="read(i,si)"
                :key="'subChapter'+si"
              >
                <div class="nav-block small row justify-around items-center">{{si+1}}</div>
                <h3>{{s.title}}</h3>
              </li>
            </ul>
          </template>

          <!-- summary content -->

          <narrative-editor
            :editable="edit"
            :pSelectable="false"
            ref="summary"
            class="index-summary"
            v-model="summaryContent[i]"
            placeholderText="Write a summary …"
            hideFloatingMenu
            :style="{
              maxHeight:summaryHeight[i]==-1? 'unset':summaryHeight[i] +'px',
            }"
            @focus="focusSummaryEditor(i)"
            @blur="focusSummaryEditor(i,true)"
          />

          <!-- edit content button (to read view) -->

          <edit-button
            v-if="edit"
            class="absolute-bottom-right write-chapter"
            icon="fa fa-pen-fancy"
            tooltip="left"
            @click="read(i)"
          >Write chapter content</edit-button>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { dom } from 'quasar';
import { clone,UseEase } from '../tic'
import { ref, computed, watch } from '@vue/composition-api';
import NarrativeEditor from './NarrativeEditor';
import EditButton from '../tags/EditButton';

const { height, width } = dom;

export default {
  name: "NarrativeIndex",

  props: {

    chapters:{
      type:Array,
      default:() => []
    },

    view: {
      type: String,
      default: null
    },

    edit:{
      type:Boolean,
      default:false
    },

    debug:{
      type:Boolean,
      default:false
    },

    activeChapter:{
      type:Number,
      default:0
    },

    chapterProgress:{
      type:Array,
      default:() => []
    },

    //force-hide the index menu
    hideIndex:{
      type:Boolean,
      default:false
    }

  },

  components: {
    NarrativeEditor,
    EditButton
  },

  data () {
    return {
      localContent:[],
      localChapters:[],
      hideMenu:false,
      hideImmediate:false,
      indexMenu:false,
      indexActive:false,
      indexTransition:false,
      subIndexActive:[],
      summaryHeight:[],
      summaryContent:[],
      summaryEdit:false,
      focusChapters:[]
    }
  },

  computed: {
  },

  watch: {
    view (to,from) {
      this.indexTransition = true;
    },

    edit () {
      this.summaryEdit = this.edit && this.view=='summary';
    },

    chapters: {
      handler() {
        //const hasChanged = JSON.stringify(this.chapters)!=JSON.stringify(this.localChapters);
        const hasChanged = JSON.stringify(this.chapters)!=JSON.stringify(this.localContent.concat(this.localChapters));

        console.log('📗 Narrative-index: chapters change',hasChanged);

        if (hasChanged)
        {
          //this.localChapters = clone(this.chapters);

          //remove non-chapter contents and clone to local data
          this.localChapters = clone(this.chapters.filter(c => c.type=='chapter'));

          //store non-chapter content as well
          this.localContent = clone(this.chapters.filter(c => c.type!='chapter'));

          //reset current max heights (when visible)
          if (this.view=='summary') this.resetSummaryHeight();

          //set tiptap editors content
          this.localChapters.forEach((c,i) => {
            const summary = this.localChapters[i].summary && this.localChapters[i].summary.length? this.localChapters[i].summary:[{ type:'paragraph' }]
            this.summaryContent[i] = {
              type:"doc",
              content:summary
            }
          });

          //force update the editors
          if (this.$refs.summary)
          {
            this.$nextTick(t => this.$refs.summary.forEach(e => e.updateContent()))
          }

          //async update max heights (so editors are ready)
          if (this.view=='summary') setTimeout(e => {
            this.setSummaryHeight();
          },100)

        }
      },
      immediate:true,
      deep:true
    },

    summaryContent: {
      handler() {
        this.$emit('unsaved');

        let chapters;

        //update chapters with new summary content
        this.localChapters.forEach((c,i) => {
          c.summary = this.summaryContent[i].content;
        })

        //notify parent
        this.updateNarrative();
      },
      deep:true
    }
  },

  mounted () {
    console.log('📗 Narrative-index mounted')

    this.$el.addEventListener('transitionend',(e) => {
      let c = e.target.classList;
      if (c.contains('index'))
      {
        this.indexTransition = false;
        this.$emit('showBuilderIndex')

        if (this.view=='summary')
        {
          this.setSummaryHeight();
          this.scrollToChapter();
        }

        //toggle editors after transition
        if (this.edit)
        {
          this.summaryEdit = this.view=='summary';
        }

        //??? should this be only in summary view?
        this.emitHeight()
      }
      if (c.contains('index-text') && !this.indexActive)
      {
        this.indexMenu = false;
      }
    });

    if (this.view=='summary')
    {
//      console.log('>> initial set height')
//       this.setSummaryHeight();
//       this.emitHeight();
      this.summaryEdit = this.edit;
    }

  },

  methods: {

    read(index,sub) {
      //enter read view
      if (this.view=='summary') this.$emit('read');

      //scroll to chapter
      this.$emit('readChapter',index,sub);
      this.showIndex(false);
    },

    summary(index) {
      //collapse menu without transition and switch to summary view
      this.indexMenu = false;
      this.hideImmediate = true;
      this.showIndex(false);
      this.$nextTick(e => {
        this.$emit('summary',index);
        this.hideImmediate = false;
      });
    },

    subChapters (c) {
      return c.content.filter(c => c.type=='subchapter');
    },

    subChaptersHeight (c,i) {
      return this.subIndexActive[i]? (this.subChapters(c).length * 51) -1 +'px':'0';
    },

    toggleSub(i,v) {
      this.$set(this.subIndexActive,i,v===undefined? !this.subIndexActive[i]:v);
    },

    collapsed(index) {
      return this.view=='read' && index<this.activeChapter && this.chapters.length>4;
    },

    showIndex(s) {
      //show/hide the index menu
      if (this.view!='read' || (s && this.hideIndex)) return;
      this.indexActive = s;
      if (s)
      {
        //expand
        this.indexMenu = true;
      }
      else
      {
        //collapse
        this.$refs.scroll.scrollTo(0,0);
        this.subIndexActive.splice(0);
      }
    },

    scrollToChapter() {

      if (!this.$refs.chapters) return;
      const elm = this.$refs.chapters[this.activeChapter];

      if (elm) setTimeout(e => {

        let top = elm.offsetTop;
        if (top==0) top = 1; //scroll to 1 to keep main menu hidden

        new UseEase(
          v => window.scrollTo(0,v.y),
          false,
          .15,
          20
        )
        .start({ y:[window.scrollY,top] })
      },150)
    },

    focusSummaryEditor(index,blur) {
      if (this.edit)
      {
        this.setSummaryHeight(index,blur? 0:-1)

        //set hover state of chapter
        this.$set(this.focusChapters,index,!blur)
      }
    },

    setSummaryHeight(index,offset) {

      //console.log('set summary height, index=',index,'offset',offset)

      if (this.$refs.summary)
      {
        this.$refs.summary
          .filter((e,i) => index!=undefined? i==index:true)
          .forEach((e,i) => {
            const h = height(e.$el);
            //console.log(i,'height=',h,e.$el)
            if (h>150)
            {
              //if (offset==undefined && this.edit) offset = -22;
              this.$set(this.summaryHeight,index!=undefined? index:i,offset!=undefined? offset==-1? -1:h+offset:h);
            }
          })

        if (index==undefined)
        {
          //console.log('* emit height to parent *')
          this.emitHeight()
        }
      }

      //console.log('->',this.summaryHeight.toString())
    },

    resetSummaryHeight() {
      //force clear the maxheights, as just clearing the array reactively does not seem to work
      this.summaryHeight.splice(0);
      if (this.$refs.summary)
      {
        this.$refs.summary.forEach(e => e.$el.style.maxHeight = 'unset')
      }
    },

    emitHeight() {
      //broadcast rendered content height to parent component
      this.$emit('setPageHeight',height(this.$el)+100+'px')
      this.$emit('summaryOffsets',this.summaryHeight)
    },

    addChapterIcon(index) {
      const options = {
        label: 'Add as chapter icon',
        max:1,
        action:(s) => {

          console.log('icon change @',index,s)

          const source = s[0];

          this.$set(this.localChapters[index],'icon',{
            uuid:source.uuid,
            url:source.url
          })

          this.updateNarrative();
        }
      }

      this.$emit('addSources',options);
      this.$emit('addMedia');
    },

    titleInput (e,title) {
      console.log(e,title);
    },

    updateNarrative() {
      console.log('📗 Narrative-index: push update to Narrative');

      //merge non-chapter content (when not empty) back into content array
      //const chapters = this.chapters.filter(c => c.type!='chapter' && c.content?.length>0).concat(this.localChapters);
      const chapters = this.localContent.concat(this.localChapters);

      this.$emit('edit',chapters);
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~quasar-variables'

.index.start
  display:none;

.index
  position:absolute;
  top:0;
  left:0;
  width:100%;
  max-width:910px;
  overflow:hidden;
  transition:transform var(--view-slide-speed) ease;
  z-index:900;

.index-scroll
  width:100%;
  transition:width var(--view-slide-speed) ease;

.chapter
  display:flex;
  width:calc(100% + 100px);
  padding-bottom:0;
  transition:padding-bottom var(--view-slide-speed) ease;

.index-text >>> h2
  font-size:1.5rem;
  font-weight:400;

.index-text >>> .ProseMirror p
  padding-left:0;
  padding-right:0;

h3
  font-size:1.25rem;
  font-weight:400;
  margin:0 0 0 20px;

.nav-block
  z-index:1;
  transition:
    width var(--view-slide-speed) ease,
    height var(--view-slide-speed) ease,
    margin-bottom var(--view-slide-speed) ease;
  transform-origin: 0 0;

.index.summary .chapter > .nav-block:not(.delete-chapter)
  margin-bottom:50px;

.nav-block > .progress
  width:4px;
  background-color:alpha($appelgroen,.6);
  z-index:1;

.nav-block .number
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  padding:8px 10px;
  opacity:0;

.nav-block .number .nav-block:not(.action)
  position:absolute;
  left:0;
  top:0;
  font-size: 1.625rem;
  font-weight: 500;
  background-color:alpha($plasticgroen,.9);
  color:$white;

.nav-block.no-icon
  background-color:$lighter-gray;

.nav-block.no-icon .number
  opacity:1;

.nav-block .number .nav-block.edit,
.nav-block .number .nav-block.delete
  display:none;
  align-items: center;
  justify-content: center;
  bottom:0;
  top:auto;

.chapter .edit-button
  display:none;

.chapter:not(:last-child) > .nav-block
  margin-bottom:1px;

.nav-block .number .nav-block.delete
  top:50px;
  bottom:auto;

.nav-block.read-chapter
  position:absolute;
  bottom:0;
  right:0;
  transition:opacity var(--view-slide-speed) ease;

.nav-block.delete
  position:absolute;
  left:0;
  bottom:0;

.nav-block .number .nav-block.edit:hover,
.nav-block .number .nav-block.delete:hover,
.nav-block.next:hover
  background-color:$white !important;
  color:$primary;

.index-text
  /* position:relative; */
  padding:6px 50px 0 25px;
  width:calc(910px - 200px);
  min-width:710px;
  transition:transform var(--view-slide-speed) ease;

.index-text.no-transition
  transition:none;

.index.transition .index-text
  min-height:0 !important;

.index-summary
  overflow:hidden;
  transition:
    opacity var(--view-slide-speed) ease,
    max-height var(--view-slide-speed) ease;


/* summary view */

.hint
  padding:50px;
  text-align:center;
  color:$medium-gray;

.index.summary .chapter
  position:relative;
  width:100%;

.index.summary  .nav-block .number
  transition:opacity var(--view-slide-speed) ease;
  opacity:1;

.index.summary:not(.transition) .chapter:hover
  background-color:alpha($appelgroen,.1)

.index.summary.edit:not(.transition) .chapter:hover,
.index.summary.edit:not(.transition) .chapter.focus
  background-color:alpha($primary,.03);

.index.summary .chapter > .nav-block:not(.delete-chapter)
  width:200px;
  height:200px;

.index.summary:not(.transition) .index-summary
  min-height:calc(150px);
  /* background-color:rgba(250,0,0,.1) */

.index.summary .chapter:not(:hover),
.index.summary.transition,
.index.summary.slide-in-leave-active
  .nav-block.next
    visibility:hidden;

.index.summary h2.placeholder
  color:alpha($black,.22);

.index-text >>> .summary-title
  height:36px;
  margin-bottom:8px;

.index-text >>> .summary-title .q-field__native
  font-size:1.5rem;
  line-height: 2.25rem;
  letter-spacing: -0.00833em;
  font-family:"Ubuntu", Arial, Helvetica, sans-serif;
  padding:0;

.index-text >>> .ProseMirror p::before
  background-color:transparent !important;

.index.summary .index-text
  padding-bottom:6px;

.chapter
  .read-chapter,
  .delete-chapter
    display:none;

/*
.index.summary:not(.transition)
  .chapter:hover,
  .chapter.focus
    .read-chapter,
    .delete-chapter,
    .edit-button
      display:block;
 */

.index.summary:not(.transition)
  .chapter:hover
  .chapter.focus
    .edit-icon,
    .delete-chapter,
    .write-chapter
      display:block;


.chapter .delete-chapter
  right:50px;

.nav-block .number .nav-block.edit-icon
  position:absolute;
  left:0;
  bottom:0;



/* read view index (menu) */

.index.read
  position:fixed;
  left:inherit;
  margin-top:100px;
  transform:translateX(-100px);
  transform-origin:0 0;
  pointer-events:none;

.index.read:not(.transition)
  transition:margin $menu-transition;

.index.read .index-scroll
  width:calc(50% + 100px);

.chapter.collapsed:not(:hover) .nav-block
  height:50px;

.chapter.collapsed:not(:hover) .nav-block.sub
  display:none;

.index.read .index-summary
  max-height:0 !important;
  opacity:0;

.index.read .index-text
  transform:translateX(-100%);

.index.read .nav-block.next
  display:none;

.index.read .nav-block,
.index.read .index-text
  cursor:pointer;
  pointer-events:all;

.index.read .nav-block.active .number
  transition:opacity var(--view-slide-speed) ease;
  opacity:1

.index.read .nav-block.active .nav-block
  background-color:$plasticgroen;
  /* background-color:$grasgroen; */

.index.read .nav-block.no-icon:not(.active) .number .nav-block:not(.edit)
  background-color:transparent;
  color:$plasticgroen;



/* expanded menu */

.index.index-menu .chapter
  width:100%;

.index.active .nav-block
  transition:none;

.index.active .index-scroll
  /* allow menu to be scrolled */
  height:calc(100vh - 100px);
  overflow-y:auto;
  overflow-x:hidden;
  transition:backdrop-filter .5s ease;
  pointer-events:auto;
  /* -webkit-overflow-scrolling: touch; */

.index-menu h2
  font-size:1.25rem;
  font-weight:500;

.index.active .chapter:hover
  .index-text
    background-color:$appelgroen;
    color:$plasticgroen;
    border-bottom:1px solid $plasticgroen;

.index.active .chapter:last-child
  .index-text
    border-bottom:none;

.index.active .index-text
  min-width:0 !important;
  transform:translateX(0);

.index.index-menu .index-text
  min-width:0;
  transition-duration:var(--menu-slide-speed);
  background-color:$plasticgroen;
  color:$white;

.index.read:not(.hide-index).nav-block:hover .number,
.index.active .chapter:hover .number
  opacity:1;

.index.active .chapter:hover .number .nav-block
  background-color:$appelgroen;

.index.index-menu .nav-block.next
  display:none;

/*
.index.read .chapter:hover .nav-block
  .edit
    display:flex;
    background-color:alpha($white,.6);
    color:$primary
 */

.index.index-menu
  .chapter:hover
    .edit-summary
      display:block;




/* sub chapters */

.nav-block.sub
  display:none;
  margin-left:-25px;
  padding:10px;
  font-size:18px;

.chapter:hover .nav-block.sub
  display:flex;
  background-color:transparent !important;
  color:$plasticgroen;

.chapter:hover .nav-block.sub:hover
  color:$white;

.sub-chapters
  display:block;
  overflow:hidden;
  margin:0 -25px 0 -25px;
  padding:0;
  list-style:none;
  transition:height .2s ease;

.sub-chapters .nav-block
  background-color:$white;
  color:$plasticgroen;
  font-weight:600;

.sub-chapters h3
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;

.sub-chapters li
  display:flex;
  margin-bottom:1px;

.sub-chapters li:hover
  color:$white;

.sub-chapters li:hover .nav-block
  background-color:$plasticgroen;
  color:$white;

.chapter:hover .sub-chapters
  display:block;
</style>