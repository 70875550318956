<template>
  <q-dialog :value="showDialog" @hide="$emit('hide')">
    <q-card style="min-width: 400px" class="q-pa-md q-gutter-sm">
      <q-card-section>
        <h4><i class="fa fa-unlock">&nbsp;</i>Reset password</h4>
      </q-card-section>
      <q-card-section>
        <p>
          Enter your email and we will sent you an email reset-password mail:
        </p>
        <label>
          <q-input type="text" v-model="email" placeholder="Your E-mail" />
        </label>
      </q-card-section>
      <q-card-actions>
        <q-btn q-sm no-caps size="small" label="Cancel" v-close-popup />
        <q-btn no-caps color="primary" @click="do_reset">Reset password</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { API } from '../tic';

export default {
  name: 'ResetPassword',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: ''
    };
  },

  methods: {
    do_reset() {
      let vm = this;

      API.post('/reset-password', {
        email: this.email,
        location: document.location.origin
      })
        .then(response => {
          if (response.data.isError) {
            vm.$q.dialog({
              title: 'Error:',
              message: `${response.data.msg}`
            });
          } else {
            vm.$q.dialog({
              title: 'Mail sent',
              message: 'If you do not receive it soon check your spambox!'
            });
          }
        })
        .catch(e => {
          vm.$q.dialog({
            title: 'Error:',
            message: `${e.response.data.msg}`
          });
          vm.login = '';
        });
    }
  }
};
</script>
