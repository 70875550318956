<template>
  <div class="box center">
    <div class="row">
      <div class="col-2">
        <!-- ===== LOGO ===== -->
        <!-- <img class="logo svg" src="/static/interface-img/H/Logo-H.svg" /> -->
        <svg
          style="max-height: 100px;"
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 72.89 100"
          class="logo svg replaced-svg"
          fill="#dd0077"
        >
          <path
            class="cls-1"
            d="M53.36,0V26.86H19.53V0H0V100H19.24l4.47-24.23h-.07L19.82,63.93h0a17.19,17.19,0,1,1,33.24,0h0L49.26,75.77h-.08L53.65,100H72.89V0Z"
          />
        </svg>
      </div>

      <div class="col-8 q-ml-xs" style="align-self: flex-end;">
        <div style="vertical-align:bottom">
          <h3>Password reset</h3>
        </div>
      </div>
    </div>

    <form v-if="requestValid">
      <input type="hidden" name="username" value="username" />
      <div class="row first">
        <div class="col">
          <q-input
            label="New password"
            type="password"
            autocomplete="new-password"
            v-model="p1"
          />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <q-input
            label="Repeat password"
            type="password"
            autocomplete="new-password"
            v-model="p2"
          />
        </div>
      </div>

      <div class="row q-pt-xl">
        <div class="col">
          <p class="text-red" v-if="p1.length > 0 && p1.length < 6">
            Minimal password length is 6 characters.
          </p>

          <q-btn
            no-caps
            @click="setPassword"
            :disabled="getButtonState"
            color="green"
            label="Set new password"
          />
        </div>
      </div>
    </form>
    <div v-else>
      <div class="row first">
        <div
          class="column small-12 medium-9 large-9 small-order-2 medium-order-1 large-order-1"
        >
          <h3 style="color:red">Error</h3>
          <p>
            Your request could not be validated. If you think this is in error
            please send an e-mail to
            <a
              href="mailto:support@historiana.eu?subject=Password reset not working"
              >support@historiana.eu</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { API } from '../tic';
export default {
  name: 'ResetPassword',
  props: {
    challenge: {
      type: String,
      default: ''
    }
  },

  computed: {
    getButtonState() {
      //console.log("getbuttonstate: p1: ", this.p1, "p2: ", this.p2, " state: ", this.p1===this.p2)
      let valid = this.p1 === this.p2 && this.p1 && this.p2;
      return !valid;
    }
  },

  methods: {
    setPassword() {
      console.log('set pass');
      API.post('/validate-password-reset', {
        challenge: this.challenge,
        newpass: this.p1
      })
        .then(resp => {
          if (resp.data.isError) {
            window.eventBus.$emit('alert', 'Cannot change password.', 'Error');
          } else {
            window.eventBus.$emit(
              'alert',
              'Password changed successfully',
              'Info'
            );
            this.$router.push('/');
          }
        })
        .catch(resp => {
          // request could not be validated
          window.eventBus.$emit('alert', 'Cannot change password.');
        });
    }
  },

  mounted() {
    //console.log("mounted with: ", this.challenge)
    API.post('validate-request', {
      challenge: this.challenge
    })
      .then(r => {
        this.requestValid = true;
      })
      .catch(r => {
        // request could not be validated
        this.requestValid = false;
      });
  },

  data() {
    return {
      requestValid: false,
      p1: '',
      p2: ''
    };
  }
};
</script>

<style>
.box {
  padding: 4em;
  min-width: 600px;
  margin: auto;
  width: 50%;
}

.first {
  margin-top: 2em;
}
</style>
