<!-- popup editor for a Collection (H and Partners) -->
<!-- used in My > Collections -->
<template>
  <section>
    <q-dialog
      maximized
      :value="show"
      @hide="close"
      ref="collectionEditor"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <sourceEditor
        @hide="hideSourceEditor"
        @prev="prevSource"
        @next="nextSource"
        :record="currentRecord"
        :show="showSourceEditor"
        :licenses="licenses"
        :current="current"
        :total="items.length"
      />

      <!-- used by the iconic image button -->
      <q-uploader
        v-show="false"
        ref="uploadIconic"
        url="/api/upload"
        :headers="getHeaders(collection, 'collection-iconic')"
        :multiple="false"
        label="Iconic"
        @uploaded="processIconicUpload"
        auto-upload
        accept="image/*"
      />

      <q-card>
        <q-bar class="bg-hardroze text-white">
          Edit Collection &bull; {{ items.length }} item
          <span v-if="items.length > 1">s</span>
          <q-space />
          <q-btn
            flat
            round
            class="float-right text-white"
            icon="far fa-times"
            v-close-popup
          />
        </q-bar>
        <q-scroll-area style="height: 90vh">
          <div class="row q-pa-md">
            <div class="col-4">
              <q-img
                style="max-height: 31vh; max-width: 30vw"
                :src="getThumb()"
              >
                <div class="absolute-bottom text-subtitle1 text-center">
                  <q-btn
                    :disable="isEmpty(collection.thumb)"
                    round
                    icon="fa fa-trash"
                    @click="deleteIconic(collection)"
                  />
                  <q-btn
                    :disable="!isEmpty(collection.thumb)"
                    round
                    icon="fa fa-upload"
                    @click="$refs.uploadIconic.pickFiles()"
                  />
                </div>
              </q-img>
            </div>
            <div class="col q-pl-md q-gutter-md">
              <q-input
                autogrow
                filled
                v-model="collection.name"
                label="Name of collection"
              />
              <q-input
                v-model="collection.introduction"
                type="textarea"
                filled
                autogrow
                label="Introduction"
              />
              <q-input
                v-model="collection.description"
                type="textarea"
                filled
                autogrow
                label="Description"
              />
              <q-input
                v-model="collection.acknowledgements"
                type="text"
                filled
                label="Acknowledgements"
              />
              <hi-language v-model="collection.language" />
              <hi-license v-model="collection.license" :items="licenses" />
            </div>
          </div>
          <div id="card-holder">
            <!-- <h3 class="row q-pa-md text-white">Items in collection</h3> -->
            <q-btn
              size="md"
              icon="fas fa-plus"
              class="q-ma-md"
              no-caps
              label="Add item"
              color="primary"
              @click="$refs.uploader.pickFiles()"
            />

            <q-uploader
              v-show="false"
              ref="uploader"
              url="/api/upload"
              :headers="getHeaders(collection)"
              :multiple="true"
              label="Add item"
              @uploaded="processUpload"
              auto-upload
            />

            <div
              class="q-pa-md row items-start q-gutter-md"
              style="min-height: 100vh"
            >
              <!-- ALL ITEMS IN COLLECTION -->
              <div :key="i" v-for="(card, i) in items" class="column">
                <Card
                  type="Source"
                  csstype="source"
                  :title="card.title"
                  :thumb="card.url"
                  :item="card"
                  :index="i"
                  :itemCount="items.length"
                  transition-show="jump-up"
                  :btnEditModal="true"
                  :btnDelete="!card.is_published"
                  :btnPublish="!card.is_published"
                  :btnUnPublish="card.is_published"
                  :btnSelect="true"
                  :btnEdit="true"
                  :btnUseAsCover="true"
                  :showOrdering="true"
                  :activeSelection="selected"
                  @toggleSelect="toggleSelect"
                  @showEdit="editSource(card, i)"
                  @useCover="setCover(card)"
                  @left="moveLeft"
                  @right="moveRight"
                >
                  {{ card.introduction }}</Card
                >
              </div>
            </div>
          </div>
        </q-scroll-area>

        <div class="row">
          <q-card-actions class="q-pa-md q-mx-auto">
            <q-btn
              v-if="selected.length"
              no-caps
              color="primary"
              @click="deleteItem(selected)"
              :label="`Delete ${selected.length} items`"
            />

            <q-btn no-caps v-close-popup label="Cancel" />
            <q-btn
              no-caps
              color="primary"
              @click="saveCollection()"
              label="Save"
            />
          </q-card-actions>
        </div>
      </q-card>
    </q-dialog>
  </section>
</template>
<script>
import { API } from "../tic";
import HiLicense from "@/components/hiLicense";
import HiLanguage from "@/components/hiLanguage";
import sourceEditor from "../components/SourceEditor";
import Card from "../tags/Card";

export default {
  name: "CollectionEditor",
  components: { HiLicense, HiLanguage, sourceEditor, Card },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    collection: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // get values for hi-license select
      licenses: [],
      iconic: [],
      items: [],
      selected: [],
      showSourceEditor: false,
      currentRecord: {},
      current: 0,
    };
  },
  mounted() {
    API.get("/select/copyright").then((r) => {
      this.licenses = [...r.data];
    });
  },
  watch: {
    collection: {
      immediate: true,
      handler() {
        if (this.collection && this.collection.uuid) {
          this.iconic = this.collection.thumb_uuid;
          console.log(
            "→ load items for collection: ",
            this.collection.uuid,
            this.collection.slug
          );
          API.post(`collection/${this.collection.slug}`).then((r) => {
            console.log("ITEMS FROM API: ", r.data.data.items);
            this.items = [...r.data.data.items];
            this.items.map((e) => (e.introduction = e.introduction || ""));
          });
        }
      },
    },
  },

  methods: {
    moveLeft(index) {
      // move card to left
      const item = this.items[index];
      this.items.splice(index, 1);
      this.items.splice(index - 1, 0, item);
    },

    moveRight(index) {
      // move card to left
      const item = this.items[index];
      this.items.splice(index, 1);
      this.items.splice(index + 1, 0, item);
    },

    toggleSelect(uuid) {
      if (this.selected.includes(uuid)) {
        this.selected = this.selected.filter((e) => e != uuid);
      } else {
        this.selected.push(uuid);
      }
    },

    // current is offset 0; UI is offset 1
    prevSource() {
      if (this.current >= 1) {
        this.current--;
        this.editSource(this.items[this.current]);
      }
    },

    nextSource() {
      if (this.current < this.items.length - 1) {
        this.current++;
        this.editSource(this.items[this.current]);
      }
    },

    hideSourceEditor() {
      this.showSourceEditor = false;
    },

    editSource(item, offset) {
      this.currentRecord = { ...item };
      this.showSourceEditor = true;
    },

    // this handler used to be in MyCollection.vue
    // moved here to be more self-contained
    saveCollection() {
      let sortOrder = [];
      let index = 0;
      for (const e of this.items) {
        sortOrder.push({ uuid: e.uuid, order: index++ });
      }

      API.post("/collection/update", { ...this.collection, order: sortOrder })
        .then((r) => {
          this.$q.notify({
            type: "positive",
            position: "top",
            message: "Saved changes",
          });
        })
        .catch((err) => {
          // console.log('XXXX: ', err.request);
          // console.log('===== ', typeof err.request.response);
          let resp = JSON.parse(err.request.response);
          this.$q.dialog({
            title: '<i class="fa fa-bomb"></i>&nbsp;Error',
            message: `${resp.data.msg}`,
            html: true,
            ok: { label: "Ok", color: "primary", noCaps: true },
          });
        });

      this.close();
    },

    close() {
      this.selected = [];
      this.$emit("closeEditor");
    },

    getThumb() {
      return this.collection.thumb && this.collection.thumb.url
        ? this.collection.thumb.url
        : "/No-Image-Placeholder.svg";
    },

    deleteIconic(item) {
      API.post("/collection/delete-iconic", { collection: item.uuid }).then(
        (r) => {
          this.collection.thumb = {};
        }
      );
    },

    processIconicUpload(reply) {
      console.log("**** iconic new upload", reply);
      const thumb = JSON.parse(reply.xhr.response);
      console.log("t: ", thumb.data);
      this.collection.thumb = thumb.data;
    },

    processUpload() {
      // reload the items for this collection after upload
      API.post(`collection/${this.collection.slug}`).then((r) => {
        this.items = [...r.data.data.items];
      });
    },

    getHeaders(collection, context = "collection-item") {
      return [
        {
          name: "X-Session",
          value: this.$store.getters["user/session"],
        },
        { name: "X-Process", value: context },
        { name: "X-Collection", value: collection.uuid },
      ];
    },

    setCover(item) {
      const vm = this;
      console.log("setCover > iconic now: ", this.iconic);
      console.log("coll: ", this.collection);
      console.log("set cover to ", item);
      API.post("/collection/set-iconic-image", {
        collection: this.collection.uuid,
        image: item,
      })
        .then((r) => {
          // force reload of Collections in MyCollections
          vm.collection.thumb = {
            url: item.url,
            uuid: item.uuid,
          };

          this.$emit("isDirty");
        })
        .catch((error) => {
          console.log("error resp: ", error);
        });
    },

    saveItem(item) {
      console.log("save item: ", item);
      API.post("/collection/update-item", { item: item }).then((e) => {
        this.$q.notify({
          type: "positive",
          position: "top",
          message: "Saved changes",
        });
      });
    },

    up(item) {
      // move item one up
      // TODO: connect to back-end
      let i = this.items.findIndex((x) => x.uuid === item.uuid);
      this.items.splice(i, 1);
      this.items.splice(i - 1, 0, item);
    },

    down(item) {
      // move item one down
      // TODO: connect to back-end
      let i = this.items.findIndex((x) => x.uuid === item.uuid);
      this.items.splice(i, 1);
      this.items.splice(i + 1, 0, item);
    },

    deleteItem(item) {
      // delete a CollectionItem from the Collection (click-on-trash)
      console.log("item: ", item);
      console.log(typeof item);
      console.log("len: ", item.length);

      let vm = this;
      vm.$q
        .dialog({
          title: "Confirm",
          message:
            item.length === 1 ? "Delete this item?" : "Delete these items?",
          cancel: true,
        })
        .onOk(() => {
          API.post(`collection/delete-source`, {
            collection: this.collection.uuid,
            item: item,
          })
            .then((resp) => {
              // reload the data from API
              API.post(`collection/${this.collection.slug}`).then((r) => {
                vm.items = [...r.data.data.items];
              });
            })
            .catch((error) => {
              // log details on console, give generic feedback in UI
              console.log("ERROR IN BACKEND ", error.response.data.data.msg);
              vm.$q.dialog({
                title: "Error",
                message: "Error in back-end, cannot continue.",
              });
            });
        });
    },
  },
};
</script>
