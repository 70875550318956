<template>

  <div
    ref="container"
    class="discovery-zoomer"
    @mousedown.prevent="onMouseDown"
    @mouseup="onMouseUp"
    @mousemove="onMouseMove"
    @touchstart="onTouchStart"
    @touchend="onTouchEnd"
    @touchmove="onTouchMove"
    @wheel="onWheel"
  >
    <q-resize-observer @resize="containerPosition" />

<!--
    <div class="debug">
      pan={{ isPanning }}
      scale={{ scale.toFixed(4) }} {{ animScale.toFixed(4) }}, tx={{ transX.toFixed(4) }} {{ animX.toFixed(4) }}, ty={{ transY.toFixed(4) }}, pointerX={{ pointerX }}, pointerY={{ pointerY }}<br>
      <!~~ {{ transform }} ~~>
      <!~~ {{ selectedNodes }} ~~>
    </div>
 -->

    <div class="zoomer" ref="zoomer" :style="{ transform:transform }">
      <slot />
    </div>

    <div class="controls items-center" v-if="controls" @mousedown.stop>
      <q-btn round color="primary" @click.stop="zoom(zoomIn,true)" icon="far fa-search-plus" :disabled="this.scale===this.maxScale"><q-tooltip>Zoom in</q-tooltip></q-btn>
      <q-btn round color="primary" @click.stop="zoom(zoomOut,true)" icon="far fa-search-minus" :disabled="this.scale===this.minScale"><q-tooltip>Zoom out</q-tooltip></q-btn>
      <!-- <q-btn round color="primary" @click.stop="reset" icon="far fa-search" :disabled="this.scale===1"><q-tooltip>Actual size</q-tooltip></q-btn> -->
      <!-- <q-btn round color="primary" @click.stop="resetZoom" icon="far fa-search" :disabled="this.scale===1"><q-tooltip>Actual size</q-tooltip></q-btn> -->
      <q-btn round color="primary" @click.stop="fit()" icon="far fa-expand"><q-tooltip>Fit entire map</q-tooltip></q-btn>
<!--
      <q-input filled v-model="filter" label="Selected nodes" class="q-mx-md" size="8" @keyup.enter="fit(selectedNodes)"/>
      <q-btn no-caps xcolor="primary" @click.stop="fit(selectedNodes)" label="Zoom to node(s)"/>
      <q-btn no-caps xcolor="primary" @click.stop="fit(selectedNodes,true)" label="Pan to node(s)"/>
 -->
    </div>

  </div>
</template>

<script>

export default {
  name: 'DiscoveryZoomer',

  components:{
  },

  props:{
    controls: {
      type: Boolean,
      default: false
    },
    wheelBlocked: {
      type: Boolean,
      default: false
    },
    nodes:{
      type:Array,
      default:() => []
    },
    minScale:{
      type:Number,
      default:.25
    }
  },

  data () {
    return {

      filter:'1',

      isPanning:false,
      zoomIn:2,
      zoomOut:.5,
      zoomPadding:50, //px around map when fitted

      //minScale:.25,
      maxScale:5,
      scale:1,
      transX:0,
      transY:0,

      pointerX:0,
      pointerY:0,
      lastPointer:{
        x:0,
        y:0
      },

      animX:0,
      animY:0,
      animScale:1,

      w:1000, //natural width
      h:1000,
      container: {
        x:0,
        y:0,
        w:0,
        h:0
      }
    }
  },

  computed:{
    containerElm () {
      return this.$refs.container;
    },

    transform() {
      //sync parent
      this.emitUpdate()

      //apply transform
      return 'translate('+(this.animX * this.container.w)+'px, '+(this.animY * this.container.h)+'px) scale('+this.animScale+')';
    },

    displayScaleX() {
      return this.w/this.container.w
    },

    displayScaleY() {
      return this.h/this.container.h
    },



    selectedNodes () {
      return this.nodes.filter((node,index) => this.filter.split(',').includes(String(index)))
    }

  },

  watch:{

  },


  mounted() {
    this.containerPosition();
    this.animate()
  },

  destroyed() {

  },

  methods: {

    zoom(scaleDelta,reset,target) {

      if (reset) this.resetPointerPosition()

      let newScale = this.scale * scaleDelta

       // damping //->REVIEW THIS, allows to scale beyond limits
//       if (newScale < this.minScale || newScale > this.maxScale) {
//         let log = Math.log2(scaleDelta)
//         log *= 0.2
//         scaleDelta = Math.pow(2, log)
//         newScale = this.scale * scaleDelta
//       }

      //cap scale
      newScale = this.limitScale(newScale);
//       newScale = Math.max(this.minScale,newScale);
//       newScale = Math.min(this.maxScale,newScale);

      scaleDelta = newScale / this.scale
      this.scale = newScale

      if (target)
      {
        //zoom with target position
        this.transX = target.x;
        this.transY = target.y;
      }
      else
      {
        //use pointer position as zoom center
        const mx = (this.pointerX - this.container.x) / this.container.w
        const my = (this.pointerY - this.container.y) / this.container.h
        this.transX = (0.5 + this.transX - mx) * scaleDelta + mx - 0.5
        this.transY = (0.5 + this.transY - my) * scaleDelta + my - 0.5
      }
    },

    resetZoom() {
      this.zoom(1/this.scale,true)
    },

    fit(nodes,nozoom) {

      if (!nodes) nodes = this.nodes;
      if (!nodes.length)
      {
        //empty canvas, reset zoom
        if (typeof(nozoom)=='number') this.zoom(nozoom/this.scale,true);
        return;
      }

      //HORIZONTAL
      let rangeX = nodes.map(n => n.x);
      let minX = Math.min(...rangeX); //most left node
      let maxX = Math.max(...rangeX); //most right node
      maxX += Math.max(...(nodes.filter(n => n.x==maxX).map(n => n.w))); //add (largest) width to right node
      let deltaX = maxX - minX;// + (this.zoomPadding/this.w);

      //VERTICAL
      let rangeY = nodes.map(n => n.y);
      let minY = Math.min(...rangeY); //most top node
      let maxY = Math.max(...rangeY); //most bottom node
      maxY += Math.max(...(nodes.filter(n => n.y==maxY).map(n => n.h + (n.edit? .13:(n.text? .05:0)) ))); //add (largest) height to bottom node ->NEEDS REVIEW: we need the lowest point, not the tallest height
      let deltaY = maxY - minY;// + (this.zoomPadding/this.h);

      //calculate target scale
      let scale = this.scale;
      if (!nozoom)
      {
        //pick smallest scale
        scale = Math.min((this.container.w - this.zoomPadding) / (deltaX * this.w), (this.container.h - this.zoomPadding) / (deltaY * this.h));

        //cap it
        scale = this.limitScale(scale);
      }
      else if (typeof(nozoom)=='number')
      {
        //set zoom to specified number
        scale = nozoom;
      }

      //calculate target position
      let tx = (minX + (deltaX/2)) * scale * this.displayScaleX;
      let ty = (minY + (deltaY/2)) * scale * this.displayScaleY;

      this.zoom(
        scale/this.scale,
        false,
        { x:-tx, y:-ty }
      );

    },

    limitScale(s) {
      return Math.min(this.maxScale,Math.max(this.minScale,s))
    },

    reset() {
      this.scale = 1;
      this.transX = 0;
      this.transY = 0;
    },

    refresh() {
      //trigger display refresh by decreasing animScale a tiny bit
      this.animScale -= .0001;
    },

    animate() {
      this.animScale = this.transition(this.animScale, this.scale)
      this.animX = this.transition(this.animX, this.transX)
      this.animY = this.transition(this.animY, this.transY)

      //->TO DO: stop animating when values are same

      this.animating = window.requestAnimationFrame(this.animate)
    },

    transition (from, to) {
      let delta = (to - from) * 0.2

      if (Math.abs(delta) > 1e-5) {
        return from + delta
      } else {
        return to
      }
    },

    pointerPosition (x,y) {

      if (this.isPanning)
      {
        this.transX += (x - this.pointerX) / this.container.w
        this.transY += (y - this.pointerY) / this.container.h
      }

      this.pointerX = x
      this.pointerY = y
    },

    resetPointerPosition() {
      this.pointerX = this.container.x + this.container.w / 2
      this.pointerY = this.container.y + this.container.h / 2
    },

    containerPosition () {
      if (!this.containerElm) return console.log('... no container elm'); //->review when this happens, maybe only by dev hot-reload

      const { x, y, width, height } = this.containerElm.getBoundingClientRect();
      this.container = { x:x, y:y, w:width, h:height }

    },

    emitUpdate () {
      this.$emit('update',{
        x:(this.container.w / 2) + (this.animX * this.container.w),
        y:(this.container.h / 2) + (this.animY * this.container.h),
        w:this.w * this.animScale,
        h:this.h * this.animScale,
        z:this.animScale
      })
    },





    //DOM event handling

    onWheel (e) {
      //track vertical only, allow override by wheelBlocked prop
      if (!this.wheelBlocked && e.deltaY)
      {
        e.preventDefault(); //prevent scroll while tracking
        //this.refreshContainerPos();
        this.containerPosition()
        this.onMouseWheelDo(-e.deltaY);
      }
    },
    onMouseWheelDo (wheelDelta) {
      // Value basis: One mouse wheel (wheelDelta=+-120) means 1.25/0.8 scale.
      let scaleDelta = Math.pow(1.25, wheelDelta / 120)
      this.zoom(scaleDelta)
      //this.onInteractionEnd()
    },

    onMouseDown (e) {
      this.panStart(e.clientX,e.clientY)
    },

    onMouseUp (e) {
      this.isPanning = false;
      this.clickEnd(e,e.clientX,e.clientY)
    },

    onMouseLeave () {
      this.isPanning = false;
    },

    onMouseMove (e) {
      this.pointerPosition(e.clientX,e.clientY);
    },

    onTouchStart (e) {
      if (e.touches.length==1)
      {
        //panning
        this.panStart(ev.touches[0].clientX,ev.touches[0].clientY)
      }
      else if (e.touches.length==2) {
        //pinch zoom
        //->TO BE DONE
      }
    },

    onTouchEnd (e) {
      this.isPanning = false
      this.clickEnd()
    },

    onTouchMove (e) {
      if (e.touches.length==1)
      {
        //panning
        this.pointerPosition(ev.touches[0].clientX,ev.touches[0].clientY)
      }
      else if (e.touches.length==2) {
        //pinch zooming
        //->TO BE DONE
      }
    },

    panStart (x,y) {
      this.containerPosition() //->needed?

      this.isPanning = true
      this.pointerPosition(x,y)
      this.clickStart()
    },

    clickStart() {
      //store pointer position for click detection
      this.lastPointer = {
        x:this.pointerX,
        y:this.pointerY
      }
    },

    clickEnd(e,x,y) {
      //emit click event when position hasn't changed
      let dx = this.lastPointer.x - x;
      let dy = this.lastPointer.y - y;
      if (Math.abs(dx)<3 && Math.abs(dy)<3)
      {
        this.$emit('click',e,x - this.container.x,y - this.container.y)
      }
    }

	}
}

</script>

<style scoped lang="stylus">

.debug
  position:absolute;
  padding:4px;
  background-color:rgba(0,0,0,.75);
  color:magenta;
  z-index:1000

.discovery-zoomer
  overflow:hidden;

.zoomer
  position:absolute;
  left:50%;
  top:50%;
  margin:-1000px 0 0 -1000px
  width:2000px;
  height:2000px;
  /* background-color:rgba(250,0,0,.1) */

.controls
  position: absolute;
  left: 10px;
  bottom: 15px;
  display:flex;
  z-index:1000;

.controls button
  margin-left:6px;


</style>
