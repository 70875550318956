<!--
Example: 
  
  <alert>An <b>alert</b> which is removeable</alert>
-->
<template>
  <div>
    <Transition name="fade">
      <div
        class="callout alert"
        data-closable
      >
        <button
          class="close-button"
          aria-label="close alert"
          type="button"
          data-close
        >
          <span aria-hidden="true">
            &times;
          </span>
        </button>
        <slot>
          NOTE: Be <b>alert</b> You've been warned!
        </slot>
      </div>
    </Transition>
  </div>
</template>
<script>
export default {
  name: 'Alert'
}
</script>