<!--
Name: ActionBar
Purpose:
-->
<template>
  <div>
    <div class="actionbar">
      <ul class="actions menu align-right simple">
        <slot />
        <!-- ICON MENU -->
        <li v-if="btnEdit">
          <a @click="$emit('showEdit')">
            <i class="fa fa-pencil" aria-hidden="true" />
          </a>
        </li>
        <li v-if="btnPublish">
          <a title="Publish">
            <i class="fa fa-cloud-upload" />
          </a>
        </li>
        <li v-if="btnShare">
          <a
            @click="showSharing"
            xata-toggle="share_modal"
            href="javascript:"
            title="Share"
          >
            <i class="fa fa-share" />
          </a>
        </li>
        <li v-if="btnPreview">
          <a @click="$emit('showPreview', item)" title="Preview">
            <i class="fa fa-eye" />
          </a>
        </li>
        <li v-if="btnDuplicate">
          <a title="Duplicate">
            <i class="fa fa-copy" />
          </a>
        </li>
        <!--      <li v-if="btn_edit_url"><a :href="btn_edit_url" title="Edit"><i class="fa fa-pencil"></i></a></li>-->
        <li v-if="btnEditModal && mode === 'teacher'">
          <a @click="$emit('toggleEdit')" title="EditModal">
            <i class="fa fa-pencil" />
          </a>
        </li>
        <li v-if="btnAdd">
          <a @click="$emit('addSource', item)" title="Add to MyHistoriana">
            <i class="fa fa-plus" />
          </a>
        </li>
        <li v-if="btnDelete && mode === 'teacher'">
          <a @click="confirmDelete(item)" title="Delete">
            <i class="fa fa-trash" />
          </a>
        </li>
        <li v-if="btnSelect">
          <input
            @change="$emit('toggleSelect', uuid)"
            type="checkbox"
            :id="'img_' + uuid"
            class="nomargin"
          />
          <label :for="'img_' + uuid">
            <i class="fa fa-circle" />
          </label>
        </li>
        <q-btn
          color="primary"
          round
          flat
          icon="fa fa-chevron-left"
          title="previous"
          @click="$emit('prev')"
        />
        <q-btn
          color="primary"
          round
          flat
          icon="fa fa-chevron-right"
          title="next"
          @click="$emit('next')"
        />
      </ul>
    </div>

    <!--
  <div class="button-group">
      <a @click="prev" class="button small"><i class="fa fa-chevron-left" aria-hidden="true"></i></a>
      <a @click="next" class="button small"><i class="fa fa-chevron-right" aria-hidden="true"></i></a>
  </div>
-->
    <Confirm
      v-show="showConfirmDelete"
      :title="'Delete ' + type + '?'"
      @closeConfirm="showConfirmDelete = !showConfirmDelete"
      @confirmed="deleteItem()"
    >
      Are you sure you want to delete this {{ type }}
      <p class="red small">(actionbar)</p>
    </Confirm>
  </div>
</template>
<script>
import Confirm from "../tags/Confirm";

export default {
  name: "Actionbar",
  components: { Confirm },
  props: {
    csstype: {
      type: String,
      default: "",
    },

    btnPublish: {
      type: String,
      default: "",
    },

    btnDuplicate: {
      type: String,
      default: "",
    },

    btnShare: {
      type: String,
      default: "",
    },

    btnUse: {
      type: String,
      default: "",
    },

    btnPreview: {
      type: String,
      default: "",
    },

    btnEditUrl: {
      type: String,
      default: "",
    },

    btnEditModal: {
      type: Boolean,
      default: false,
    },

    btnAdd: {
      type: String,
      default: "",
    },

    btnEdit: {
      type: String,
      default: "",
    },

    btnDelete: {
      type: Boolean,
      default: false,
    },

    btnSelect: {
      type: String,
      default: "",
    },

    item: {
      type: String,
      default: "",
    },

    mode: {
      type: String,
      default: "",
    },
  },

  methods: {
    confirmDelete() {
      this.showConfirmDelete = true;
    },
    deleteItem() {
      this.$emit("deleteItem");
      this.showConfirmDelete = false;
    },
  },
  data() {
    return {
      showConfirmDelete: false,
      type: "source",
    };
  },
};
</script>
<style scoped="true">
.actionbar {
  background-color: #f5f5f5;
  width: 100%;
  padding: 0.25em 0.5em;
  margin: 0 0 1.5em 0;
}
</style>