<template>
  <div v-if="!$q.loading.isActive" class="teachingstrategies">
    <section id="learning-activity">
      <div class="row q-mx-auto justify-start items-start q-gutter-md q-mb-xl">
        <div class="col q-py-md">
          <h3 class="title">{{ la.title }}</h3>
          <h5 class="subtitle">{{ la.intro }}</h5>
          <p class="la_author medium">
            <i class="fas fa-user" />&nbsp;{{ la.author }}
          </p>

          <!-- keyimage of a learning activity left -->
          <div v-if="la && la.thumb" class="on-left float-left">
            <img :src="la.thumb" />
            <p class="caption">
              {{ la.thumblabel }}
            </p>
          </div>
          <div v-else>
            <img src="http://placehold.it/500x500&text=Thumbnail" />
          </div>

          <!-- <div class="col"> -->

          <p>{{ la.summary }}</p>
          <!-- </div> -->
          <!--
          <div class="row"> -->
          <q-separator color="transparent" class="row" />

          <h3 style="border-bottom: 3px solid #0ab; color: #0ab">Downloads</h3>

          <div class="row justify-start items-start">
            <div class="col">
              <h5 class="row q-mt-md q-mb-xs">
                <img
                  class="float-left on-left"
                  src="/static/la/icn_la_teacher.png"
                  width="40px"
                  height="40px"
                />
                Teacher material
              </h5>
              <div class="row" :key="i" v-for="(t, i) in teacherLinks">
                <a
                  target="download"
                  :href="
                    t.prub && t.prub.path ? '/ua/' + t.prub.path : `/${t.url}`
                  "
                >
                  <img class="float-left on-left" :src="t.icon" />{{ t.title }}
                </a>
              </div>
            </div>
            <div class="col">
              <h5 class="row q-mt-md q-mb-xs">
                <img
                  class="float-left on-left"
                  src="/static/la/icn_la_student.png"
                  width="40px"
                  height="40px"
                />
                Student material
              </h5>

              <div class="row" v-for="(t, i) in studentLinks" :key="i">
                <a
                  target="download"
                  :href="
                    t.prub && t.prub.path ? '/ua/' + t.prub.path : `/${t.url}`
                  "
                >
                  <img class="float-left on-left" :src="t.icon" /> {{ t.title }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- END LEFY COLUMN, START SIDEBAR -->
        <div class="col-shrink q-pa-md q-pt-lg sidebar" style="width: 300px">
          <q-separator style="margin: 0 0 1em 0" color="transparent" />

          <p class="text-weight-bold">
            <i class="fa fa-user" /> Indicative age
          </p>
          <q-list>
            <q-list-item :key="i" v-for="(e, i) in ages">
              {{ e.label }}
              <span v-if="i < ages.length - 1">,</span>
            </q-list-item>
          </q-list>

          <q-separator style="margin: 1em 0" />
          <p class="text-weight-bold">
            <i class="far fa-clock" /> Approximate time
          </p>
          <q-list>
            <q-list-item :key="i" v-for="(e, i) in durations">
              {{ e.label }}
              <span v-if="i < durations.length - 1">,</span>
            </q-list-item>
          </q-list>

          <q-separator style="margin: 1em 0" />

          <!-- alternatieve manier, meer ala popover van vroeger
          <p class="text-weight-bold"><i class="fa fa-user-plus" /> Learning outcomes
              <q-menu>
            <q-card class="align-right">
              <q-list
                bordered
                dense
                seperator
              >
                <q-item-label>Students will…</q-item-label>
                <q-item-section>
                  <q-item
                    :key="i"
                    v-for="(o,i) in outcomes"
                    class="text-weight-regular text-body2"
                  >
                    &bull; {{ o }}
                  </q-item>
                </q-item-section>
              </q-list>
            </q-card>
            </q-menu>
          </p>
          -->

          <q-expansion-item
            class="text-weight-bold"
            label="Learning outcomes"
            xicon="fa fa-user-plus"
          >
            <q-card class="align-right">
              <q-list bordered dense seperator>
                <q-item-label>Students will…</q-item-label>
                <q-item-section>
                  <q-item
                    :key="i"
                    v-for="(o, i) in outcomes"
                    class="text-weight-regular text-body2"
                  >
                    &bull; {{ o }}
                  </q-item>
                </q-item-section>
              </q-list>
            </q-card>
          </q-expansion-item>

          <q-separator style="margin: 1em 0" />
          <p class="text-weight-bold">
            <i class="fa fa-users" /> Acknowledgements
          </p>
          <p class="text-weight-regular text-body2" style="max-width: 300px">
            {{ la.acknowledgements }}
          </p>

          <q-separator style="margin: 1em 0" />
          <p class="text-weight-bold"><i class="fa fa-copyright" /> License</p>
          <p class="text-body2">{{ la.copyright }}</p>

          <q-separator style="margin: 1em 0" />
          <!-- related bevat {title:"null"} als er niks is -->
          <!-- moet gefixed worden in de API -->
          <p class="text-weight-bold"><i class="fa fa-code-fork" /> Related</p>
          <ul class="taglist">
            <li :key="i" v-for="(r, i) in related">
              <a
                @click="loadSlug(r.slug)"
                :href="'/learning-activity/' + r.slug"
              >
                <li>{{ r.title }}</li>
              </a>
            </li>
          </ul>

          <q-separator style="margin: 1em 0" />
          <p class="text-weight-bold"><i class="fa fa-tag" /> Tags</p>
          <q-chip :key="i" v-for="(t, i) in tags">{{ t.name }}</q-chip>
        </div>
        <br clear="all" />
      </div>
    </section>
  </div>
</template>
<script>
import { API } from "../tic";
export default {
  name: "LearningActivity",
  props: {
    slug: {
      type: String,
      default: "",
    },
  },
  watch: {
    slug: function (e) {
      console.log("SLUG CHANGED TO ", e);
      this.loadSlug(e);
    },
  },
  methods: {
    loadSlug(slug) {
      console.log("LOAD: ", slug);
      this.isLoading = true;
      API.get("/get-learning-activity/" + this.slug).then((r) => {
        console.log(">>>> r: ", r);
        console.log("loaded: ", r.data.data.la.title);
        this.la = r.data.data.la;
        this.ages = r.data.data.age;
        this.durations = r.data.data.duration;
        this.studentLinks = r.data.data.student_links;
        this.teacherLinks = r.data.data.teacher_links;
        this.outcomes = r.data.data.outcomes;
        this.linktypes = r.data.data.linktype;
        this.tags = r.data.data.tags;
        this.related = r.data.data.related;
        this.isLoading = false;
        this.$q.loading.hide();
      });
    },
  },

  mounted() {
    this.$q.loading.show({
      delay: 400, // ms
    });

    this.loadSlug(this.slug);
  },

  data() {
    return {
      la: {},
      ages: [],
      durations: [],
      studentLinks: [],
      teacherLinks: [],
      outcomes: [],
      linktypes: [],
      contexts: [],
      related: [],
      isLoading: false,
      showLegend: "",
    };
  },
};
</script>
<style scoped>
h5.subtitle {
  font-size: 1.6em;
  font-weight: 400;
  color: #0ab;
  line-height: 1.4em;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 1.5em 0.8em 1.5em;
}

p.la_author {
  /* color: #999999; */
  text-align: center;
  border-bottom: 4px solid #0ab;
  padding: 0 0 2em 0;
}

h3.title {
  border-top: 4px solid #0ab;
  font-size: 2.4em;
  font-weight: 400;
  color: #0ab;
  line-height: 1.4em;
  text-align: center;
  margin-bottom: 0;
  padding: 1em 0.8em 0.5em 0.8em;
}

h6 {
  font-weight: bold;
}

.caption {
  color: #999999;
  margin-top: 0.5em;
}

.bg_korenblauw {
  background-color: #0066cc;
  color: #ffffff;
}
.bg_eigeel {
  background-color: #eebb00;
  color: #333;
}
.bg_hardgeel {
  background-color: #ee0;
  color: #333;
}

a:hover,
a:focus {
  color: #0ab;
}
a:hover {
  cursor: pointer;
}
a {
  color: #0ab;
  background: transparent;
}
a {
  color: #0ab;
  text-decoration: none;
  line-height: inherit;
}

a.pdf:after,
a.word:after,
a.photo:after,
a.image:after {
  font-size: 0.8em;
  text-transform: uppercase;
  opacity: 0.8;
}
a[href$=".docx"]:after,
a.word:after {
  content: " Word, " attr(data-size) "";
}

.popup-content {
  max-width: 24em;
}
.bg_hardroze {
  background-color: #dd0077;
}

.wit,
.diap a,
.diap h5,
.diap p,
.diap ul {
  color: #ffffff;
}

.bg_donkergrijs {
  background-color: #999;
}

.sidebar {
  font-size: 0.86em;
  /* text-align: right; */
  color: #666;
  background-color: #f5f5f5;
}
</style>
