// hi-button global
// somehow the animations om hover & click are lost when wrapping a q-btn
// in this custom element..
<template>
  <q-btn
    size="md"
    outline
    no-caps
    :color="color"
    :text-color="textcolor"
    :label="label"
    :icon="`fa fa-${icon}`"
  />

  <!-- <span :style="{color: textcolor}">
      <i :class="`fa fa-${icon}`" />&nbsp;zz{{label}}
    </span>
  </q-btn> -->
</template>
<script>
export default {
  name: 'HiButton',
  props: {
    label: {
      type: String,
      default: 'no-label'
    },

    icon: {
      type: String,
      default: 'close'
    },

    color: {
      type: String,
      default: 'white'
    },

    textcolor: {
      type: String,
      default: 'black'
    }
  },

  methods: {

    // click (e) {
    // 	this.$emit("click", e)
    // 
  }
}
</script>