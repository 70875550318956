<!-- VIEW COLLECTIONS OF PARTNER -->
<!-- PartnerPage at /partners/<partner-name> -->
<!-- https://docs.google.com/presentation/d/1GBunx-UxieAn4Vq7S9lpfeURAYJeEjfx8431I1JB9VM/edit#slide=id.p8 -->
<template>
  <div id="partner-page">
    <section id="partner-header" class="bg-grey-2 q-py-md">
      <div class="row q-mx-auto justify-between items-center">
        <div class="col vertical-top q-pb-md text-body2">
          <h3>{{ partner.name }}</h3>
          <p class="text-grey-8" style="max-width: 53em">
            {{ partner.introduction }}
          </p>
        </div>
        <div class="col-shrink q-mr-md" id="partner-logo">
          <img
            :src="partner.logo"
            style="max-height: 10vh; max-width: 14vw; padding: 0em 0 1em 1em"
          />
        </div>
      </div>
    </section>

    <div v-if="!$q.loading.isActive" class="historical-content">
      <hi-search placeholder="Find" v-model="searchFor" />

      <hi-filter v-show="true" :types="types" @setFilter="setFilter" />
    </div>
    <section id="card-holder">
      <div v-if="filteredItems.length === 0">No entries</div>

      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md">
          <div :key="i" v-for="(card, i) in filteredItems" class="column">
            <Card
              :partner="partner"
              :title="card.csstype === 'collection' ? card.name : card.title"
              :item="card"
              :uuid="card.uuid"
              :thumb="card.thumb_url"
              :type="card.type"
              :csstype="card.csstype + ' published'"
              :url="url(card)"
              :show-meta="true"
              @showMeta="showMeta"
              :btn-edit-modal="uuid === owner ? false : false"
              @showEdit="editCollection"
              :show-add-to-my-historiana="card.csstype === 'ela'"
              @addToMyHistoriana="addELA"
            >
              <span v-if="card.csstype === 'collection'">{{
                card.introduction
              }}</span>
              <span v-if="card.csstype === 'ela'">{{ card.description }}</span>
              <span v-if="card.csstype === 'narrative'">{{ card.teaser }}</span>
            </Card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { API } from "@/tic";
import Card from "../tags/Card";
import { mapGetters } from "vuex";

export default {
  name: "PartnerPage",
  components: { Card },
  props: {
    slug: {
      type: String,
      default: "",
    },
  },

  watch: {
    // monitor change of search result, sent to stats
    filterCount: function (search) {
      console.log("add search insight** ")
      API.post("/add-search-insight", {
        results: search,
        searchstring: this.searchFor,
        section: "partner-pages",
        context: this.partner.name,
      });
    },
  },

  computed: {
    ...mapGetters("user", ["uuid"]),
    filteredItems() {
      // filter by type
      let items = [];
      this.filterCount = 0;
      switch (this.activeFilter) {
        case "Collection":
          items = [...this.collections];
          break;

        case "eActivity":
          items = [...this.activities];
          break;

        case "Narrative":
          items = [...this.narratives];
          break;

        default:
          items = [...this.collections, ...this.activities, ...this.narratives];
      }

      return items.filter((e) => {
        if (this.searchFor) {
          // search in all property values; this needs more work
          // as deeper property-names are part of the string now
          let item = JSON.stringify(Object.values(e)).toLowerCase();
          if (item.includes(this.searchFor.toLowerCase())) {
            this.filterCount++;
          }
          return item.includes(this.searchFor.toLowerCase());
        } else {
          return true;
        }
      });
    },
  },

  mounted() {
    let vm = this;

    this.$q.loading.show({
      delay: 100, // ms
    });
    this.getCollections();
    console.log(`Partner.vue mounted for ${this.slug}`);
  },

  data() {
    return {
      filterCount: 0,
      partner: {},
      owner: null,
      collections: [],
      activities: [],
      narratives: [],
      name: "index",
      searchFor: null,
      types: ["Collection", "eActivity", "Narrative"],
      activeFilter: "All",
      collection: null,
      showEditor: false,
    };
  },

  methods: {
    addELA(item) {
      API.post("/ea/add-to-my-historiana", {
        activity: item.uuid,
        member: this.uuid,
      }).then((response) => {
        console.log("◆ API /ea/add-to-my-historiana", response);

        this.$q.notify({
          type: "positive",
          position: "top",
          html: true,
          timeout: 1400,
          message:
            "<strong>e-Learning Activity added to your Historiana!</strong><br>You can now edit your own version in My Historiana and share it",
        });
      });
    },

    getCollections() {
      const vm = this;
      console.log("** LOAD DATA");
      API.get(`/partners/${this.slug}`)
        .then((r) => {
          this.owner = r.data.owner;
          this.partner = { ...r.data.partner };
          this.collections = [...r.data.collections];
          this.activities = [...r.data.activities];
          this.narratives = [...r.data.narratives];
          this.$q.loading.hide();
        })
        .catch((e) => {
          vm.$q.loading.hide();
          vm.$q.dialog({
            title: '<i class="fa fa-bomb"></i>&nbsp;Error',
            message:
              "No collections found, please create them in My Historiana. You are now redirected to the Partner index.",
            html: true,
            ok: {
              color: "primary",
            },
          });
          vm.$router.replace("/partners");
        });
    },
    saveCollection(e) {
      console.log("** save now: ", this.collection);
      API.post("/collection/update", this.collection).then((r) => {
        this.$q.notify({
          type: "positive",
          position: "top",
          message: "Saved changes",
        });

        this.showEditor = false;
      });
    },
    editCollection(item) {
      console.log("edit item: ", item);
      this.showEditor = true;
      this.collection = item;
    },
    renderMeta(data) {
      let table =
        '<table border="1" cellpadding="4" style="border-collapse: collapse" class="meta-info" valign=top width="100%"';

      for (const [key, value] of Object.entries(data)) {
        if (!key.startsWith("type")) {
          table =
            table +
            `<tr><td valign="top" class="label">${key}:</td><td class="value">${value}</td></tr>`;
        }
      }
      table = table + "</tr></table>";
      return table;
    },

    showMeta(item) {
      // get meta info from API; these are temp calls, the i button willeventually
      // be replaced with gallery-like viewer/editor
      let url = null;
      switch (item.csstype) {
        case "collection":
          url = `/getcollectionmeta/${item.uuid}`;
          break;

        case "ela":
          url = `/getelameta/${item.uuid}`;
          break;
      }

      API.get(url).then((r) => {
        this.$q.dialog({
          title: `<i class="fa fa-info"></i>&nbsp;Meta information for ${r.data.data.type}`,
          message: this.renderMeta(r.data.data),
          html: true,
          ok: {
            color: "primary",
          },
        });
      });
    },

    url(item) {
      switch (item.type) {
        case "Collection":
          return `${this.$route.path}/${item.slug}`;

        case "e-Learning Activity":
          return `/ea/view/${item.uuid}`;

        case "Narrative":
          return `/narratives/${item.slug}/start`;
      }
      console.log("Card is for an unsupported type", item.type);
      return null;
    },

    setFilter(what) {
      this.activeFilter = what;
      this.searchFor = null;
    },
  },
};
</script>
