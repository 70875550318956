<!--
  description: Main content containter template used by whole site except popups like MediaLibrary,
  Studentview and the BlockEditor.
-->
<template>
  <div v-if="isAPIOnline">
    <hi-header />
    <q-page-container>
      <q-page>
        <!-- <transition name="router-anim"> -->
        <router-view />
        <!-- </transition> -->
      </q-page>
    </q-page-container>
    <hi-footer />
  </div>
</template>
<script>
import HiHeader from "./components/HiHeader";
import HiFooter from "./components/HiFooter";
import { mapState } from "vuex";

export default {
  name: "Historiana",
  computed: {
    ...mapState(["isAPIOnline"]),
  },
  components: {
    HiHeader,
    HiFooter,
  },
};
</script>

<style>
/* @import "https://cdn.jsdelivr.net/npm/animate.css";

.router-anim-enter-active {
  animation: coming 1s;
  animation-delay: 0.5s;
  opacity: 0;
}
.router-anim-leave-active {
  animation: going 1s;
}

@keyframes going {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes coming {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */
</style>
