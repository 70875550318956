<template>
  <div id="language-select">
    <q-select
      v-model="language"
      filled
      fill-input
      use-chips
      :options="options"
      option-label="name"
      option-value="uuid"
      @filter="filterFn"
      :label="label"
      @input="$emit('input', language)"
    >
      <template v-slot:selected v-if="language">
        {{ language.native }} ({{ language.name }})
      </template>

      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
          {{ scope.opt.name }} &mdash; {{ scope.opt.native }}
        </q-item>
      </template>
    </q-select>
  </div>
</template>
<script>
import { API } from '../tic';
export default {
  name: 'HiLanguage',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      }
    },
    label: {
      type: String,
      default: 'Language'
    }
  },

  data() {
    return {
      language: null,
      allOptions: [],
      options: []
    };
  },

  mounted() {
    API.get('/language')
      .then(resp => {
        this.allOptions = [...resp.data.data];
      })
      .then(() => {
        console.log('prop val: ', this.value);
        this.language = this.value;
      });
  },

  methods: {
    filterFn(val, update, abort) {
      // avoid abort on empty string, otherwise pulldown does not work
      if (val !== '' && val.length < 2) {
        abort();
        return;
      }

      if (val === '') {
        update(() => {
          this.options = this.allOptions;

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.options = this.allOptions.filter(v =>
          v.label ? v.label.toLowerCase().indexOf(needle) > -1 : false
        );
      });
    }
  }
};
</script>
