<template>
  <!-- index for /sas/partners -->
  <div
    v-if="!$q.loading.isActive"
    class="historical-content row q-mx-auto justify-between items-start"
  >
    <div class="col">
      <!-- <section id="search-partners">

        <div class="row q-mx-auto justify-between items-start q-gutter-sm">
          <h3>Search Partners</h3>
        </div>
      </section>-->

      <section id="card-holder">
        <div class="row q-mx-auto justify-start items-start">
          <div class="row q-gutter-md">
            <span :key="i" v-for="(card, i) in searchpartners">
              <Card
                :show-meta-button="false"
                :show-more-button="false"
                :index="i"
                type="Search partner"
                csstype="searchpartner"
                :item="card"
                :title="card.name"
                :image-url="card.logo"
                :url="'/sas/partners/slug/' + card.uuid"
                :is-owner="false"
              >
                <div v-if="card.short">{{ card.short }}</div>
              </Card>
            </span>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
// import _ from 'lodash'
import { API } from "../tic";
import Card from "../tags/Card";

export default {
  name: "SearchPartners",
  components: { Card },

  mounted: function() {
    this.$q.loading.show({
      delay: 400 // ms
    });
    console.log("start Search Partner search");
    API.get("/get-search-partners").then(resp => {
      this.searchpartners = resp.data;
      this.$q.loading.hide();
    });
  },

  data() {
    return {
      searchpartners: [],
      filter: "",
      results: []
    };
  }
};
</script>
<style>

</style>
