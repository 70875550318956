<template>
  <span>
    <Register :show-dialog="showRegister" @hide="showRegister = false" />
    <q-btn
      flat
      dense
      size="13px"
      class="q-px-sm"
      icon="far fa-sign-in"
      label="Register"
      :color="inline? 'primary':dark? 'white':'dark-gray'"
      @click="showRegister = true"
    />
  </span>
</template>
<script>
import Register from "@/components/Register.vue";

export default {
  name: "RegisterButton",
  components: { Register },
  props: {
    dark:{
      type:Boolean,
      default:false
    },
    inline:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showRegister: false
    };
  }
};
</script>
