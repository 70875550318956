<template>
  <q-dialog
    :value="show"
    @hide="close"
  >
    <q-card style="width:50vw; max-width:50vw; height:50vh; background:transparent">

      <!-- header -->
      <q-bar class="row justify-between bg-primary text-white">
        <h5><i class="fas fa-chalkboard-teacher on-left"></i>Instructions</h5>
        <q-btn dense round flat icon="fa fa-times" v-close-popup />
      </q-bar>

      <!-- scrollable body -->
      <q-scroll-area class="bg-white">
        <q-card-section>

          <!-- editor or text display -->
          <div v-if="mode=='teacher'" class="editor">
            <quill-editor
              v-model="instructions"
              :toolbar-options="[
                [{ 'header': [1, 2, 3, 4, 5, false] }],
                ['bold', 'italic', 'underline'],
                ['blockquote',{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ]"
            />
          </div>
          <div v-else class="text" v-html="instructions"/>

        </q-card-section>
      </q-scroll-area>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import QuillEditor from '../components/QuillEditor';

export default {
  name: 'BlockInstructions',

  components: {
    QuillEditor
  },

  props: {
    value: {
      type: String,
      default: ''
    },
    blockId: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'student'
    },
    show: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: 'view'
    },
    editor: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      instructions:'',
    }
  },

  watch: {
    show(v) {
      if (v) this.instructions = this.value;
    }
  },

  methods: {
    close () {
      //emit input for v-model
      this.$emit('input', this.instructions);
      this.$emit('close');
    }
  }
}
</script>