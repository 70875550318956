<template>
  <span>
    <!-- REGISTER DIALOGUE -->
    <q-dialog :value="showDialog" @hide="$emit('hide')">
      <q-card class="q-pa-md q-gutter-sm" style="min-width: 400px">
        <h4 class="title">
          <i class="fa fa-sign-in">&nbsp;</i> Register
        </h4>
        <p>
          Complete this form to create a
          <b>free</b> account on Historiana.
        </p>
        <p v-if="msg" style="color:red" class="message">{{ msg }}</p>
        <q-form>
          <label>
            <q-input
              autocomplete="email"
              type="text"
              v-model="registerEmail"
              @blur="checkRegisterName"
              placeholder="Your email address"
            />
          </label>
          <label>
            <q-input
              autocomplete="new-password"
              type="password"
              v-model="password"
              placeholder="Choose your password"
            />
          </label>
          <label>
            <q-input
              autocomplete="new-password"
              type="password"
              v-model="password2"
              @blur="checkPasswordMatch"
              placeholder="Type your password again just to be sure"
            />
          </label>
        </q-form>

        <q-card-actions class="q-gutter-sm row justify-end">
          <q-btn q-sm no-caps size="small" v-close-popup>Cancel</q-btn>
          <q-btn
            :disable="
              password !== password2 || msg !== '' || registerEmail === ''
            "
            no-caps
            color="primary"
            @click="doRegister"
          >Register</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </span>
</template>
<script>
import { API } from "../tic";

export default {
  name: "Register",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    noRedirect: {
      type: Boolean,
      default:false
    }
  },

  methods: {
    doRegister() {
      let vm = this;
      API.post("/register", {
        login: this.registerEmail,
        password: this.password
      }).then(response => {
        // automatic login if account is created ok
        // goto profile
        if (!response.data.isError) {
          API.post("/login", {
            login: vm.registerEmail,
            password: vm.password
          }).then(response => {
            vm.$emit("hide");
            window.localStorage.setItem("autologin", true);
            window.localStorage.setItem(
              "session",
              response.data.data.session.sessionId
            );
            vm.$store.dispatch("Welcome");
            vm.$store.commit("user/setUser", response.data);
            if (!this.noRedirect) vm.$router.push("/my/profile");
          });
        } else {
          console.log("-- error creating account");
          this.msg = response.data.msg;
        }
      });
    },

    checkRegisterName() {
      console.log("check this new email: ", this.registerEmail);
      if (!this.registerEmail.length) {
        this.msg = "Invalid email";
        return;
      }

      API.post("/checkRegisterEmail", {
        email: this.registerEmail
      }).then(response => {
        if (response.data.isError) {
          this.msg = response.data.msg;
        } else {
          this.msg = "";
        }
      });
    },

    checkPasswordMatch() {
      if (this.password && this.password2 && this.password !== this.password2) {
        this.msg = "Passwords do not match";
        return false;
      } else {
        this.msg = "";
        return true;
      }
    }
  },
  data() {
    return {
      registerEmail: "",
      password: "",
      password2: "",
      msg: ""
    };
  }
};
</script>
