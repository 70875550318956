var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"source"},[_c('q-resize-observer',{attrs:{"debounce":"0"},on:{"resize":_vm.zoomerRefresh}}),_c('div',{staticClass:"fit"},[_c('v-zoomer',{ref:"zoomer",staticClass:"fit",attrs:{"controls":!_vm.addSelection,"max-scale":_vm.maxZoom,"zoom":_vm.zoom,"zoomed":_vm.zoomed,"wheel-blocked":_vm.noZoom},on:{"update:zoom":function($event){_vm.zoom=$event},"update:zoomed":function($event){_vm.zoomed=$event},"update":_vm.zoomerUpdate,"click":_vm.onClick,"hook:mounted":_vm.zoomerMounted}},[_c('img',{attrs:{"src":_vm.source.url}})]),_c('div',{ref:"selections",staticClass:"selections",class:{ active:_vm.addSelection, drag:_vm.drag },on:{"mousedown":_vm.onMouseDown,"mouseup":_vm.onMouseUp,"mousemove":_vm.onMouseMove,"touchstart":_vm.onTouchStart,"touchend":_vm.onTouchEnd,"touchmove":_vm.onTouchMove,"wheel":_vm.onWheel}},[_c('svg',{staticClass:"mask fit",class:{ active:_vm.activeAnnotation!='' },attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('defs',[_c('mask',{attrs:{"id":'mask'+_vm.index}},[_c('rect',{attrs:{"fill":"white","width":_vm.container.w,"height":_vm.container.h}}),_vm._l((_vm.activeSelections),function(s,i){return _c('rect',{key:i,attrs:{"x":_vm.selectionLeft(s.x) + 1 +'px',"y":_vm.selectionTop(s.y) + 1 +'px',"width":_vm.selectionWidth(s.w) - 2 +'px',"height":_vm.selectionHeight(s.h) - 2 +'px'}})})],2)]),_c('rect',{staticClass:"fade",attrs:{"x":"0","y":"0","width":"100%","height":"100%","mask":'url(#mask'+_vm.index+')'}})]),(_vm.drag && !_vm.editSelection)?_c('div',{staticClass:"new selection",style:({
            left:_vm.dragLeft + 'px',
            top:_vm.dragTop + 'px',
            width:_vm.dragWidth + 'px',
            height:_vm.dragHeight + 'px',
            borderColor:_vm.annotationColor(_vm.activeAnnotation)
          })}):_vm._e(),_vm._l((_vm.selections),function(s,i){return _c('div',{key:i,staticClass:"selection",class:{ active:s.active, edit:s.edit },style:({
            left:_vm.selectionLeft(s.x) + 'px',
            top:_vm.selectionTop(s.y) + 'px',
            width:_vm.selectionWidth(s.w)+ 'px',
            height:_vm.selectionHeight(s.h)+ 'px',
            borderColor:_vm.annotationColor(s.a)
          })},[(s.edit)?_c('div',{staticClass:"resize top left",on:{"mousedown":function($event){_vm.resize='top-left'},"mouseup":function($event){_vm.resize=false}}},[_c('div',{staticClass:"handle",style:({ borderColor:_vm.annotationColor(s.a) })})]):_vm._e(),(s.edit)?_c('div',{staticClass:"resize top right",on:{"mousedown":function($event){_vm.resize='top-right'},"mouseup":function($event){_vm.resize=false}}},[_c('div',{staticClass:"handle",style:({ borderColor:_vm.annotationColor(s.a) })})]):_vm._e(),(s.edit)?_c('div',{staticClass:"resize bottom left",on:{"mousedown":function($event){_vm.resize='bottom-left'},"mouseup":function($event){_vm.resize=false}}},[_c('div',{staticClass:"handle",style:({ borderColor:_vm.annotationColor(s.a) })})]):_vm._e(),(s.edit)?_c('div',{staticClass:"resize bottom right",on:{"mousedown":function($event){_vm.resize='bottom-right'},"mouseup":function($event){_vm.resize=false}}},[_c('div',{staticClass:"handle",style:({ borderColor:_vm.annotationColor(s.a) })})]):_vm._e(),(s.edit)?_c('q-btn',{staticClass:"remove",attrs:{"flat":"","round":"","size":"sm"},on:{"click":function($event){return _vm.removeSelection(i)}}},[_c('i',{staticClass:"fas fa-trash q-icon"})]):_vm._e()],1)})],2),(!_vm.addSelection && !_vm.editSelection && _vm.mode=='teacher')?_c('q-btn',{staticClass:"remove-source",attrs:{"round":"","size":"sm","icon":"fas fa-trash"},on:{"click":function($event){return _vm.$emit('remove')}}}):_vm._e(),(!_vm.addSelection && !_vm.editSelection)?_c('q-btn',{staticClass:"source-info",attrs:{"round":"","size":"sm","icon":"fas fa-info"},on:{"click":function($event){_vm.showInfo=!_vm.showInfo}}}):_vm._e(),_c('source-info',{attrs:{"mode":_vm.mode,"show":_vm.showInfo,"source":_vm.source},on:{"update:show":function($event){_vm.showInfo=$event},"update:source":function($event){_vm.source=$event},"store":function($event){return _vm.$emit('updateSource')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }