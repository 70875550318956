<template>
  <div id="experiment">
    <h1>Experiment</h1>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  name: 'Experiment',
  mounted () {
    console.log("EXPERIMeNT")
    $(document).ready(function () {
      let bla = $('#experiment').on('click', (e) => { 
        if (e.target.id === 'experiment') {
          let x = e.offsetX
          let y = e.offsetY
          e.preventDefault()
          console.log("click: ", e, "bla: ", bla)
          console.log("click on: ", e.target.id)
          let a = $('<div class="blk"  contenteditable="true">TEKST</div>')
          .draggable({ 
            containment: "#experiment",
            cursorAt: { left: 0, top: 0 }
          })
          .click(function () {
            $(this).draggable({disabled: false})
          }).dblclick(function () {
            $(this).draggable({disabled: true})
          })
          .css({
            "background-color": 'red', 
            "top": y - 100,
            "left": x
          })


          console.log("a: ", a)
          $('body').append(a)
        }
      
      })
    })
  }
}
</script>
<style>
.blk {
  width: 210px;
  height: 210px;
/*
  display: inline;
  position: absolute;
*/
  z-index: 10;
}

#experiment {
  position: absolute;
  border: 18px solid purple;
  height: 400px;
  width: 100%;
}
</style>