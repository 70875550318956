<template>
  <div id="bblock-highlight" class="ea-canvas">

    <!-- source tabs -->
    <div id="source-tabs">
      <HighlightTab
        v-for="(source, index) in tabs"
        :index="index"
        :key="source.id"
        :source="source.image"
        :answer="answers[index]"
        :color="source.color"
        :active="source.active"
        :mode="mode"
        @remove="removeSource(index)"
        @store="storeSource(index, ...arguments)"
        @storeAnswer="storeAnswer(index, ...arguments)"
        @click.native="selectSource(index)"
      />

      <h5 class="guide" v-if="tabs.length == 0">
        <span v-if="mode!='teacher'">Please add sources in teacher mode first</span>
        <span v-else>
          Start creating your highlighting block by adding one or more
          <a href="/add-media" class="text-hardroze" @click.prevent="$emit('addMedia')">sources</a>
        </span>
      </h5>
    </div>

    <!-- instructions -->
    <block-instructions
      v-model="instructions"
      :mode="mode"
      :show="showInstructions"
      @close="closeInstructions"
    />

  </div>
</template>

<script>
import { clone } from '../../tic'
import HighlightTab from './components/Highlight-tab'
import { mapGetters } from 'vuex'
import BlockInstructions from '../BlockInstructions'

//tab color shades
const colors = ['#0066CC', '#005EBC', '#0055AB', '#004D9B', '#00458A']

//alternative options:
//['#00A382','#009C7D','#009577','#008E72','#00886C']
//['#00aabb','#00aaee','#0066cc','#1133bb','#331188'];
//['#00997A','#00886C','#00775F','#006651','#005544'];
//['#00AABB','#00A2B2','#0099A8','#00909F','#008895'];

export default {
  name: 'BBhighlighting',

  components: {
    HighlightTab,
    BlockInstructions
  },

  props: {
    blockId: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'student'
    },
    view: {
      type: String,
      default: 'view'
    },
    editor: {
      type: Boolean,
      default: false
    },
    showInstructions: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      instructions:null,
      sources: [],
      answers: [],
      tabs: [],
      selectedTab: 0,
      maxSources: 5
    }
  },

  computed: {
    ...mapGetters('activity',['record','answer']),
  },

  watch: {
    record: {
      immediate: true,
      deep:true,
      handler() {
        console.log('BB-highlight rec change')
        if (this.record && this.record.images) this.setSources()
      }
    },
    mode() {
      this.setActions()
    }
  },

  mounted() {
  },

  methods: {

    setActions () {
      this.$emit('addSources',this.mode=='teacher'? { max:this.maxSources - this.sources.length }:false)
      this.$emit('addInstructions',this.mode=='teacher'? true:this.instructions && this.instructions!='');
    },

    closeInstructions () {
      this.$emit('closeInstructions');
      this.storeRecord(true);
    },

    setSources() {
      //deep copy record.images to local array
      this.sources = clone(this.record.images)
      this.instructions = this.record.instructions

      //set answers (always default to empty array)
      this.answers = this.sources.map((s, index) =>
        this.answer ? this.answer[index] || [] : []
      )

      //add tab for each source
      this.createTabs()

      //enable sidebar actions
      this.setActions()
    },

    createTabs: function() {
      //create a tab component for each image in record
      console.log('□■ BB-highlight: createTabs', this.sources)

      this.tabs = []
      //this.selectedTab = 0

      this.sources.forEach((image, index) => {
        this.tabs.push({
          color: colors[index],
          active: index == this.selectedTab,
          image: image
        })
      })
    },

    selectSource: function(index) {
      if (this.selectedTab == index) return

      //switch active tab
      this.selectedTab = index
      this.tabs.map((tab, i) => {
        tab.active = i == index
      })

      //store and save
      this.storeRecord(true)
    },

    removeSource: function(index) {
      //remove a source from the record, confirm first

      let title = this.tabs[index].image.title
      if (title != '') title = '"' + title + '"'

      this.$q
        .dialog({
          title: '<i class="fa fa-trash"></i>&nbsp;Confirm Remove',
          message:
            '<p>Removing source ' +
            title +
            ' from this block: text and highlights will be lost.</p><p>Are you sure?</p>',
          html: true,
          cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
          ok: { label: 'Yes', color: 'primary', noCaps: true }
        })
        .onOk(() => {
          //remove source
          this.sources.splice(index, 1)
          this.selectedTab = index > 0 ? index - 1 : 0

          //store and save
          this.storeRecord(true)

          //sync record, this will trigger UI refresh
          this.$store.commit('activity/syncCurrentRecord',this.blockId)
        })
    },

    storeSource(index, source, save) {
      //console.log('□■ BB-highlight: store source with index=',index);

      //update record with updated source
      this.sources[index] = source
      //update tab component
      this.tabs[index].image = source

      //push record changes to global state
      this.storeRecord(save)
    },

    storeAnswer(index, page, text) {
      if (this.editor) return //do not keep answers in student-preview mode

      //update local record
      this.answers[index][page] = text

      //push record to store
      this.$emit('answer',this.answers)
    },

    storeRecord(save) {
      if (this.mode=='teacher')
      {
        this.$store.commit('activity/setRecord', {
          block: this.blockId,
          record: {
            images: this.sources,
            instructions: this.instructions
          }
        })

        if (save) this.$store.dispatch('activity/save')
      }
    }
  }
}
</script>

<style scoped lang="stylus">
@import '~quasar-variables';

#bblock-highlight {
  position: relative;
  background-color: $white;
  color: $black;
  cursor: default;
}

.guide {
  margin: 100px auto;
}

#source-tabs {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
}
</style>
