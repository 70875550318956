
const state = {
  feedback: {
    msg: '',
    status: ''
  }
};

const getters = {
  xxveTags (state) {
    return state.msg
  }
  
}

const mutations = {
  
}

const actions = {
  logout ({ commit } , iets) {
    console.log("PERFORM FEEDBACK: ", iets)
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
};