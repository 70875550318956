<template>
  <div class="pdf-zoom">
    
    <q-btn
      round
      color="primary"
      :disabled="isDisabled"
      @click.prevent.stop="zoomIn"
    ><i class="far fa-search-plus q-icon"/></q-btn>
    <q-btn
      round
      color="primary"
      :disabled="isDisabled"
      @click.prevent.stop="zoomOut"
    ><i class="far fa-search-minus q-icon"/></q-btn>
 
   <!--  &nbsp;[scale: {{ scale }} pixelRatio: {{ pixelRatio }}] -->
<!-- 
    <a @click.prevent.stop="fitWidth" class="icon" :disabled="isDisabled">f</a>
    <a @click.prevent.stop="fitAuto" class="icon" :disabled="isDisabled">s</a>
-->
  </div>
</template>

<script>

export default {
  name: 'PDFZoom',

  components: {
  },

  props: {
    scale: {
      type: Number,
      default:1
    },
    increment: {
      type: Number,
      default: 0.1,
    },
    pixelRatio: {
      type: Number,
      default:1
    }
  },

  computed: {
    isDisabled() {
      return !this.scale;
    },
  },

  methods: {
    zoomIn() {
      this.updateScale(this.scale + this.increment);
    },

    zoomOut() {
      if (this.scale <= this.increment) return;
      this.updateScale(this.scale - this.increment);
    },

    updateScale(scale) {
      this.$emit('change', {scale});
    },

    fitWidth() {
      this.$emit('fit', 'width');
    },

    fitAuto() {
      this.$emit('fit', 'auto');
    },
  },
}
</script>
