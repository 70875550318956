<template>
<div>
    <q-dialog v-model="showDialog" >
      <q-card style="width: 700px; max-width: 80vw;">
        <q-bar class="text-white bg-green"><strong>{{title}}</strong>
            <q-space/>
            <q-btn v-close-popup dense round flat icon="close" />
        </q-bar>
        <q-scroll-area :style="{height:height}">
        <!-- <q-scroll-area style="height:400px"> -->
        <q-card-section>

        <slot style="height:400px" ></slot>

        </q-card-section>
        <q-card-actions align="center">
            <q-btn v-close-popup no-caps label="Cancel"/>
        </q-card-actions>
        </q-scroll-area>

    </q-card>
    </q-dialog>
</div>
</template>
<script>
export default {
  name: 'hiDialog',
  props: {
      title: {
          type: String,
          default: ''
      },

      show: {
          type: Boolean,
          default: false
      },

      uuid: {
          type: String,
          default: ''
      }
  },
  computed: {

    height() {
        // calculate dialog height based on current window height
        return (window.innerHeight/5)*3 +'px'
    },

    showDialog: {
      get() {
        console.log("hiDialog get showDialog: ", this.show)
        return this.show;
      },
      set(val) {
        this.$emit('close');
        return val;
      }
    }
  },

  data() {
    return {}
  }
};
</script>
