<template>
  <div class="pdf-viewer">

    <!-- PDF viewer -->
    <PDFData
      :url="url"
      @page-count="updatePageCount"
      @page-focus="updateCurrentPage"
      @document-rendered="onDocumentRendered"
      @document-errored="onDocumentErrored"
      >
      <template v-slot:document="{pages}">
        <PDFDocument
          v-bind="{pages, scale, optimalScale, fit, currentPage, pageCount, pixelRatio }"
          @scale-change="updateScale"
          />
      </template>
    </PDFData>

    <!-- controls -->
  	<div class="controls">
      <PDFZoom
        :scale="scale"
        :pixel-ratio="pixelRatio"
        @change="updateScale"
        @fit="updateFit"
        class="header-item"
        />
    </div>

  </div>
</template>

<script>
import PDFDocument from './PDFDocument';
import PDFData from './PDFData';
import PDFZoom from './PDFZoom';

function floor(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.floor(value * multiplier) / multiplier;
}

export default {
  name: 'PDFViewer',

  components: {
    PDFDocument,
    PDFData,
    PDFZoom,
  },

  props: {
    url: {
      type:String,
      default:undefined
    },
    fetchText: {
      type: Boolean,
      default:true
    },
    jumpToPage: {
      type:Number,
      default:1
    }
  },

  data() {
    return {
      scale: undefined,
      optimalScale: undefined,
      fit: undefined,
      currentPage: 1,
      pageCount: undefined,
      pixelRatio: window.devicePixelRatio * 2 || 2,
    };
  },

  methods: {
    onDocumentRendered() {
      this.$emit('document-errored', this.url);
    },

    onDocumentErrored(e) {
      this.$emit('document-errored', e);
    },

    updateScale({scale, isOptimal = false}) {
      const roundedScale = floor(scale, 2);
      if (isOptimal) this.optimalScale = roundedScale;
      this.scale = roundedScale;
    },

    updateFit(fit) {
      this.fit = fit;
    },

    updatePageCount(pageCount) {
      this.pageCount = pageCount;
      this.$emit('page-count', pageCount);
    },

    updateCurrentPage(pageNumber,page) {
      this.currentPage = pageNumber;

      //send page object to parent (Highlight-source), so it can fetch text if needed
      this.$emit('page-focus', this.currentPage, page);
    },

  },

  watch: {
    url() {
      this.currentPage = 1;
    },
//     currentPage () {
//       this.$emit('page-focus', this.currentPage, this.page);
//     },
    jumpToPage () {
      this.currentPage = this.jumpToPage;
    }
  },

  mounted() {
  },
};
</script>

<style scoped lang="stylus">
.controls
{
  position:absolute;
  left:10px;
  bottom:15px;
  display:flex;
}
.controls >>> button
{
  margin-left:6px;
}
</style>