<template>
  <!-- UJsed by Gallery to display all MemberTags for a source -->
  <div>
    <h5 class="tags-title">{{ name }}</h5>
    <div :key="offset" v-for="(t, offset) in tags(nodelabel)" class="q-pb-sm">
      <q-checkbox
        @input="meChanged(t.uuid)"
        :value="isChecked(t.uuid)"
        color="accent"
        keep-color
        :label="t.name"
        :toggle-indeterminate="false"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "MemberTags",
  methods: {
    isChecked(uuid) {
      return this.active.includes(uuid);
    },

    tags(tagsetname) {
      return this.$store.state.user.tags.filter((e) => {
        return e.tag === tagsetname;
      });
    },

    meChanged(uuid) {
      this.$emit("input", uuid);
    },
  },

  props: {
    nodelabel: {
      type: String,
      default: "",
    },

    name: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: null,
    },

    value: {
      type: String,
      required: false,
      default: "",
    },

    active: {
      type: Array,
      default: () => [],
    },
  },
};
</script>