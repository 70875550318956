<template>
  <div class="source">
    <q-resize-observer @resize="zoomerRefresh" debounce="0" />

    <div
      class="fit"
    >

      <!-- image zoomer -->
      <v-zoomer
        :controls="!addSelection"
        ref="zoomer"
        class="fit"
        :max-scale="maxZoom"
        :zoom.sync="zoom"
        :zoomed.sync="zoomed"
        :wheel-blocked="noZoom"
        @update="zoomerUpdate"
        @click="onClick"
        @hook:mounted="zoomerMounted"
      >
        <img :src="source.url">
      </v-zoomer>

      <!-- selections container -->
      <div
        ref="selections"
        class="selections"
        :class="{ active:addSelection, drag:drag }"
        @mousedown="onMouseDown"
        @mouseup="onMouseUp"
        @mousemove="onMouseMove"
        @touchstart="onTouchStart"
        @touchend="onTouchEnd"
        @touchmove="onTouchMove"
        @wheel="onWheel"
      >
        <!-- active selections mask -->
        <svg
          class="mask fit"
          :class="{ active:activeAnnotation!='' }"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <mask :id="'mask'+index">
              <rect fill="white" :width="container.w" :height="container.h"/>
              <rect
                v-for="(s,i) in activeSelections"
                :key="i"
                :x="selectionLeft(s.x) + 1 +'px'"
                :y="selectionTop(s.y) + 1 +'px'"
                :width="selectionWidth(s.w) - 2 +'px'"
                :height="selectionHeight(s.h) - 2 +'px'"
              />
            </mask>
          </defs>
          <rect class="fade" x="0" y="0" width="100%" height="100%" :mask="'url(#mask'+index+')'"/>
        </svg>

        <!-- debugging -->
<!--
        <div style="color:magenta; background-color:rgba(0,0,0,.8); margin-top:0px">
          zoom={{ this.zoom }}
        </div>
 -->

        <!-- draw selection -->
        <div
          v-if="drag && !editSelection"
          class="new selection"
          :style="{
            left:dragLeft + 'px',
            top:dragTop + 'px',
            width:dragWidth + 'px',
            height:dragHeight + 'px',
            borderColor:annotationColor(activeAnnotation)
          }"
        />
        <!-- selections -->
        <div
          class="selection"
          :class="{ active:s.active, edit:s.edit }"
          v-for="(s,i) in selections"
          :key="i"
          :style="{
            left:selectionLeft(s.x) + 'px',
            top:selectionTop(s.y) + 'px',
            width:selectionWidth(s.w)+ 'px',
            height:selectionHeight(s.h)+ 'px',
            borderColor:annotationColor(s.a)
          }"
        >
          <!-- resize handles -->
          <div
            v-if="s.edit"
            class="resize top left"
            @mousedown="resize='top-left'"
            @mouseup="resize=false"
          ><div class="handle" :style="{ borderColor:annotationColor(s.a) }"/></div>
          <div
            v-if="s.edit"
            class="resize top right"
            @mousedown="resize='top-right'"
            @mouseup="resize=false"
          ><div class="handle" :style="{ borderColor:annotationColor(s.a) }"/></div>
          <div
            v-if="s.edit"
            class="resize bottom left"
            @mousedown="resize='bottom-left'"
            @mouseup="resize=false"
          ><div class="handle" :style="{ borderColor:annotationColor(s.a) }"/></div>
          <div
            v-if="s.edit"
            class="resize bottom right"
            @mousedown="resize='bottom-right'"
            @mouseup="resize=false"
          ><div class="handle" :style="{ borderColor:annotationColor(s.a) }"/></div>
          <!-- remove button -->
          <q-btn
            v-if="s.edit"
            flat
            round
            size="sm"
            class="remove"
            @click="removeSelection(i)"
          ><i class="fas fa-trash q-icon"/></q-btn>
        </div>
      </div>

      <!-- remove source -->
      <q-btn
        v-if="!addSelection && !editSelection && mode=='teacher'"
        round
        size="sm"
        icon="fas fa-trash"
        class="remove-source"
        @click="$emit('remove')"
      />

      <!-- source info -->
      <q-btn
        v-if="!addSelection && !editSelection"
        round
        size="sm"
        icon="fas fa-info"
        class="source-info"
        @click="showInfo=!showInfo"
      />

      <!-- source info panel -->
      <source-info
        :mode="mode"
        :show.sync="showInfo"
        :source.sync="source"
        @store="$emit('updateSource')"
      />

    </div>
  </div>
</template>

<script>

import SourceInfo from '../components/SourceInfo';

export default {
  name: 'CompareSource',

  components: {
    SourceInfo
  },

  props: {
    noZoom: Boolean,       //true while parent is scrolling
    maxZoom:{
      type:Number,
      default:5
    },
    addSelection:Boolean,  //toggle drag-to-add selection mode
    index:{
      type:Number,
      default:0
    },
    mode: {
      type: String,
      default: 'student'
    },
    disabled: {
      type:Boolean,
      default:false
    },
    activeAnnotation:{
      type:String,
      default:''
    },
    annotations:{
      type:Array,
      default:function () {
        return []
      }
    },
    selections:{
      type:Array,
      default:function () {
        return []
      }
    },
    annotationColors:{
      type:Array,
      default:function () {
        return []
      }
    },
    source: {
      type: Object,
      default: function () {
        return {
          url: ''
        }
      }
    },
    singleSelection:{
      type:Boolean,
      default:false
    }
  },

  data () {
    return {
      activeSelection:false,
      editSelection:false,
      localSource:{},
      zoom:1,
      zoomed:false,
      showInfo:false,

      //values below are provided by zoomer component
      cx: 0, //center of zoomer image (includes pan transform)
      cy: 0,
      zw: 0, //current display width/height of zoomer image
      zh: 0,

      //draw/edit selection
      drag:false,
      resize:false,
      minimumSize:35,
      mx: -100,
      my: 0,
      container: {
        x:0,
        y:0,
        w:0,
        h:0
      },
    };
  },

  computed: {
    activeSelections () {
      return this.selections.filter(s => s.active==true);
    },
    containerElm () {
      return this.$refs.selections;
    },
    dragLeft () {
      return this.drag.w<0? this.drag.x + this.drag.w:this.drag.x;
    },
    dragTop () {
      return this.drag.h<0? this.drag.y + this.drag.h:this.drag.y;
    },
    dragWidth () {
      return Math.abs(this.drag.w);
    },
    dragHeight () {
      return Math.abs(this.drag.h);
    },
  },

  watch: {
    activeAnnotation (a) {
      this.activeSelection = false;
      this.selections.forEach(s => {
        s.active = s.a===this.activeAnnotation;
        s.edit = false;
        if (s.active) this.activeSelection = s;
      })
      if (this.singleSelection) {
        if (a && this.activeSelection) {
          //zoom & pan to selection
          this.fit();
        }
        else {
          this.$refs.zoomer.reset();
        }
      }
    },
    addSelection () {
      this.editSelection = false;
    },
  },

  mounted () {
  },

  methods: {

    annotationColor (id) {
      //get color for annotation id
      return this.annotationColors[this.annotations.find(a => a.id==id).color];
    },

    zoomerMounted () {
      //get container position
      this.$nextTick(() => { this.containerPosition() });
    },

    zoomerRefresh () {
      //force trigger zoomer refresh
      this.containerPosition();
      this.$refs.zoomer.onWindowResize();
    },

    zoomerUpdate (center,scale) {
      this.cx = center.x;
      this.cy = center.y;
      this.zw = scale.w;
      this.zh = scale.h;

      //broadcast active selection position
      let s = this.activeSelection;
      if (s) this.$emit('activeSelection',{
        x:this.selectionLeft(s.x) + (this.selectionWidth(s.w)/2),
        y:this.selectionTop(s.y) - 12,
        zoom:this.zoom
      })
    },


    //DOM event handling

    onClick (e,x,y) {
      if (this.disabled) return;

      this.showInfo = false;

      //click event received from zoomer: manually match against currend selections
      let onSelection = this.selections.some(s => {

        let sx = this.selectionLeft(s.x);
        let sy = this.selectionTop(s.y);
        let sw = this.selectionWidth(s.w);
        let sh = this.selectionHeight(s.h);

        if (x>=sx && x<=sx+sw && y>=sy && y<=sy+sh)
        {
          //switch to edit mode when already active
          if (s.active && s.editable)
          {
            //stop editing previous selection
            if (this.editSelection) this.activeSelection.edit = false;

            s.edit = true;
            this.editSelection = true;

            //notify tool
            this.$emit('editSelection',s.a);
          }
          s.active = true;

          this.activeSelection = s;

          //broadcast active annotation
          this.$emit('update:activeAnnotation',s.a);

          return true;
        }
      });

      //no match, reset active selection
      if (!onSelection)
      {
        this.resetSelection();
        this.$emit('update:activeAnnotation','');
      }

      //reset other/all selections
      this.selections.forEach(s => {
        if (s.a!=this.activeSelection.a)
        {
          s.active = false;
          s.edit = false;
        }
      })
    },

    onMouseDown (e) {
      this.startSelection(e.clientX,e.clientY)
    },
    onMouseUp () {
      if (this.addSelection) this.endSelection()
      if (this.editSelection) this.saveSelection()
    },
    onMouseMove (e) {
      this.passEvent(e);
      if (this.drag) this[(this.editSelection? 'update':'drag')+'Selection'](e.clientX, e.clientY)
    },
    onTouchStart (e) {
      if (e.touches.length === 1) this.startSelection(e.touches[0].clientX,e.touches[0].clientY)
    },
    onTouchEnd (e) {
      if (e.touches.length === 0) this.endSelection();
    },
    onTouchMove (e) {
      if (this.drag && e.touches.length === 1) this.dragSelection(e.touches[0].clientX, e.touches[0].clientY);
    },
    onWheel (e) {
      if (e.deltaY) e.preventDefault(); //block horizontal scrolling while zooming
      this.passEvent(e)
    },
    passEvent (event) {
      //pass event to zoomer elm
      this.$refs['zoomer'].$el.dispatchEvent(new event.constructor(event.type, event));
    },


    //selections

    startSelection (x,y) {
      this.containerPosition();
      this.drag = {
        x:x - this.container.x,
        y:y - this.container.y,
        w:0,
        h:0
      }
      if (this.editSelection)
      {
        let s = this.activeSelection;
        this.drag.sx = this.selectionLeft(s.x);
        this.drag.sy = this.selectionTop(s.y);
        this.drag.sw = this.selectionWidth(s.w);
        this.drag.sh = this.selectionHeight(s.h);
      }
    },

    dragSelection (tx,ty) {
      this.containerPosition();
      this.drag.w = tx - this.container.x - this.drag.x;
      this.drag.h = ty - this.container.y - this.drag.y;
    },

    endSelection () {
      //skip small selections
      if (this.dragWidth>=this.minimumSize && this.dragHeight>=this.minimumSize)
      {
        //create and insert new selection
        this.activeSelection = {
          active:true,
          edit:false,
          editable:true,
          a:this.activeAnnotation,
          x:this.selectionX(this.dragLeft),
          y:this.selectionY(this.dragTop),
          w:this.selectionW(this.dragWidth),
          h:this.selectionH(this.dragHeight)
        }
        this.selections.push(this.activeSelection);

        if (this.singleSelection) this.fit();

        this.storeSelections();
      }
      else if (this.dragWidth<=3 && this.dragHeight<=3)
      {
        //click: exit add-annotation mode
        this.$emit('addSelection',false);
      }

      this.drag = false;
    },

    updateSelection (tx,ty) {
      //get delta
      let dx = tx - this.container.x - this.drag.x;
      let dy = ty - this.container.y - this.drag.y;
      let x,y,w,h,drag = false;

      //calculate move/resize
      switch (this.resize)
      {
        case 'top-left':
          x = this.drag.sx + dx;
          y = this.drag.sy + dy;
          w = this.drag.sw - dx;
          h = this.drag.sh - dy;
          break;

        case 'top-right':
          y = this.drag.sy + dy;
          w = this.drag.sw + dx;
          h = this.drag.sh - dy;
          break;

        case 'bottom-left':
          x = this.drag.sx + dx;
          w = this.drag.sw - dx;
          h = this.drag.sh + dy;
          break;

        case 'bottom-right':
          w = this.drag.sw + dx;
          h = this.drag.sh + dy;
          break;

        default:
          //drag selection
          drag = true;
          x = this.drag.sx + dx;
          y = this.drag.sy + dy;
          break;
      }

      //apply to selection
      if (drag || (w>=this.minimumSize && h>=this.minimumSize))
      {
        let s = this.activeSelection;
        if (x) s.x = this.selectionX(x);
        if (y) s.y = this.selectionY(y);
        if (w) s.w = this.selectionW(w);
        if (h) s.h = this.selectionH(h);
      }
    },

    saveSelection () {
      this.drag = false;
      this.storeSelections();
    },

    resetSelection () {
      this.editSelection = false;
      if (this.activeSelection)
      {
        this.activeSelection.edit = false;
        this.activeSelection = false;
      }
    },

    removeSelection (index) {
      const remove = () => {
        this.activeSelection = false;
        //this.$emit('update:activeAnnotation','');
        this.selections.splice(index,1);

        //update store
        this.storeSelections();
      }

      if (!this.singleSelection) remove()
      else {
        //confirm first in single section mode (visual analysis)
        this.$q.dialog({
          title: '<i class="fa fa-trash"></i>&nbsp;Confirm Remove',
          message: '<p>Removing annotation</p><p>Are you sure?</p>',
          html: true,
          cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
          ok: { label: 'Yes', color: 'primary', noCaps: true, }
        }).onOk(remove)
      }
    },

    sortSelections () {
      //sort selections, smallest selections first so they can be selected when placed within larger ones
      this.selections.sort((a,b) => {
        return (a.w*a.h) - (b.w*b.h)
      })
    },

    storeSelections () {
      //sync selections to parent after sorting them
      this.sortSelections();
      this.$emit('storeSelections',this.selections);
    },


    //position translations

    containerPosition () {
      if (!this.containerElm) return;
      const { x, y, width, height } = this.containerElm.getBoundingClientRect();
      this.container = { x:x, y:y, w:width, h:height };
    },

    fit(selections = [this.activeSelection]) {
      //zoom & pan to selection(s)

      //HORIZONTAL
      let rangeX = selections.map(n => n.x);
      let minX = Math.min(...rangeX); //most left node
      let maxX = Math.max(...rangeX); //most right node
      maxX += Math.max(...(selections.filter(n => n.x==maxX).map(n => n.w))); //add (largest) width to right node
      let deltaX = maxX - minX;// + (this.zoomPadding/this.w);

      //VERTICAL
      let rangeY = selections.map(n => n.y);
      let minY = Math.min(...rangeY); //most top node
      let maxY = Math.max(...rangeY); //most bottom node
      maxY += Math.max(...(selections.filter(n => n.y==maxY).map(n => n.h ))); //add (largest) height to bottom node ->NEEDS REVIEW: we need the lowest point, not the tallest height
      let deltaY = maxY - minY;// + (this.zoomPadding/this.h);

      //calculate (smallest) target scale
      let scale = Math.min((this.container.w/2) / (deltaX * (this.zw/this.zoom)), (this.container.h/3) / (deltaY * (this.zh/this.zoom)));

      //cap it
      scale = Math.min(this.maxZoom,Math.max(1,scale));

      //calculate target position for calculated scale
      let tx = (minX + (deltaX/2)) * scale * (this.zw / this.zoom / this.container.w);
      let ty = (minY + (deltaY/3)) * scale * (this.zh / this.zoom / this.container.h); //slightly below center

      //apply to vue-zoomer
      this.$refs.zoomer.zoomIn(scale/this.zoom,{ x:-tx, y:-ty })
    },

    //pixel position/size of selection elm
    selectionLeft (x) {
      return this.cx + (x * this.zw);
    },
    selectionTop (y) {
      return this.cy + (y * this.zh);
    },
    selectionWidth (w) {
      return w * this.zw;
    },
    selectionHeight (h) {
      return h * this.zh;
    },

    //relative position/size of selection  elm
    selectionX (x) {
      return (x - this.cx) / this.zw;
    },
    selectionY (y) {
      return (y - this.cy) / this.zh;
    },
    selectionW (w) {
      return w / this.zw;
    },
    selectionH (h) {
      return h / this.zh;
    }
  },
}
</script>

<style scoped>

.source
{
  position:relative;
  background-color:#fefefe;
  border-left:1px solid rgba(254,254,254,.5);

  overflow:hidden;
}
.source.remove
{
  transition:
    opacity .3s linear 0s,
    width .4s ease-in .5s;

  width:0 !important;
  opacity:0;
}
.source:nth-child(odd)
{
  background-color:#f2f2f2;
  /* box-shadow: -2px 0 5px rgba(0,0,0,.1); */
}

.remove-source
{
  position:absolute;
  right:50%;
  transform:translateX(50%);
  bottom:15px;
  background-color:rgba(0,102,204,.5);
}


.selections
{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  pointer-events:none;
  user-select: none;
}

.selections.active,
.selections.drag
{
  pointer-events:all;
}
.selections.active .selection:not(.new):not(.active)
{
  opacity:.3;
}

.mask
{
  position:absolute;
  left:0;
  top:0;
  transition:opacity .6s ease 0s;
  opacity:0
}
.mask.active
{
  opacity:1;
}
.selections.active .mask.active
{
  opacity:0;
}
.mask > .fade
{
  fill:white;
  opacity:.3;
}

.selection
{
  position:absolute;
  border:2px solid;

  /* pointer-events:all; */

  box-shadow:
    1px 1px 3px rgba(0,0,0,.2),
    1px 1px 3px rgba(0,0,0,.2) inset;

}
.selection:not(.new):not(.active)
{
  border-color:rgba(255,255,255,.5) !important;
}
.selection.active
{
  /* border-color:magenta; */
}
.selection.edit
{
  border-color:red;
  pointer-events:auto;
  cursor:move;
  z-index:2000;
}

.resize
{
  position:absolute;
  padding:5px;
  width:20px;
  height:20px;
}
.resize .handle
{
  border:2px solid;
  width:10px;
  height:10px;
}
.resize.top
{
  top:-15px;
  cursor:n-resize;
}
.resize.left
{
  left:-15px;
  cursor:e-resize;
}
.resize.right
{
  right:-15px;
  cursor:w-resize;
}
.resize.bottom
{
  bottom:-15px;
  cursor:s-resize;
}
.resize.top.left,
.resize.bottom.right
{
  cursor:nwse-resize;
}
.resize.top.right,
.resize.bottom.left
{
  cursor:nesw-resize;
}



.selection > .remove
{
  position:absolute;
  right:50%;
  bottom:2px;
  transform: translateX(50%);
  background-color:rgba(0,102,204,.5);
}

</style>