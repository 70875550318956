<template>
  <section>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 100"
      class="logo svg replaced-svg"
      :style="getSize"
    >
      <path
        class="cls-1"
        :style="{ fill: color }"
        d="M53.36,0V26.86H19.53V0H0V100H19.24l4.47-24.23h-.07L19.82,63.93h0a17.19,17.19,0,1,1,33.24,0h0L49.26,75.77h-.08L53.65,100H72.89V0Z"
      />
    </svg>
  </section>
</template>
<script>
const sizes = {
  xs: 18,
  sm: 24,
  md: 32,
  lg: 38,
  xl: 46,
  xxl: 138,
};

export default {
  name: "HiLogo",
  props: {
    color: {
      type: String,
      default: "#9c9c9c",
    },
    size: {
      type: String,
      default: "md",
    },
  },
  computed: {
    getSize() {
      return {
        height: this.size in sizes ? `${sizes[this.size]}px` : this.size,
      };
    },
  },
};
</script>
<style scoped>
.logo.svg {
  margin: 0px;
  padding: 0px;
  margin-right: 0.6em;
}

.logo.svg path {
  fill: #9c9c9c;
  color: red;
}

.logo.svg:hover path {
  fill: blue;
  color: #f5f5f5;
  transition-duration: 500ms;
  transition-timing-function: linear;
  transition-duration: 0.5s;
  transition-timing-function: all;
  transition-delay: 0s;
}
</style>
