<template>
  <div id="tool">
    <div id="canvastool">
      <!--  #toolspecs ONLY TEACHERS view -->
      <ul
        v-if="mode==='teacher'"
        class="menu"
        id="actionbar"
      >
        <router-link
          title="Add sources from MySources"
          to="/MediaLibrary"
        >
          <li class="addimages">
            <span class="txt">
              Add sources
            </span>
          </li>
        </router-link>
      </ul>

      <div
        id="set"
        class="row1"
        :key="`img${i}`"
        v-for="(img,i) in ximages"
      >
        <div
          class="img-src"
          :id="i"
          :data-position="JSON.stringify(img.position)"
        >
          <i
            class="fa fa-info"
            @click="showMetaInfo(img,i)"
          />
          <img
            :src="img.url"
            width="100%"
            height="100%"
          >
        </div>
      </div>

      <div
        id="set"
        class="row1"
        :key="i"
        v-for="(img,i) in ximages"
      />

      <!--
<div class="row">
  <div class="answer">
    <h1>Explain...</h1>
    <p style="white-space: pre">{{ message }}</p>
    <br>
    <textarea v-model="message" placeholder="add multiple lines"></textarea>
  </div>
</div>
-->
      <q-btn
        style="position: absolute; bottom: 0; right: 0;"
        filled
        x-if="mode==='student'"
        label="Save position"
        :disabled="!needSave"
        @click="submitAnswer"
        color="primary"
        class="q-ma-md float-right"
      />



      <Gallery
        v-show="show"
        :is-view="isView"
        :item="showItem"
        :total="totalImages"
        :current="currentFocus"
        :show-delete="true"
        :mode="mode"
        @delete="deleteItem"
        @close="close"
        @prev="prev"
        @next="next"
        @toggleEdit="isView=!isView"
        @saveChanges="saveChanges"
      />







    </div>



  </div>
</template>



<script>
import Gallery from '../tags/Gallery'
import tic from '../tic'

import $ from 'jquery'
import 'jquery-ui'
import 'jquery-ui/ui/widgets/resizable'
import 'jquery-ui/ui/widgets/sortable'
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/widgets/droppable'
import 'jquery-ui/ui/disable-selection'
import 'jquery-ui/themes/base/all.css'

export default {
  components: {
    Gallery
  },

  mounted: function () {
    let me = this
    me.mode = this.$route.path.includes('student') ? 'student' : 'teacher'

    this.record = this.$store.getters['activity/getCurrentRecord'](this.current)

    $(document).ready(function () {
      $('#canvastool').css('background-image', 'url("/static/img/sorting/Prioritize_blue.svg")')

      $("#set .img-src").resizable({
        aspectRatio: 2 / 2
      });

      $("#set .img-src").draggable({
        stack: "#set .img-src",
        containment: "#canvastool",
        scroll: false,
        stop: function (event, ui) {
          if (me.mode === 'student') {
            // set the new position as an answer
            me.$set(me.answer, event.target.id, ui.position)
            me.needSave = true
          }
          me.$store.commit('activity/setBlockImagePosition', {
            image: event.target.id,
            position: ui.position
          })
        }
      });

    }) // end ready
  },

  updated () {

    // we need to check the current type because the update fires on others as well
    // and then this code might execute. Caused wrong backgrounds in sort/prio    
    console.log("*** bb-priotizing updated")
    if (this.$store.getters['activity/getRecordType'] === 'prioritizing') {
      $('#canvastool').css('background-image', 'url("/static/img/sorting/Prioritize_blue.svg")')
    }

    $('.img-src').each(function (i, el) {
      //console.log("el in updated: ", el, el.dataset.position)
      if (el.dataset && el.dataset.position) {
        $(el).css(JSON.parse(el.dataset.position));
      }
    })

  },

  methods: {
    submitAnswer () {
      // pass answer to parent (studentView)
      console.log("submitanswer: ", JSON.stringify(this.answer))
      this.$emit('answerFor', { block: this.current, answer: JSON.parse(JSON.stringify(this.answer)) })
      this.needSave = false
    },

    saveChanges (e) {
      let uuid = e.uuid
      this.$store.commit('activity/setImageMeta', { current: this.current, uuid: uuid, item: this.showItem })
    },
    deleteItem (e) {
      // console.log("deleteItem bb-sorting")
      // get array index of item to delete
      let toDelete = this.record.images.indexOf(e)
      this.record.images.splice(toDelete, 1)
      this.show = false

    },
    prev () {
      if (this.record.images[this.currentFocus - 1]) {
        this.showItem = this.record.images[this.currentFocus - 1]
        this.currentFocus--
      }
    },
    next () {
      if (this.record.images[this.currentFocus + 1]) {
        this.showItem = this.record.images[this.currentFocus + 1]
        this.currentFocus++
      }

    },

    showMetaInfo: function (item, index) {
      console.log("item: ", item)
      this.showItem = JSON.parse(JSON.stringify(item))
      this.show = true
      this.currentIndex = index
    },
    close: function () {
      this.show = false
      // disable edit mode on close
      this.isView = true
    }
  },
  computed: {
    totalImages () {
      let cb = this.$store.getters['activity/currentBlock']
      if (cb.background) {
        $('#canvastool').css('background-image', `url("${cb.background}")`)
      }
      //console.log("###### cb: ", cb)  
      if (this.$store.getters['activity/getCurrentRecord'](cb).images) {
        return this.$store.getters['activity/getCurrentRecord'](cb).images.length
      } else {
        return 0
      }
    },
    current () {
      return tic.getCurrent(this.$route.path)
    },
    ximages () {
      // make sure the data is there
      this.$store.commit('activity/updateNavigation', this.current)
      let cb = this.$store.getters['activity/currentBlock']

      // forceer positie plaatjes :)
      // console.log("update pics via computed.ximages")

      $('.img-src').each(function (i, el) {
        //console.log("el in updated: ", el, el.dataset.position)
        if (el.dataset && el.dataset.position) {
          $(el).css(JSON.parse(el.dataset.position));
        }
      })
      return this.$store.getters['activity/getCurrentRecord'](cb).images
    }
  },

  data () {
    return {
      mode: 'teacher',
      isView: true,
      show: false,
      showItem: {},     // the current gallery item     
      currentFocus: 0, // index of the current gallery item for next/prev      
      record: {
        images: [{}]
      },
      answer: {},
      needSave: false
    }
  }
}
</script>

<style scoped>
.img-src {
  position: relative;
  z-index: 0;
  overflow: hidden;
  min-height: 100px;
  min-width: 100px;
  background-color: #575757;
  float: left;
  margin: 6px;
}
#set i {
  z-index: 3;
  position: absolute;
  bottom: 4px;
  left: 4px;
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: #0ab;
  padding: 6px 0 0 12px;
  border-radius: 40px 40px 40px 0;
}
.dropdown-pane {
  border: 1px solid #f60;
}
#canvastool {
  background-image: url("/static/img/sorting/Prioritize_blue.svg");
  background-repeat: repeat-y;
}
</style>