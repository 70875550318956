// NOTE: Vue3 recommends to not use mixins anymore
// https://vuejs.org/api/options-composition.html#mixins
// refactor when we can
// used in:
// components/hiFilter.vue
// components/hiSearch.vue
// tags/Card.vue
// pages/Search.vue
// pages/PartnerIndex.vue
// tags/AVCard.vue

export default {
    computed: {
        getColor(e) {
            //console.log("getColor mixin: ", e.$route.meta.color)
            if (!e.$route.meta.color) {
                console.log(`getColor: no meta.color defined for ${e.$route.path}`)
            }
            return e.$route.meta.color ? e.$route.meta.color : null
        }
    }
}
