<!--
  can we isolate this more in such a way that event handling is done here 
  and that the calling parent receives the update just via the v-model? 

  see https://jsfiddle.net/6h8gam1c/

  what is that this.$parent.$emit there? 

-->
<template>
  <div>
    <q-select
      v-model="selectedOption"
      autocomplete="off"
      :options="options"
      option-value="code"
      option-label="name"
      @filter="findName"
      use-input
      input-debounce="10"
      label="Country"
      @input="selectChanged"
    />
  </div>
</template>

<script>
  // get the data
  import {API} from '../tic'
  export default {

    name: 'CountrySelect',
    // set default selected to '' in order to hilite Choose..
    // when no value is found
    props: {
      selected: {
        type: String,
        default: ''
      }
    },

    mounted () {
      // get data when we need
      //this.selectedOption = this.selected;
      API.get('/ac/country')
      .then((response) => {
        this.countries = response.data
        this.options = response.data
        // we receive current country code from user record
        // look it up and set relevant object as current
        this.selectedOption = this.countries.find((v) => {
          return v.code === this.selected
        })
      })
    },    
  
    methods: {

      findName (val, update, abort) {
        update(() => {
          const needle = val.toLowerCase()
          // tagKeywords.filter(v.name => v.name.toLowerCase().indexOf(needle) > -1)
          this.options = this.countries.filter(v => v.name.toLowerCase().indexOf(needle) > -1)
        })
      },

      selectChanged () {
        // this calls the parent update function
        // as defined in the parent by the 
        // <countryselect @update="updateCountry" ... >
        // is this really needed; can't we just use v-model in the calling parent?
        this.$emit('update', this.selectedOption.code)
      }
    },

    data () {
      return {
        selectedOption: '',
        options: [],
        countries: []
      }
    }
  }
</script>