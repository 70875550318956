import Vue from "vue"
import { Screen } from "quasar"

import "./styles/quasar.styl"
import "@quasar/extras/material-icons/material-icons.css"
//import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
// import "@fortawesome/fontawesome-pro/css/fontawesome.css"
// import "@fortawesome/fontawesome-pro/css/light.css"
// // do you want these too?
// // import '@fortawesome/fontawesome-pro/css/brands.css'
// import "@fortawesome/fontawesome-pro/css/solid.css"
// import "@fortawesome/fontawesome-pro/css/regular.css"
// import "@fortawesome/fontawesome-pro/css/all.css"



// als je @fortawesome/fontawesome-pro/js/all.js gebruikt
// dan zijn de iconen plots veel groter, maar dat is dan ook niet het juiste bestand
//import iconSet from 'quasar/icon-set/fontawesome-v5.js'
import iconSet from "quasar/icon-set/fontawesome-v5-pro.js"

import Quasar from "quasar"

// config Quasar here
Vue.use(Quasar, {
  config: {
    screen: {
      bodyClasses: true // <<< add this
    },
    loadingBar: {
      skipHijack: true
    }
  }
})
