<template>
  <!--
    select License; automatically retrieves all existing licenses from back-end
    general purpose; currently used in CollectionEditor
  -->
  <section>
    <q-select
      v-model="license"
      fill-input
      :options="items"
      label="License"
      option-label="name"
      option-value="uuid"
      :hint="hint"
      @input="$emit('input', license)"
    >
      <template v-slot:selected v-if="license">
        {{ license.code }}
      </template>
      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
<!--
          <q-item-section avatar>
            <q-img :src="scope.opt.icon" />
          </q-item-section>
 -->
          <q-item-section>
            <q-item-label>{{ scope.opt.code }}</q-item-label>
            <q-item-label caption style="font-size:.875rem">{{ scope.opt.name }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </section>

</template>

<script>
export default {
  name: "HiLicense",
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    hint: {
      type: String,
      default: "",
    },

    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      license: null,
    };
  },

  watch: {
    value: {
      handler() {
        this.license = this.value;
      },
      deep:true,
      immediate:true
    }
  }

};
</script>
