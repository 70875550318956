<template>
  <div v-if="!$q.loading.isActive" class="historical-content">
    <section id="searcheuropeana">
      <div class="row q-mx-auto q-py-md justify-between items-center">
        <div class="col q-pr-md">
          <q-input
            bottom-slots
            placeholder="Find sources from within Europeana"
            type="text"
            v-model="searchFor"
            use-input
            debounce="1000"
            style="max-width:960px;"
            class="biggerfont"
          >
            <template v-slot:prepend>
              <q-icon name="fa fa-search" />
            </template>
            <template v-slot:append>
              <q-icon
                name="fa fa-close"
                @click="searchFor = ''"
                class="cursor-pointer"
              />
            </template>
          </q-input>
        </div>
      </div>
    </section>

    <section id="card-holder" v-if="hasResults">
      <div class="row q-mx-auto q-py-md justify-between items-center">
        <div class="row q-gutter-md">
          <div class="col">
            <p>
              {{ result.totalResults }} items match
              <em>{{ searchFor }}</em>
            </p>
          </div>

          <div class="col">
            <q-select
              @input="reLoad"
              v-model="showMax"
              :options="itemsPerPage"
              label="Items per page"
            />
          </div>

          <div class="col">
            <q-btn-group class="float-right">
              <q-btn
                :disable="!prevCursor.length"
                @click="prevnext('prev')"
                icon="fas fa-chevron-double-left"
                label="Prev"
                hint="Previous"
                no-caps
                class="bg-sas"
              />

              <q-btn
                @click="prevnext('next')"
                icon="fas fa-chevron-double-right"
                hint="Next"
                label="Next"
                no-caps
                class="bg-sas"
              />
            </q-btn-group>
          </div>
        </div>
      </div>

      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md">
          <div :key="i" v-for="(card, i) in items">
            <Card
              :index="i"
              :item="card"
              type="Europeana source"
              csstype="euresult"
              :title="card.title[0]"
              :description="card.dcDescription ? card.dcDescription[0] : ''"
              :image-url="card.edmPreview ? card.edmPreview[0] : ''"
              uuid
              url
              :partner="partner"
              :is-owner="false"
              :btn-add="true"
              :show-meta-button="true"
              :meta="meta(card)"
            >
              <div class="card-description" v-if="card.dcDescription">
                {{ card.dcDescription.join(' ') }}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div
        class="row q-mx-auto q-py-md justify-between items-center"
        v-if="searchFor && result && result.totalResults === 0"
      >
        No results for {{ searchFor }}
      </div>
    </section>
  </div>
</template>
<script>
import { API } from '../tic';
import Card from '../tags/Card';

export default {
  name: 'EuropeanaSearch',
  components: { Card },
  watch: {
    searchFor: function() {
      this.isLoading = true;
      this.$q.loading.show({
        delay: 400 // ms
      });

      API.post('/sas/search', {
        searchFor: this.searchFor,
        showMax: this.showMax
      }).then(resp => {
        //console.log("data: ", resp.data.data.items)
        this.result = resp.data.data;
        this.isLoading = false;
        this.hasResults = Boolean(resp.data.data.totalResults);
        this.$q.loading.hide();
      });
    }
  },
  computed: {
    items() {
      return this.result.items
        ? this.result.items.filter(e => {
            return e.edmPreview;
          })
        : [];
      //return this.result.items
    }
  },

  methods: {
    reLoad() {
      console.log('changed itemsperpage');
      this.$q.loading.show({
        delay: 400 // ms
      });
      API.post('/sas/search', {
        searchFor: this.searchFor,
        showMax: this.showMax
      }).then(resp => {
        this.result = resp.data.data;
        this.isLoading = false;
        this.hasResults = Boolean(resp.data.data.totalResults);
        this.$q.loading.hide();
      });
    },
    prevnext(what) {
      this.isLoading = true;

      if (what === 'next') {
        this.prevCursor.push(this.result.nextCursor);
      }

      // get next or prev cursor
      API.post('/sas/search', {
        searchFor: this.searchFor,
        showMax: this.showMax,
        cursor: what === 'next' ? this.result.nextCursor : this.prevCursor.pop()
      }).then(resp => {
        this.result = resp.data.data;
        this.isLoading = false;
        this.hasResults = Boolean(resp.data.data.totalResults);
      });
    },
    meta(item) {
      let fields = [
        { label: 'title', value: item.title.join(',') },
        { label: 'provider', value: item.provider.join(',') || null },
        {
          label: 'dataprovider',
          value: item.dataProvider ? item.dataProvider.join(',') : null
        },
        {
          label: 'year',
          value: item.year ? item.year.join(',') : null
        },
        {
          label: 'Eu collection',
          value: item.europeanaCollectionName
            ? item.europeanaCollectionName.join(',')
            : null
        },
        {
          label: 'country',
          value: item.country ? item.country.join(',') : null
        },
        {
          label: 'created',
          value: item.timestamp_created ? item.timestamp_created : null
        },
        { label: 'id', value: item.id ? item.id : null },
        { label: 'type', value: item.type ? item.type : null }
      ].filter(e => {
        return e.value;
      });

      return {
        type: 'europeana',
        objectType: item.type,
        fields: fields
      };
    }
  },

  mounted() {
    console.log('MOUNT EU SEARCH');
    this.hasResults = false;

    this.$q.loading.show({
      delay: 400 // ms
    });

    // all content in this module is Europeana's
    // get the partner info for europeana
    API.get('/partners/europeana').then(resp => {
      console.log('GET PARTNER INFO: ', resp.data.partner);
      this.partner = resp.data.partner;
      this.$q.loading.hide();
    });
    this.$q.loading.hide();
  },

  data() {
    return {
      partner: {},
      isLoading: true,
      hasResults: false,
      searchFor: '',
      result: null,
      prevCursor: [],
      showMax: 10,
      itemsPerPage: [10, 25, 50, 100]
    };
  }
};
</script>
<style>
#searcheuropeana .q-placeholder {
  color: #9c9c9c;
  font-size: 1.65rem;
}
</style>
