<template>
  <span>
    <q-dialog ref="loginBox" :value="showDialog" @hide="$emit('hide')">
      <q-card
        class="q-pa-md q-gutter-sm animated"
        style="width: 700px; max-width: 80vw;"
        id="login_modal"
        :class="{ animate__shakeX: doShake }"
      >
        <q-card-section>
          <h4 class="q-mb-lg"><i class="fa fa-lock"></i>&nbsp;Login to Historiana</h4>

          <p>
            You do not have an account?
            <RegisterButton inline class="q-ml-sm"/>
          </p>

          <p>
            Forgot your login or password?
            <ResetPasswordButton inline class="q-ml-sm" />
          </p>
        </q-card-section>

        <!-- error box --->
        <q-badge
          v-if="msg"
          class="q-pa-sm q-gutter-sm"
          color="yellow-6"
          text-color="black"
        >
          <q-icon left name="fas fa-exclamation-triangle" size="14px" />
          {{ msg }}
        </q-badge>

        <q-card-section>
          <q-form @submit="do_login(false)">
            <q-input
              autocomplete="email"
              type="text"
              v-model="login"
              id="login"
              placeholder="Your Login or E-mail"
              label="Email"
            />

            <q-input
            style="padding-top: 1em"
              type="password"
              autocomplete="current-password"
              v-model="password"
              id="passwd"
              placeholder="Your password"
            />

            <q-checkbox
            style="padding-top: 1em"
              label="Keep me logged in"
              v-model="autologin"
              id="autologin"
            />
          </q-form>
        </q-card-section>
          <q-card-actions class="q-gutter-sm row justify-center">
              <q-btn no-caps size="small" v-close-popup label="Cancel" />
              <q-btn no-caps type="submit" color="primary" @click="do_login(false)">Login</q-btn>
          </q-card-actions>
        </q-card-section></q-card
      >
    </q-dialog>
  </span>
</template>
<script>
import { API } from "../tic";
import RegisterButton from "@/components/RegisterButton.vue";
import ResetPasswordButton from "@/components/ResetPasswordButton.vue";

export default {
  name: "Login",
  components: { RegisterButton, ResetPasswordButton },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    do_login(e, gotoProfile) {
      var me = this;
      me.doShake = false;

      me.gotoProfile = gotoProfile;
      if (!this.login) {
        me.msg = "Please enter your login";
        return;
      }
      API.post("/login", {
        login: me.login,
        password: me.password,
      })
        .then((response) => {
          if (response.data.isError) {
            me.doShake = true;
            me.msg = response.data.msg;
          } else {
            me.$store.commit("user/setUser", response.data);
            // console.log('**** SET LOCAL STORAGE *****', response.data.data.session.sessionId)
            // console.log('sess: ', me.$localStorage, response.data.data.session.sessionId)
            // console.log('auto: ', me.autologin)
            // console.log('####: ', me.$localStorage)
            window.localStorage.setItem(
              "session",
              response.data.data.session.sessionId
            );
            window.localStorage.setItem("autologin", me.autologin);
            me.$store.commit("showRegister", false);
            me.$store.commit("showLogin", false);
            me.$store.dispatch("Welcome");
            if (me.gotoProfile) {
              me.$router.push("/my/profile");
            }
          }
        })
        .catch((error) => {
          me.$q.dialog({
            title: "Error:",
            message: `${error.response.data.data.msg}`,
          });
        });
    },
  },

  data() {
    return {
      showLogin: false,
      doShake: false,
      msg: "",
      login: null,
      password: "",
      autologin: true,
    };
  },
};
</script>
