<!-- HOMEPAGE BUILDER EDITOR WITH ACTIVITY CARD -->
<template>
  <div>
    <div id="activity-builder">
      <!--li>{{$store.state.activity.bblocks}}</li-->
      <!-- Home e-Learning Activity Builder -->
      <section id="AB-homepage">
        <div
          class="row q-mx-auto q-pa-sm justify-between items-center"
          id="AB-intro"
        >
          <div class="col q-pt-md">
            <h3>{{ mode }} your e-Learning Activity</h3>
            <!-- isChanged {{ isChanged }} -->
            <!--loggedIn: {{ this.$store.getters['user/uuid']}}</li>
                <li>user: {{ this.$store.state.user.uuid}}</li>
                <li>activity: {{this.$store.state.activity.uuid}}</li>
          <li>mode: {{mode}}</li>-->
            <!-- <li>{{this.$store.state.activity.bblocks}}</li>-->
          </div>
          <!-- <div class="col-shrink">
            <q-dialog v-model="showMoreInfo">
              <q-card
                class="q-gutter-md"
                style="width: 740px"
              >
                <h6>Explanation about the IndexCard, the Building Blocks and how it all connects.</h6>
                <q-card-section><img src="/static/img/3.Steps.jpg"></q-card-section>
              </q-card>
            </q-dialog>

            <q-btn
              v-show="$store.getters['user/showBeta']"
              @click="showMoreInfo=!showMoreInfo"
              icon="fa fa-info"
              label="More information"
              size="md"
            />
          </div> -->
        </div>

        <div class="row q-mx-auto q-pb-xl justify-between items-start">
          <div class="col-shrink q-pt-md q-pb-xl q-pr-xl">
            <!-- ===== Example Card e-Learning Activities ===== -->
            <div class="bb-ela">
              <q-card class="no-shadow">
                <q-card-section class="type ela"
                  >e-Learning Activity</q-card-section
                >

                <!--                       :on-preview="handlePreview"
              :on-remove="handleRemove"-->
                <img
                  v-if="
                    $store.getters['activity/getImage'] &&
                      $store.getters['activity/getImage'].url
                  "
                  :src="$store.getters['activity/getImage'].url"
                  width="280"
                  height="210"
                  style="object-fit:contain;width:280px; height:210px;"
                />
                <img
                  v-else
                  src="//via.placeholder.com/280x210"
                  width="280"
                  height="210"
                />
                <!-- Old scenario: 'Upload' or go to 'MySources'. Should become a pencil icon which opens the media library. A user can then choose to add a source or to upload and choose this source. Note: when 'Upload' the it is logical to immediately add that source.  -->
                <q-card-section
                  style="margin:0px; padding:0; margin-bottom:12px;"
                >
                  <q-btn-group spread>
                    <q-btn
                      size="md"
                      color="primary"
                      no-caps
                      @click="pickFile"
                      label="Upload"
                      icon="fa fa-upload"
                    />
                    <!-- <q-btn
                      size="md"
                      no-caps
                      color="secondary"
                      label="MySources"
                      icon="fas fa-images"
                      @click="
                        $q.dialog({
                          dark: true,
                          title: 'Alert',
                          message: 'Not implemented yet'
                        })
                      "
                    /> -->
                  </q-btn-group>
                  <!-- triggered by upload button but hidden in UI to remove the noise -->
                  <q-uploader
                    v-show="false"
                    url="/api/upload"
                    ref="uploader"
                    :multiple="false"
                    auto-upload
                    @uploaded="getUpInfo"
                    :headers="headers"
                  />
                </q-card-section>

                <q-card-section>
                  <!--
                        <editable tag="h5" class="title" placeholder="Activity Title" :value="activity.title" v-on:update="function(t) { activity.title = t; }"></editable>
                        <editable tag="p" class="description" placeholder="Description of your e-Learning Activity" :value="activity.description" v-on:update="v => { activity.description = v; saveActivity('description') }"></editable>
                -->
                  <!-- <textarea
                    class="title"
                    placeholder="Title of Activity"
                    v-model="title"
                    @change="saveA"
                  /> -->
                  <q-input
                    v-model="title"
                    @change="saveActivity"
                    placeholder="Title of Activity"
                    filled
                    type="textarea"
                    autogrow
                    style="margin-bottom:6px;"
                  />
                  <q-input
                    v-model="description"
                    @change="saveActivity"
                    placeholder="Description of your e-Learning Activity"
                    filled
                    type="textarea"
                  />
                </q-card-section>
              </q-card>
            </div>
          </div>
          <div class="col q-pt-md q-pb-xl">
            <!-- ABPanel communicates with the store directly -->
            <AbPanel ref="theabpanel" @saveActivity="saveActivity" />
          </div>

          <hr />
          <!--ul>
      <li v-for="b in $store.getters['activity/blocks']">
        {{b}}
      </li>
        </ul-->
        </div>
      </section>
    </div>
    <pre v-if="$store.state.activity.debug">
      {{ JSON.stringify(activity, null, 2) }}
    </pre>
  </div>
</template>

<script>
import AbPanel from '../eabuilder/ABPanel';
import { mapGetters } from 'vuex';
import $ from 'jquery';
import 'jquery-ui';

export default {
  name: 'Builder',

  components: {
    AbPanel
  },

  props: {
    uuid: {
      type: String,
      default: ''
    }
  },

  data() {
    let me = this;
    return {
      showMoreInfo: false,
      isInitial: true,
      builderHome: null, // the url for this instance (for Back button)
      activeTags: null,
      // activity: {},
      is_placeholder: true,
      isActive: false,
      headers: [
        // these are needed in the API to identify the user
        { name: 'X-Session', value: me.$store.getters['user/session'] },
        { name: 'X-Process', value: 'createSource' }
      ]
    };
  },

  computed: {
    ...mapGetters('activity', ['activity', 'blockOrder', 'isChanged']),

    ...mapGetters({
      mode: 'activity/action'
      //      activity: 'activity/activity'
    }),

    title: {
      set(title) {
        // this.$store.commit('ea/SET_ACTIVITY', {title})
        this.$store.commit('activity/updateTitle', title);
      },

      get() {
        return this.activity.title;
      }
    },

    description: {
      set(description) {
        this.$store.commit('activity/updateDescription', description);
        // this.$store.commit('ea/SET_ACTIVITY', {description})
      },

      get() {
        return this.activity.description;
      }
    },

    imageUrl() {
      return this.$store.getters['activity/image'];
    },

    aTitle: {
      get() {
        return this.$store.getters['activity/title'];
      },

      set(value) {
        this.$store.commit('activity/updateTitle', value);
      }
    },

    aDescription: {
      get() {
        return this.$store.state.activity.description;
      },
      set(value) {
        this.$store.commit('activity/updateDescription', value);
      }
    }
  },

  //   created () {
  //     console.log("### BUILDER.VUE CREATED ###", this.uuid)
  //     let vm = this;
  //     // load the activity if there is a uuid set
  //     if (this.uuid) {
  //       console.log("LOAD ACTIVITY IN CREATED()")
  //       this.$store.dispatch("activity/load", {
  //         route: this.$route,
  //         uuid: this.uuid,
  //         mode: "teacher"
  //       })
  //         .then(() => {
  //           // update ABpanel with the loaded blocks
  //           vm.builderHome = this.uuid
  //           vm.$refs.theabpanel.updateBlocks()
  //           // me.mode='Edit'
  //         })
  //     }
  //   },

  mounted: function() {
    console.log('□□ Builder: mounted, activity changed=', this.isChanged);

    if (this.isChanged) {
      //save
      console.log('□□ Builder: save activity');
      this.saveActivity();
    } else if (this.$route.params.uuid) {
      //load activity if we have uuid
      console.log(
        '□□ Builder: load activity with uuid=',
        this.$route.params.uuid
      );
      this.loadActivity();
    }

    //let me = this;

    //->review code below!

    // only load the activity if ?load is in the url
    //     if ("load" in this.$route.query) {
    //       this.$store.dispatch("activity/load", {
    //         route: this.$route,
    //         uuid: this.uuid,
    //         mode: "teacher"
    //       });
    //     }

    //console.log("thumb: ", this.$store.getters['activity/getImage'].url)
    // https://vuejs.org/v2/guide/migration.html#ready-replaced
    // Use the new mounted hook instead. It should be noted though that with mounted,
    // there’s no guarantee to be in-document. For that, also include Vue.nextTick/vm.$nextTick.
    // For example:

    //->disabled for review
    /*
        this.$nextTick(function () {
          // $(document).ready(function() {
          //   $("body")
          //     .removeClass()
          //     .addClass("is-activity-builder");
          //   // we loose the selected when moving back to the builder; force it
          //   $("#main-menu-activity-builder").addClass("selected");
          // });

          if (
            this.$route.params.uuid &&
            !this.$store.getters["activity/isChanged"]
          ) {



            console.log("LOAD ACTIVITY :", this.uuid);
            this.$store
              .dispatch("activity/load", {
                route: this.$route,
                uuid: this.$route.params.uuid,
                mode: "teacher"
              })
              .then(() => {
                // update ABpanel with the loaded blocks
                me.builderHome = this.$route.params.uuid;
                me.$refs.theabpanel.updateBlocks();
                // me.mode='Edit'
              });
          } else if (
            this.$route.params.uuid && this.isChanged
          ) {

            console.log('□□ Builder: save changed activity');

            me.saveActivity()

            // me.mode='Edit'

            //me.saveActivity("backclick"); //->moved this to BlockEditor
          } else {
            me.builderHome = "/builder";
            //console.log("KEEP CURRENT")
            //console.log("title: ", me.$store.getters['activity/title'])
            //console.log("keep current activity ", this.$store.getters['activity/getImage'])
            // $(myVueDropzone).css({
            //    background: 'white url('+this.$store.getters['activity/getImage'].url+') no-repeat center',
            //    backgroundSize: 'cover'
            //  })
          }
          //console.log("DONE")
        });
    */
  },

  methods: {
    loadActivity() {
      this.$store.dispatch('activity/load', {
        route: this.$route,
        uuid: this.$route.params.uuid,
        mode: 'teacher'
      });
    },

    getUpInfo(info) {
      let resp = JSON.parse(info.xhr.response);
      this.$store.commit('activity/setImage', resp);
      this.$refs.uploader.reset();
    },

    pickFile() {
      this.$refs.uploader.pickFiles();
      // upload is triggered with auto-upload!
    },

    discard() {
      this.activity.title = '';
      this.activity.description = '';
    },

    saveA(arg) {
      console.log('save: ', arg);
    },

    removeTag(tag, iets) {
      $('#' + iets).addClass('active');
      this.$store.commit('activity/removeTag', tag);
    },

    uploadSuccess(file, rsp) {
      this.$store.commit('activity/setImage', rsp.response);
      // save this change
      this.saveActivity('dropzone');
      //this.$store.commit('activity/setImage', rsp.response)
    },

    uploadRemoved() {
      //remove uploaded file url from activity
      this.$store.commit('activity/removeImage');
      this.saveActivity('dropzone');
    },

    uploadError() {
      this.$notify.error({
        title: 'Error uploading file',
        message: ''
      });
    },

    saveActivity(mode) {
      console.log('□□ Builder: saveActivity type=',mode,', uuid=',this.activity.uuid);
      if (mode=='create' || !this.activity.uuid)
      {
        this.$store.dispatch('activity/create');
      }
      else
      {
        this.$store.dispatch('activity/save', this.mode);
      }
    }
  }
};
</script>

<style scoped lang="stylus">
/* first page of the Builder */
/* style for the colored drag/drop squares is defined in ABPanel.vue */
// @import '~quasar-variables';
@import '../styles/eActivity';

/* card style
 */
/* VUE dropzone */
#myVueDropzone {
  background-color: #595959;
  background-color: rgba(89, 89, 89, 0.4) !important;
  border: 0;
  margin-bottom: 0.3em;
}

.vue-dropzone, .vue-dropzone span {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
}

.dropzone {
  min-height: 210px;
  border: 0;
  background: #fff;
  padding: 0;
}

.dz-image {
  width: 280px;
  height: 220px;
}

.dropzone .fa-cloud-upload {
  font-size: 3em;
  color: $medium-gray;
}

.dz-image, .dropzone .dz-preview .dz-image {
  background-size: cover;
  width: 100%;
  height: 100%;
}

.dropzone img {
  padding-bottom: 0;
}

.dropzone .dz-message {
  color: #fff;
}

.dz-message {
  color: #fff;
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
}

.vue-dropzone .dz-preview .dz-details, .dropzone .dz-preview .dz-details, .dz-details {
  background-color: #595959 !important;
  background-color: rgba(89, 89, 89, 0.4) !important;
}

.card .dropzone, .card .dropzone .dz-preview {
  padding: 0;
  margin: 0;
  min-width: 100%;
  max-height: 210px;
  overflow: hidden;
}

.dropzone .dz-preview {
  margin: 0px;
  height: 210px;
  width: 280px;
}

#tags-modal {
}
</style>
