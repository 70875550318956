import { API } from '../../tic';
import { clone } from '../../tic';
import { BLOCK_TYPES } from '../../config/blocktypes';
import _ from 'lodash';
import $ from 'jquery';
import { router } from '../../main.js';

// dummy link used in prev/next
const dummy = { href: null };

// we really should have mode student|teacher here as well
const state = () => ({
  version: '2.1',

  // activity attributes
  image: {},
  title: '',
  description: '',
  bblocks: {},
  blockOrder: [],
  answers: {},
  tags: [],
  showBBHelp: false,

  // navigation state
  next: dummy,
  prev: dummy,
  currentBlock: null,
  blockType: null,
  isPlaceholder: true,

  isStored: false, // is this loaded
  isChanged: false,
  editUrl: '/builder',
  uuid: null,
  sa_uuid: null,
  action: 'Create',
  studentviewURL: null, // complete url for studentview with /uuid/type/block

  //record and answer for current block
  record: null,
  answer: null,

  mode: '',
  showStudentPreview: false,
  debug: false
});

// ===========================================================================
const getters = {
  record: state => {
    //record for current block
    return state.record;
  },

  answer: state => {
    //answer object for current block
    return state.answer;
  },

  answers: state => {
    //all answers for current block
    return state.answers;
  },

  showBBHelp: state => {
    return state.showBBHelp;
  },
  showStudentPreview: state => {
    return state.showStudentPreview;
  },
  debug: state => state.debug,

  dump: state => {
    return JSON.stringify(state);
  },
  version: state => state.version,
  activity: state => state,
  title: state => state.title,
  blocks: state => state.bblocks,
  items: state => {
    if (state.blockOrder) {
      return state.blockOrder.length;
    } else {
      //state.blockOrder = []
      //console.log("XXXXXX ", state.blockOrder)
      return 0;
    }
  },
  getActiveTags: state => state.tags,
  blockOrder: state => state.blockOrder,
  getImage: state => state.image,
  editUrl: state => state.editUrl,
  viewUrl: state => state.studentviewURL,
  currentBlock: state => state.currentBlock,
  isChanged: state => state.isChanged,
  action: state => state.action,
  uuid: state => state.uuid,
  answerUuid: state => state.answerUuid,
  sa_uuid: state => state.sa_uuid,
  getBlock: state => id => {
    //console.log("getblock for ", id)
    return state.bblocks[id];
  },

  // used in BB-bblock functions
  //   getCurrentRecord: state => id => {
  //     //console.log("getcurrentrecord?; ", id)
  //     //console.log("currentblock: ", state.currentBlock)
  //     //console.log("xblocks: ", state.bblocks)
  //     //console.log("forceer images via eActivity.getCurrentRecord")
  //     $(".img-src").each(function(i, el) {
  //       //console.log("el in updated: ", el, el.dataset.position)
  //       if (el.dataset && el.dataset.position) {
  //         $(el).css(JSON.parse(el.dataset.position))
  //       }
  //     })
  //     return state.bblocks[id].record
  //   },

  //   getRecord: state => {
  //     // console.log("getRecord: ", state.currentBlock)
  //     console.log("blocks: ", state.bblocks, state.currentBlock)
  //
  //     if (state.currentBlock) {
  //       //console.log("ALL BLOCKS: ", JSON.stringify(state.bblocks))
  //       //console.log("cURRENT   : ", JSON.stringify(state.bblocks[state.currentBlock].record))
  //       if (
  //         state.bblocks[state.currentBlock] &&
  //         state.bblocks[state.currentBlock].record
  //       ) {
  //         return _.clone(state.bblocks[state.currentBlock].record)
  //       } else {
  //         return {}
  //       }
  //       //return _.clone(state.bblocks[state.currentBlock].record || {})
  //     } else {
  //       console.log("eactivity/getRecord ERROR: state.currentBlock not set")
  //       return {}
  //     }
  //   },

  //   getRecordType: state => {
  //     console.log("getRecordType: ", state.currentBlock)
  //     if (state.currentBlock) {
  //       return state.bblocks[state.currentBlock].type
  //     } else {
  //       return null
  //     }
  //   },

  //   prevBlock: (state, r, x) => {
  //     // used in navBuildingBlock for next/prev buttons
  //     if (state.mode === "student" && state.prev.href) {
  //       let base =
  //         "/" +
  //         x.route.path
  //           .split("/")
  //           .splice(1, 3)
  //           .join("/") +
  //         "/" +
  //         state.prev.type +
  //         "/" +
  //         state.prev.id
  //       return { href: base }
  //     } else if (state.prev.href) {
  //       return {
  //         href:
  //           "/builder/" +
  //           state.uuid +
  //           "/edit/" +
  //           state.prev.type +
  //           "/" +
  //           state.prev.id
  //       }
  //     } else {
  //       return state.prev
  //     }
  //   },

  //   nextBlock: (state, r, x) => {
  //     // used in navBuildingBlock for next/prev buttons
  //     if (state.mode === "student" && state.next.href) {
  //       let base =
  //         "/" +
  //         x.route.path
  //           .split("/")
  //           .splice(1, 3)
  //           .join("/") +
  //         "/" +
  //         state.next.type +
  //         "/" +
  //         state.next.id
  //       return { href: base }
  //     } else if (state.next.href) {
  //       return {
  //         href:
  //           "/builder/" +
  //           state.uuid +
  //           "/edit/" +
  //           state.next.type +
  //           "/" +
  //           state.next.id
  //       }
  //     } else {
  //       return state.next
  //     }
  //   },

  getTags: state => {
    // console.log("something called getTags")
    // return an array of true Tags.uuids for the current activity
    // return state.activity.tags.map( function (o) {
    //   return state.profile.user.tags[o].uuid
    // })

    var uuids = [];
    // console.log("xX: ", state)
    for (var o in state.tags) {
      uuids.push(state.tags[o].uuid);
    }
    return uuids;
  }
};

// ===========================================================================
const mutations = {
  toggleDebug(state, payload) {
    state.debug = !state.debug;
    state.showDebug = !state.showDebug;
  },

  setStudentAnswersUuid(state, payload) {
    console.log('set sa ', state, ' laod: ', payload);
    state.sa_uuid = payload;
  },

  addSource(state, payload) {
    //console.log('store/addSource',payload);
    //console.log("block: ", state.bblocks[state.currentBlock])
    if (state.bblocks[state.currentBlock].type === 'analysing') {
      //for BB-anaysing always add just a single image and reset previous annotations
      state.bblocks[payload.current].record.images = [payload.source];
      state.bblocks[payload.current].record.data = {
        answers: [],
        annotations: []
      };
    } else if (state.bblocks[payload.current].record.images === undefined) {
      state.bblocks[payload.current].record.images = [];
    } else {
      state.bblocks[payload.current].record.images.push(payload.source);
    }
    state.isChanged = true;
  },

  addAnnotation(state, payload) {
    console.log('store/addAnnotation', payload);
    console.log('record: ', state.bblocks[payload.current].record);
    if (state.bblocks[payload.current].record.data === undefined) {
      state.bblocks[payload.current].record['data'] = {};
    }
    state.bblocks[payload.current].record.data[payload.datatype] = payload.data;
    state.isChanged = true;
  },

  submitAnnotation(state, payload) {
    console.log('store/submitAnnotation', payload);

    state.bblocks[payload.current].record.submitted = true;
    state.isChanged = true;
  },

  setMode(state, payload) {
    // set by the blocks
    state.mode = payload;
  },

  setBlockImagePosition(state, payload) {
    // console.log("set block position: ", payload)
    state.bblocks[state.currentBlock].record.images[payload.image].position =
      payload.position;
    //console.log(JSON.stringify(state.bblocks[state.currentBlock]))
    state.isChanged = true;
  },

  setIsStored(state, value) {
    state.isStored = value;
  },
  setAction(state, value) {
    state.action = value;
  },
  setStudentviewURL(state, value) {
    // construct url to the first block of this Activity
    try {
      let firstBlock = state.blockOrder[0];
      let firstType = state.bblocks[firstBlock].type || null;
      state.studentviewURL = value + firstType + '/' + firstBlock;
    } catch (err) {
      state.studentviewURL = null;
      state.blockOrder = [];
    }
  },

  setIsChanged(state, value) {
    state.isChanged = value;
  },

  removeImage(state) {
    state.image = false;
  },

  setImage(state, uploadInfo) {
    // create a { uuid: xxx, url: xxx } image entry
    console.log('**************');
    console.log('uploadInfo: ', uploadInfo);
    console.log('**************');
    // dict with path: and uuid:
    state.image = uploadInfo;
  },

  setRecord(state, payload) {
    console.log('◆ STORE activity/setRecord', payload);

    //flag for save
    state.isChanged = true;

    //insert new block if needed //-> does this happen at all?
    if (!state.bblocks[payload.block]) state.bblocks[payload.block] = {};

    //update record, use clone to prevent vuex mutation error
    state.bblocks[payload.block].record = clone(payload.record);

    //console.log('update current record...') //-> this would be nice, but breaks current tools, even better: just update state.record?
    //state.record = payload.record;
  },

  setAnswer(state, answer) {
    //store answer for current block
    console.log('◆ STORE activity/setAnswer', state.currentBlock, answer);

    //we modify current answer without overwriting entire object, to keep state.answers reference
    if (answer) {
      Object.assign(state.answer, clone(answer));
    } else {
      Object.keys(state.answer).forEach(key => {
        delete state.answer[key];
      });
    }

    //mark for saving
    state.isChanged = true;
  },

  syncCurrentRecord(state, blockId) {
    //make sure state.record is the same as state.bblocks[block].record
    console.log('◆ STORE activity/syncCurrentRecord', blockId);
    state.record = state.bblocks[blockId].record;
  },

  setCurrentBlock(state, blockId) {
    const block = state.bblocks[blockId];

    if (!block)
      return console.log(
        '|||||||||||||| setCurrentBlock called for undefined block (activity not loaded yet)'
      );

    console.log('//// setCurrentBlock', block);

    state.currentBlock = blockId;
    state.blockType = block.type;
    state.record = block ? block.record : {};
    state.answer = state.answers[blockId];
  },

  setCurrentRecord(state, record) {
    console.log('◆ STORE activity/setCurrentRecord', record);
    state.record = record;
  },

  setCurrentAnswer(state, blockId) {
    console.log('◆ STORE activity/setCurrentAnswer', blockId);
    state.answer = state.answers[blockId];
  },

  setInfo(state, activity) {
    console.log('◆ STORE activity/setInfo', JSON.stringify(activity));
    state.title = activity.title;
    state.description = activity.description;
    state.image = activity.image;
  },

  setUuid(state, uuid) {
    console.log('◆ STORE activity/setUuid', uuid);
    state.uuid = uuid;
  },

  setOwner(state, uuid) {
    console.log('◆ STORE activity/setOwner', uuid);
    state.owner = uuid;
  },

  setAnswerUuid(state, uuid) {
    state.answerUuid = uuid;
  },

  setAnswers(state, answers) {
    console.log('◆ STORE activity/setAnswers');
    state.answers = answers;
  },

  addSources(state, payload) {
    console.log('◆ STORE activity/addSources', payload);

    //make sure state.record sync with the bblock rec
    state.record = state.bblocks[payload.block].record;

    //insert images
    payload.sources.forEach(source => {
      state.record.images.push(source);
    });

    state.isChanged = true;
  },

  //   addSource(state, payload) {
  //     //console.log('store/addSource',payload);
  //     //console.log("block: ", state.bblocks[state.currentBlock])
  //     if (state.bblocks[state.currentBlock].type === "analysing") {
  //       //for BB-anaysing always add just a single image and reset previous annotations
  //       state.bblocks[payload.current].record.images = [payload.source]
  //       state.bblocks[payload.current].record.data = {
  //         answers: [],
  //         annotations: []
  //       }
  //     } else if (state.bblocks[payload.current].record.images === undefined) {
  //       state.bblocks[payload.current].record.images = []
  //     } else {
  //       state.bblocks[payload.current].record.images.push(payload.source)
  //     }
  //     state.isChanged = true
  //   },

  //   setActiveBlock (state,blockid) {
  //
  //     state.currentBlock = blockid;
  //     const block = state.bblocks[blockid]
  //     state.record = state.bblocks[blockid].record
  //
  //   },

  // add a tag to the tags of this activity
  addTag(state, tag) {
    // console.log('ADD: ', tag.uuid)
    state.tags.push(tag);
  },

  removeTag(state, tag) {
    // console.log("REMOVE: ", tag.uuid)
    state.tags = state.tags.filter(function(e) {
      return e.uuid !== tag.uuid;
    });
    // console.log('tags now: ', state.tags )
  },

  deleteBlock(state, blockId) {
    // remove from current blocks
    delete state.bblocks[blockId];
    // and remove from order helper
    state.blockOrder.splice(state.blockOrder.indexOf(blockId), 1);
    state.items = state.blockOrder.length;
  },

  _setCurrentBlock(state, block) {
    console.log('setting currentBlock to: ', block);
    state.currentBlock = block;
  },

  setBlockOrder(state, order) {
    //console.log("setting blockOrder to: ", order)
    state.blockOrder = order;
  },

  addBlock(state, block) {
    // add an existing block when an eActivity is loaded
    // console.log("## activity.addBlock id: ", block.id)
    state.bblocks[block.id] = block;
    switch (typeof block.record) {
      case 'string':
        state.bblocks[block.id].record = JSON.parse(block.record);
        break;

      case 'object':
        state.bblocks[block.id].record = block.record;
        break;
    }

    // if (typeof block.record === string) {
    //   state.bblocks[block.id].record = JSON.parse(block.record)
    // } else {
    //   state.bb
    // }
    // remove this later; needed for the old data during dev
    // state.bblocks[block.id].href = 'edit/text/bb_0' //state.bblocks[block.id].href.replace("/#", "")
  },

  addBlockAnswer(state, payload) {
    state.answers[payload.blockId] = payload.answer || {};
  },

  __addBlock(state, block) {
    //add block to bblocks
    state.bblocks[block.id] = block;

    //convert block.record to object
    if (typeof block.record == 'string')
      block.record = JSON.parse(block.record);
  },

  createBlock(state, block) {
    // create a new block as the User just dropped it in ABPanel
    //console.log("## activity.createBlock id: ", block.id, " with type ", block.type)
    console.log('JSON: ', BLOCK_TYPES[block.type]);
    state.bblocks[block.id] = block;
    // create an empty record with the correct attributes
    // XXX: use JSON to create a real fresh object. clone, object.Create etc tend to
    // get data sticked into it causing unwanted duplicates
    state.bblocks[block.id].record = JSON.parse(
      JSON.stringify(BLOCK_TYPES[block.type])
    );
    //console.log("CREATED: ", state.bblocks[block.id])
  },

  updateTitle(state, title) {
    state.title = title;
  },

  updateDescription(state, text) {
    state.description = text;
  },

  updateBlockOrder(state, value) {
    state.blockOrder = value;
  },

  updateTags(state, value) {
    state.tags = value;
  },

  editUrl(state, value) {
    // path STARTS with / ; do not add one
    state.editUrl = value;
  },

  updateImage(state, value) {
    state.image = value; // received from the graph: {{uuid:'', path:'' and more fields}}
    // create url based on path, all meta is in the object too!
    state.image.url = '/ua/' + state.image.path;
  },

  // for bb-sequence
  setBlockBackground(state, value, blockId) {
    //console.log("setBlockBG: ", value, state.currentBlock)
    state.bblocks[state.currentBlock].record.background = value;
    //console.log(JSON.stringify(state.bblocks[state.currentBlock]))
    state.isChanged = true;
  },

  zapState(state) {
    console.log('zapping state....');
    state.next = dummy;
    state.prev = dummy;
    state.currentBlock = null;
    state.isPlaceholder = true;
    state.image = {};
    state.title = '';
    state.description = '';
    state.tags = [];
    state.blockOrder = [];
    state.bblocks = {};
    state.isStored = false;
    state.isChanged = false;
    state.editUrl = '/builder';
    state.uuid = null;
    state.action = 'Create';

    state.image = null;
    $('#myVueDropzone').css({
      background: 'white no-repeat center',
      backgroundSize: 'cover'
    });
    $('.dz-preview').hide();
  }
};

// ===========================================================================
const actions = {
  //   saveStudentAnswers: (ctx, vm) => {
  //     console.log("save students answers for ", ctx.state.sa_uuid)
  //     console.log("store these: ", JSON.stringify(vm.answers))
  //     API.post("/save-student-answers", {
  //       sa: ctx.state.sa_uuid,
  //       answers: vm.answers
  //     })
  //       .then(resp => {
  //         console.log("XXXXX: ", resp)
  //         if (resp.data.isError) {
  //           vm.$message({
  //             type: "error",
  //             message: `Error submitting answers<br>${resp.data.errorCode}`,
  //             dangerouslyUseHTMLString: true,
  //             showClose: true,
  //             duration: 0 // keep error on screen
  //           })
  //         } else {
  //           vm.$message({
  //             type: "success",
  //             message: "Answers submitted"
  //           })
  //         }
  //       })
  //       .catch(resp => {
  //         console.log("CATCH ERROR resp: ", resp)
  //         console.debug("DEBUG: ", resp)
  //         vm.$message({
  //           type: "error",
  //           message: `No answers submitted<br><br>${resp}`,
  //           dangerouslyUseHTMLString: true
  //         })
  //       })
  //   },

  //   startStudentView: (ctx, sv) => {
  //     console.log("start studentview with: ", sv)
  //     API.post("/start-activity", {
  //       ...sv
  //     }).then(resp => {
  //       console.log("XXXXX: ", resp)
  //       ctx.commit("setStudentAnswersUuid", resp.data.sa_uuid)
  //     })
  //   },

  logout: ({ commit }, iets) => {
    console.log('PERFORM LOGOUT: ', iets);
    return 'logout';
  },

  delete: (ctx, activities) => {
    console.log('delete: ', activities);
    API.post('/remove/activities', {
      user: ctx.rootGetters['user/uuid'],
      activities: activities
    });
  },

  zapBuilder: ctx => {
    console.log('uuid: ', ctx.state.uuid);
    // call api to delete this activity
    ctx.commit('zapState');
    API.post('/delete-activity', {
      user: ctx.rootGetters['user/uuid'],
      activity: ctx.state.uuid
    }).then(resp => {
      console.log('zapped activity');
    });
  },

  // =======================================================
  // api-call /create-activity creates a new empty node with
  // only the uuid set.
  create: ctx => {
    // call API backend to get a new Activity-UUID first
    // then immediately save the new activity as well.

    console.log('--> Activity/CREATE');

    return API.post('/create-activity', {
      user: ctx.rootGetters['user/uuid']
    }).then(
      resp => {
        // API returns a uuid; update current activity
        // with this and update its status:
        //console.log("---> activity.create resp: ", resp.data.uuid)
        ctx.commit('setUuid', resp.data.uuid);
        ctx.commit('setIsChanged');
        ctx.commit('setAction', 'Edit');

        // now save this updated activity in the Graph
        API.post('/update-activity', {
          user: ctx.rootGetters['user/uuid'],
          activity: ctx.getters['activity']
        })
          .then(function() {
            console.log(
              'update after create: ',
              ctx.getters['activity/action']
            );
            // push browser to the newly generated record
            router.push(`/builder/${resp.data.uuid}`);
          })
          .catch(() => {
            window.eventBus.$emit(
              'alert',
              'Cannot create new creating activity'
            );
          });
      },

      err => {
        console.log('ERROR');
        // XXX esint funky but prob not ok
        err(new Error('something bad happened'));
      }
    );
  },

  // =======================================================
  save: (ctx, userUuid) => {
    console.log('◆ STORE activity/save');

    //console.log("## SAVE ACTIVITY ", ctx.state.action)
    // console.log("ACT: ", JSON.stringify(ctx.getters['activity']))
    //console.log("SAVE?BLOCKORDER: ", ctx.getters['activity'])
    // sometimes there is no myVueDropzone active in the page
    // eg in MediaLibrary. Don't die then.
    try {
      $('#myVueDropzone').css({
        background: `white url(${ctx.getters['getImage'].url}) no-repeat center`,
        backgroundSize: 'cover'
      });
    } catch (e) {
      // missing image; silently ignore
      // alert("dropzone error")
    }

    // console.log("SAVE : ", JSON.stringify(ctx.getters['activity']))
    return API.post('/update-activity', {
      user: ctx.rootGetters['user/uuid'],
      activity: ctx.getters['activity']
    }).then(resp => {
      console.log('◆ STORE activity/save, response=', resp, ctx);
      //mark acitivy as saved
      if (resp.statusText == 'OK') ctx.commit('setIsChanged', false);
    });
  },

  // =======================================================
  load: (ctx, params) => {
    console.log('◆ STORE activity/load', params);

    // the /#/builder should not cause an error; assume an empty activity
    if (!params.uuid)
      return console.log('◆ STORE activity/load, not loaded: no uuid set');

    let mode = params.mode;

    // route.params.currentBlock='bb_0'
    // console.log("added currentBlock to route: ", route.params)

    let apiUrl = '';
    if (mode === 'student' || mode === 'share') {
      apiUrl = '/load-activity';
    } else {
      apiUrl = '/edit-activity';

      // without a user uuid edit-activity returns nok
      if (!ctx.rootGetters['user/uuid'])
        return console.log('◆ STORE activity/load, not loaded: no user yet');
    }

    return API.post(apiUrl, {
      user: ctx.rootGetters['user/uuid'],
      activity: params.uuid
    }).then(
      response => {
        console.log('◆ STORE activity/load, response=', response.data);

        ctx.commit('editUrl', params.route.path);
        ctx.commit('updateTitle', response.data.data.activity.title);
        ctx.commit(
          'updateDescription',
          response.data.data.activity.description
        );
        ctx.commit('updateBlockOrder', response.data.data.activity.blockOrder);
        ctx.commit('updateTags', response.data.data.tags);
        ctx.commit('setIsStored', true);
        ctx.commit('setAction', 'Edit');
        ctx.commit('setUuid', response.data.data.activity.uuid);

        // XXX eslint FIX myVueDropzone issues
        if (response.data.data.image) {
          let img = response.data.data.image;
          ctx.commit('updateImage', img);
          if (mode !== 'student') {
            try {
              $('#myVueDropzone').css({
                background:
                  'white url(' +
                  encodeURI(ctx.getters['getImage'].url) +
                  ') no-repeat center',
                backgroundSize: 'cover'
              });
            } catch (e) {
              $('#myVueDropzone').css({
                background: 'white url("") no-repeat center',
                backgroundSize: 'cover'
              });
              //console.log("e:", e)
            }
          }
        } else {
          // remove the image from the interface if there currently is one.
          let myVueDropzone = '';
          if (mode !== 'student') {
            if (myVueDropzone !== undefined) {
              $('#myVueDropzone').css({
                background: 'white url("") no-repeat center',
                backgroundSize: 'cover'
              });
            }
          }
        }

        //   let img = response.data.data.image
        //   ctx.commit('updateImage', img)
        //   $(myVueDropzone).css({
        //     background: 'white url('+ encodeURI(ctx.getters['getImage'].url)+') no-repeat center',
        //     backgroundSize: 'cover'
        //   })
        // }
        //console.log("adding blocks ***")

        //add bblocks to state
        for (let b of response.data.data.blocks) {
          ctx.commit('addBlock', b);

          //set state.record to current block
          if (b.id == params.block) {
            ctx.commit('setCurrentRecord', b.record);
            ctx.commit('setCurrentBlock', params.block);
          }
        }

        ctx.commit('setStudentviewURL', params.route.path);
      },
      error => {
        console.log('API call error:', error);
        window.alert(error);
      }
    );
  },

  // =======================================================

  view: (ctx, params) => {
    console.log('◆ STORE activity/view');

    const api = params.mode == 'review' ? '/ea/review' : '/ea/share/view';

    return new Promise(resolve => {
      API.post(api, {
        share: params.uuid
      }).then(response => {
        console.log('◆ API ' + api, response.data, params);

        const data = response.data.data;
        const currentBlockId = params.block || data.activity.blockOrder[0];

        ctx.commit('setBlockOrder', data.activity.blockOrder);
        ctx.commit('setInfo', data.activity);
        ctx.commit('setUuid', data.activity.uuid);
        ctx.commit('setOwner', data.owner);

        let redirect = '/ea/' + params.mode + '/' + params.uuid + '/';

        //add all bblocks to state
        data.activity.blockOrder.forEach(blockId => {
          let block = data.blocks.find(b => b.id == blockId);

          ctx.commit('addBlock', block);

          //set current block
          if (blockId == currentBlockId) {
            ctx.commit('setCurrentBlock', blockId);
            redirect += block.type + '/' + blockId;
          }
        });

        //redirect to current block if needed
        if (!params.block) router.replace(redirect);

        //send available answers list to eaViewer
        if (params.mode == 'review') resolve(data.answers);
      });
    });
  },

  start: (ctx, params) => {
    console.log('◆ STORE activity/start');

    return new Promise(resolve => {
      API.post('/ea/student/start', {
        email: params.email,
        share: params.uuid
      }).then(response => {
        console.log('◆ API /ea/student/start response=', response.data, params);

        //keep email in session, to support reloading
        sessionStorage.setItem('studentEmail', params.email);

        const data = response.data.data;
        const currentBlockId = params.block || data.activity.blockOrder[0];

        ctx.commit('setBlockOrder', data.activity.blockOrder);
        ctx.commit('setInfo', data.activity);

        //set uuid for submitting answers
        ctx.commit('setAnswerUuid', data.answer.uuid);

        let redirect = '/ea/student/' + params.uuid + '/';

        //add all bblocks to state
        data.activity.blockOrder.forEach(blockId => {
          let block = data.blocks.find(b => b.id == blockId);

          ctx.commit('addBlock', block);
          ctx.commit('addBlockAnswer', {
            blockId: blockId,
            answer: JSON.parse(data.answer.answers)[blockId]
          });

          //set current block
          if (blockId == currentBlockId) {
            ctx.commit('setCurrentBlock', blockId);
            redirect += block.type + '/' + blockId;
          }
        });

        //redirect to current block if needed
        if (!params.block) router.replace(redirect);

        resolve(data.answer.submitted ? data.answer.updated : false);
      });
    });
  },

  saveAnswers: (ctx, params) => {
    console.log('◆ STORE activity/saveAnswers');

    API.post('/ea/student/save', {
      sa: ctx.state.answerUuid,
      answers: ctx.state.answers
    }).then(response => {
      console.log('◆ API /ea/student/save response=', response);

      ctx.commit('setIsChanged', false);
    });
  },

  submitAnswers: (ctx, params) => {
    console.log('◆ STORE activity/submitAnswers');

    return new Promise(resolve => {
      API.post('/ea/student/submit', {
        sa: ctx.state.answerUuid,
        answers: ctx.state.answers
      }).then(response => {
        console.log('◆ API /ea/student/submit response=', response);

        resolve(response.data.record.updated);
      });
    });
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
};
