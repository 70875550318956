<template>
  <div class="quill-editor">
    <!-- glossary input popup -->
    <div
      v-show="showGlossary"
      class="tooltip glossary"
      ref="menu"
      :style="{
        left: `${x}px`,
        top: `${y}px`
      }"
    >
      <q-input
        v-model="glossary"
        ref="glossary"
        type="textarea"
        class="textarea"
        placeholder="Add glossary definition"
        dark
        filled
        autogrow
        @keyup.enter.stop
      />
    </div>
    <div ref="editor"/>
  </div>
</template>

<script>
import '../../node_modules/quill/dist/quill.snow.css'
import Quill from 'quill'

//extend Quill editor with <mark> and <dnf> support

//allow <mark class="" data-comment=""/> in editor text
let Inline = Quill.import('blots/inline');
class MarkBlot extends Inline {
  static create(value) {
    let node = super.create();
    node.setAttribute('class', value[0]);
    if (value[1]) node.setAttribute('data-comment', value[1]);
    return node;
  }
  static formats(node) {
    return [node.getAttribute('class'),node.getAttribute('data-comment')];
  }
}
MarkBlot.blotName = 'mark';
MarkBlot.tagName = 'mark';

Quill.register(MarkBlot);

//allow <dfn title=""/> in editor
class GlossaryBlot extends Inline {
  static create(value) {
    let node = super.create();
    node.setAttribute('title', value);
    return node;
  }
  static formats(node) {
    return node.getAttribute('title');
  }
}
GlossaryBlot.blotName = 'glossary';
GlossaryBlot.tagName = 'dfn';

Quill.register(GlossaryBlot);

const CustomHandlers = {
  'glossary': function(value) {
    let q = this.quill;
    if (value) {
      //insert <dnf> element and show input dialogue
      q.format('glossary',q.getText(q.getSelection()),'silent');
      q.vue.addGlossary();
    } else {
      //remove selected <dfn>
      q.format('glossary', false);
      q.vue.storeContent();
    }
  }
}

export default {
  name: 'QuillEditor',

  components: {
  },

  props: {
    value: {
      type: String,
      default: ''
    },
    toolbarOptions: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Enter your text'
    }
  },

  data () {
    return {
      initialBlur: true,
      content: null,
      editor: null,
      defaultOptions: {
        theme: 'snow',
        bounds: '.editor',
        matchVisual: false,
        modules: {
          toolbar: {
            container:[
              [{ 'header': [1, 2, 3, 4, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ],
            handlers: CustomHandlers
          }
        },
      },
      x:0,
      y:0,
      showGlossary:false,
      glossary:''
    }
  },

  watch: {
    value (newVal) {
      //only update when value changed externally
      if (newVal !== this.content) {
        this.editor.pasteHTML(newVal)
      }
    }
  },

  mounted () {
    this.init()
  },

  beforeDestroy () {
    // Turn off all listeners set on text-change
    this.editor.off('text-change')
    this.editor.root.removeEventListener('focus',this.focus)
    this.editor.root.removeEventListener('blur',this.blur)
  },

  methods: {
    init () {
      //set initial content
      this.$refs.editor.innerHTML = this.value;

      //overwrite toolbar layout if set as property
      if (this.toolbarOptions.length) this.defaultOptions.modules.toolbar.container = this.toolbarOptions;
      this.defaultOptions.placeholder = this.placeholder;

      //init Quill
      this.editor = new Quill(this.$refs.editor, this.defaultOptions);
      this.editor.vue = this; //reference to access glossary methods

      //optional glossary handling
      this.initGlossary();

      //listen for text-changes, focus and blur events
      this.editor.on('text-change', this.change);
      this.editor.root.addEventListener('focus',this.focus);
      this.editor.root.addEventListener('blur',this.blur);

      //prevent auto-focus
      this.$nextTick(() => { this.editor.root.blur() });

      //save initial content internally
      this.setContent()
    },

    change (delta, oldDelta, source) {
      //update local content
      this.setContent();

      //store if change by user input
      if (source=='user') this.storeContent()
    },

    focus () {
      this.editor.container.classList.add('focus')
    },

    blur () {
      this.editor.container.classList.remove('focus')
      //if (!this.initialBlur)
      this.$emit('blur')
      this.initialBlur = false;
    },

    setContent () {
      this.content = this.editor.getText().trim()
        ? this.editor.root.innerHTML
        : ''
    },

    storeContent () {
      //emit input for v-model
      this.$emit('input', this.content);

      //emit additional update event with plaintext contents
      this.$emit('update', this.editor.getText());
    },

    initGlossary () {
      if (this.defaultOptions.modules.toolbar.container.flat().includes('glossary'))
      {
        this.editor.on('selection-change', (range, oldRange, source) => {
          if (range == null) return;

          const q = this.editor;

          //click on existing definition?
          if (range.length===0 && source==='user')
          {
            let [dfn, offset] = q.scroll.descendant(GlossaryBlot, range.index);
            if (dfn != null)
            {
              //expand selection and show glossary input
              const r = { index:range.index - offset, length:dfn.length() };
              return this.addGlossary(GlossaryBlot.formats(dfn.domNode),r);
            }
          }

          //store glossary text and hide input
          if (this.showGlossary)
          {
            q.setSelection(this.glossaryRange);
            q.format('glossary',this.glossary!=''? this.glossary:false);
            this.storeContent();
            this.showGlossary = false;
          }

        });
      }
    },

    addGlossary (value,range) {
      //show glossary input dialog
      const q = this.editor;
      if (!range) range = q.getSelection();
      if (!range.length) return;

      //position based on selection
      const { left,top,width } = q.getBounds(range);
      const { x,y } = q.container.getBoundingClientRect();
      this.x = x + left + (width / 2);
      this.y = y + top + window.scrollY - 10;

      //set value and reveal
      this.glossary = value || '';
      this.glossaryRange = range;
      this.showGlossary = true;

      //auto-focus textarea
      this.$nextTick(() => { this.$refs['glossary'].focus() });
    }
  }
}
</script>

<style lang="stylus">
@import '~quasar-variables'

.ql-container
  overflow: hidden

.quill-editor .ql-editor
  font-family 'Open Sans',Arial, Helvetica, sans-serif
  font-size 16px
  min-height 150px

.quill-editor p
  margin-bottom: 1rem

.quill-editor .ql-glossary::before
  content: '\f02d'
  display: block
  margin-top: -3px
  font-family: 'Font Awesome 5 Pro'

.ql-snow .ql-editor h1
  font-size: 2rem
  line-height: 3rem

.ql-toolbar.ql-snow
  padding 8px 8px 4px 12px;

/* match styling with Quasar field */

$field-transition = .36s cubic-bezier(.4,0,.2,1)

.ql-editor
  background: rgba(0,0,0,0.03)

.ql-container.ql-snow,
.ql-toolbar.ql-snow
  border: none
  border-radius: 4px 4px 0 0

.ql-editor.ql-blank::before
  font-style normal

.ql-container::before,
.ql-editor::after
  content: ''
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  pointer-events: none

.ql-container::before
  background: rgba(0,0,0,.02)
  border-bottom: 1px solid rgba(0,0,0,.42)
  opacity:0
  transition: opacity $field-transition, background $field-transition

.ql-container.focus::before
  opacity: 1
  background: rgba(0,0,0,.05)

.ql-container:hover::before
  opacity: 1

.ql-editor::after
  height: 2px
  top: auto
  transform-origin: center bottom
  transform: scale3d(0, 1, 1)
  background: $primary
  transition: transform $field-transition

.ql-editor:focus::after
  transform: scale3d(1, 1, 1)


/* Quill editor formats menu (snow theme) */

.ql-snow .ql-picker-label
  overflow:hidden

.ql-snow .ql-picker.ql-header .ql-picker-label::before
  width calc(100% - 10px)
  overflow hidden
  text-overflow ellipsis

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before
  content 'Title'

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before
  content 'Subtitle'

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before
  content 'Chapter heading'

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before
  content 'Paragraph heading'

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before
  content 'Subparagraph heading'

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: 'Heading 6';
}

</style>