<template>
  <q-btn
    class="edit-button"
    round
    unelevated
    :icon="icon"
    :size="size"
    :text-color="color"
    @click="$emit('click')"
  >
    <q-tooltip
      v-if="$slots.default"
      content-class="edit-button-tooltip"
      :anchor="anchor"
      :self="self"
      :transition-show="show"
      transition-hide="fade"
      :offset="[6,6]"
    ><slot/></q-tooltip>
  </q-btn>
</template>

<script>

export default {
  name:'EditButton',
  props: {
    icon: {
      type:String,
      default:'fa fa-pencil'
    },
    size: {
      type:String,
      default:'12.5px'
    },
    color: {
      type:String,
      default:'primary'
    },
    tooltip:{
      type:String,
      default:'right'
    }
  },
  computed: {
    x () {
      return this.tooltip=='left' || this.tooltip=='right';
    },
    anchor() {
      return this.x? 'center '+this.tooltip:this.tooltip+' middle';
    },
    self() {
      return this.x? 'center '+(this.tooltip=='right'? 'left':'right'):(this.tooltip=='top'? 'bottom':'top')+' middle';
    },
    show() {
      return 'jump-'+(this.x? this.tooltip:this.tooltip=='top'? 'up':'down');
    }
  }
}
</script>

<style lang="stylus">
@import '~quasar-variables'

.edit-button
  margin:8px !important;
  background-color:alpha($white,.7) !important;

.edit-button .q-btn__wrapper
  min-width:34px !important;
  min-height:34px !important;

.edit-button-tooltip
  background-color:alpha($white,1) !important;
  color:$primary !important;

</style>