<template>
<div>
    <hi-dialog :title="title" :show="showDialog" @close="$emit('close')">
      dit is de dialog voor {{uuid}}
    </hi-dialog>
</div>
</template>
<script>
import hiDialog from '@/components/hiDialog.vue'

export default {
  name: 'hiPerson',
  components: { hiDialog },
  props: {
      title: {
          type: String,
          default: ''
      },

      show: {
          type: Boolean,
          default: false
      },

      uuid: {
          type: String,
          default: ''
      }
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('close');
        return val;
      }
    }
  },

  data() {
    return {}
  }
};
</script>
