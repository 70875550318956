<template>
  <!-- <section class="ea-blocks-navigation"> -->
<!--
    {{ blockId }} {{ mode }} path = {{ basePath }}<br>
    {{ blockOrder }} index={{ index }}
-->
    <div id="ea-navigation" class="row q-mx-auto">
      <div class="col">
        <ul class="menu horizontal" id="sequence-navigator">
          <!-- PREVIOUS ARROW BUTTON -->

          <li v-if="prevBlock">
            <router-link :to="blockUrl(prevBlock)" id="prev">
              <i class="fas fa-arrow-left"/>
            </router-link>
          </li>
          <li v-else>
            <a class="disabled" id="prev">
              <i class="fas fa-arrow-left"/>
            </a>
          </li>

          <!-- NUMERIC TABS -->
          <li
            v-for="(b, i) in blockOrder"
            :class="{ 'bb-active': blockOrder.indexOf(blockId) === i }"
            :key="b"
          >
            <router-link
              :to="blockUrl(blocks[b])"
              :title="blocks[b].record.title || blocks[b].name"
              >{{ i + 1 }}<i v-if="answered.indexOf(b)>-1" class="far fa-check"/>
            </router-link>
          </li>

          <!-- NEXT ARROW BUTTON -->
          <li v-if="nextBlock">
            <router-link :to="blockUrl(nextBlock)" id="next">
              <i class="fas fa-arrow-right"/>
            </router-link>
          </li>
          <li v-else>
            <a class="disabled" id="next">
              <i class="fas fa-arrow-right"/>
            </a>
          </li>
        </ul>
      </div>

      <slot/>

    </div>
  <!-- </section> -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "BlocksNavigation",

  props: {
    //current eactivity
    uuid: {
      type: String,
      default: ''
    },

    //current block id
    blockId: {
      type: String,
      default: ''
    },

    mode: {
      type: String, //teacher, student or share (?)
      default: ''
    },

    editor: {
      type:Boolean, //true when in BlockEditor, so builder url is always returned regardless of mode.
      default:false
    },

    answered:{
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters('activity',['blocks','blockOrder']),

    basePath () {
      //student/view/uuid/type/block -> //ea/student/uuid/type/block
      //builder/uuid/edit/type/block
      return this.mode=='teacher' || this.editor? '/builder/'+this.uuid+'/edit/':'/ea/'+this.mode+'/'+this.uuid+'/';
    },

    index () {
      return this.blockOrder.indexOf(this.blockId);
    },

    prevBlock () {
      return this.index>0? this.blocks[this.blockOrder[this.index - 1]]:false;
    },

    nextBlock () {
      return this.index<this.blockOrder.length-1? this.blocks[this.blockOrder[this.index + 1]]:false;
    },

  },

  methods: {
    blockUrl (block) {
      return block? this.basePath + block.type +'/'+ block.id:false;
    }
  }

}
</script>