<template>
  <span>
    <ResetPassword :show-dialog="showForm" @hide="showForm = false" />
    <q-btn
      flat
      dense
      size="13px"
      class="q-px-sm"
      icon="far fa-lock"
      label="Reset password"
      :color="inline? 'primary':'dark-gray'"
      @click="showForm = !showForm"
    />
  </span>
</template>
<script>
import ResetPassword from "@/components/ResetPassword.vue";
export default {
  name: "ResetPasswordButton",
  components: { ResetPassword },
  props: {
    inline:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showForm: false
    };
  }
};
</script>
