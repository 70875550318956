<!-- TODO:

    myTags of iets dergelijks tbv mykeywords input
    myCountry maken voor landkeuze
    manier vinden om alle velden door api op te laten slaan, via client driven data definitie?
    note: license-id / origin / type werken nu niet hierdoor



-->
<template>
  <div>
    <q-dialog
      v-model="sEditor"
      transition-show="slide-up"
      transition-hide="slide-down"
      full-width
      @before-hide="editorClose"
    >
      <q-card style="width:100%; max-height:90%">
        <q-bar class="bg-hardroze text-white">
          <i class="fas fa-pencil" />
          &nbsp; Edit item
          <q-space />
          <q-btn dense flat round icon="far fa-times" v-close-popup>
            <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section>
          <div class="row flex flex-center">
            <h4>
              Collection <b>{{ collection.name }}</b>
            </h4>
          </div>
          dirty: <i :key="i" v-for="(e, i) in isDirty.values()">{{ e }},</i>
          <div class="row flex flex-center">
            <q-pagination
              v-model="current"
              :max="collection.items.length"
              :input="true"
            />
          </div>
        </q-card-section>

        <q-card-section>
          <div class="row">
            <div class="col" style="border: 1px solid lightgrey">
              <v-zoomer controls ref="zoomer" class="fit" :zoomed.sync="zoomed">
                <img
                  v-if="item.ua"
                  :src="`/ua/${item.ua.path}`"
                  class="full-width"
                />
              </v-zoomer>
            </div>
            <div class="col" style="border: 1px solid lightgrey">
              <q-scroll-area>
                <form>
                  <!-- p class="form-header">{{ typdef.name }}</p-->
                  <dd
                    class="q-ma-sm"
                    :key="offset"
                    v-for="(field, offset) in typdef.fields"
                  >
                    <q-input
                      v-if="field.widget === 'input'"
                      :label="field.label"
                      :type="field.type"
                      :value="item[field.prop]"
                      v-model="item[field.prop]"
                    />

                    <hi-license
                      v-if="field.widget === 'hi-license'"
                      v-model="item.license"
                      :value="item.license"
                    />

                    <hi-language
                      v-if="field.widget === 'hi-language'"
                      v-model="item.language"
                      :value="item.language"
                    />

                    <q-date
                      landscape
                      :title="field.label"
                      v-if="field.widget === 'date'"
                      v-model="item[field.prop]"
                    />
                  </dd>
                </form>
              </q-scroll-area>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="center" class="q-pa-md">
          <q-btn no-caps label="Cancel" v-close-popup />
          <q-btn no-caps color="primary" @click="saveItem(item)" label="Save" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <section>
      <div class="row q-mx-auto justify-between items-center q-gutter-sm">
        <label
          >Title
          <h1 class="editable" contenteditable="true" data-label="kipTitle">
            {{ collection.name }}
          </h1>
        </label>
      </div>

      <div class="row q-mx-auto justify-between items-center q-gutter-sm">
        <h2>{{ collection.subtitle }}</h2>
      </div>

      <div class="row q-mx-auto justify-between items-center q-gutter-sm">
        <h3>{{ collection.introduction }}</h3>
      </div>

      <div class="row q-mx-auto justify-between items-center q-gutter-sm">
        <h3>{{ collection.description }}</h3>
      </div>

      <div class="row q-mx-auto justify-between items-center q-gutter-sm">
        <h3>{{ collection.acknowledgements }}</h3>
      </div>
    </section>

    <section id="card-holder">
      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md">
          <div :key="card.uuid" v-for="(card, i) in collection.items">
            <Card
              :index="i"
              :title="card.title ? card.title : 'No title'"
              type="Source"
              csstype="source"
              :image-url="card.url"
              :item="card"
              :show-meta-button="true"
              :btn-edit-modal="true"
              @showEdit="showEditor(i + 1)"
              >{{ card.introduction }} {{ i }}</Card
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { API } from '@/tic';
import Card from '../tags/Card';
import HiLicense from '@/components/hiLicense';
import HiLanguage from '@/components/hiLanguage';
//import HiCountry from '@/components/hiCountry';

const typdef = {
  name: 'form',
  fields: [
    { label: 'Title', widget: 'input', type: 'string', prop: 'title' },
    {
      label: 'Introduction',
      widget: 'input',
      type: 'text',
      prop: 'introduction'
    },
    {
      label: 'Description',
      widget: 'input',
      type: 'text',
      prop: 'description'
    },
    { label: 'Local ID', widget: 'input', type: 'string', prop: 'local_id' },
    { label: 'Origin', widget: 'input', type: 'string', prop: 'origin' },
    { label: 'Type', widget: 'input', type: 'string', prop: 'type' },
    { label: 'Order', widget: 'input', type: 'string', prop: 'order' },
    { label: 'License', widget: 'hi-license', type: 'rel', prop: 'license' },
    { label: 'Language', widget: 'hi-language', type: 'rel', prop: 'language' },
    {
      label: 'Period start',
      widget: 'date',
      type: 'date',
      prop: 'period_start'
    },
    {
      label: 'Period End',
      widget: 'date',
      type: 'date',
      prop: 'period_end'
    },
    {
      label: 'Country',
      widget: 'hi-country',
      type: 'rel',
      relation: ['Country', ':IN'],
      prop: 'country'
    }
  ]
};

export default {
  name: 'MyCollectionViewer',
  components: { Card, HiLicense, HiLanguage },
  props: {
    slug: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      zoomed: true,
      sEditor: false,
      collection: {},
      items: [],
      isDirty: new Set(),
      current: 0,
      item: {},
      typdef: { ...typdef }
    };
  },

  watch: {
    slug: {
      // load a collection if the slug changes
      immediate: true,
      handler(slug, a) {
        this.$q.loading.show({
          delay: 100 // ms
        });
        console.log('mycolview handler', slug, a);
        API.get(`/collection/${slug}`).then(resp => {
          console.log('resp: ', resp);
          this.collection = { ...resp.data.data };
          this.$q.loading.hide();
        });
      }
    },

    current: {
      handler(e, a) {
        console.log('blader: ', this.current);
        this.item = { ...this.collection.items[this.current - 1] };
      }
    },

    item: {
      handler(e, a) {
        console.log('CURRENT: ', this.current);
        console.log('isDirty: ', this.isDirty);
        console.log(`e: ${e.uuid}`);
        console.log(`a: ${a.uuid}`);

        // console.log(`ITEM! e: ${JSON.stringify(e)}`);
        // console.log(`      a: ${JSON.stringify(a)}`);

        if (e.uuid === a.uuid) {
          console.log('SAME OBJECT ');
          this.isDirty.add(this.current);
        }
      }
    }
  },

  mounted() {
    console.log('MYCOLLECTION VIEWER ', typdef);
    window.addEventListener('keydown', this.nextItem);
    this.isDirty.clear();
  },

  destroyed() {
    window.removeEventListener('keydown', this.nextItem);
  },

  methods: {
    editorClose() {
      if (this.isDirty.size) {
        alert('close editor');
      }
    },
    saveItem(item) {
      console.log('save item: ', JSON.stringify(item));
      API.post('/collection/update-item', { item: item }).then(e => {
        this.$q.notify({
          type: 'positive',
          position: 'top',
          message: 'Saved changes'
        });
      });
    },

    nextItem() {
      if (this.sEditor) {
        if (event.keyCode === 37 && this.current > 1) {
          this.current--;
        } else if (
          event.keyCode === 39 &&
          this.current < this.collection.items.length
        ) {
          this.current++;
        }
      }
    },

    showEditor(current) {
      console.log('show editor for ', current);
      this.current = current;
      this.item = { ...this.collection.items[current] };
      this.sEditor = true;
    }
  }
};
</script>
<style scoped>
.form-header {
  background-color: lightgrey;
  color: black;
}

.editable:focus {
  border-bottom: 2px dotted grey;
}

.editable:focus:before {
  color: black;
  background-color: lightgrey;
  content: attr(data-label) '  \276F';
  font-size: 0.4em;
  text-transform: lowercase;
  padding: 0.2em;
}

.editable:focus:after {
  content: '\276E';
  color: lightgrey;
  font-size: 0.6em;
}
</style>
