<template>
  <div class="editor">

    <!-- Quill editor -->
    <quill-editor
			ref="editor"
			v-model="localText"
      @input="storeText"
			:toolbar-options="[
				[{ 'header': [1, 2, 3, 4, 5, false] }],
				['bold', 'italic', 'underline'],
				['blockquote',{ 'list': 'ordered' }, { 'list': 'bullet' }],
				['glossary'],
			]"
		/>

  </div>
</template>

<script>
import QuillEditor from '../../../components/QuillEditor';

export default {
  name: 'Highlighttext',
  
  components: {
    QuillEditor
  },
  
  props: {
    text:{
      type:String,
      default:''
    }
  },

  watch: {
     text () {
       this.localText = this.text;
     },
  },

  data () {
    return {
      localText:'',
    };
  },

  created () {
    this.localText = this.text;
  },

  methods: {
    storeText () {
      //notify parent of text change
      this.$emit('change',this.localText);
    }
  }
}
</script>

<style scoped>

.editor
{
	position:relative;
	height:calc(100% - 75px);
}
.editor >>> .quill-editor
{
	height:calc(100% - 50px);
}

</style>