<template>
  <div
    class="builder-index"
  >
    <!-- (extra) Chapter index on the left while editing in summary view -->

    <div class="index-scroll hide-scrollbar" ref="scroll" v-if="!hidden">
    <div
      v-for="(c,i) in localChapters"
      class="chapter -hidden"
      ref="chapters"
      :key="'chapter'+i"
    >
      <div
        class="nav-block" style="height:50px"
      >
        <h2 class="nav-block small row justify-around items-center" style="background-color:transparent">{{i+1}}</h2>

        <edit-button
          class="absolute-bottom-right write-chapter"
          icon="fa fa-trash"
          style="margin-bottom:7px !important"
          @click="$emit('removeChapter',i)"
        >Delete chapter</edit-button>

      </div>
    </div>
    <div class="nav-block action" @click="addChapter">
      <i class="fa fa-plus"/>
      <label>Add Chapter</label>
    </div>

    </div>
  </div>
</template>

<script>
import { clone,UseEase } from '../tic'
import EditButton from '../tags/EditButton';

export default {
  name: "NarrativeIndex",

  props: {

    chapters:{
      type:Array,
      default:() => []
    },

    view: {
      type: String,
      default: null
    },

    hidden: {
      type: Boolean,
      default:false
    }

  },

  setup (props, context) {
  },

  components: {
    EditButton
  },

  data () {
    return {
      localChapters:[],
    }
  },

  computed: {
  },

  watch: {

    chapters: {
      handler() {
        const hasChanged = JSON.stringify(this.chapters)!=JSON.stringify(this.localChapters);
        if (hasChanged)
        {
          this.localChapters = clone(this.chapters.filter((elm,index) => elm.type=='chapter'));
        }
      },
      immediate:true,
      deep:true
    },

  },

  mounted () {
    console.log('📚 Narrative-builder-index mounted')

  },

  methods: {

    addChapter () {
      this.$emit('addChapter');
    },

    scrollToChapter() {
      if (!this.$refs.chapters) return;
      const elm = this.$refs.chapters[this.activeChapter];
      if (elm) setTimeout(e => {
        new UseEase(
          v => window.scrollTo(0,v.y),
          false,
          .15,
          20
        )
        .start({ y:[window.scrollY,elm.offsetTop] })
      },150)
    },

    updateNarrative() {
      this.$emit('edit',this.localChapters);
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~quasar-variables'


.builder-index
  position:fixed;
  top:200px;
  left:50px;
  width:100px;
  background-color:$light-gray;
  transition:top $menu-transition;
  .nav-block
    background-color:$light-gray;
  .chapter > .nav-block
    border-bottom:1px solid $plasticgroen;
  h2
    font-size: 1.625rem;
    font-weight: 500;
    color:$plasticgroen;
  .edit-button
    display:none;

.page-scrolled .builder-index
  top:100px;

.builder-index
  .nav-block:hover
    background:alpha($primary,.03);
    .edit-button
      display:block;

.builder-index .nav-block.action
  color:$primary

</style>