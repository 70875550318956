<template>
  <div class="tab" ref="source-tab" :class="{ active:active, transition:active }" :style="{ 'background-color':color }">

    <!-- collapsed tab title -->

    <!-- <div class="tab-index" :style="{ 'color':color }">{{ tabIndex }}</div> -->
    <div class="tab-title" v-if="!active">{{ source.title || 'Untitled Source' }}</div>

    <!-- epanded tab header -->

    <div v-if="active" class="header">
      <div class="header-bg" :style="{ 'background-color': headerColor }"/>
      <h3 class="header-title"><i class="fa fa-highlighter on-left"/>{{ source.title || 'Untitled Source' }}</h3>

<!--
      <div style="background-color:rgba(255,255,255,.7); height:600px; overflow-y:scroll">
        {{ source.text }}
      </div>
 -->

    </div>

    <!-- source display -->

    <highlight-source v-if="active"
      :source="source"
      :answer="answer"
      :render="render"
      :color="color"
      :mode="mode"
      @store="storeSource"
      @storeAnswer="storeAnswer"
      @remove="$emit('remove')"
    />

  </div>
</template>

<script>
import HighlightSource from './Highlight-source';

export default {
  name: 'HiglightingTab',

  components: {
    HighlightSource,
  },

  props: {
    mode:{
      type:String,
      default:'student'
    },
    index: {
      type:Number,
      default:0
    },
    source: {
      type: Object,
      default: function () {
        return { url: 'default url' }
      }
    },
    answer:{
      type:Array,
      default:function () {
        return []
      }
    },
    color:{
      type:String,
      default:'white'
    },
    active:{
      type:Boolean,
      default:false
    }
  },

  data () {
    return {
      render:false,
      currentPage:1,
      pageCount:undefined,
      headerColor:'#0066CC'
    };
  },

  computed: {
    tabIndex() {
      return this.index+1;
    }
  },

  watch: {
    active () {
      this.render = false;
    }
  },

  mounted () {
    var obj = this;

    //remove transtion class when expanding is done
    var elm = this.$refs['source-tab'];

    ['transitionend','webkitTransitionEnd'].forEach( e =>
        elm.addEventListener(e, function() {

          this.classList.remove('transition');
          obj.render = obj.active;

        }, false)
    );

    elm.classList.remove('transition');

    this.render = this.active;

   //elm.dispatchEvent(new Event('transitionend'));
  },

  methods: {
    storeSource (source,save) {
      this.$emit('store',source,save);
    },
    storeAnswer (page,text) {
      this.$emit('storeAnswer',page,text);
    }
  },

}
</script>

<style scoped>
.tab
{
  position: relative;
  width:35px;
  /* height:100%; */
  transition:width .25s ease 0s;
  overflow:hidden;
  flex-shrink:1;
  border-right:1px solid rgba(255,255,255,.2);
  user-select: none;
}
.tab.active
{
  width:100%;
  /* background-color:white !important; */
}
.tab:not(.active)
{
  cursor:pointer
}
.tab-index
{
  position:absolute;
  left:0;
  bottom:0;
  padding-bottom:8px;

  width:31px;
  line-height:30px;
  text-align:center;
  color:#0066cc;
  font-weight:bold;
  z-index:1;
}
.tab-index::before
{
  content:'\f15b';
  position:absolute;
  left:7px;
  bottom:10px;
  color:white;
  z-index:-1;
  font-family: 'Font Awesome 5 Pro';
  font-size:24px;
}
.tab.active .tab-index
{
  top:4px;
  left:14px;
  bottom:auto;
}

.tab-title
{
  position:absolute;
  bottom:-10px;
  left:2px;
  color:white;
  transform:rotate(-90deg);
  transform-origin:0 0;
  width:calc(100vh - 195px);

  font-weight:bold;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;

}

.header
{
  position:absolute;
  top:0;
  left:0;
  width:50%;
  height:42px;
  z-index:10;
}
.header-bg
{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  opacity:.2;
  z-index:0;
}
.header-title
{
  position:absolute;
  left:20px;
  top:5px;
  right:20px;

  padding:0;
  margin:0;
  font-size:1.25rem;

  z-index:1;
  color:#0066cc;

  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}


</style>