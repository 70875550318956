<template>
  <section
    id="hiThemeFilter"
    class=""
  >
    <div class="row q-mx-auto justify-start content-start items-center">
      <div class="col-shrink q-pr-md">
        <h4>What are you looking for?</h4>
      </div>
      <div
        class="col q-py-md"
        v-if="context==='HC'"
      >
        <q-select
          v-model="selectedPeriods"
          multiple
          :options="periods"
          label="Historial Periods"
          wrap
          style="max-width:280px;"
        />
      </div>
      <div
        class="col q-py-md"
        v-if="context==='HC'"
      >
        <q-select
          v-model="selectedThemes"
          multiple
          :options="themes"
          label="Themes"
          wrap
          style="max-width:280px;"
        />
      </div>
      <div
        class="col q-py-md"
        v-if="context==='HC'"
      >
        <q-select
          v-model="selectedSourcetypes"
          multiple
          :options="sourcetypes"
          label="Sourcetypes"
          wrap
          style="max-width:280px;"
        />
      </div>
      <div
        class="col q-py-md"
        v-if="context==='TL'"
      >
        <q-select
          v-model="selectedThinking"
          multiple
          :options="thinking"
          label="Historical thinking"
          wrap
          style="max-width:280px;"
        />
      </div>
      <div
        class="col q-py-md"
        v-if="context==='TL'"
      >
        <q-select
          v-model="selectedMethods"
          multiple
          :options="methods"
          label="Teaching methods"
          wrap
          style="max-width:280px;"
        />
      </div>
      <div
        class="col q-py-md"
        v-if="context==='TL'"
      >
        <q-select
          v-model="selectedChallenges"
          multiple
          :options="challenges"
          label="Teaching Challenges"
          wrap
          style="max-width:280px;"
        />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'HiThemeFilter',
  props: {
    context: {
      type: String,
      default: 'HC'
    }
  },

  data () {
    return {
      selectedThemes: [],
      selectedPeriods: [],
      selectedSourcetypes: [],
      selectedThinking: [],
      selectedMethods: [],
      selectedChallenges: [],

      themes: [
        'Conflict and Cooperation', 'Ideas and Ideologies', 'Life and Leisure', 'People on the Move', 'Rights and Responsibilities', 'The Environment', 'Work and Technology'
      ],
      periods: [
        '20th Century', '19th Century', '18th Century', '15-17th Century', 'Late Middle Ages', 'Early Middle Ages', 'First Millennium', 'Antiquity'
      ],
      sourcetypes: [
        'Artefacts', 'Audio', 'Images', 'Maps', 'Monuments', 'Statistics', 'Video'
      ],
      thinking: [
        'Analysis of Historical Sources', 'Applying Historical Knowledge', 'Cause and Consequence', 'Change and Continuity', 'Chronological Understanding', 'Historical Interpretations', 'Historical Significance', 'Presenting the Past'
      ],
      methods: [
        'Compare and Contrast', 'Debate and Discussion', 'Drama and Roleplay', 'Interdisciplinary Work', 'Persuade and Present'
      ],
      challenges: [
        'Challenging Stereotypes', 'Encouraging Multiperspectivity', 'Making History Accessible', 'Making the Past Relevant', 'Moral Dilemmas in the Past and the Present'
      ]
    }
  }
}
</script>
<style scoped>
</style>