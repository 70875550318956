<template>
  <div>
    <search-europeana
      :show="showSearchEuropeana"
      @hide="showSearchEuropeana = false"
      @dirty="getData"
    />

    <show-meta :show="showMetaDialog" @hide="showMetaDialog = false" />

    <!-- single-file excel upload -->
    <UploadCollection
      :show="showExcelUploader"
      type="collection-excel"
      accept=".xlsx"
      @close="showExcelUploader = false"
      @isDirty="getCollections"
      @uploaded="processExcel"
    />

    <UploadCollection
      :show="showPPTXUploader"
      type="collection-powerpoint"
      accept=".pptx"
      @close="showPPTXUploader = false"
      @isDirty="getCollections"
      @uploaded="processPPTX"
    />

    <!-- used to upload collections -->
    <UploadCollection
      :show="showCollectionUploader"
      type="collection-batch"
      @close="showCollectionUploader = false"
      @isDirty="getCollections"
    />

    <!-- popup editor for Collection info -->
    <CollectionEditor
      :show="showEditor"
      :collection="collection"
      @closeEditor="showEditor = false"
      @isDirty="getCollections"
    />

    <section id="buttonbar">
      <div class="row q-mx-auto">
        <div class="col q-py-md">
          <!--
            Add a new collection via Excel or upload images directly as a
            collection.
          -->
          <a class="subnavlink" href="#" @click="showSearchEuropeana = true">
            Import From Europeana
            <q-avatar square size="28px">
              <img
                src="/europeana-icon.svg"
                class="icon_europeana"
              /> </q-avatar
          ></a>

          <a
            class="subnavlink"
            href="#"
            @click="showExcelUploader = !showExcelUploader"
          >
            Upload Excel batch</a
          >

          <a
            class="subnavlink"
            href="#"
            @click="showPPTXUploader = !showPPTXUploader"
          >
            Upload Powerpoint
          </a>

          <a
            class="subnavlink"
            href="#"
            @click="doUpload"
            @isDirty="getCollections"
          >
            Batch upload of images
          </a>

          <a
            class="subnavlink"
            href="/static/Historiana_BatchUpload_Example.xlsx"
          >
            Download Excel template
          </a>

          <a
            class="subnavlink"
            href="#"
            :disable="activeSelection.length <= 0"
            @click="deleteSelection"
          >
            Delete selection
          </a>
        </div>
      </div>
    </section>

    <hi-search
      v-if="collections.length"
      v-model="searchFor"
      placeholder="Find your Collections"
    />

    <section id="card-holder">
      <div class="row q-mx-auto justify-start items-start" id="collections">
        <div class="row q-gutter-md">
          <div :key="i" v-for="(card, i) in filteredItems" class="column">
            <Card
              cardTooltip="Edit collection"
              :item="card"
              :index="i"
              :uuid="card.uuid"
              :thumb="card.thumb.url"
              type="Collection"
              :csstype="
                card.is_published ? 'collection published' : 'collection'
              "
              :btnSelect="true"
              :title="card.name"
              :btnEditModal="true"
              :btnMeta="true"
              :btnDelete="!card.is_published"
              :btnPublish="!card.is_published"
              :btnUnPublish="card.is_published"
              @showEdit="editCollection"
              @deleteCard="deleteCollection"
              @publish="publishCollection"
              @unpublish="unpublishCollection"
              @toggleSelect="toggleSelect"
              @showMeta="showMeta"
              :hover="false"
              :activeSelection="activeSelection"
              @cardClicked="editCollection(card)"
              >{{ card.introduction }}</Card
            >
          </div>
        </div>
        <div v-if="collections.length === 0">
          <h3>
            <i class="fa fa-exclamation-circle" aria-hidden="true" /> No
            collections yet
          </h3>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { API } from "../tic";
import { mapState, mapActions, mapGetters } from "vuex";
import Card from "../tags/Card";
import CollectionEditor from "../pages/CollectionEditor";
import UploadCollection from "../components/UploadCollection";
import SearchEuropeana from "../components/searchEuropeana.vue";
import showMeta from "../components/metaTable.vue";

export default {
  name: "MyCollections",
  components: {
    Card,
    CollectionEditor,
    UploadCollection,
    SearchEuropeana,
    showMeta,
  },
  computed: {
    filteredItems() {
      if (this.searchFor) {
        return this.collections.filter((e) => e.name.includes(this.searchFor));
      } else {
        return this.collections;
      }
    },

    ...mapGetters("user", [
      "displayname",
      "isAuthenticated",
      "uuid",
      "email",
      "session",
    ]),
  },
  data() {
    return {
      searchFor: "",
      collection: {}, // current collection
      collections: [], // all collections
      showMetaDialog: false,
      showEditor: false,
      showSearchEuropeana: false,
      showCollectionUploader: false,
      showPPTXUploader: false,
      showExcelUploader: false,
      activeSelection: [],
    };
  },

  watch: {
    collections: {
      immediate: true,
      handler(e, a) {},
    },
  },

  created() {
    const vm = this;
    // get Collections for current user
    this.getData();
  },

  methods: {
    showMeta(iets) {
      console.log("show meta: ", iets);
      this.showMetaDialog = true;
    },

    toggleSelect(uuid) {
      const vm = this;
      if (vm.activeSelection.includes(uuid)) {
        vm.activeSelection = vm.activeSelection.filter((e) => e !== uuid);
      } else {
        vm.activeSelection.push(uuid);
      }
    },

    deleteSelection() {
      const vm = this;
      this.$q
        .dialog({
          title: "Confirm",
          message: "Really delete current selection?",
          cancel: true,
          persistent: true,
        })
        .onOk((e) => {
          console.log("** delete selection");
          API.post("/collection/delete", {
            collections: vm.activeSelection,
            user: this.uuid,
          }).then((r) => {
            this.$q.notify({
              type: "positive",
              position: "center",
              message: "Collections deleted",
            });
            this.getCollections();
          });
        });
    },

    getData() {
      console.log(`GET COLLECTIONS FOR USER ${this.uuid}`);
      API.post("/collection", { user: this.uuid }).then((r) => {
        this.collections = r.data.data;
      });
    },

    doUpload() {
      this.collection = {};
      this.showCollectionUploader = true;
    },

    closeEditor(e) {
      this.showEditor = false;
    },

    uploadError(info) {
      this.$q.dialog({
        title: '<i class="fa fa-bomb"></i>&nbsp;Error',
        message: `Upload failed, please try later.`,
        html: true,
        ok: {
          color: "primary",
        },
      });
      this.$refs.uploadExcel.reset();
    },

    processPPTX(res) {
      let vm = this;
      console.log * ("PPTX: ", res);
    },

    processExcel() {
      let vm = this;
      this.$q.dialog({
        title: '<i class="fa fa-info"></i>&nbsp;Batch received',
        message: `Processing started, progress will be reported via ${vm.email}.`,
        html: true,
        ok: {
          color: "primary",
        },
      });
    },

    unpublishCollection(item) {
      let vm = this;
      console.log("MyCollections PUBLISH : ", item, vm.uuid);
      API.post("/collection/private", {
        partner: vm.uuid,
        collection: item.uuid,
      })
        .then((r) => {
          vm.$q.notify({
            type: "positive",
            position: "top",
            message: "Collection made private successfully!",
          });
          this.getCollections();
        })
        .catch((err) => {
          this.$q.dialog({
            title: '<i class="fa fa-bomb"></i>&nbsp;Error',
            message: `${err}`,
            html: true,
            ok: { label: "Ok", color: "primary", noCaps: true },
          });
        });
    },

    publishCollection(item) {
      let vm = this;
      console.log("MyCollections PUBLISH : ", item, vm.uuid);
      API.post("/collection/publish", {
        partner: vm.uuid,
        collection: item.uuid,
      })
        .then((r) => {
          vm.$q.notify({
            type: "positive",
            position: "top",
            message: "Collection published successfully!",
          });
          this.getCollections();
        })
        .catch((err) => {
          this.$q.dialog({
            title: '<i class="fa fa-bomb"></i>&nbsp;Error',
            message: `${err}`,
            html: true,
            ok: { label: "Ok", color: "primary", noCaps: true },
          });
        });
    },

    getCollections() {
      console.log("DIRTY! -- ReLOAD DATA");
      API.post("/collection", { user: this.uuid }).then((r) => {
        this.collections = r.data.data;
      });
    },

    editCollection(item) {
      console.log("edit item: ", item);
      this.showEditor = true;
      this.collection = item;
    },

    deleteCollection(item, uuid) {
      API.post("/collection/delete", {
        collection: uuid,
        user: this.uuid,
      }).then((r) => {
        this.$q.notify({
          type: "positive",
          position: "center",
          message: "Collection deleted",
        });
        this.getCollections();
      });
    },
    getHeaders(e) {
      return [
        // these are needed in the API to identify the user
        { name: "X-Session", value: this.session },
        { name: "X-Process", value: "ExcelBatch" },
      ];
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~quasar-variables';

.icon_europeana {
  filter: invert(0.6);
  webkit-filter: invert(0.6);
}

.icon_europeana:hover {
  filter: invert(1);
  webkit-filter: invert(1);
}
</style>
