import { render, staticRenderFns } from "./hiLogo.vue?vue&type=template&id=57bc473e&scoped=true&"
import script from "./hiLogo.vue?vue&type=script&lang=js&"
export * from "./hiLogo.vue?vue&type=script&lang=js&"
import style0 from "./hiLogo.vue?vue&type=style&index=0&id=57bc473e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57bc473e",
  null
  
)

export default component.exports