<template>
  <!-- START CONTENT HOME -->
  <div id="homepage">
    <section id="tagline" class="q-mb-lg">
      <div
        class="row q-mx-auto justify-between items-center"
        id="Historiana-intro"
      >
        <div class="col">
          <h1>
            Historiana offers free
            <RouterLink to="/historical-content">
              historical content,
            </RouterLink>
            ready to use
            <RouterLink to="/teaching-learning">
              learning activities,
            </RouterLink>
            and
            <RouterLink to="/builder"> innovative digital tools </RouterLink>
            made by and for history educators across Europe.
          </h1>
          <!--<h3 data-open="discover-modal"><a href="#">Discover Historiana <i class="fa fa-arrow-right fa-lg"></i></a></h3>-->
        </div>
      </div>
    </section>

    <!-- DIRECTIONS -->
    <section id="site-directions" class="q-mb-xl">
      <div class="row items-stretch q-col-gutter-sm">
        <div class="col-12 col-md-6 col-lg-4">
          <RouterLink
            custom
            v-slot="{ href, navigate }"
            to="/historical-content"
          >
            <div class="bg-historicalcontent">
              <h5>
                Historical Content
                <q-icon
                  name="fa fa-arrow-right"
                  size="sm"
                  class="float-right"
                />
              </h5>
              <h6>
                Find your multiperspective resources to teach history beyond
                borders
              </h6>
            </div>
          </RouterLink>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <RouterLink
            custom
            v-slot="{ href, navigate }"
            to="/teaching-learning"
          >
            <div class="bg-teachingandlearning">
              <h5>
                Learning activities
                <q-icon
                  name="fa fa-arrow-right"
                  size="sm"
                  class="float-right"
                />
              </h5>
              <h6>
                Find ideas and ready to use resources for your classroom
                practice
              </h6>
            </div>
          </RouterLink>
        </div>

        <!-- <div class="col-12 col-md-6 col-lg-4">
          <RouterLink tag="div" to="/sas/partners" class="bg-searchcollections">
            <h5>
              Search sources
              <q-icon name="fa fa-arrow-right" size="sm" class="float-right" />
            </h5>
            <h6>
              Search sources in the digital collections of Europeana or selected
              archives, libraries and museums
            </h6>
          </RouterLink>
        </div> -->

        <div class="col-12 col-md-6 col-lg-4">
          <RouterLink custom v-slot="{ href, navigate }" to="/builder">
            <div class="bg-activity-builder">
              <h5>
                Activity Builder
                <q-icon
                  name="fa fa-arrow-right"
                  size="sm"
                  class="float-right"
                />
              </h5>
              <h6>
                Create and share e-Learning activities in your language of
                choice
              </h6>
            </div>
          </RouterLink>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <a
            href="http://historiana.eu/themes/"
            target="_blank"
            class="bg-casestudies"
            style="display: block"
          >
            <h5>
              Themes
              <q-icon
                name="fa fa-external-link"
                size="sm"
                class="float-right"
              />
            </h5>
            <h6>
              Find thematic case studies that allow for comparison across time
              and space
            </h6>
          </a>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <a
            href="https://blogs.historiana.eu/"
            target="_blank"
            class="bg-direction"
            style="display: block"
          >
            <h5>
              Blog
              <q-icon
                name="fa fa-external-link"
                size="sm"
                class="float-right"
              />
            </h5>
            <h6>
              Read history educator's ideas and activities for digital learning
            </h6>
          </a>
        </div>

        <!--<div class="callout createapps">
                    <h6>Create apps in your own language using tools designed specifically for history learning</h6>
                    <a href="AB-home.html" class="">Create e-Learning Activities <i class="fa fa-arrow-right fa-lg"></i></a>
                </div>-->
        <!--<div class="callout direction">
                    <h6>Call to action<br /> Help to develop Historiana<br />See how you can contribute</h6>
                    <a href="#" class="a_demo_four">Learn how  <i class="fa fa-arrow-right fa-lg"></i></a>
                </div>-->
      </div>
    </section>
    <section id="card-holder" class="q-mt-xl">
      <!-- Selected units and modules -->
      <div class="row q-mx-auto justify-start items-start">
        <div class="col q-pb-md">
          <h3 class="text-white">
            <i class="fa fa-star" aria-hidden="true" /> Featured resources
            Historical Content ...
          </h3>
        </div>
      </div>

      <!-- cards -->
      <div class="row homepage q-mx-auto justify-start items-start q-gutter-md">
        <q-card>
          <a href="/historical-content/key-moments/world-war-1">
            <q-card-section class="type keymoments">
              Key moments
            </q-card-section>
            <img
              class="image"
              src="/static/img/Homepage/ww1.jpg"
              alt="Word War 1"
            />
            <q-card-section>
              <div class="title">World War 1: in wider perspective</div>
              <div class="description">
                It started on 28 July 1914 when Austria-Hungary declared war on
                Serbia but it quickly escalated into a European and then a
                global war. It was the first ‘total war’ of the industrial era,
                a phrase attributed to the German General, Erich Ludendorff. It
                lasted for more than four years.
              </div>
            </q-card-section>
          </a>
        </q-card>
        <q-card>
          <a href="/historical-content/key-moments/world-war-2">
            <q-card-section class="type keymoments">
              Key moments
            </q-card-section>
            <img
              class="image keymoments"
              src="/static/img/Homepage/ww2.jpg"
              alt="World War 2"
            />
            <q-card-section>
              <div class="title">World War 2: in wider perspective</div>
              <div class="description">
                The Second World War comprised many different, interlocking
                wars. In the Far East, conflict exploded when Japan invaded
                China in 1937, then widened into a vast war between the Empire
                of Japan and the United States and its allies from December 1941
                until Japan’s surrender in August 1945.
              </div>
            </q-card-section>
          </a>
        </q-card>
        <q-card>
          <a href="/historical-content/units/changing-europe">
            <q-card-section class="type keymoments"> Units </q-card-section>
            <img
              class="image keymoments"
              src="/static/img/Homepage/changing.jpg"
              alt="Changing Europe"
            />
            <q-card-section>
              <div class="title">
                Changing Europe: European decisions and European dilemmas
              </div>
              <div class="description">
                This Historiana unit sets the European Union in its historical
                context and enables students to understand that Europe faces,
                and has always faced, dilemmas that require decisions.
              </div>
            </q-card-section>
          </a>
        </q-card>
        <q-card>
          <a href="/historical-content/key-moments/the-cold-war">
            <q-card-section class="type keymoments">
              Key moments
            </q-card-section>
            <img
              class="image keymoments"
              src="/static/img/Homepage/coldwar.jpg"
              alt="Cold War"
            />
            <q-card-section>
              <div class="title">The Cold War: the long peace?</div>
              <div class="description">
                The world that emerged from World War II in 1945 was almost
                unrecognisable from the one of only six years earlier. The
                conflict left a wake of immense human and material destruction
                from which it would take years to recover.
              </div>
            </q-card-section>
          </a>
        </q-card>
      </div>

      <div class="row q-mx-auto justify-start items-center">
        <div class="col">
          <q-btn
            no-caps
            tag="a"
            to="/historical-content"
            class="q-my-md float-right bg-homepage"
            icon-right="fa fa-arrow-right fa-lg"
            label="See all historical content"
            text-color="white"
          />
        </div>
      </div>
      <q-separator class="q-mb-xl q-mt-md" dark style="height: 2px" />

      <div class="row q-mx-auto justify-start items-start">
        <div class="col q-pb-md">
          <h3 class="text-white">
            <i class="fa fa-star" aria-hidden="true" /> Featured resources
            Teaching & Learning
          </h3>
        </div>
      </div>
      <!-- Selected learning activities -->

      <div class="row homepage q-mx-auto justify-start items-start q-gutter-md">
        <q-card>
          <a
            href="/learning-activity/old-fears-and-new-threats-western-european-defence-negotiations"
          >
            <q-card-section class="type learningactivities">
              Learning Activities
            </q-card-section>
            <img
              class="image"
              src="/static/img/Homepage/oldfears.jpg"
              alt="Old fears and new threats"
            />
            <q-card-section>
              <div class="title">
                Old fears and new threats: Western European defence negotiations
              </div>
              <div class="description">
                How revealing are the negotiations over European defence
                1948-1954 about the hopes and fears of the people involved?
              </div>
            </q-card-section>
          </a>
        </q-card>
        <q-card>
          <a
            href="/learning-activity/opening-europes-borders-border-controls-in-a-post-schengen-world"
          >
            <q-card-section class="type learningactivities">
              Learning Activities
            </q-card-section>
            <img
              class="image"
              src="/static/img/Homepage/openingup.jpg"
              alt="Opening Europe's borders"
            />
            <q-card-section>
              <div class="title">
                Opening Europe's borders: border controls in a (post-)Schengen
                world
              </div>
              <div class="description">
                How did the migrant crisis shake the foundations and principles
                of the European Union?
              </div>
            </q-card-section>
          </a>
        </q-card>
        <q-card>
          <a href="/learning-activity/would-you-like-to-play-in-the-orchestra">
            <q-card-section class="type learningactivities">
              Learning Activities
            </q-card-section>
            <img
              class="image"
              src="/static/img/Homepage/wouldyoulike.jpg"
              alt="Would you like to play in the orchestra?"
            />
            <q-card-section>
              <div class="title">Would you like to play in the orchestra?</div>
              <div class="description">
                Discover the attitude that Mussolini kept toward the press,
                starting from his speech to the newspapers editors on 10th
                October 1928.
              </div>
            </q-card-section>
          </a>
        </q-card>
        <q-card>
          <a href="/learning-activity/perpetrators-or-victims">
            <q-card-section class="type learningactivities">
              Learning Activities
            </q-card-section>
            <img
              class="image"
              src="/static/img/Homepage/perpetrators2.jpg"
              alt="Perpetrators or victims?"
            />
            <q-card-section>
              <div class="title">Perpetrators or victims?</div>
              <div class="description">
                How should we remember the camp and commemorate its victims,
                although many of those interned were accused of perpetrating
                crimes in the Nazi era?
              </div>
            </q-card-section>
          </a>
        </q-card>
      </div>

      <div class="row q-mx-auto q-pa-sm justify-between items-center">
        <div class="col">
          <q-btn
            no-caps
            tag="a"
            to="/teaching-learning"
            class="q-my-md float-right bg-homepage"
            icon-right="fa fa-arrow-right fa-lg"
            label="See all learning activities"
            text-color="white"
          />
        </div>
      </div>
    </section>
    <section id="blogentries">
      <div class="row q-mx-auto q-pa-sm justify-between items-center">
        <div class="col">
          <h3 class="area-title q-mt-lg">
            <i class="fa fa-commenting" aria-hidden="true" /> A Guide for
            Teacher Training
          </h3>
          <img
            src="/static/img/Homepage/Japanese market scene (cropped) Wellcome Collection_CC BY 4.0.jpg"
            alt=""
            width="280px"
            class="float-left on-left"
          />
          <p><small>Wellcome Collection. CC BY. Found via Europeana.</small></p>
          <p>
            Together with Europeana, EuroClio developed a teacher training guide
            <strong
              >”How to use online tools to promote historical thinking?”</strong
            >
            This guide is designed for teacher trainers to challenge and support
            their trainees or colleagues to create, adapt and use eLearning
            Activities that promote historical thinking using the Historiana
            eLearning Environment and Europeana’s Source Collections. The guide
            includes practical information on how to set up a workshop, a set of
            ready to use teacher challenges to create eLearning activities, and
            various types of support material.
          </p>
          <q-btn
            no-caps
            type="a"
            href="https://www.euroclio.eu/Historiana-Teacher-Training-Guide"
            icon-right="fas fa-download"
            label="Download guide"
            color="cyaan"
            class="bg-homepage"
            text-color="white"
          />

          <!-- Test new features -->
          <h3 class="area-title q-mt-lg">
            <i class="fa fa-th-large" aria-hidden="true" /> Test new features
          </h3>
          <img
            src="/static/img/Homepage/eLearning.png"
            alt=""
            width="280px"
            class="float-left on-left"
          />
          <h5>
            Build your own eLearning Activities. Using tools specifically
            designed for history education
          </h5>
          <p>
            A new feature of Historiana, which is now available in beta version,
            is the eLearning Activity Builder. With this Activity Builder, you
            can create eLearning Activities in your own language, by using and
            rearranging building blocks specifically designed for history
            education practice. We are in the process of testing and making
            improvements, and therefore welcome feedback. You can test the
            features by clicking on "e-Activity Builder" in the top menu. If you
            are willing to help with testing please express your interest by
            sending an email to
            <a href="mailto:testteam@historiana.eu" class="linkcolor">
              testteam@historiana.eu </a
            >.
          </p>
          <q-btn
            no-caps
            tag="a"
            to="/builder/"
            icon-right="fas fa-arrow-right"
            label="Test the eLearning Activity Builder "
            color="cyaan"
            class="bg-homepage"
            text-color="white"
          />

          <q-separator inset style="margin: 2em 0" />

          <h5>
            Historiana works best using Chrome as browser. Download Chrome now
            for the best user's experience
          </h5>
          <p>
            Although it is our ambition to develop Historiana as a
            webapplication that works well on all modern browsers and on tablets
            as well as laptops and PC's, we know that at the moment, some issue
            occur that are browser specific. Therefore, we recommend you to use
            Google Chrome as browser to get the best user experience, especially
            when you want to try out the eLearning Activity Builder. You can
            download Google Chrome for free at
            <a
              href="https://www.google.com/chrome/"
              target="_blank"
              class="linkcolor"
            >
              https://www.google.com/chrome/ </a
            >.
          </p>

          <!-- Set priorities -->
          <h3 class="area-title q-mt-lg">
            <i class="fa fa-lightbulb-o" aria-hidden="true" /> Set priorities
          </h3>
          <img
            src="/static/img/Homepage/Priorities.png"
            alt=""
            width="280px"
            class="float-left on-left"
          />
          <h5>
            Help us decide what to develop next by filling out the needs
            assessment survey
          </h5>
          <p>
            The development of historical content and teaching and learning
            resources is done by a team of professional volunteers. In order for
            these teams to develop the resources that are most helpful for you
            as history educators, we are assessing your needs via a running
            survey. The results of this survey are discussed during each
            development meeting and provide evidence on the basis of which the
            teams set their priorities. If you have specific requests or
            suggestions, you can also contact us directly.
          </p>
          <p>
            <q-btn
              no-caps
              type="a"
              target="_blank"
              href="https://euroclio.eu/historiana-survey"
              icon-right="fas fa-arrow-right"
              label="Fill out the survey"
              color="cyaan"
              class="bg-homepage"
              text-color="white"
            />
          </p>
          <q-separator inset style="margin: 2em 0 4em 0" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from "jquery";
// var $ = window.jQuery
export default {
  name: "Homepage",
  mounted: function () {
    //     $("body").removeClass()
    //     $("body").addClass('is-homepage')
  },
};
</script>

<style scoped lang="stylus">
@import '~quasar-variables';

.is-homepage .blog a {
  color: $black;
}

.is-homepage .bg-historicalcontent, .is-homepage .bg-teachingandlearning, .is-homepage .bg-searchcollections, .is-homepage .bg-casestudies, .is-homepage .bg-activity-builder, .is-homepage .bg-direction {
  border-radius: 4px;
  padding: 32px;
  min-height: 210px;
}

.is-homepage .bg-historicalcontent {
  background-color: $plasticgroen;
  color: $white;
  border: 1px solid $plasticgroen;

  &:hover {
    background-color: $white;
    color: $plasticgroen;
    border: 1px solid $plasticgroen;
  }
}

.is-homepage .bg-teachingandlearning {
  background-color: $blauwgroen;
  color: $white;
  border: 1px solid $blauwgroen;

  &:hover {
    background-color: $white;
    color: $blauwgroen;
    border: 1px solid $blauwgroen;
  }
}

.is-homepage .bg-searchcollections {
  background-color: $korenblauw;
  color: $white;
  border: 1px solid $korenblauw;

  &:hover {
    background-color: $white;
    color: $korenblauw;
    border: 1px solid $korenblauw;
  }
}

.is-homepage .bg-casestudies {
  background-color: $paars;
  color: $white;
  border: 1px solid $paars;

  &:hover {
    background-color: $white;
    color: $paars;
    border: 1px solid $paars;
  }
}

.is-homepage .bg-activity-builder {
  background-color: $hardroze;
  color: $white;
  border: 1px solid $hardroze;

  &:hover {
    background-color: $white;
    color: $hardroze;
    border: 1px solid $hardroze;
  }

  a {
    color: $white;
    text-decoration: none;
  }
}

.is-homepage .bg-direction {
  background-color: $cyaan;
  color: $white;
  border: 1px solid $cyaan;

  &:hover {
    background-color: $white;
    color: $cyaan;
    border: 1px solid $cyaan;
  }
}

#tagline {
  font-family: Ubuntu;
}

#tagline a, a {
  color: #00aaee;
}

.area-title {
  border-bottom: 2px solid;
  display: block;
  margin-top: 2em;
}

#blogentries h4, #blogentries h5 {
  margin-bottom: 0;
  margin-top: 0.5em;
}

.q-card a {
  color: black;
}

.q-card {
  width: 235px;
  border-radius: 4px;

  .type {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .title {
    padding: 0;
    font-weight: 600;
    max-height: calc(1.5em * 5);
    min-height: auto;
    letter-spacing: -0.03em;
  }

  img {
    width: 100%;
    height: 165px;
  }
}
</style>
