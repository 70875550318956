// used in Upload.vue to Batch upload collection
export let collection = {
  user: '',
  name: '',
  introduction: '',
  description: '',
  acknowledgements: '',
  uuid: '',
  language: {},
}


