<template>
  <!-- SASExplore.html -->
  <div>
    <section id="card-holder" class="collections">
      <div class="row card-holder-title">
        <div class="column">
          <h4>
            Explore various thematic collections featuring selected high-quality
            and openly licensed content from Europeana
          </h4>
        </div>
      </div>

      <div class="row small-up-2 medium-up-3 large-up-4 stack">
        <div
          :key="i"
          v-for="(card, i) in collections"
          class="column"
          :id="card.col.uuid"
        >
          <Card
            :item="card"
            :title="card.col.name"
            :image-url="
              '/uploads/' + card.thumb.uuid + '_' + card.thumb.filename
            "
            :uuid="card.col.uuid"
            type="Collection"
            :btn-add="false"
            :btn-preview="false"
            csstype="sas collection"
            :goto="'/sas/explore/' + card.col.slug"
          >
            {{ card.col.subtitle }}
          </Card>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Card from '../tags/Card';
import { API } from '../tic';

export default {
  name: 'SasExplore',
  components: { Card },

  mounted() {
    API.post('/sas/explore', {}).then(response => {
      this.collections = response.data.data;
    });
  },

  data() {
    return {
      collections: [],
      image: ''
    };
  }
};
</script>
