<template>
  <div>
    <div v-if="!$store.getters['user/isAuthenticated']">
      <Alert>
        You are not logged, you can create an Activity but you can't save
        it.
      </Alert>
    </div>
    <!-- ===== START ACTIVITY BUILDER ===== -->
    <!--div>
  <li>order:  {{blockOrder}}</li>
  <li>blocks: {{this.$store.state.activity.bblocks}}  </li>
  <li>xx: {{blocks}}</li>
</div>
    <li>{{blocks}}</li>
    <li>direct: {{$store.state.activity.bblocks}}</li>
    <li>getter: {{$store.getters['activity/blocks']}}</li>
    -->

    <q-dialog v-model="showTags">
      <q-card style="min-width: 80vw; max-width: 80vw;">
        <q-bar>
          <q-toolbar-title>
            <span class="text-weight-bold">Select tags</span>
          </q-toolbar-title>
          <q-space />
          <q-btn flat round dense icon="fa fa-close" class="black" v-close-popup />
        </q-bar>
        <div class="row q-gutter-md q-pa-md">
          <div class="col q-pa-md">
            <MemberTags name="Class" nodelabel="MyClasses" />
          </div>
          <div class="col q-pa-md">
            <MemberTags name="Year" nodelabel="MyYears" />
          </div>
          <div class="col q-pa-md">
            <MemberTags name="Keyword" nodelabel="MyKeywords" />
          </div>
          <div class="col q-pa-md">
            <MemberTags name="Language" nodelabel="MyLanguages" />
          </div>
        </div>

        <q-card-actions align="center">
          <q-btn color="primary" v-close-popup label="OK" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- ===== 1. DRAG AND DROP ===== -->

    <div class="row" id="AB-holder">
      <ul id="drag-drop-area" class="connectedSortable clearfix">
        <li class="placeholder" />
      </ul>

      <!-- ===== 2. CONTAINMENT AREA ===== -->
      <!-- these can be dropped into the Drop area -->
      <ul id="containment-wrapper" class="connectedSortable clearfix">
        <li class="building-block text" data-type="text">
          <div class="block-title">Text</div>
          <div class="bb-actions show-after-drop">
            <ul class="menu simple align-center">
              <li>
                <a class="delete-this-bb">
                  <i class="fa fa-trash" aria-hidden="true" />
                </a>
              </li>
              <li>
                <RouterLink class="myLink" to="/builder/edit/text/">
                  <i class="fa fa-pencil" aria-hidden="true" />
                </RouterLink>
              </li>
            </ul>
          </div>
        </li>
        <li class="building-block question" data-type="question">
          <div class="block-title">Question</div>
          <div class="bb-actions show-after-drop">
            <ul class="menu simple align-center">
              <li>
                <a class="delete-this-bb">
                  <i class="fa fa-trash" aria-hidden="true" />
                </a>
              </li>
              <li>
                <RouterLink class="myLink" to="/builder/edit/question/">
                  <i class="fa fa-pencil" aria-hidden="true" />
                </RouterLink>
              </li>
            </ul>
          </div>
        </li>
        <li class="building-block embed" data-type="embed">
          <div class="block-title">Embed</div>
          <div class="bb-actions show-after-drop">
            <ul class="menu simple align-center">
              <li>
                <a class="delete-this-bb">
                  <i class="fa fa-trash" aria-hidden="true" />
                </a>
              </li>
              <li>
                <RouterLink class="myLink" to="/builder/edit/embed/">
                  <i class="fa fa-pencil" aria-hidden="true" />
                </RouterLink>
              </li>
            </ul>
          </div>
        </li>
        <li class="building-block sorting" data-type="sorting">
          <!-- Hoe? kan dit? -->
          <div class="block-title">Sorting</div>
          <div class="bb-actions show-after-drop">
            <q-btn flat class="delete-this-bb" icon="fa fa-trash" />
            <RouterLink class="myLink" to="/builder/edit/sorting/">
              <i class="fa fa-pencil" aria-hidden="true" />
            </RouterLink>
          </div>
        </li>
        <li
          v-if="false"
          class="building-block prioritizing"
          data-type="prioritizing"
        >
          <div class="block-title">Prioritizing</div>
          <div class="bb-actions show-after-drop">
            <ul class="menu simple align-center">
              <li>
                <a class="delete-this-bb">
                  <i class="fa fa-trash" aria-hidden="true" />
                </a>
              </li>
              <li>
                <RouterLink class="myLink" to="/builder/edit/prioritizing/">
                  <i class="fa fa-pencil" aria-hidden="true" />
                </RouterLink>
              </li>
            </ul>
          </div>
        </li>
        <li
          class="building-block analysing"
          data-type="analysing"
        >
          <div class="block-title">Analysing</div>
          <div class="bb-actions show-after-drop">
            <ul class="menu simple align-center">
              <li>
                <a class="delete-this-bb">
                  <i class="fa fa-trash" aria-hidden="true" />
                </a>
              </li>
              <li>
                <RouterLink class="myLink" to="/builder/edit/analysing/">
                  <i class="fa fa-pencil" aria-hidden="true" />
                </RouterLink>
              </li>
            </ul>
          </div>
        </li>
        <li class="building-block highlighting" data-type="highlighting">
          <div class="block-title">Highlighting</div>
          <div class="bb-actions show-after-drop">
            <ul class="menu simple align-center">
              <li>
                <a class="delete-this-bb">
                  <i class="fa fa-trash" aria-hidden="true" />
                </a>
              </li>
              <li>
                <RouterLink class="myLink" to="/builder/edit/highlighting/">
                  <i class="fa fa-pencil" aria-hidden="true" />
                </RouterLink>
              </li>
            </ul>
          </div>
        </li>

        <li class="building-block comparing" data-type="comparing">
          <div class="block-title">Comparing</div>
          <div class="bb-actions show-after-drop">
            <ul class="menu simple align-center">
              <li>
                <a class="delete-this-bb">
                  <i class="fa fa-trash" aria-hidden="true" />
                </a>
              </li>
              <li>
                <RouterLink class="myLink" to="/builder/edit/compare-contrast/">
                  <i class="fa fa-pencil" aria-hidden="true" />
                </RouterLink>
              </li>
            </ul>
          </div>
        </li>

        <li class="building-block discovering" data-type="discovering">
          <div class="block-title">Discovering</div>
          <div class="bb-actions show-after-drop">
            <ul class="menu simple align-center">
              <li>
                <a class="delete-this-bb">
                  <i class="fa fa-trash" aria-hidden="true" />
                </a>
              </li>
              <li>
                <RouterLink class="myLink" to="/builder/edit/discovering/">
                  <i class="fa fa-pencil" aria-hidden="true" />
                </RouterLink>
              </li>
            </ul>
          </div>
        </li>

      </ul>

      <p id="guidance" v-html="guidance" />

      <div class="full-width">
        <q-btn-group filled size="md" class="q-my-md">
          <q-btn no-caps @click="save" icon="fa fa-save" label="Save" />
          <q-btn no-caps @click="handleDelete" icon="fa fa-trash" label="Delete" />
          <q-btn no-caps @click="savenew" icon="fa fa-plus" label="New" />
          <q-btn
            no-caps
            @click="showTags = !showTags"
            v-if="$store.getters['user/hasTags']"
            icon="fa fa-tags"
            label="Select Tags"
          />
          <!-- <q-btn
            type="a"
            no-caps
            :href="studentUrl"
            v-if="$store.state.activity.uuid"
            icon="fa fa-eye"
            label="Show student view"
            target="_new"
          />-->
        </q-btn-group>
      </div>

      <!-- SHOW THE TAGS ACTIVE FOR THIS ACTIVITY -->
      <!-- <h5>Tags for this activity</h5> -->
      <div class="full-width q-my-md">
        <q-chip
          class="q-pa-md remove"
          clickable
          :key="i"
          v-for="(at, i) in tags"
          :id="i"
          title="Click to remove"
          @click="removeTag(at, i)"
          icon="fa fa-remove"
          :label="at.name"
        />
      </div>

      <!--h5 id="guidance">
        <span class="idea"></span>
        <span v-html="guidance" />
      </h5-->

      <q-dialog :value="$q.platform.is.mobile">
        <q-card>
          <q-bar>
            <i class="fa fa-warning" />&nbsp;Warning
          </q-bar>
          <q-card-section class="q-pa-md">
            <p>
              Historiana is optimised for laptops and PC’s, not yet for tablets
              and smartphones.
            </p>
            <P>This section may not function correctly.</P>
            <q-card-actions align="center">
              <q-btn no-caps label="I understand" v-close-popup />
            </q-card-actions>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>

    <span style="float:right">{{ $store.getters["activity/version"] }}</span>
  </div>
</template>
<script>
import Alert from "../tags/Alert"
import { API } from "../tic"
import MemberTags from "../tags/MemberTags"

import $ from "jquery"
import "jquery-ui"
import "jquery-ui/ui/widgets/sortable"
import "jquery-ui/ui/widgets/draggable"
import "jquery-ui/ui/widgets/droppable"
import "jquery-ui/ui/disable-selection"

//let $ = window.jQuery;

function mkBlock(block, uuid) {
  // console.log('mkBlock for: ', block)
  let html = `<li class="building-block ${block.type}" id="${block.id}" data-type="${block.type}">
    <div class="block-title">${block.type}</div>
      <div class="bb-actions">
        <ul class="menu simple" style="margin-top:4px;padding-left:26px;">
          <li>
            <a href="javascript:" class="delete-this-bb"><i class="fa fa-trash" aria-hidden="true"></i></a>
          </li>
          <li>
            <a href="/builder/${uuid}/edit/${block.type}/${block.id}" class="edit-bb">
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
    </li>
  `

  // as there is no access to me.$store from here we cannot setup
  // the delete handler now; so we just return and set it up from the caller
  return $(html)
}

export default {
  name: "AbPanel",
  components: {
    Alert,
    MemberTags
  },
  data() {
    return {
      showTags: false,
      guidance: "Click pen to edit. Trashcan to remove."
    }
  },
  computed: {
    studentUrl() {
      return `/student/view/${this.$store.getters["activity/activity"].uuid}/?uuid=${this.$store.getters["user/uuid"]}`
    },

    uuid() {
      return this.$store.getters["activity/uuid"]
    },

    blocks() {
      return this.$store.getters["activity/blocks"]
    },

    blockOrder() {
      return this.$store.getters["activity/blockOrder"]
    },

    tags() {
      return this.$store.state.activity.tags.filter(e => e.name)
    }
  },

  watch: {
    blockOrder() {
      console.log("□□ ABPanel: blockOrder change")
      this.updateBlocks()
    },

    uuid() {
      console.log("□□ ABPanel: uuid change")
      this.updateBlocks();
    }
  },

  mounted() {
    let me = this

    me.guidance =
      "Drag and drop the building blocks of your choice and put them in the order you want.<br>Click pencil to edit."

    //console.log("AB PANEL MOUNTED");

    // setup on page sortable config and interaction
    $("#drag-drop-area")
      .sortable({
        cursor: "move",
        revert: 50, // animation duration in ms
        placeholder: "ui-sortable-placeholder",
        tolerance: "pointer",
        zIndex: 1,

        activate: function(event, ui) {
          // remove placeholder when dropping a block
          $("li").remove("li.placeholder")
        },

        receive: function(event, ui) {
          console.log(
            "□□ ABPanel: dragdrop receive, current order=",
            me.blockOrder.toString()
          )

          var elm = $(event.originalEvent.target)
          if (!elm.is(".building-block")) {
            elm = elm.parents(".building-block")
          }

          //find highest index in current blockOrder to prevent duplicate ids
          const index = me.blockOrder.length
            ? me.blockOrder
                .map(a => parseInt(a.substring(3)))
                .reduce((a, b) => Math.max(a, b)) + 1
            : 0

          let uuid = me.$store.getters["activity/uuid"]
          let id = "bb_" + index
          let type = elm.data("type")

          elm
            .attr("id", id)
            .find(".bb-actions")
            .removeClass("show-after-drop")
            .find("a.myLink")
            .each(function() {
              // $(this).attr('href',$(this).attr('href') + id)
              // $(this).attr(
              //   "href",
              //   "/xuilder/" + uuid + "/edit/" + type + "/" + id
              // );
            })
            .end()
            .find(".delete-this-bb")
            .click(function() {
              me.confirmDelete(this)
            })

          // create new block in the store
          console.log("□□ ABPanel: create new block with id=", id)
          me.$store.commit("activity/createBlock", {
            id: id,
            name: type + "_" + index,
            type: type,
            href: "/builder/edit/" + type + "/bb_" + index,
            record: {}
          })

          // we need to set the blockorder otherwise the dropped item disappears
          console.log(
            "□□ ABPanel: store new blockorder=",
            $("#drag-drop-area")
              .sortable("toArray")
              .toString()
          )
          me.$store.commit(
            "activity/setBlockOrder",
            $("#drag-drop-area").sortable("toArray")
          )

          // make sure all blocks are created with mkBlocks
          // so it has an onClick for edit instead of a href
          me.updateBlocks()
        },

        stop: function(event, ui) {
          console.log(
            "□□ ABPanel: dragdrop stop, store new blockorder=",
            $("#drag-drop-area")
              .sortable("toArray")
              .toString()
          )
          //drag stop: store blockorder, call save activity in Builder
          me.$store.commit(
            "activity/setBlockOrder",
            $("#drag-drop-area").sortable("toArray")
          )
          me.$emit("saveActivity", "sortable-stop")

          //update guidance
          me.guidance = "Click pen to edit. Trashcan to remove."
        }
      })
      .disableSelection() // make text in sortable unselectable

    $("#containment-wrapper li").draggable({
      connectToSortable: "#drag-drop-area", // this line does the magic
      appendTo: "#drag-drop-area",
      zIndex:1000,
      //cursor: "move",
      revert: "invalid",
      helper: "clone",
      opacity: 0.8
    })

    // Add help text below
    $("#containment-wrapper li").droppable({
      // Add an element when droppable items starts to drag
      activate: function(event, ui) {
        me.guidance =
          "Drop inside droppable area to use, drag outside to remove."
      }
    })

    // make sure all blocks are ok
    me.updateBlocks()
  },

  methods: {
    removeTag(tag, index) {
      console.log("remove: ", tag, "index: ", index)
      //$('#' + iets).addClass('active')
      //this.$store.commit('activity/removeTag', tag)
      this.$store.commit("activity/removeTag", tag)
    },

    handleDelete(activity) {
      // delete the whole activity
      let vm = this
      this.$q
        .dialog({
          title: '<i class="fa fa-trash"></i>&nbsp;Confirm',
          message: "Permanently delete this activity?",
          cancel: true,
          persistent: true,
          html: true
        })
        .onOk(() => {
          API.post("/delete-activity", {
            user: this.$store.getters["user/uuid"],
            activity: this.$store.getters["activity/uuid"]
          }).then(response => {
            vm.$store.dispatch("activity/zapBuilder")
            vm.$store
              .dispatch("sendFeedback", {
                msg: response.data.msg,
                status: response.data.status
              })
              .then(() => {
                vm.$router.push("/builder/")
              })
          })
        })
    },

    save() {
      this.$store.dispatch("sendFeedback", {
        msg: "eActivity saved",
        status: "ok"
      })
      this.$emit("saveActivity", "savebutton")
    },

    savenew() {
      this.$store.dispatch("activity/zapBuilder")
      this.$emit("saveActivity", "create")

      this.$store.dispatch("sendFeedback", {
        msg: "Empty eActivity created",
        status: "ok"
      })
    },

    updateBlocks() {
      console.log("□□ ABPanel: updateBlocks", this.blockOrder.toString())

      //create the blocks in the panel with jQuery

      let vm = this

      //      if (this.blockOrder.length) {
      // clear current blocks
      $("#drag-drop-area").empty()

      this.blockOrder.forEach(e => {
        //console.log('* call mkBlock for: ', e)
        // var block = me.$store.state.activity.bblocks[e];
        let b = this.$store.getters["activity/getBlock"](e)

        // create block and add it to the area
        let mk = mkBlock(b, this.$store.getters["activity/uuid"])
          .appendTo("#drag-drop-area")
          .find(".delete-this-bb")
          .click(function() {
            vm.confirmDelete(this)
          })
          .end()
          .find("a.edit-bb")
          .mousedown(function(e) {
            e.stopPropagation()
          })
          .click(function(e) {
            //catch edit url click and send it to the router (quick hack until ABPanel is redone)
            e.preventDefault()
            vm.$router.push(this.getAttribute("href"))
          })
      })
      //     }
    },

    confirmDelete(elm) {
      // handle the trashcan delete of a single BB
      this.$q
        .dialog({
          title: '<i class="fa fa-trash"></i>&nbsp;Confirm Delete',
          message: "Are you sure you want to delete this block?",
          html: true,
          cancel: { noCaps: true, color: "grey-2", textColor: "black" },
          ok: { label: "Yes", color: "primary", noCaps: true }
        })
        .onOk(() => {
          let elmToDelete = $(elm).parents("li.building-block")
          let id = elmToDelete.remove().attr("id")
          this.$store.commit("activity/deleteBlock", id)
          this.$emit("saveActivity", "sortable-after-block-delete")
        })
        .onCancel(() => {
          console.log("keep...")
        })
    },

    closeConfirm() {
      this.showConfirm = false
      this.showConfirmDiscardActivity = false
    }
  }
}
</script>
<style lang="stylus">
/* startpage Builder: two area's with the suqare colored blocks */
@import '~quasar-variables';

/* ---- BUILDING BLOCKS ===== */
.building-block {
  position: relative;
  width: 100px;
  margin: 0.2em;
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
  // @include box-shadow($shadow-3dp);
  border-radius: $generic-border-radius;

  &::before {
    font-family: 'Font Awesome 5 Pro';
    font-size: 32px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }

  a {
    color: $white;
  }

  &:hover {
    // @include box-shadow($shadow-6dp);
  }

  &:active {
    // @include box-shadow($shadow-24dp);
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  &.add a {
    color: $dark-gray;
  }

  &.text::before {
    content: '\f15c';
  }

  &.question::before {
    content: '\f128';
    font-weight: 900;
  }

  &.embed::before {
    content: '\f0c1';
  }

  &.apps::before {
    content: '\f009';
  }

  &.sorting::before {
    content: '\f4d0';
    font-weight: 900;
  }

  &.prioritizing::before {
    content: '\f88e';
    font-weight: 900;
  }

  &.analysing::before {
    content: '\f002';
    font-weight: 900;
  }

  &.highlighting::before {
    content: '\f591';
    font-weight: 900;
  }

  &.comparing::before {
    content: '\f086';
  }

  &.discovering::before {
    content: '\f542';
    font-weight:300;
  }


  .block-title {
    font-weight: bold;
    font-size: 70%;
    // font-variant: small-caps;//
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    padding: 0.4em 0;
    bottom: 26px;
    // &:hover {
    // color: $black;
    // }
  }

  &.add-image {
    border: 0;
  }

  .bb-actions {
    position: absolute;
    bottom: 0;
    height: 24px;
    width: 100px;
    background-color: $light-gray;

    a:link, a:visited {
      color: $dark-gray;
    }

    a:hover {
      color: $hardroze;
    }
  }
}

#drag-drop-area, #containment-wrapper {
  position: relative;
  margin-left: 0;
  width: 100%;
  list-style: none;
  padding: 0.4em 0.4em;
  margin-top: 0px;
}

#drag-drop-area {
  min-height: 136px;
  -moz-box-shadow: inset 0 0 20px 0px $dark-gray;
  -webkit-box-shadow: inset 0 0 20px 0px $dark-gray;
  box-shadow: inset 0 0 20px 0px $dark-gray;
  border: 1px dashed $medium-gray;
  cursor: move;

  .building-block {
    min-height: 124px;

    &::before {
      top: 40%;
    }
  }

  .building-block.dropped {
    opacity: 1;
    margin-right: 0.3em;
  }

  .block-title {
    bottom: 26px;
  }
}

#containment-wrapper {
  min-height: 122px;
  // border: 1px solid $dark-gray;
  margin: 0;
  padding: 0;

  .building-block {
    min-height: 100px;

    &::before {
      top: 50%;
    }
  }

  .bb-actions {
    display: none;
  }

  .block-title {
    bottom: 0px;
  }
}

p.hint {
  background-color: #eebb00;
  border-radius: 4px;
  padding: 0.2em;
}

p.hint:before {
  font-family: FontAwesome;
  font-size: 1em;
  content: '\f0eb ';
  background-color: #eebb00;
}

.show-after-drop {
  display: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.building-block a.delete-this-bb {
  color: #595959;
}

.buildingblock-intro, .bblock {
  .title, .description {
    /* padding: 0 4px; */
    border: 1px dashed #9c9c9c;
    background-color: transparent;
    outline: none;
    -webkit-appearance: none;

    &:focus {
      border: 1px dashed #dd0077;
    }
  }

  .title {
    width: 100%;
    height: auto;
    min-height: 3em;
    margin-bottom: 0.5rem;
  }

  .description {
    height: auto;
    font-size: inherit;
  }
}

/* actionbar = orange buttons within the canvas, top-left, add background, sources etc. */
#actionbar li {
  position: relative;
  margin-right: 1px;
  width: 100px;
  height: 100px;
  background-color: #ff6600;
  color: #fefefe;
}

#actionbar li:hover {
  color: #ff6600;
  background-color: #fefefe;
}

#actionbar li.selected {
  color: #ff6600;
  background-color: #fefefe;
}

#actionbar li:before {
  font-family: FontAwesome;
  font-size: 24px;
  position: absolute;
  left: 20px;
  top: 30px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#actionbar li .txt {
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
  position: absolute;
  left: 10px;
  bottom: 10px;
  right: 10px;
}

#actionbar li.addimages:before {
  content: '\f067';
}

#actionbar li.choosebackground:before {
  content: '\f03e';
}

#activity-builder {
  background-color: $medium-gray;
}
</style>
