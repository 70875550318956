import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import Component from './NarrativeSourceCollection.vue'

export default Node.create({
  name: 'NarrativeSourceCollection',

  addAttributes() {
    // Return an object with attribute configuration
    return {
      title: { default:null },
      caption: { default:null },
      description: { default:null },
      related: { default:null },
      sources: { default:null }
    }
  },

  group: 'block',

  content: 'paragraph*',

  draggable: true,

  isolating: true,

  parseHTML() {
    return [
      {
        tag: 'narrative-source-collection',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['narrative-source-collection', mergeAttributes(HTMLAttributes),0]
  },

  addNodeView() {
    return VueNodeViewRenderer(Component)
  },

})