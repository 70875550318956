<template>
  <div
    id="narrative"
    class="narrative"
    :class="{ 'show-menu':showMenu }"
    v-scroll="onScroll"
  >
    <hi-header dark bg-color="plasticgroen"/>

    <pre v-if="debug" class="debug">{{narrative}}</pre>

    <!-- header -->

    <div class="header">

      <div
        class="nav-block head overflow-hidden cursor-pointer"
        :class="view"
        @click="overview"
      >
        <label class="text-plasticgroen">Narrative</label>
        <q-img
          v-if="narrative.icon"
          class="cursor-pointer"
          :src="narrative.icon.url"
          :ratio="1"
          position="50% 50%"
          spinner-color="plasticgroen"
          @click.stop="changeView(view=='summary' || menu.length==2? 'start':'summary')"
        />
      </div>

      <h1 class="narrative-title cursor-pointer" v-if="isEditor || view!='start'" @click="changeView('start')">
        {{narrative.title}}
        <q-badge
          v-if="isEditor"
          align="middle"
          :color="saved? 'appelgroen':'primary'"
          :text-color="saved? 'plasticgroen':'white'"
          @click.stop="!saved && narrative.uuid? store():null"
        >
          <q-spinner v-if="saving" class="q-mr-xs"/>
          <i class="fas fa-check q-mr-xs" v-if="saved"/>
          {{editState}}
        </q-badge>
        <q-badge align="middle" class="q-ml-sm" v-if="apiError" color="rood"><i class="far fa-exclamation-triangle q-mr-xs"/> {{apiError}}</q-badge>
        <q-badge align="middle" class="q-ml-sm" outline v-if="narrative.uuid && debug">{{narrative.uuid}}</q-badge>
        <q-icon v-if="isEditor" name="fa fa-bug small" class="cursor-pointer q-ml-sm" :class="{ 'text-primary':this.debug }" size="14px" @click.stop="debug=!debug"/>
        <q-icon v-if="isEditor" name="fa fa-eye small" class="cursor-pointer q-ml-sm" size="14px" @click.stop="showReader"/>
      </h1>

      <q-tabs
        v-if="!isEditor"
        class="narrative-view-menu"
        v-model="viewMenu"
        no-caps
        dense
        active-color="appelgroen"
        indicator-color="appelgroen"
        align="justify"
        narrow-indicator
        :style="{ width: menu.length*100 +'px' }"
      >
        <q-tab
          v-for="(v,i) in menu"
          :key="v"
          :name="v"
          :label="readViews[i]"
        />
      </q-tabs>

    </div>

    <!-- body -->

    <div
      class="page"
      ref="page"
      :style="{
        minHeight:pageHeight,
      }"
      :class="{
        'no-drop':hideDropcursor
      }"
    >
      <div class="page-body-margin bg-plasticgroen"/>

      <!-- cover and reading view -->

      <div class="page-body overflow-hidden">
        <transition name="slide-off">
          <narrative-cover
            v-if="view=='start'"
            :edit="isEditor"
            :editview="edit=='edit'"
            :info="info"
            @discover="changeView(menu.length>2? 'summary':'read')"
            @edit="updateInfo"
            @autosave="info=>updateInfo(info,true)"
            @addMedia="showMedia=true"
            @addSources="options=>{ this.addSources=options }"
          />
        </transition>
        <transition-group name="slide-in" tag="div" v-on:after-enter="enteredRead">

          <!-- narrative reader/editor -->

          <narrative-read
            key="reader"
            v-if="view=='read'"
            ref="read"
            :debug="debug"
            :edit="isEditor"
            :chapters="chapters"
            :activeChapter="activeChapter"
            :activeSubChapter="activeSubChapter"
            @chapterOffsets="e=>chapterOffsets=e"
            @chapterNodeOffsets="e=>chapterNodeOffsets=e"
            @chapterProgress="e=>chapterProgress=e"
            @scrollSelectChapter="scrollSelectChapter"
            @scrollSelectNode="(i,s)=>scrollSelectNode(i,s)"
            @scrollEnd="hideIndex=false"
            @edit="e=>updateChapters(e,'editor')"
            @resize="onReadResize"
            @toggleDrop="e=>hideDropcursor=!e"
            @showBuilder="sidePanelHidden=false;toggleSidePanelExpanded(false)"
          />

          <!-- related items in edit view -->

          <narrative-related
            key="related"
            v-if="isEditor && view=='read'"
            class="related-edit"
            active
            :view="view"
            :edit="isEditor"
            :active="sidePanelHidden"
            :narrative="narrative"
            :relatedByChapter="relatedByChapter"
            :summaryOffsets="summaryOffsets"
            :chapterOffsets="chapterOffsets"
            :chapterNodeOffsets="chapterNodeOffsets"
            :activeChapter="activeChapter"
            :activeChapterNode="activeChapterNode"
            :activeSubChapter="activeSubChapter"
            :lastScroll="lastScroll"
            :style="{ height:sidePanelHeight }"
            @setSidePanelHidden="e=>sidePanelHidden=e"
            @removeRelated="removeRelated"
          />
        </transition-group>
      </div>

      <!-- navigation and index -->

      <div
        class="index-wrapper absolute-top fit"
        :class="{
          'overflow-hidden':hideIndexOverflow,
          'no-pointer-events':view=='read'
        }"
      >
        <transition name="slide-in" @after-enter="hideBuilderIndex=false">
          <narrative-index
            v-if="view!='start'"
            ref="index"
            :view="view"
            :edit="isEditor"
            :debug="debug"
            :chapters="chapters"
            :activeChapter="activeChapter"
            :chapterProgress="chapterProgress"
            :hideIndex="hideIndex"
            @showBuilderIndex="hideBuilderIndex=false"
            @read="changeView('read')"
            @summary="viewSummary"
            @readChapter="readChapter"
            @setPageHeight="setPageHeight"
            @summaryOffsets="e=>summaryOffsets=e"
            @unsaved="saved=false"
            @edit="e=>updateChapters(e,'index')"
            @addChapter="addChapter"
            @addMedia="showMedia=true"
            @addSources="options=>{ this.addSources=options }"
          />
        </transition>
      </div>

      <narrative-builder-index
        v-if="isEditor && view=='summary'"
        :chapters="chapters"
        :hidden="hideBuilderIndex"
        @addChapter="addChapter"
        @removeChapter="i=>removeChapter(i)"
      />

    </div>

    <!-- side panel -->

    <div
      v-if="showSidePanel"
      v-show="!sidePanelHidden"
      class="side-panel"
      ref="sidePanel"
      :class="{
        fixed:view=='start' || isEditor,
        builder:isEditor,
        active:sidePanelActive,
        expanded:sidePanelExpanded,
        transition:sidePanelTransition
      }"
      :style="{
        height:sidePanelHeight
      }"
    >
      <div class="side-panel-header">
        <q-btn
          v-if="sidePanelActive"
          class="expand"
          flat
          round
          dense
          size="12px"
          :color="sidePanelExpanded || edit? 'plasticgroen':'appelgroen'"
          :icon="sidePanelExpanded? 'fa fa-chevron-right':'fa fa-chevron-left'"
          @click.stop="toggleSidePanelExpanded"
        />
        <div
          v-if="isEditor || hasRelated"
          class="nav-block small button toggle"
          @click="toggleSidePanel"
        ><i :class="isEditor? 'fa fa-pencil':'far fa-project-diagram'"/>
        </div>
        <h2 v-if="sidePanelActive" class="ellipsis">{{sidePanelLabel}}</h2>
        <q-btn
          v-if="sidePanelActive && !isEditor"
          class="close"
          flat
          round
          dense
          style="position:absolute; right:10px"
          :color="sidePanelExpanded? 'plasticgroen':'appelgroen'"
          size="12px"
          icon="fa fa-times"
          @click.stop="toggleSidePanel"
        />

      </div>

      <!-- related items -->

      <narrative-related
        v-if="!isEditor && hasRelated"
        :view="view"
        :active="sidePanelActive"
        :narrative="narrative"
        :relatedByChapter="relatedByChapter"
        :summaryOffsets="summaryOffsets"
        :chapterOffsets="chapterOffsets"
        :chapterNodeOffsets="chapterNodeOffsets"
        :activeChapter="activeChapter"
        :activeChapterNode="activeChapterNode"
        :activeSubChapter="activeSubChapter"
        :lastScroll="lastScroll"
        @setActive="toggleSidePanel(true)"
        @debug="v=>debug=v"
      />

      <!-- edit view: narrative builder -->

      <narrative-builder
        v-if="isEditor"
        v-show="sidePanelActive"
        :view="view"
        :views="views"
        :viewLabels="editViews"
        :narrative="narrative"
        @store="store"
        @changeView="changeView"
        @unsaved="saved=false"
        @setSidePanelExpanded="e=>toggleSidePanelExpanded(e)"
        @debug="v=>debug=v"
        @addChapter="addChapter"
      />

    </div>

    <!-- media lib -->

    <my-sources-select
      v-if="showMedia"
      :show="showMedia"
      :options="addSources"
      @close="showMedia=false"
    />

  </div>
</template>

<script>
import { API, clone } from '../tic'

import { ref, computed, watch } from '@vue/composition-api'
import HiHeader from '../components/HiHeader';
import NarrativeIndex from '../narratives/NarrativeIndex';
import NarrativeCover from '../narratives/NarrativeCover';
import NarrativeRead from '../narratives/NarrativeRead';
import NarrativeRelated from '../narratives/NarrativeRelated';
import NarrativeBuilder from '../narratives/NarrativeBuilder';
import NarrativeBuilderIndex from '../narratives/NarrativeBuilderIndex';

import MySourcesSelect from '../eabuilder/MySourcesSelect';

//->for development: use test narrative json
//import narrative from '../../../narratives/ww1_narrative.json'
import sample from '../narratives/sampleNarrative.json'

const emptyParagraph = {
  type:'paragraph',
  content:[]
}

export default {
  name: "Narrative",

  components: {
    HiHeader,
    NarrativeIndex,
    NarrativeCover,
    NarrativeRead,
    NarrativeRelated,
    NarrativeBuilder,
    NarrativeBuilderIndex,
    MySourcesSelect
  },

  props: {
    view: {
      type: String,
      default: null
    },

    slug: {
      type: String,
      default: null
    },

    edit: {
      type: String,
      default: null
    }

  },

  setup (props, context) {
    //??? composition-api, use at later stage?
  },

  data () {
    return {
      debug:false,
      narrative:{},
      emptyNarrative:{
        //"uuid": "uuid-test",
        "type": "narrative",
        "title": "",
        "teaser": "",
        "acknowledgement": "",
        "meta": {
          "license": {}
        },
        "summary": [],
        "content": [emptyParagraph]
      },
      emptyParagraph:emptyParagraph,
      emptyChapter:{
        "type": "chapter",
        "title": "",
        "teaser": "",
        "summary": [emptyParagraph],
        "content": [emptyParagraph]
      },
      views:['start','summary','read'],
      menu:['start','summary','read'],
      viewMenu:'start',
      readViews:['Start','Summary','Read'],
      editViews:['Info','Outline','Write'],
      pageHeight:'calc(100vh - 100px)',
      bodyHeight:'100vh',
      summaryOffsets:[],
      chapterOffsets:[],
      chapterProgress:[],
      chapterNodeOffsets:[],
      activeChapter:0,
      activeSubChapter:-1,
      activeChapterNode:0,
      lastScroll:0,
      scrolled:0,
      hasRelated:false,
      hideIndex:false,
      hideBuilderIndex:false,
      hideIndexOverflow:true,
      hideDropcursor:false,
      showMedia:false,
      sidePanelActive:this.isEditor,
      sidePanelExpanded:false,
      sidePanelHidden:false,
      sidePanelTransition:false,
      addSources:{},
      saving:false,
      saved:true,
      apiError:false
    }
  },

  computed: {

    showMenu () {
      return !this.scrolled;// && !this.edit;
    },

    viewLevel: {
      get: function () {
        return this.views.findIndex(v => v==this.view);
      },
      set: function (v) {
        this.changeView(this.views[v])
      }
    },

    viewDisable () {
      return this.edit && (!this.narrative.title || !this.narrative.icon); //require title and cover for new narratives
    },

    viewModeLabel () {
      const labels = this.isEditor? this.editViews:this.readViews;
      return labels[this.viewLevel];
    },

    info () {
      //return narrative without content
      const { content, ...info} = this.narrative; //strip content from narrative
      return info;
    },

    chapters () {
      //return narrative content array
      return this.narrative.content || [];
    },

    relatedByChapter () {
      //collect all related items, indexed by chapter
      let related = [];
      this.chapters.filter(c => c.type=='chapter').forEach((chapter,index) => {

        //items directly related to chapter
        related[index] = chapter.related? [...chapter.related]:[];

        let node = 0;

        //items related to content nodes
        chapter.content.forEach((c,i) => {

          if (c.related) {
            //insert node info
            const relatedItems = c.related.map(r => { return { ...r, nodeIndex:node, nodeType:c.type, sub:c.type=='subchapter'? i:undefined, subNode:-1 } })
            //append to chapter collection
            related[index] = [...related[index], ...relatedItems];
          }

          //subchapter? -> look in content one level deeper as well
          if (c.type=='subchapter' && c.content) c.content.forEach((s,n) => {

            node++;

            if (s.related) {
              //insert node info
              const relatedItems = s.related.map(r => { return { ...r, nodeIndex:node, nodeType:s.type, sub:i, subNode:n } })
              //append to chapter collection
              related[index] = [...related[index], ...relatedItems];
            }
          });

          node++
        });
      });

      return related;
    },

    showSidePanel() {
      //side panel only in edit and read view (for now)
      return this.isEditor || this.view=='read';
    },

    sidePanelHeight () {
      return this.view=='summary'? this.pageHeight:this.view=='start'? 'calc(100vh - 100px)':this.bodyHeight;
    },

    sidePanelLabel () {
      return this.isEditor? 'Narrative Builder':'Learn more…'
    },

    isEditor () {
      return this.edit=='edit' && this.user!=null; //TODO && user has editing rights
    },

    editState () {
      return this.saved? 'Saved':this.saving? 'Saving':this.narrative.uuid? 'Unsaved changes':'New Narrative';
    },

    user () {
      return this.$store.getters['user/uuid'];
    }

  },

  watch: {
    view: {
      handler (to, from) {
        console.log('view change:',from+'->'+to)
        this.viewMenu = this.view;
        if (from) this.hideBuilderIndex = true;
        this.hideIndexOverflow = to=='start' || from=='start';

        if (from=='start' && to=='summary')
        {
          this.sidePanelTransition = false;
          this.$nextTick(e=> this.sidePanelTransition=true);
        }
      },
      immediate:true
    },

    viewMenu (to) {
      if (to!=this.view) this.changeView(to)
    },

    isEditor: {
      handler () {
        //expand builder after (delayed) login
        this.sidePanelActive = this.isEditor;
      },
      immediate:true
    }
  },

  mounted () {
    console.log('📗 Narrative mounted','view='+this.view)

    //redirect invalid views
    if (!this.views.includes(this.view)) this.changeView('start');

    //this.viewMenu = this.view;

    //load empty or sample narrative?
    let n = false;
    if (this.slug=='new')
    {
      this.saved = false;
      n = this.emptyNarrative; //will always be in edit view (redirected in router)
    }
    else if (this.slug=='sample')
    {
      n = sample;
    }

    if (!n) this.load()
    else this.narrative = n;
  },

  methods: {

    /* Navigation */

    setViews () {
      //toggle available views: remove summary view from menu when no summaries are set
      const hasSummary = this.chapters.filter(c => c.type=='chapter' && c.summary[0] && c.summary[0].content?.length).length;
      if (!hasSummary)
      {
        this.menu.splice(1,1)
        this.readViews.splice(1,1)
      }

    },

    changeView(v) {
      if (v!=this.view)
      {
        //reset page scroll (in reading mode to 1, hides main menu) //->done in Router now
        //const offset = this.edit? 0:1;
        //window.scrollTo(0,offset);

        this.$router.push('/narratives/'+this.slug+'/'+v+(this.isEditor? '/edit':''))
        if (v!='summary') this.pageHeight = 'calc(100vh - 99px)';
        if (v=='start') this.activeChapter = 0;

        //collapse sidepanel
        this.sidePanelExpanded = false;

        //always show sidepanel in edit
        this.sidePanelHidden = false;

      }
    },

    showReader() {
      //open new window with reader view (for development)
      window.open('/narratives/'+this.slug+'/'+this.view);
    },

    overview () {
      if (this.view!='start') this.changeView(this.view=='summary' || this.menu.length==2? 'start':'summary');
    },

    viewSummary (index) {
      //switch to summary view & scroll to index
      this.activeChapter = index;
      this.changeView('summary')
    },

    enteredRead () {
      this.hideIndex = false;
    },

    scrollSelectChapter (index) {
      this.hideIndex = false;
      this.activeChapter = index;
    },

    scrollSelectNode (index,direction) {
      this.activeChapterNode = index;
      this.lastScroll = direction;
    },

    readChapter (index,sub) {
      console.log('set active chapter to ',index,sub)
      this.hideIndex = true;
      this.activeSubChapter = sub==undefined? -1:sub;

      if (this.activeChapter==index && this.$refs.read)
      {
        //force scroll to top of current chapter
        this.$nextTick(e => this.$refs.read.scrollToChapter());
      }

      this.activeChapter = index;
    },

    toggleSidePanel (a) {
      if (this.edit && this.sidePanelActive) return; //do not allow builder panel collapse

      this.toggleSidePanelTransition()
      this.sidePanelActive = a===true? true:!this.sidePanelActive;
      if (a!==true) this.sidePanelExpanded = false;
    },

    toggleSidePanelExpanded (state) {
      this.toggleSidePanelTransition()
      this.sidePanelExpanded = typeof(state)=='boolean'? state:!this.sidePanelExpanded;
    },

    toggleSidePanelTransition () {
      //temporary enable transitions
      this.sidePanelTransition = true;
      setTimeout(e => this.sidePanelTransition = false,100);
    },

    toggleRelated () {
      //check if there are any related props in the narrative, hide the side panel if none
      this.hasRelated = this.chapters.some(c => { //??? could this be done more elegantly?
        //chapter
        if (c.related?.length) return true;
        return c.content.some(c => {
          //sub-chapter
          if (c.related?.length) return true
          return c.content.some(c => {
            //node
            return c.related?.length;
          })
        })
      });
    },

    setActiveChapterNode (index,scroll) {
      console.log('update activeChapterNode',index,scroll);

      //do not update
      if (scroll>0 && this.activeChapterNode>index) return;

      this.activeChapterNode = index;

    },


    /* DOM */

    onScroll (scroll) {
      this.scrolled = scroll>0;
    },

    onReadResize (height) {
      //sync sidepanel to current page height
      console.log('N:onReadResize',height)
      this.bodyHeight = height<window.innerHeight? '100vh':height +'px';
    },

    setPageHeight (h) {
      const height = Math.max(parseInt(h),window.innerHeight+1)
      console.log('set pagHeight to ',height,window.innerHeight);
      this.pageHeight = height + 'px';
    },


    /* API */

    load () {
      console.log('load narrarive',this.slug);
      API.get('/narrative/'+this.slug)
        .then(rsp => {
          const narrative = rsp.data.data;

          console.log('hello loaded!',narrative)

          //compatibility with missing data during development
          if (!narrative.meta.license) narrative.meta.license = { uuid:null };

          this.saved = true;
          this.narrative = narrative;
          this.setViews();
          this.toggleRelated();

        })
        .catch(err => {
          // handle error
          console.log(err.response.data);
          this.$q.dialog({
            title: '<i class="far fa-exclamation-triangle"></i>&nbsp;Narrative not found',
            message: '',
            html: true,
            persistent: true
          }).onOk(() => {
            this.$router.push('/narratives');
          })
        });
    },

    store () {
      if (this.slug=='sample') return this.saved = true; //skip sample data

      console.log('📗 Narrative: POSTING to API...');

      this.saving = true;
      this.saved = false;
      this.$nextTick(e => API.post('/narrative', {
          user: this.$store.getters['user/uuid'],
          narrative: this.narrative
        })
        .then(rsp => {
          this.apiError = false;
          this.saved = true;

          //store uuid for new narratives, and update url with slug
          if (!this.narrative.uuid)
          {
            console.log('📗 Narrative: created new narrative, slug=',rsp.data.slug);
            this.narrative.uuid = rsp.data.uuid;
            this.$router.replace('/narratives/'+rsp.data.slug+'/'+this.view+'/edit');
          }

          console.log('posted!',rsp);
        })
        .catch(err => {
          console.log('error!',err.response.data);
          this.apiError = err.response.status+' '+err.response.statusText;
        })
        .then(() => {
          this.saving = false;
        })
      );
    },

    updateInfo (info,save) {
      console.log('📗 Narrative: info update');

      //merge info data into narrative JSON
      this.saved = false;
      this.narrative = Object.assign({}, this.narrative, info);

      if (save) this.store();
    },

    addChapter () {
      console.log('📗 Narrative: add a chapter');

      this.saved = false;

      //remove preceding paragraph when empty and adding a first chapter
      const
        first = this.chapters.filter(c => c.type=='chapter').length==0,
        hasEmptyParagraph = this.chapters[0] && this.chapters[0].type=='paragraph' && !this.chapters[0].content.length;

      if (first && hasEmptyParagraph)
      {
        console.log('remove empty p')
        this.narrative.content.shift();
      }

      this.narrative.content.push(clone(this.emptyChapter));

      //TODO auto save?
      //..
    },

    removeChapter(index) {

      //remove a chapter, confirm first
      this.$q.dialog({
        title: '<i class="fa fa-trash"></i>&nbsp;Confirm Remove',
        message: '<p>Removing chapter from the Narrative.</p><p>All chapter content will be deleted, are you sure?</p>',
        html: true,
        cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
        ok: { label: 'Yes', color: 'primary', noCaps: true, }
      }).onOk(() => {

        //remove chapter @ index
        const offset = this.chapters.filter(c => c.type!='chapter').length;
        this.narrative.content.splice(offset + index,1);

      })
    },

    updateChapters (chapters,from) {

      console.log('📗 Narrative: chapters update from ',from,chapters)

      //use rules below to define dirty props?
      //IF from=='editor' THEN summaries are unchanged
      //IF from=='index' THEN all content except headings are unchanged
      //.. more?

      //make sure there's at least an empty paragraph, otherwise nothing can be written in editor
      if (!chapters.length) chapters.push(this.emptyParagraph);

      //set new narrative content
      this.saved = false;
      this.$set(this.narrative,'content',chapters);

      //TODO auto save?
      //..
    },

    removeRelated (chapter,node,index,subchapter,subNode) {

      console.log('remove related @index=',index,' from chapter=',chapter,'/node=',node,'/subchapter=',subchapter,'/subnode=',subNode)

      this.saved = false;

      if (node==-1)
      {
        //remove item from chapter (top level)
        this.narrative.content[chapter].related.splice(index,1)
      }
      else if (subchapter==undefined)
      {
        //remove item from chapter node
        this.narrative.content[chapter].content[node].related.splice(index,1);
      }
      else
      {
        if (subNode==-1)
        {
          //remove item from subchapter (top level)
          this.narrative.content[chapter].content[subchapter].related.splice(index,1);
        }
        else
        {
          //remove item from subchapter node
          this.narrative.content[chapter].content[subchapter].content[subNode].related.splice(index,1);
        }
      }

      //TODO store narrative to API
      //..
    }
  }
}
</script>

<style lang="stylus">
@import '~quasar-variables'

/* non-scoped style!! */

:root
  --view-slide-speed:.7s;
  --menu-slide-speed:.25s;
  --related-slide-speed:.35s;
  --fast-ease:cubic-bezier(.08,.26,.06,1);

.debug
  position:fixed;
  left:0;
  top:65vh;
  height:35vh;
  width:100vw;
  overflow:scroll;
  font-size:12px;
  line-height:1
  padding:3px 10px;
  background-color:rgba(0,0,0,.8);
  color:magenta;
  z-index:10000;

.slide-in-enter-active,
.slide-in-leave-active,
.slide-off-enter-active,
.slide-off-leave-active
  transition: transform var(--view-slide-speed) ease 0s;

.slide-off-enter,
.slide-off-leave-to
  transform: translateX(calc(-100% - 25px));

.slide-in-enter,
.slide-in-leave-to
  transform: translateX(calc(100% + 25px));


/* main layout */

.is-narrative-view
  background-color: $plasticgroen;
  transition:none;
  overflow-x:hidden;

.narrative header
  position:fixed;
  padding-bottom:0;

.narrative .header
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100px;
  background-color: alpha($plasticgroen,.95);
  backdrop-filter:blur(2px);
  z-index:1000;

.narrative-title
  position:absolute;
  margin:0;
  left:175px;
  top:15px;
  font-size:30px;
  line-height:1.5;
  color:$white;
  font-weight:500;

.narrative-view-menu
  position:absolute !important;
  width:260px;
  right:calc(100vw - 910px - 150px);
  bottom:0;
  font-size:15px;
  font-weight:600;
  color:white;

.narrative-view-menu .q-tabs__content
  overflow:visible;

.narrative-view-menu .q-tab__indicator
  margin-bottom:-6px;
  height:6px !important;

.narrative .page
  position:relative;
  margin:0 50px 0 150px;
  padding-top:100px;
  max-width:910px;
  background-color: $white;
  transition:padding $menu-transition;


.narrative .page-body
  min-height:calc(100vh - 99px);

.narrative .page-body-margin
  position:absolute;
  inset:0;
  height:100px;
  transition:top $menu-transition;

.narrative .q-slider
  font-size:14px;
  font-weight:bold;

.narrative .index-wrapper
  left:inherit;
  top:100px;
  height:calc(100% - 100px) !important;



/* full main-menu visible */

.narrative
  transition:padding $menu-transition;
  .header
    transition:top $menu-transition;

.narrative.show-menu
  padding-top:100px;

.narrative.show-menu
  .header
  .side-panel
    top:100px;

.narrative.show-menu
  .side-panel-header
    top:200px;

.narrative.show-menu .index.read
  margin-top:200px

.narrative.show-menu .page-body
  min-height:calc(100vh - 200px);

.narrative.show-menu .index-wrapper
  height:calc(100% - 200px) !important;

.narrative.show-menu
  .cover-body
    min-height: calc(100vh - 200px);



/* sidepanel */

.narrative .side-panel
  position:absolute;
  right:calc(100vw - 910px - 200px);
  top:0;
  min-width:50px;
  width:50px;
  overflow:hidden;
  background-color:$plasticgroen;
  height:calc(100vh - 100px);
  z-index:950;
  color:$plasticgroen;
  transition:top $menu-transition;

.narrative
  .side-panel.transition,
  .side-panel.transition .side-panel-header
    transition:
      top $menu-transition,
      width var(--menu-slide-speed) ease 0s,
      min-width var(--menu-slide-speed) ease 0s,
      right var(--menu-slide-speed) ease 0s,
      background-color 1s ease 0s;

.narrative .side-panel.active
  right:0;
  width:calc(100vw - 910px - 150px);

.narrative .side-panel.expanded,
.narrative .side-panel.expanded .side-panel-header
  min-width:60vw !important;
  background-color:$appelgroen;
  color:$plasticgroen;

.narrative .side-panel.expanded .side-panel-header
  background-color:alpha($appelgroen,.95);

.narrative .side-panel-header
  position:fixed;
  top:100px;
  height:50px;
  width:50px;
  display:flex;
  flex-wrap:nowrap;
  align-items:center;
  color:$appelgroen;
  background-color:alpha($plasticgroen,.95);
  backdrop-filter:blur(2px);
  overflow:hidden;
  z-index:2;
  transition:top $menu-transition;

.narrative .builder .side-panel-header
  background-color:$appelgroen;
  color:$plasticgroen;

.narrative .side-panel.fixed
  position:fixed;
  top:100px;

.narrative.show-menu .side-panel.fixed
  top:200px;

.narrative .side-panel.active .side-panel-header
  width:calc(100vw - 910px - 150px);

.narrative .side-panel-header
  .toggle,
  .expand
    color:$appelgroen;
    background-color:transparent;

.narrative .side-panel.expanded,
.narrative .side-panel.builder
  .side-panel-header .toggle
    color:$plasticgroen;

.narrative .side-panel.builder:not(.active)
  .toggle
    color:$white;
    background-color:$primary;
    &:hover
      color:$primary;
      background-color:$white;

.narrative .side-panel .expand
  margin:0 -5px 0 11px;
  z-index:1;

.narrative .side-panel-header
/* .narrative .side-panel.active .side-panel-header */
  .toggle:hover
    color:$white;

.narrative .side-panel-header h2
  margin:0;
  font-size: 1.25rem;

/*
.narrative .side-panel.active.builder,
.narrative .side-panel.active.builder .side-panel-header
  min-width: 300px;
 */

.narrative-title
  .fa-bug,
  .fa-eye
    color:alpha($black,.18);


/* square navigation block */

.nav-block
  position:relative;
  flex-shrink:0;
  width:100px;
  height:100px;
  background-color:$light-gray;

.nav-block.small
  width:50px;
  height:50px;

.nav-block.head
  position:absolute;
  left:50px;

.nav-block >
  .fa,
  .far,
  .fas
    margin:13px 14px;
    font-size:1.5rem;

.nav-block.small
  .fa,
  .far,
  .fas
    margin:0;
    font-size:1.2rem;

.nav-block label
  position:absolute;
  left:0;
  bottom:0;
  margin:10px;
  font-size:0.6875rem;
  text-transform:uppercase;
  font-weight:bold;
  cursor:inherit;

.nav-block.action
  background-color:$primary;
  color:$white;
  cursor:pointer;

.nav-block.action.over
  background-color:alpha($white,.6)
  color:$primary;

.nav-block.small.action,
.nav-block.small.button
  cursor:pointer;
  display:flex;
  justify-content: center;
  align-items: center;


.nav-block.action.disabled,
.nav-block.action:not(.disabled):hover
  background-color:$white
  color:$primary;

.nav-block.head svg
  margin:8px 0 0 10px;
  width:25px;

.nav-block.head svg path
  fill:$plasticgroen;

.nav-block.head .q-img
  position:absolute;
  right:0;
  bottom:0;
  transform-origin:100% 100%;
  transition:transform var(--view-slide-speed) ease 0s;
  /* border-bottom:1px solid $plasticgroen; */

.nav-block.head.start .q-img
  transform:translateX(100%);


/* misc */

.narrative .dropcursor
  background-color:$primary !important;

.narrative .no-drop .dropcursor
  display:none !important;

.is-narrative-view .q-card__section--vert:empty
  padding:0; /* shrink cards by hiding empty tags section */



@media (max-width:1310px)
  .narrative .side-panel .close
    display:none;

@media (max-width:1110px)
  .narrative .side-panel
    right:0;

  .narrative-view-menu
    right:50px

  .narrative .side-panel.active .side-panel-header
    width:50px;

@media (max-width:1010px)
  .narrative .page
    margin-left:100px;

  .nav-block.head
    left:0;

  .main-menu
    .home
      left:0;
    .menu-large
      left:100px;

  .narrative-title
    left:125px;

</style>