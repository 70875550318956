<template>
  <section id="hiFilter">
    <div class="row q-mx-auto justify-between items-center">
      <div class="col-shrink q-py-md q-pr-md">
        <h4>Filter on</h4>
      </div>
      <div class="col-shrink q-py-md q-pr-md">
        <q-btn
          :color="getColor"
          no-caps
          flat
          :class="{ active: activeFilter === 'All' }"
          @click="setFilter('All')"
          label="All"
        />
      </div>
      <div class="col q-py-md items-start q-gutter-md">
        <q-btn
          :color="getColor"
          :key="i"
          v-for="(t, i) in types"
          no-caps
          flat
          :class="{ active: activeFilter === t }"
          @click="setFilter(t)"
          :label="t"
        />
      </div>
    </div>
  </section>
</template>
<script>
import getColor from '../mixins/getColor.mixin.js';

export default {
  name: 'HiFilter',
  mixins: [getColor],
  props: {
    types: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    reset_animation() {
      var el = document.getElementsByClassName('animated');
      for (let x of el) {
        x.style.animation = 'none';
        x.offsetHeight; /* trigger reflow */
        x.style.animation = null;
      }
    },

    setFilter(a) {
      this.activeFilter = a;
      this.$emit('setFilter', a);
      this.reset_animation();
    }
  },

  data() {
    return {
      activeFilter: 'All'
    };
  }
};
</script>
