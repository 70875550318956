<template>
  <div id="narrative-body" class="read" v-scroll="onScroll" ref="body">

    <!--read view: Not used at the moment, we use the editor here as well -->
<!--
    <template v-if="!edit">
      <div
        v-for="(chapter,i) in chapters"
        class="chapter"
        ref="chapters"
      >
        <h2>{{chapter.title}}</h2>
        <!~~ todo support non-subchapter content ~~>
        <div
          v-for="subchapter in chapter.content"
          class="sub-chapter relative-position"
        >
          <div class="nav-block small sub bg-plasticgroen"></div>
          <h3>{{subchapter.title}}</h3>
          <narrative-content
            :content="subchapter.content"
          />
        </div>

      </div>
    </template>
 -->

    <!--  TODO: if we keep on using the editor for the read view,
          this component can be removed and NarrativeEdit can be used directly in Narrative.vue -->

    <!-- editor -->

    <narrative-edit
      ref="editor"
      :editable="edit"
      :debug="debug"
      :chapters="chapters"
      @edit="e=>this.$emit('edit',e)"
      @offsets="setChapterOffsets"
      @nodeOffsets="e=>setChapterNodeOffsets(e)"
      @resize="onResize"
      @toggleDrop="e=>$emit('toggleDrop',e)"
      @showBuilder="e=>$emit('showBuilder')"
    />

    <div class="hidden" style="position:fixed; left:0; width:100%; border-bottom:1px dotted magenta;" :style="{top:halfPage + 100 +'px'}"/>

  </div>
</template>

<script>
import { UseEase } from '../tic';
import { ref, computed, watch } from '@vue/composition-api'
import NarrativeEdit from './NarrativeEdit';

import { dom } from 'quasar'
const { offset } = dom

export default {
  name: "NarrativeRead",

  props: {

    edit:{
      type:Boolean,
      default:false
    },

    debug:{
      type:Boolean,
      default:false
    },

    chapters:{
      type:Array,
      default:() => []
    },

    activeChapter:{
      type:Number,
      default:0
    },

    activeSubChapter:{
      type:Number,
      default:0
    }

  },

  setup (props, context) {
  },

  components: {
    NarrativeEdit
  },

  data () {
    return {
      viewHeight:0,
      chapterOffsets:[],
      chapterNodeOffsets:[],
      chapterProgress:[],
      scrollPos:0,
      scrollChapter:0,
      scrollTo:false,
      userScroll:false,
    }
  },

  computed: {
    halfPage() {
      return (this.viewHeight + 100) / 3;
    }
  },

  watch: {
    activeChapter() {
      if (!this.userScroll) this.scrollToChapter()
    },

    scrollChapter() {
       this.$emit('scrollSelectChapter',this.scrollChapter);
    }
  },

  mounted () {
    console.log('📗 Narrative-read mounted')

    if (this.activeChapter>0) this.scrollToChapter()

    //cache chapter offsets
    this.onResize();

  },

  methods: {

    setChapterOffsets (offsets) {
      //set or cache chapter vertical offsets
      if (!offsets)
      {
        offsets = this.$refs.chapters;
        if (!offsets) return; //DOM not ready
      }
      offsets.forEach((c,i) => this.$set(this.chapterOffsets,i,c.offsetTop || c));

      console.log('emit chapterOffsets')
      this.$emit('chapterOffsets',this.chapterOffsets)
      this.chapterProgress = this.chapterOffsets.map(o=>0);
    },

    setChapterNodeOffsets (offsets) {

      //store for scroll detect
      this.chapterNodeOffsets = offsets;

      //pass to parent for use in NarrativeRelated
      this.$emit('chapterNodeOffsets',offsets);
    },

    onResize () {
      console.log('NRead: resize')
      this.viewHeight = window.innerHeight;

      //sync sidebar
      this.$emit('resize',this.$refs.body.offsetHeight)


      //if (!this.edit) this.setChapterOffsets();
    },

    onScroll (position) {

      //determine progress in chapters
      this.onChapterProgress(position);

      //skip if programmatic
      if (this.scrollTo) return;

      const scrollDirection = position - this.scrollPos;

      this.userScroll = true;
      this.scrollPos = position;

      if (this.scrolling) clearTimeout(this.scrolling);
      this.scrolling = setTimeout(e => this.userScroll = false,300);

      //switch to next chapter halfway viewport
      let index = this.chapterOffsets.findIndex(e => e>=position + this.halfPage)
      if (index<0) index = position<25? 1:this.chapters.length;

      this.scrollChapter = index - 1;

      let nodeIndex = -1;
      if (this.chapterNodeOffsets.length && position>25)
      {
        let offset = this.chapterOffsets[this.scrollChapter] + 25;
        nodeIndex = this.chapterNodeOffsets[this.scrollChapter].findIndex(e => e>=position - offset + this.halfPage);
        if (nodeIndex<0) nodeIndex = this.chapterNodeOffsets[this.scrollChapter].length;
      }
      this.$emit('scrollSelectNode',nodeIndex,scrollDirection)
    },

    onChapterProgress (position) {
      position += this.halfPage;
      const
        l = this.chapterOffsets.length,
        h = this.$refs.body.offsetHeight;

      this.chapterOffsets.forEach((offset,i) => {
        let
          next = i<l-1? this.chapterOffsets[i+1]:h,
          p = position<offset? 0:position>next? 1:(position-offset)/(next-offset);

        this.$set(this.chapterProgress,i,p);
      })

      this.$emit('chapterProgress',this.chapterProgress)
    },

    scrollToChapter() {
      if (!this.chapterOffsets.length)
      {
        //wait for offsets to be set
        return setTimeout(e => this.scrollToChapter(),100);
      }

      //temporary block scroll events
      this.scrollTo = true;

      //get top of active chapter and scroll there with ease
      let
        offset = 0,//this.edit? 0:25,
        top = this.chapterOffsets[this.activeChapter] - offset;

      //do not scroll to zero, keeps main menu hidden
      if (top==0) top = 1;

      if (this.activeSubChapter>-1)
      {
        //get subchapter offset directly from DOM
//         if (this.edit)
//         {
          //find h3 at index following current h2
          const heading = this.$refs.editor.$el.getElementsByTagName('h2').item(this.activeChapter);
          let
            index = 0,
            nextSibling = heading.nextElementSibling;

          while (nextSibling) {
            if (nextSibling.tagName=='H3')
            {
              if (index==this.activeSubChapter)
              {
                top = nextSibling.offsetTop + 25;
                break;
              }
              index++;
            }
            nextSibling = nextSibling.nextElementSibling;
          }
//         }
//         else
//         {
//           const sub = this.$refs.chapters[this.activeChapter].getElementsByClassName('sub-chapter').item(this.activeSubChapter);
//           if (sub) top = sub.offsetTop;
//         }
      }

      setTimeout(e => {
        new UseEase(
          v => window.scrollTo(0,v.y),
          v => {
            setTimeout(e => this.scrollTo = false,100); //delay so selected chapter remains active, even when next chapter is halfway screen
            this.$emit('scrollEnd')
          },
          .2,
          20
        )
        .start({ y:[window.scrollY,top] })
      },250)

    }
  }
}
</script>

<style lang="stylus">

/* non-scoped style: also used for editor */

#narrative-body
{
  font-size:19px;
  line-height:29px;
}

#narrative-body h2
{
  font-size:1.625rem;
  font-weight:500;
  line-height:calc(1.5 * 1.625rem);
  margin-bottom:calc(75px - 1.5 * 1.625rem);

}
#narrative-body h3
{
  font-size:1.25rem;
  font-weight:400;
  line-height:calc(1.5 * 1.25rem);
  padding-top:7px;
  margin-bottom:calc(43px - calc(1.5 * 1.25rem));
}
#narrative-body h4
{
  font-size:1.25rem;
  font-weight:400;
}
</style>

<style lang="stylus" scoped>
@import '~quasar-variables'

.read
{
/*
  position:absolute;
  top:0;
 */
  padding:25px 50px 100px 50px;
  /* background-color:rgba(250,0,0,.2); */
}


.chapter
{
  margin-bottom:100px;
  /* min-height:750px; */
  max-width:770px;

  /* background-color:rgba(250,0,0,.1); */
}

.sub-chapter
{
  padding:0 25px;

  min-height:250px;
}

.nav-block.sub
{
  position:absolute;
  left:-50px;

}
</style>