<template>
  <q-btn
    flat
    dense
    size="13px"
    class="q-px-sm"
    icon="far fa-unlock"
    label="Logout"
    :color="dark? 'white':'dark-gray'"
    @click="confirm()"
  />
</template>
<script>
export default {
  name: "LogoutButton",
  props: {
   dark:{
      type:Boolean,
      default:false
    },
  },
  methods: {
    confirm() {
      this.$q
        .dialog({
          title: "Confirm",
          message: "Really LOGOUT from Historiana?",
          cancel: true,
          persistent: true
        })
        .onOk(() => {
          this.$store.dispatch("user/logout");
          window.localStorage.setItem("autologin", false);
          window.localStorage.setItem("session", "");
          this.$router.push('/');
          //document.location = "/";
        });
    }
  }
};
</script>
