<template>
  <div>
    <!-- the content for logged in -->
    <div
      v-if="$store.getters['user/isAuthenticated']"
      id="my-historiana-content"
    >
      <!-- subnav component -->
      <hi-subnav :childs="childs" />

      <q-dialog :value="$q.platform.is.mobile" title="NOTICE:" type="error">
        <q-card class="q-px-md q-gutter-sm">
          <h5>WARNING</h5>
          <p>
            Historiana is optimised for laptops and PC’s, not yet for tablets
            and smartphones.
          </p>
          <q-card-actions align="center">
            <q-btn v-close-popup color="primary" label="OK" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <RouterView />
    </div>
    <section v-else>
      <!-- the content shown when NOT logged in -->
      <div style="display: flex; align-items: center; flex-direction: column">
        <h3 class="q-mb-lg">Not logged in</h3>
        <p>
          You need an Historiana account before you can access the My Historiana
          functionality.
        </p>
        <p>
          Either <login-button inline /> or <register-button inline /> a new
          account if you don't have one yet.
        </p>
      </div>
    </section>
  </div>
</template>
<script>
import LoginButton from "@/components/LoginButton";
import RegisterButton from "@/components/RegisterButton";

let childs = [
  {
    url: "/my/dashboard",
    label: "Dashboard",
    perms: ["admin", "partner"],
  },
  {
    url: "/my/narratives",
    label: "Narratives",
    perms: ["all"],
  },
  {
    url: "/my/activities",
    label: "e-Learning Activities",
    perms: ["all"],
  },
  {
    url: "/my/collections",
    label: "Collections",
    perms: ["admin", "partner"],
  },
  {
    url: "/my/viewpoints",
    label: "Viewpoints",
    perms: ["admin"],
  },
  {
    url: "/my/sources",
    label: "Sources",
    perms: ["all"],
  },
  {
    url: "/my/tags",
    label: "Tags",
    perms: ["all"],
  },
  {
    url: "/my/shares",
    label: "Shares",
    perms: ["all"],
  },
  // {
  //   url: "/my/profile",
  //   label: "Profile",
  //   perms: ["all"],
  // },
];

export default {
  name: "MyHistoriana",

  components: {
    LoginButton,
    RegisterButton,
  },

  computed: {
    childs() {
      if (this.$store.getters["user/isPartner"]) {
        return childs.filter((e) => {
          return e.perms.includes("partner") || e.perms.includes("all");
        });
      }

      if (this.$store.getters["user/isAdmin"]) {
        return childs.filter((e) => {
          return e.perms.includes("admin") || e.perms.includes("all");
        });
      }

      return childs.filter((e) => e.perms.includes("all"));
    },
  },
};
</script>
