<!-- update one or more sources via a Quasar Dialog -->
<template>
  <section>
    <q-dialog
      :value="show"
      @hide="$emit('close')"
      persistent
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card style="width:100%;">
        <q-bar class="bg-white text-white">
          <q-space />
          <q-btn
            flat
            round
            class="float-right text-hardroze"
            icon="far fa-times"
            v-close-popup
          />
        </q-bar>
        <q-card-section align="center">
          <q-uploader
            ref="uploader"
            url="/api/upload"
            label="Batch upload"
            multiple
            batch
            :headers="getHeaders"
            auto-upload
            @finish="uploadDone"
          />
        </q-card-section>

        <q-card-actions align="center">
          <!--q-btn no-caps label="Cancel" @click="$emit('close')" /-->
        </q-card-actions>

        <!-- </q-scroll-area> -->
      </q-card>
    </q-dialog>
  </section>
</template>

<script>
import { collection } from '../datamodel'
import _ from 'lodash'
import { API } from '../tic'
import HiLanguage from '../components/hiLanguage'

export default {
  name: 'UploadSource',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getHeaders(e) {
      return [
        // these are needed in the API to identify the user
        { name: 'X-Session', value: this.$store.getters['user/session'] },
        { name: 'X-Process', value: 'createSource' },
      ]
    },

    uploadDone() {
      this.$emit('close')
      this.$emit('reload')
      this.$q.notify({
        type: 'positive',
        position: 'top',
        message: 'Sources uploaded',
      })
    },

    uploadError(e, x, y) {
      // XXX find out how we can access our JSON errorCode
      // to report exact error
      console.log('ERROR ON UPLOAD', e, typeof e)
      console.log('x: ', x)
      console.log('y: ', y)
      this.$notify.error({
        title: 'Error uploading file',
        message: '',
      })
    },
  },
}
</script>
