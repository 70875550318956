var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$q.loading.isActive)?_c('div',{staticClass:"teaching-learning"},[_c('hi-search',{attrs:{"placeholder":"Find ideas and ready-to-use resources for your classroom practice"},model:{value:(_vm.searchFor),callback:function ($$v) {_vm.searchFor=$$v},expression:"searchFor"}}),_c('hi-filter',{attrs:{"types":_vm.types},on:{"setFilter":_vm.setFilter}}),_c('section',{attrs:{"id":"card-holder"}},[_c('div',{staticClass:"row q-mx-auto justify-start items-start"},[_c('div',{staticClass:"row q-gutter-md"},_vm._l((_vm.filteredSources(_vm.activeFilter)),function(card,i){return _c('div',{key:i,attrs:{"id":card.activity.uuid}},[(
              card.owner.uuid === _vm.$store.getters['user/uuid'] &&
                _vm.$store.getters['user/isAuthenticated']
            )?_c('Card',{staticClass:"animated slideInUp fast",attrs:{"index":i,"item":card,"title":card.activity.title,"image-url":card.icon !== '/ua/null' ? card.icon : card.image_url,"uuid":card.activity.uuid,"tags":card.tags,"has_tags":card.has_tags,"btn-share":false,"btn-edit-url":'/ela/' + card.activity.uuid,"btn-student-view":card.typecode === 'ela'
                ? ("/ea/view/" + (card.activity.uuid) + "/")
                : card.url,"btn-delete":true,"btn-publish":true,"goto":card.typecode === 'ela'
                ? ("/ea/view/" + (card.activity.uuid) + "/")
                : card.url,"type":card.type,"typecode":card.typecode,"url":"","csstype":"ela","is-owner":true,"show-meta-button":true,"meta":_vm.meta(card)},on:{"goShowSharing":function($event){return _vm.showShareModal(card.activity.uuid)},"deleteCard":_vm.deleteCard,"publishCard":_vm.publishCard,"unpublishCard":_vm.unpublishCard}},[(card.typecode === 'la')?_c('span',[_vm._v(" "+_vm._s(card.activity.summary)+" ")]):_vm._e(),(card.typecode === 'ela')?_c('span',[_vm._v(" "+_vm._s(card.activity.description)+" ")]):_vm._e()]):_c('Card',{staticClass:"animated slideInUp fast",attrs:{"index":i,"target":'_blank',"is-owner":false,"item":card,"title":card.activity.title,"image-url":card.icon !== '/ua/null' ? card.icon : card.image_url,"uuid":card.activity.uuid,"tags":card.tags,"has_tags":card.has_tags,"btn-add":(card.typecode === 'ela' ? true : false) &&
                _vm.$store.getters['user/isAuthenticated'],"btn-student-view":card.typecode === 'ela'
                ? ("/ea/view/" + (card.activity.uuid) + "/")
                : card.url,"type":card.type,"typecode":card.typecode,"url":card.typecode === 'ela'
                ? ("/ea/view/" + (card.activity.uuid) + "/")
                : card.url,"show-meta-button":true,"meta":_vm.meta(card),"csstype":"teachingstrategies"},on:{"addMyHistoriana":_vm.addMyHistoriana}},[(card.typecode === 'la')?_c('span',[_vm._v(" "+_vm._s(card.activity.summary)+" ")]):_vm._e(),(card.typecode === 'ela')?_c('span',[_vm._v(" "+_vm._s(card.activity.description)+" ")]):_vm._e()])],1)}),0)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }